import Layout from '@/layout'

export const PutInStorageManageMap = {
  //入库管理
  'ovl:storage:in': {
    component: Layout,
    name: 'PutInStorageManage',
  },
  //入库预约单
  'ovl:storage:in:form': {
    component: () => import('@/views/cargo/v0/OverseasWarehouse/PutInStorageManage/StorageReservationForm'),
    children: ['ovl:storage:in:form:detail'],
    name: 'StorageReservationForm',
  },
  'ovl:storage:in:form:detail': {
    path: '/storageReservation/detail',
    component: () => import('@/views/cargo/v0/OverseasWarehouse/PutInStorageManage/StorageReservationFormDetail'),
    name: 'StorageReservationFormDetail',
    hidden: true,
    meta: { title: 'storageReservationFormDetail' }
  },
  //入库单
  'ovl:storage:in:list': {
    component: () => import('@/views/cargo/v0/OverseasWarehouse/PutInStorageManage/GRNList'),
    children: ['ovl:storage:in:detail'],
    name: 'GRNList',
  },
  "ovl:storage:in:detail": {
    path: '/GRNDetail',
    component: () => import('@/views/cargo/v0/OverseasWarehouse/PutInStorageManage/GRNDetail'),
    name: 'GRNDetail',
    hidden: true,
    meta: { title: 'storageIn' }
  },
}
