/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

export const MemberManagementRouterMap = {
  // 会员管理
  'mem:user': {
    component: Layout,
    alwaysShow: true,
    name: 'Member'
  },
  // 会员列表
  'mem:user:list': {
    component: () => import(/* webpackChunkName: 'member' */ '@/views/member/authorization'),
    name: 'Authorization'
  },
  'mem:settle:company:list': {
    path: '/information/invoicing',
    hidden: false,
    meta: { title: '开票信息管理' },
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/invoicing'),
    name: 'InformationInvoicing',
    children: ['mem:settle:company:list:detail']
  },
  'mem:settle:company:list:detail': {
    path: '/information/invoicing/detail',
    hidden: true,
    meta: { title: '开票信息管理详情' },
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/invoicing/detail'),
    name: 'InformationInvoicingDetail'
  },
  'pnr:manager': {
    component: Layout,
    alwaysShow: true,
    name: 'Partner',
    meta: {
      icon: 'icon-user'
    }
  },
  // 客户管理
  'mem:customer:list': {
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/customer'),
    path: '/clientManager/member/customer',
    name: 'CRMCustomerList',
    meta: {
      noCache: false
    },
    children: ['mem:customer:list:add', 'mem:customer:list:edit', 'mem:customer:list:detail']
  },
  // 客户管理- 新增
  'mem:customer:list:add': {
    path: '/customer/add',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/customer/add'),
    name: 'CRMCustomerAdd',
    hidden: true,
    meta: { title: '新增客户' },
    roles: []
  },
  // 客户管理- 编辑
  'mem:customer:list:edit': {
    path: '/customer/edit',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/customer/add'),
    name: 'CRMCustomerEdit',
    hidden: true,
    meta: { title: '编辑客户' },
    roles: []
  },
  // 客户管理- 详情
  'mem:customer:list:detail': {
    path: '/customer/detail',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/customer/detail'),
    name: 'CRMCustomerDetail',
    hidden: true,
    meta: { title: '客户详情' },
    roles: []
  },
  // 客户合同
  // 'cms:customer:list': {
  //   component: () => import(/* webpackChunkName: 'member' */ '@/views/information/contract/customer'),
  //   name: 'ContractCustomerList',
  //   children: ['cms:contract:list:edit', 'cms:contract:list:editNew', 'cms:contract:list:detail', 'cms:contract:list:detailNew', 'cms:contract:list:add', 'cms:contract:list:add:child', 'cms:contract:list:addNew:child', 'cms:contract:list:addNew']
  // },
  // 客户合同管理
  'cms:spe:contract:list': {
    component: () => import('@/views/information/speContract/list'),
    name: 'SpeContractList',
    children: ['cms:spe:contract:detail']
  },
  'cms:spe:contract:detail': {
    path: '/speContract/Details',
    component: () => import('@/views/information/speContract/details'),
    name: 'SpeContractDetails',
    hidden: true,
    meta: { title: '合同详情' }
  },
  // 供应商合同(产品)
  'cms:supplier:list': {
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/contract/supplier'),
    name: 'ContractSupplierList',
    children: ['cms:contract:list:edit', 'cms:contract:list:editNew', 'cms:contract:list:detail', 'cms:contract:list:detailNew', 'cms:contract:list:add', 'cms:contract:list:add:child', 'cms:contract:list:addNew:child', 'cms:contract:list:addNew']
  },
  // 供应商合同(财务)
  'cms:supplier:finance:list': {
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/contract/finance'),
    name: 'ContractSupplierFinanceList',
    children: ['cms:contract:list:edit', 'cms:contract:list:detail', 'cms:contract:list:add', 'cms:contract:list:add:child']
  },
  // 合同管理-编辑
  'cms:contract:list:edit': {
    path: '/contract/edit',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/contract/add'),
    name: 'ContractEdit',
    hidden: true,
    meta: { title: '编辑合同' },
    roles: []
  },
  // 合同管理-编辑new
  'cms:contract:list:editNew': {
    path: '/contract/edit/new',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/contract/addNew'),
    name: 'ContractEditNew',
    hidden: true,
    meta: { title: '编辑合同' },
    roles: []
  },
  // 合同管理-详情
  'cms:contract:list:detail': {
    path: '/contract/detail',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/contract/detail'),
    name: 'ContractDetail',
    hidden: true,
    meta: { title: '合同详情' },
    roles: []
  },
  // 合同管理-详情
  'cms:contract:list:detailNew': {
    path: '/contract/detail/new',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/contract/detailNew'),
    name: 'ContractDetailNew',
    hidden: true,
    meta: { title: '合同详情' },
    roles: []
  },
  // 合同管理-新建
  'cms:contract:list:add': {
    path: '/contract/add',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/contract/add'),
    name: 'ContractAdd',
    hidden: true,
    meta: { title: '新建合同' },
    roles: []
  },
  // 合同管理-新建子合同
  'cms:contract:list:add:child': {
    path: '/contract/add/child',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/contract/add'),
    name: 'ContractAddChild',
    hidden: true,
    meta: { title: '新建子合同' },
    roles: []
  },
  // 合同管理-新建-new
  'cms:contract:list:addNew': {
    path: '/contract/addNew',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/contract/addNew'),
    name: 'ContractAddNew',
    hidden: true,
    meta: { title: '新建合同' },
    roles: []
  },
  // 合同管理-新建子合同-new
  'cms:contract:list:addNew:child': {
    path: '/contract/addNew/child',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/contract/addNew'),
    name: 'ContractAddChildNew',
    hidden: true,
    meta: { title: '新建子合同' },
    roles: []
  },
  // 供应商管理
  'scm:supplier:list': {
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/supplier'),
    name: 'CRMSupplierList',
    children: ['scm:supplier:list:add', 'scm:supplier:list:edit', 'scm:supplier:list:detail']
  },
  // 供应商管理-新建
  'scm:supplier:list:add': {
    path: '/supplier/add',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/supplier/add'),
    name: 'CRMSupplierAdd',
    hidden: true,
    meta: { title: '新增供应商' },
    roles: []
  },
  // 供应商管理-编辑
  'scm:supplier:list:edit': {
    path: 'supplier/edit',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/supplier/add'),
    name: 'CRMSupplierEdit',
    hidden: true,
    meta: { title: '编辑供应商' },
    roles: []
  },
  // 供应商管理-详情
  'scm:supplier:list:detail': {
    path: '/supplier/detail',
    component: () => import(/* webpackChunkName: 'member' */ '@/views/information/supplier/detail'),
    name: 'CRMSupplierDetail',
    hidden: true,
    meta: { title: '供应商详情' },
    roles: []
  }
}
