let toEnglish = {}
toEnglish.install = function (Vue) {
  // 扩展String方法
  // eslint-disable-next-line no-extend-native
  String.prototype.toEnglish = function () {
    let aTens = ["TWENTY", "THIRTY", "FORTY", "FIFTY", "SIXTY", "SEVENTY", "EIGHTY", "NINETY"];
    let aOnes = ["ZERO", "ONE", "TWO", "THREE", "FOUR", "FIVE", "SIX", "SEVEN", "EIGHT", "NINE",
      "TEN", "ELEVEN", "TWELVE", "THIRTEEN", "FOURTEEN", "FIFTEEN", "SIXTEEN", "SEVENTEEN", "EIGHTEEN",
      "NINETEEN"];

    function ConvertToHundreds(num) {
      let cNum, nNum;
      let cWords = "";

      num %= 1000;
      if (num > 99) {
        /* Hundreds. */
        cNum = String(num);
        nNum = Number(cNum.charAt(0));
        cWords += aOnes[nNum] + " HUNDRED";
        num %= 100;
        if (num > 0) {cWords += " AND "}
      }


      if (num > 19) {
        /* Tens. */
        cNum = String(num);
        nNum = Number(cNum.charAt(0));
        cWords += aTens[nNum - 2];
        num %= 10;
        if (num > 0) {cWords += " ";}
      }

      if (num > 0) {
        /* Ones and teens. */
        nNum = Math.floor(num);
        cWords += aOnes[nNum];
      }

      return cWords;
    }

    function ConvertToWords(num) {
      let aUnits = ["THOUSAND", "MILLION", "BILLION", "TRILLION", "QUADRILLION"];
      let cWords = num >= 1 && num < 2 ? "" : "";
      let nLeft = Math.floor(num);
      for (let i = 0; nLeft > 0; i++) {
        if (nLeft % 1000 > 0) {
          if (i !== 0) {cWords = ConvertToHundreds(nLeft) + " " + aUnits[i - 1] + " " + cWords;} else {cWords = ConvertToHundreds(nLeft) + " " + cWords;}
        }
        nLeft = Math.floor(nLeft / 1000);
      }
      num = Math.round(num * 100) % 100;
      if (num > 0) {cWords += ConvertToHundreds(num) + " CENTS ";}
// else
//  cWords += "Zero Cents";

      return cWords// + 'ONLY';
    }
    return ConvertToWords(Number(this))
  }
}
export default toEnglish
