export default {
  soNo: '提单号',
  splitOrder: '是否分单',
  declareResultEmptyInvalid: '请输入申报反馈',
  declareStatusEmptyInvalid: '请输入选择申报状态',
  reject: '驳回',
  accept: '接收',
  mainSoNo: '主提单号',
  dataEmptyInvalid: '至少选择一条数据！',
  soNoAcceptSuccess: '舱单已接收成功',
  essentialInformation: '基本信息',
  mainWithdrawalNo: '主提单号',
  podName: '目的港',
  detailedData: '明细品名及数据',
  customsWithdrawalNo: '报关分提单号',
  addSplit: '添加分单',
  deleteSplit: '删除分单',
  getBoxNumber: '获取箱号',
  addCargoDetails: '添加明细',
  sealNo: '封号',
  stuffingTyep: '整箱/拼箱',
  stuffingTyepEmptyInvalid: '装箱类型不能为空',
  wholeStuffing: '整箱',
  mixedStuffing: '拼箱',
  hsCodeEmptyInvalid: '请填写HS CODE',
  productNameChinese: '中文品名',
  productNameEnglish: '英文品名',
  productQuantity: '件数',
  productQuantityEmptyInvalid: '请填写件数',
  packagingUnit: '包装单位',
  packagingUnitEmptyInvalid: '请填写包装单位',
  grossWeightEmptyInvalid: '请填写毛重',
  volumeEmptyInvalid: '请填写体积',
  mark: '唛头',
  markEmptyInvalid: '请填写唛头',
  transceiver: '收发通信息',
  shipper: '发货人（SHIPPER）',
  countryCode: '国家代码',
  code: '代码',
  name: '名称',
  companyNamePlaceholder: '请输入发货人公司名',
  address: '地址',
  addressPlaceholder: '请输入详细地址',
  email: '邮箱',
  emailPlaceholder: '请输入联系邮箱',
  phone: '电话',
  phonePlaceholder: '请输入联系电话',
  fax: '传真',
  faxPlaceholder: '请输入传真号码',
  consignee: '收货人（CONSIGNEE）',
  specificContact: '具体联系人',
  specificContactPlaceholder: '请输入具体联系人',
  contactEmail: '联系人邮箱',
  contactEmailPlaceholder: '请输入联系邮箱',
  contactTelephone: '联系人电话',
  contactTelephonePlaceholder: '请输入具体联系人电话',
  notifier: '通知人（NOTIFIER）',
  copyConsignee: '复制收货人信息',
  boxNumberSelection: '箱号选择',
  whetherCommissionPlaceholder: '请选择',
  containerNoError: '箱号格式不正确'
}
