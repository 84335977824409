/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

export const CustomerManagerRouterMap = {
  // 客户管理
  'ovl:overseas:manager': {
    component: Layout,
    alwaysShow: true,
    name: 'customerManager'
  },
  // 客户列表
  'ovl:customer:list': {
    component: () => import('@/views/cargo/v0/OverseasWarehouse/OverseasManage/customerList.vue'), // Parent router-view
    name: 'OvlCustomerList'
  },
  'ovl:fba:warehouse:list': {
    component: () => import('@/views/cargo/v0/OverseasWarehouse/OverseasManage/FBAWarehouseList.vue'), // Parent router-view
    name: 'FBAWarehouseList'
  },
  'ovl:interface:log': {
    component: () => import('@/views/cargo/v0/OverseasWarehouse/OverseasManage/InterfaceLog.vue'), // Parent router-view
    name: 'InterfaceLog'
  }
}

