export default {
  all: '全部',
  nullText: '无',
  typeText: '类型',
  importBtn: '导入',
  uploadText: '上传',
  multipleUpload: '批量上传',
  bathExport: '批量导出',
  uploadBtn: '上传',
  createBtn: '新建',
  saveBtn: '保存',
  close: '关闭',
  confirmBtn: '确定',
  confirm: '确定',
  cancelBtn: '取消',
  cancel: '取消',
  submit: '提交',
  submitBtn: '提交',
  returnBtn: '返回',
  handler: '处理',
  viewBtn: '查看',
  addBtn: '新增',
  previewBtn: '预览',
  designBtn: '设计',
  auditBtn: '审核',
  editBtn: '编辑',
  suspension: '中止',
  modifyBtn: '修改',
  mention: '提示',
  updateBtn: '更新',
  refreshBtn: '刷新',
  disableBtn: '停用',
  enableBtn: '启用',
  exportBtn: '导出',
  deleteBtn: '删除',
  removeBtn: '移除',
  stopBtn: '结束',
  copyBtn: '复制',
  clearBtn: '清空',
  searchBtn: '搜索',
  resetBtn: '重置',
  successText: '成功',
  failedText: '失败',
  nextStep: '下一步',
  previousStep: '上一步',
  trueText: '是',
  falseText: '否',
  draft: '草稿',
  unpublished: '下架',
  published: '已上架',
  publishBtn: '发布',
  undetermined: '待定',
  download: '下载',
  closed: '关闭',
  opened: '开放',
  quantity: '数量',
  deleteSuccess: '删除成功',
  cancelSuccess: '取消成功',
  limitJPGPNGInvalid: '只能上传jpg/png文件',
  limitJPGPNG5M: '只能上传jpg/png文件，单个文件不超过5MB',
  limitSizeCommon: '文件尺寸过大',
  limitAllowUpload: '最多上传',
  imagesQuantity: '个图片',
  clickHere: '点击',
  placeholder: '请输入',
  selectPlaceholder: '请选择',
  operation: '操作',
  datepickerSeparator: '至',
  tips: '提示',
  saveTips: '请先保存当前操作',
  pageNotFound: '您所访问的资源不存在',
  deleteWarnningTips: '删除后数据将无法恢复。是否确认删除?',
  deleteChargeWarnningTips: "提单已进行计费，删除提单会删除提单相关的费用且删除后数据将无法恢复，是否继续？",
  cancelWarnningTips: '取消后数据将无法恢复。是否确认取消?',
  dropEditBtn: '放弃修改',
  warnningText: '警告',
  copyright: 'Copyright © 2015-2021 深圳海特全球供应链管理有限公司 粤ICP备14062894号',
  privacyLinkText: '海通智运用户服务协议',
  memberCenter: '会员中心',
  switchRoute: '切换',
  passwordDesc: '互联网账号存在被盗风险，建议您定期更改密码以保护账户安全。',
  authorizeDesc: '实名认证区分个人和企业，请根据自身情况选择，认证通过后不允许修改。',
  chooseFile: '选择文件',
  fiveCharCodePlaceholder: '请输入五字编码',
  fiveCharCode: '五字码',
  threeCharCode: '三字码',
  countries: '国家',
  countriesCode: '名称/代码',
  currentStatus: '当前状态',
  changeStatus: '变更状态',
  remark: '备注',
  email: '邮箱',
  siteMessage: '站内信',
  issue: '出单',
  issueDate: '签发时间',
  waitForVerify: '待审核',
  beConfirmed: '待确认',
  holdReleased: '待放舱',
  pending: '待处理',
  boxed: '已装箱',
  verified: '已审核',
  rejected: '已驳回',
  passed: '已通过',
  cabinReleased: '已放舱',
  verifyFailed: '已取消',
  reasonOfRejected: '驳回理由',
  accepted: '已接收',
  matching: '匹配中',
  matched: '匹配成功',
  submited: '已提交',
  declared: '已申报',
  deleted: '已删除',
  signed: '已签发',
  signedBy: '签发人',
  chineseName: '中文名称',
  englishName: '英文名称',
  noticeText: '注意',
  publishedSuccess: '发布成功',
  totalAmount: '总计',
  ticket: '票',
  unSign: '未签发',
  print: '打印',
  batchPrint: '批量打印',
  importData: '数据导入',
  importSuccess: '导入成功',
  chooseTemplate: '模板选择',
  templateEmptyError: '请选择模板',
  dataEmptyTips: '请先选择数据',
  printTemplate: '打印模板',
  showHundredData: '下表仅显示前100条满足检索条件的记录',
  percentText: '占',
  unitText: '单位',
  keyWordEmptyError: '请输入关键词',
  endDate: '结束日期',
  salectDate: '选择日期',
  startDate: '开始日期',
  deleteTips: '删除数据不可恢复，是否确定？',
  upload: {
    uploadTitle: '将文件拖到此处，或',
    uploadPDF: '支持扩展名：.pdf',
    uploadXlsx: '支持扩展名：.xlsx',
    downloadTemplate: '模板下载',
    uploadClickTitle: '点击上传',
    uploadSubTitle: '支持扩展名：.jpg .png',
    imgTypeError: '只能上传 JPG 或 PNG 格式!',
    imgSizeError: '图片大小不能超过 50MB!',
    pleaseUploadFile: '请上传文件',
    imgSizeError5MB: '图片大小不能超过 5MB!',
    imgSizeErrorMax: '文件超出最大限制!',
    uploadSubTip: '支持扩展名：.jpg .png .jpeg',
    uploadSubTipEx: '支持扩展名：.xlsx',
    imgTypeErrTip: '只能上传 JPG 或 PNG 或 JPEG格式!',
    uploadLimitTip: '文件上传个数超出限制',
    uploadFile: '上传文件',
    uploadFileUnMatchedType: "文件格式不匹配!"
  },
  navbar: {
    screenfull: '全屏',
    dashboard: '首页',
    github: '项目地址',
    theme: '换肤',
    notification: '通知',
    taskList: '任务',
    messageCenter: '消息中心',
    accountInfo: '个人中心',
    hideFixed: '收起导航',
    showFixed: '展开导航',
    viewMore: '查看更多',
    messageEmpty: '暂无未读消息',
    taskEmpty: '暂无待处理任务',
    changePassword: '修改密码',
    subscribeConfig: '订阅配置',
    messageSender: '消息接收人管理',
    logOut: '退出登录',
    setFavoriteSuccess: '操作成功',
    setFavoriteFailed: '操作失败',
    menuListEmpty: '暂未开放',
    BIReport: 'BI报表',
    leftMenu: {
      favoriteMenu: '常用菜单',
      lastestMenu: '最近使用'
    }
  },
  footer: {},
  backList: '返回列表',
  refuse: '通过 ',
  reject: '拒绝',
  rejectBill: '驳回',
  save: '保存',
  saveSuccess: '保存成功',
  auditSuccess: '审核成功',
  uploadSuccess: '更新成功',
  customerName: '客户名称',
  theKnow: '知道了',
  delay: '异步处理，可能会有一定延迟',
  failedReason: '失败原因',
  validateObj: {
    emailFormatInvalid: '邮箱格式不正确',
    emailEmptyInvalid: '邮箱不能为空',
    mobileFormatInvalid: '手机号码格式错误',
    mobileEmptyInvalid: '手机号码不能为空',
    telephoneFormatInvalid: '电话号码格式错误',
    telephoneEmptyInvalid: '电话号码不能为空',
    plateNoErr: '请正确填写车牌号'
  },
  priceCardType: {
    ALL: '全部',
    COST: '成本价卡',
    GUIDE: '指导卖价',
    LOWER: '最低卖价',
    CUSTOMER: '客户价卡'
  },
  unitCategoryType: {
    METRIC: '公制 （米、千克）',
    IMPERIAL: '英制（英尺、磅）'
  },
  handlerTime: '可能需要一定的处理时间',
  handlerRes: '完成后会有系统通知。请前往任务列表查看结果',
  handlerExportTips: '导出操作的耗时与数量的大小成正比',
  handlerImportTips: '导入操作的耗时与数量的大小成正比',
  copyrightFront: 'Copyright © 2015-',
  copyrightEnd: ' 深圳海特全球供应链管理有限公司',
  copyrightLink: ' 沪ICP备14054451号-8',
  cannot_be_null: '不能为空',
  USD: 'USD',
  CNY: 'CNY',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  JPY: 'JPY',
  AUD: 'AUD',
  BRL: 'BRL',
  MAN: 'MAN',
  VND: 'VND',
  please_select_one_data: '请先选择一行数据',
  submitSuccess: '提交成功'
}
