<template>
  <div :class="{'has-logo':showLogo}">
    <!--<logo v-if="showLogo" :collapse="isCollapse" />-->
    <!-- <svg-icon icon-class="cargoware-logo2" style="width:180px;height:50px;" /> -->
    <div class="logo-container" v-show="!isCollapse">
      <img src="@/assets/logo-yht.png">
      <p>SPE LINER</p>
    </div>
    <!-- <span style="width: 180px;height: 50px;color: #bfcbd9;font-size: 14px;text-align: center;line-height: 50px;display: inline-block;background: #304156">Cargo</span> -->
    <el-scrollbar wrap-class="scrollbar-wrapper" :class="['left-menu-scroll-wrap', {'opend-menu': !isCollapse}]">
      <el-menu :default-active="$route.path" :collapse="isCollapse" :background-color="variables.menuBg" :text-color="variables.menuText" :active-text-color="variables.menuActiveText" :collapse-transition="false" :unique-opened="true" mode="vertical">
        <sidebar-item v-for="route in getPermissionRoutes()" :key="route.path" :item="route" :base-path="route.path" :pending-nums="pendingNums" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import {getFlwContractCountApi, getFlwContractReminderNumApi} from "@/api/contractPrice/flwContract";

export default {
	components: { SidebarItem, Logo },
	computed: {
		...mapGetters([
			'permission_routes',
			'sidebar'
		]),
		showLogo() {
			return this.$store.state.settings.sidebarLogo
		},
		variables() {
			return variables
		},
		isCollapse() {
			return !this.sidebar.opened
		}
	},
  data() {
    return {
      pendingNums: 99
    }
  },
	methods: {
		getPermissionRoutes() {
			let routesArr = this.permission_routes
			let visbleRoutesArr = []
			routesArr.map(route => {
				if (route.children && route.children.length > 0) {
					let visbleRoute = route.children.every(child => child.hidden === true)
					if (!visbleRoute) {
						visbleRoutesArr.push(route)
					}
				} else {
					if (route.alwaysShow && !route.hidden) {
						visbleRoutesArr.push(route)
					}
				}
			})

			return visbleRoutesArr
		},
    async getStatistics() {
      const res = await getFlwContractReminderNumApi()
      if (res.ok) {
        this.pendingNums = res.content
      }
    }
	},
  created() {
    this.getStatistics()
  }
}
</script>
<style lang="scss" scoped>
.logo-container {
	width: 180px;
	height: 100px;
	// background: #304156;
	background: #fff;
	color: #97a8be;
	font-weight: bold;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	border-bottom: 0.5px solid #3a5169;
	box-shadow: 2px 0 0 3px #3a5169;
}
.logo-container img{
  height: 60px;
}
.logo-container p{
  font-size: 16px;
  margin: 0;
  margin-top: 5px;
  color:  #304156;
}
.logo-container .title {
	font-size: 18px;
	display: inline-block;
	margin-left: 8px;
}
.left-menu-scroll-wrap {
	height: 100%;
	&.opend-menu {
		height:calc(100% - 100px)!important;
	}
}
</style>
