var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      staticClass: "cargo-advance-select",
      style: { position: "relative" },
      attrs: {
        "remote-method": _vm.remoteMethod,
        loading: _vm.loading,
        placeholder: _vm.placeholder,
        clearable: _vm.clearable,
        multiple: _vm.multiple,
        "allow-create": _vm.allowCreate,
        filterable: "",
        remote: "",
        "reserve-keyword": "",
        size: _vm.size,
        disabled: _vm.disabled,
        "value-key": "id",
      },
      on: { change: _vm.change },
      model: {
        value: _vm.modelValue,
        callback: function ($$v) {
          _vm.modelValue = $$v
        },
        expression: "modelValue",
      },
    },
    [
      _vm.tips && _vm.options.length
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "tip-count-options w-full view-content" },
                [_vm._v("下表仅显示前100条满足检索条件的记录")]
              ),
              _vm._v(" "),
              _c(
                "el-option",
                {
                  key: "header",
                  staticClass: "w-full view-content",
                  attrs: { disabled: true, value: "header" },
                },
                [
                  _vm._t(
                    "default",
                    _vm._l(_vm.headers, function (field) {
                      return _c("div", { key: field, staticClass: "item" }, [
                        _vm._v(_vm._s(field)),
                      ])
                    })
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.options, function (item) {
        return _c(
          "el-option",
          {
            key: _vm.multiple ? item[_vm.valueKey] : item[_vm.valueKey],
            class: _vm.tips ? "w-full view-content" : "",
            attrs: {
              disabled: _vm.multiple
                ? _vm.disabledKey.indexOf(item[_vm.valueKey]) > -1
                : _vm.disabledKey
                ? item[_vm.disabledKey]
                : false,
              label: item[_vm.labelKey],
              value: item[_vm.valueKey],
            },
          },
          [
            _vm._t("option", null, { row: item }),
            _vm._v(" "),
            _vm._t(
              "default",
              _vm._l(_vm.fields, function (field) {
                return _c("div", { key: field, staticClass: "item" }, [
                  _vm._v(_vm._s(item[field])),
                ])
              })
            ),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }