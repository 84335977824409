import request from '@/utils/request'


// 目的港管理-资料列表
export function getCasDestinationMaterialPagesApi(params) {
  return request({
    url: `/api/cas-service/cas/destination/material/pages`,
    method: 'GET',
    params
  })
}

// 目的港管理-已推送查询列表
export function getCasDestinationMaterialPushPagesApi(data) {
  return request({
    url: `/api/cas-service/cas/destination/material/push/pages`,
    method: 'post',
    data
  })
}

// 查询船名航次所有可签发提醒的起运港
export function getCasDestinationIssueVesselVoyagePolApi(params) {
  return request({
    url: `/api/cas-service/cas/destination/issue/vesselVoyagePol`,
    method: 'GET',
    params
  })
}

// 查询船名航次和起运港所有可签发的提单列表
export function getCasDestinationIssueVesselPolJobBlApi(params) {
  return request({
    url: `/api/cas-service/cas/destination/issue/polJobBl`,
    method: 'GET',
    params
  })
}

// 确认签发
export function postCasDestinationIssueApi(data) {
  return request({
    url: `/api/cas-service/cas/destination/issue/issue`,
    method: 'post',
    data
  })
}

// 查询船名航次所有可用的目的港列表
export function getCasDestinationMaterialVesselVoyagePodApi(params) {
  return request({
    url: `/api/cas-service/cas/destination/material/vesselVoyagePod`,
    method: 'GET',
    params
  })
}

// 确认生成
export function postCasDestinationMaterialGenerateApi(data) {
  return request({
    url: `/api/cas-service/cas/destination/material/generate`,
    method: 'post',
    data
  })
}

// 批量确认推送 （多个或单个）
export function postCasDestinationMaterialPushApi(data) {
  return request({
    url: `/api/cas-service/cas/destination/material/push`,
    method: 'put',
    data
  })
}

// 确认推送 （多个）
export function postCasDestinationMaterialRePushApi(data) {
  return request({
    url: `/api/cas-service/cas/destination/material/rePush`,
    method: 'put',
    data
  })
}


// 查询船名航次所有可用的目的港列表
export function getCasDestinationMaterialDetailApi(id) {
  return request({
    url: `/api/cas-service/cas/destination/material/detail/${id}`,
    method: 'GET'
  })
}

// 删除生成的资料类型
export function delCasDestinationMaterialDeletefileApi(params) {
  return request({
    url: `/api/cas-service/cas/destination/material/deletefile`,
    method: 'delete',
    params
  })
}

// 重新生成资料
export function postCasDestinationMaterialReGenerateApi(data) {
  return request({
    url: `/api/cas-service/cas/destination/material/reGenerate`,
    method: 'put',
    data
  })
}

// 附件全量更新
export function postCasDestinationMaterialCoverAttachmentApi(data) {
  return request({
    url: `/api/cas-service/cas/destination/material/coverAttachment`,
    method: 'put',
    data
  })
}

// 目的港端-改单管理

// 根据提单号模糊查询可申请改单的提单列表
export function getCasJobBlListByBlNoApi(params) {
  return request({
    url: `/api/cas-service/omc/cas/job/bl/casJobBlListByBlNo`,
    method: 'GET',
    params
  })
}

// 获取提单详情
export function getCasJobBlJobApiBaseBlApi(id) {
  return request({
    url: `/api/cas-service/cas/bl/job/api/baseBl/${id}`,
    method: 'GET'
  })
}


// 列表
export function getCemEntityApplyPagesApi(params) {
  return request({
    url: `/api/cem-service/cem/entity/apply/pages`,
    method: 'GET',
    params
  })
}

// 查询改单详情
export function getCemEntityApplyDetailApi(id) {
  return request({
    url: `/api/cem-service/cem/entity/apply/detail/${id}`,
    method: 'GET'
  })
}

// 所有提单修改字段枚举
export function getCemEntityApplyBlEntityFieldApi(params) {
  return request({
    url: `/api/cem-service/cem/entity/apply/blUpdateEntityField`,
    method: 'get',
    params
  })
}

// 新增改单申请第一步信息
export function postCemEntityApplyInsertApi(data) {
  return request({
    url: `/api/cem-service/cem/entity/apply`,
    method: 'post',
    data
  })
}

// 修改改单申请信息
export function putCemEntityApplyUpdateApplyApi(data) {
  return request({
    url: `/api/cem-service/cem/entity/apply/updateApply/${data.id}`,
    method: 'put',
    data
  })
}

// 提交修改的内容
export function putCemEntityApplyUpdateApi(data) {
  return request({
    url: `/api/cem-service/cem/entity/apply/update/${data.id}`,
    method: 'put',
    data
  })
}

// 改单记录
export function getCemEntityApplyLogLogsApi(id) {
  return request({
    url: `/api/cem-service/cem/entity/apply/log/logs/${id}`,
    method: 'GET'
  })
}

// 审核通过
export function cemEntityApplyPassAuditApi(data) {
  return request({
    url: `/api/cem-service/cem/entity/apply/approve/${data.id}`,
    method: 'put',
    data
  })
}

// 驳回
export function cemEntityApplyRejectApi(data) {
  return request({
    url: `/api/cem-service/cem/entity/apply/reject/${data.id}`,
    method: 'put',
    data
  })
}

// 回收件上传
export function cemRecycleUploadApi(data) {
  return request({
    url: `/api/cem-service/cem/entity/apply/recycleUpload/${data.id}`,
    method: 'put',
    data
  })
}

// 目的港确认
export function podEmailConfirmApi(data) {
  return request({
    url: `/api/cem-service/cem/entity/apply/desConfirm/${data.id}`,
    method: 'put',
    data
  })
}

// 退回
export function returnBackApi(data) {
  return request({
    url: `/api/cem-service/cem/entity/apply/returned/${data.id}`,
    method: 'put',
    data
  })
}

// 回执确认
export function attachmentConfrimApi(id) {
  return request({
    url: `/api/cem-service/cem/entity/apply/recoveryConfirm/${id}`,
    method: 'put'
  })
}

// 改单资料重新推送
export function repushAttachementApi(data) {
  return request({
    url: `api/cem-service/cem/entity/apply/generateByBl`,
    method: 'post',
    data
  })
}

// 箱信息 - 可以新增的箱子
export function getContainerToAddApi(params) {
  return request({
    url: `/api/cas-service/omc/cas/order/container/cemEntity/containerList`,
    method: 'get',
    params
  })
}

// 像信息 - 获取新增箱子及详情数据
export function getContainerAddedDetailApi(params) {
  return request({
    url: `/api/cas-service/omc/cas/order/container/cargoDetail`,
    method: 'get',
    params
  })
}

// 作废申请
export function dropApplyApi(id) {
  return request({
    url: `/api/cem-service/cem/entity/apply/cancel/${id}`,
    method: 'put'
  })
}
// 改单预览
export function getApplyMergePrintApi(id) {
  return request({
    url: `/api/cem-service/cem/entity/apply/mergePrint/${id}`,
    method: 'get'
  })
}
