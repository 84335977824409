export default {
  importExcel: '导入Excel',
  downloadTemplete: '下载模板',
  batchImport: '批量导入',
  importCredentials: '导入箱证',
  exportCredentials: '导出箱证',
  supportiveExte: '支持扩展名: .xls, .xlsx',
  known: '知道了',
  money: '金额',
  currency: '币种',
  createText: '新增',
  orderType: '订单类型',
  orderNo: '订单号',
  contractNo: '合同号',
  newContainer: '新购',
  oldContainer: '旧箱',
  rent: '租入箱',
  financing: '融资租赁',
  typeAndAmount: '箱型箱量',
  status: '状态',
  containerNo: '箱号',
  containerNumber: '集装箱号',
  typeOf: '类别',
  importTitle: '集装箱信息导入',
  packingInfoImport: '装箱信息导入',
  ownerName: '箱属',
  productionDate: '生产日期',
  containerType: '箱型',
  containerSituation: '箱况',
  currSituation: '当前箱况',
  dealContainerSituation: '交箱箱况',
  deliveryPlace: '交箱地',
  deliveryDate: '交箱日期',
  redeliveryPlace: '还箱地',
  redeliveryDate: '还箱日期',
  remark: '备注',
  tareWeight: '皮重(KG)',
  payload: '有效负载(KG)',
  capacity: '容量(m³)',
  confirmBtn: '确认提交',
  lessor: '出租方',
  Lessee: '承租方',
  leaseStartDate: '起租日期',
  submitOrderSuccess: '保存订单信息成功',
  containerTypeInvalid: '请正确选择箱型',
  contractAndBatch: '合约/批次',
  situationImage: '箱况图片',
  nameplateImage: '铭牌图片',
  containerExisted: '箱号已存在',
  containerNoInvalid: '不是有效箱号',
  containerEmptyInvalid: '请输入箱号',
  productionDateInvalid: '请选择生产日期',
  orderNoEmptyInvalid: '订单号不能为空',
  deliveryPlaceInvalid: '交箱地不能为空',
  deliveryDateInvalid: '请选择交箱日期',
  viewContainerInfo: '查看集装箱信息',
  modifyContainerInfo: '修改集装箱信息',
  createContainerInfo: '新建集装箱信息',
  importVirtualNo: '导入虚拟箱号',
  uploadDuplicateWarnning: '请勿重复上传数据',
  operationYard: '发生设施',
  opreatorTime: '发生时间',
  operationCode: '发生动作',
  operationTime: '发生时间',
  outsideOperationTime: '出场时间',
  operationPort: '发生港口',
  destinationPort: '目的港',
  vesselName: '船名',
  voyageNo: '航次',
  vesselOwner: '船东',
  modifyDynamic: '修改集装箱动态',
  opreatorTimeEmptyInvalid: '发生时间不能为空',
  opreatorTimeErrorInvalid: '发生时间填写错误',
  modifyDynamicSuccess: '修改动态成功',
  gpFQuantity: '20GP',
  hcFQuantity: '40HC',
  rhFQuantity: '40RH',
  operationYardCode: '设施编码',
  operationYardName: '设施名称',
  operationYardPortName: '所在港口',
  totalAmount: '集装箱总数',
  containerTypeQty: '集装箱统计',
  port: '港口',
  arriveTime: '进场时间',
  leaveTime: '出场时间',
  insideYardDays: '在场天数',
  insidePortDays: '在港天数',
  outsideDays: '场外天数',
  warehouseName: '仓库名称',
  operationEmptyInvalid: '请选择对应的操作',
  operationPortEmptyInvalid: '请选择发生港口',
  operationYardEmptyInvalid: '请选择发生设施',
  nextYardEmptyInvalid: '请选择下一设施',
  fullAndEmptyInvalid: '请选择箱空重',
  businessTypeEmptyInvalid: '请选择业务类型',
  vesselNameEmptyInvalid: '请填写船名',
  voyageNoEmptyInvalid: '请填写航次',
  soNoEmptyInvalid: '请填写订舱号',
  onYardText: '在堆场',
  onPortText: '在码头（港口）',
  dynamicUpdateSuccess: '箱动态更新成功',
  containerRequireInfo: '集装箱需求信息',
  containerTypeAmount: '箱型数量',
  releasedContainerInfo: '放箱信息',
  portName: '装货港',
  batchNo: '批次',
  exportSelectedError: '请勾选要导出的数据',
  releaseBox: '定制运力放箱',
  releaseSOCBox: 'SOC放箱',
  tenFoldersLimited: '一次最多上传10个文件',
  tareWeightInvalid: '请填写皮重',
  teuInvalid: '请填写TEU',
  supplierCode: '箱主代码',
  statusList: {
    using: '在用',
    broken: '全损',
    loaned: '已借出',
    returned: '已退租',
    sale: '已卖出',
    washing: '清洗中'
  },
  categoryList: {
    owner: '新购',
    lease: '租入',
    financing: '融资租赁'
  },
  orderList: {
    rent: '租入',
    ownerName: '箱东',
    batchNo: '批次号',
    quantityAndAmount: '总数',
    deliveredContainerQuantity: '已交箱数量',
    memberName: '交易客户',
    invalid: '失效',
    valid: '生效',
    createdTime: '创建时间',
    expectTime: '预计交箱时间',
    statusList: ['草稿', '生效', '完成', '失效'],
    finishedBtn: '完成'
  },
  orderDetail: {
    orderNo: '订单号',
    minimumSalvageRate: '最小残值',
    purchasePrice: '采购价(美元/箱)',
    takeover: '交箱点',
    loanPlace: '退租点',
    rentSituation: '起租箱况',
    loanSituation: '退租箱况',
    rentalPerDay: '日租金(美元)',
    tieredPrice: '阶梯价格',
    editTieredPrice: '编辑',
    brokenPrice: '全损金额(美元)',
    containerAges: '箱龄要求(年)',
    depreciationRate: '折旧费率',
    buyoutPrice: 'BUY OUT 金额(美元)',
    endDate: '失效日期',
    startDate: '生效日期',
    leaseRange: '租期(天)',
    financingRange: '融资租赁时长(天)',
    lessThanOneMonth: '小于一个月',
    expectTime: '预计交箱时间',
    containerInfo: '集装箱信息',
    arrivedContainerAmount: '已交箱总数',
    arrivedTypeAndAmount: '已交箱型箱量',
    stepRate: '集装箱阶梯费率',
    createContainerType: '添加箱型',
    quantityInvalid: '请填写数量',
    containerTypeRepeatInvalid: '不能重复添加箱型',
    dropWarnningTips: '此操作将不会保留当前修改, 是否继续?',
    infoEmptyInvalid: '集装箱信息不能为空',
    timeRange: '时间范围',
    rate: '费率',
    startRangeInvalid: '请填写开始范围',
    endRangeInvalid: '请填写结束范围',
    rateInvalid: '请填写费率',
    containerRepeatInvalid: '同一箱型的时间范围不能重叠',
    rateEmptyInvalid: '集装箱费率信息不能为空',
    rateRangeInvalid: '费率信息范围填写错误',
    createOrder: '新增订单',
    client: '客户',
    orderTypeInvalid: '请选择订单类型',
    orderNoInvalid: '请输入订单号',
    contractNoInvalid: '请输入合约号',
    currencyInvalid: '请选择币种',
    ownerInvalid: '请正确填写箱东',
    contractInvalid: '请选择合同对象',
    startDateInvalid: '请选择起租日期',
    endDateInvalid: '请选择结束日期',
    expectDateInvalid: '请选择预计交箱时间',
    dateRangeInvalid: '请输入时长',
    editOrder: '编辑订单',
    viewOrder: '查看订单',
    saveAsDraft: '保存草稿',
    arrivedContainerInfo: '已交集装箱信息',
    actualDate: '实际交箱时间',
    actualCode: '实际交箱地代码',
    actualName: '实际交箱地名称',
    viewDynamic: '查看动态',
    deleteListDataTips: '是否确认删除当前数据?',
    finishedOrderTips: '是否确认完成订单?',
    operationSuccess: '操作成功',
    noneValideWarning: '没有数据录入，将不会生成草稿订单。',
    takeoverInvalid: '请选择交箱点',
    rentalPerDayInvalid: '请填写日租金',
    brokenPriceInvalid: '请填写全损金额',
    rentSituationInvalid: '请选择起租箱况',
    loanSituationInvalid: '请选择退租箱况',
    createBtn: '新增',
    tieredPriceTitle: '日租金维护',
    dialogAgeLabel: '租龄',
    dialogPriceLabel: '价格(美元)',
    dialogRangeUnit: '天',
    beginDaysInvalid: '请填写区间起始天数',
    endDaysInvalid: '请填写区间结束天数',
    daysRangeInvalid: '起始/结束区间天数错误',
    priceInvalid: '请填写区间价格',
    lastRangeInvalid: '缺少最终区间及价格'
  },
  infoList: {
    containerType: '箱型',
    containerCategory: '类别',
    selectStatus: '状态',
    situation: '箱况',
    AVContainer: 'AV',
    DMContainer: 'DM',
    credentials: '是否有证书',
    inportTitle: '箱证导入',
    importInfoListTemplet: '集装箱信息导入模板.xlsx',
    importCredentialsTemplet: '箱证导入模板.xlsx',
    inportPDFBtn: '导入PDF',
    extePDF: '支持扩展名：.pdf',
    credentialsLabel: '箱证文件',
    exportTitle: '箱证导出',
    exportCredentialsTemplate: '箱证导出模板.xlsx',
    matchLabel: '匹配关系',
    importTemplet: '新箱登记模板.xlsx',
    containerAge: '箱龄(天)',
    remainingTimeDay: '租入天数(天)',
    leaseTime: '租期(天)',
    productionDate: '生产时间',
    startTime: '起租时间',
    leaseEndTime: '租约结束时间',
    isEnabled: '是否可用',
    materialFlag: '是否物料箱',
    enabled: '是',
    disabled: '否',
    costPrice: '初始价值',
    salvage: '残值',
    contractNo: '合同号',
    contractNoUnexsit: '合同号不存在',
    contractNoInvalid: '请填写合同号',
    batchNo: '批号',
    ISONo: 'ISO码',
    teu: 'TEU',
    ownerIdInvalid: '请绑定箱东信息',
    containerSituationInvalid: '请选择箱况',
    batchNoInvalid: '请填写批次号',
    useContainerInfo: '用箱人',
    upgradeTitle: '箱况变更',
    upgradeBtn: '箱况变更',
    upgradeSelectedError: '至少选择一条记录',
    upgradeTemplete: '箱况变更信息导入.xlsx',
    upgradeFileSaveSuccess: '操作成功',
    maxPayload: 'Max Payload(KG)',
    maxPayloadInvalid: '请填写Max Payload',
    temporaryImport: '临时导入',
    temporaryFileName: '集装箱信息临时导入模板.xlsx',
    importMaterial: '导入物料箱',
    materialFileName: '集装箱物料箱变更模版.xlsx',
    cardInfoInvalid: '请先上传箱证文件',
    importMaterialSuccess: '导入物料箱成功'
  },
  uploadDialog: {
    importImage: '集装箱图片导入'
  },
  dynamicList: {
    entryBtn: '补录',
    batchUpdate: '批量补录',
    containerTypeLong: '集装箱箱型',
    fullOrEmpty: '空重',
    soNo: '订舱号',
    soMixNo: '订舱号/调运编号',
    blNo: '提单号',
    businessType: '业务类型',
    activeFlag: '最新状态',
    dynamicImportUpdate: '集装箱动态批量补录',
    dynamicImportUpdateTemplet: '集装箱动态批量补录模板.xlsx',
    byShip: '包船',
    railway: '铁路',
    oneWay: 'ONEWAY',
    outShip: '外配海船',
    shoutOut: '退关',
    multipleSelectorEmpty: '请至少选择一条记录',
    dynamicNonLatest: '的数据不是最新动态',
    unremoveable: '无法删除',
    fullPlusEmpty: '空/重',
    fullContainer: '重箱',
    emptyContainer: '空箱',
    nextYard: '下一设施',
    startPort: '起运港',
    diffSoNoTips: '前后动态订单号不一致！',
    uploadTplBtn: '海特模板导入',
    dynamicTemplateFileName: '海特模板导入.xlsx',
    importRent: '临时退租导入',
    importRentTitle: '退租导入',
    rentFile: '退租集装箱动态批量补录模板.xlsx',
    situationInvalid: '请选择箱况',
    logSource: '来源',
    logSourceEDI: 'EDI',
    logSourceImport: '导入'
  },
  leaseList: {
    throwLease: '退租',
    importThrowLease: '导入退租',
    importVirtualTemplet: '虚拟箱号导入模板.xlsx',
    loanTemplet: '集装箱借出登记模板.xlsx',
    leaseTemplet: '租箱登记模板.xlsx',
    importThrowLeaseTemplet: '退租导入模板.xlsx',
    lesseePerson: '承租人',
    startTime: '起租时间',
    timeRange: '租约期限',
    expiredTime: '到期时间',
    leftTime: '剩余时长',
    deliveryTime: '交箱时间',
    borrowedDays: '已借天数',
    loanDays: '已租天数',
    tenantName: '租借客户',
    contractDays: '租约时长',
    containerTypeEmptyInvalid: '请选择箱型'
  },
  fileUpload: {
    importImages: '导入图片',
    exteJPGPNGJPEG: '支持扩展名：.jpg .png .jpeg',
    limitJPGPNGJPEG: '只能上传jpeg/jpg/png类型的图片',
    limitSizeOfImage5M: '图片大小不能超过5M',
    folderLevelErr: '上传文件的目录层级错误'
  },
  returnDialog: {
    importExcelReturnTitle: 'Excel导入退租',
    returnLoan: '确定要将借出的集装箱退租?',
    returnLease: '确定要将租入的集装箱退租?'
  },
  leaseDetail: {
    endDate: '结束日期',
    viewLeaseDetail: '查看租入登记',
    modifyLeaseDetail: '修改租入登记',
    createLeaseDetail: '新建租入登记'
  },
  loanDetail: {
    endDate: '结束日期',
    containerDisabled: '箱号已停用',
    containerNoExist: '箱号不存在',
    viewLoanDetail: '查看借出登记',
    modifyLoanDetail: '修改借出登记',
    createLoanDetail: '新建借出登记'
  },
  insideYardList: {
    avQuantity: 'AV箱总数',
    dmQuantity: 'DM箱总数'
  },
  onBoatList: {
    originalPort: '始发港',
    gp20GPE: '20GP-E',
    gp20GPF: '20GP-F',
    gp40HCE: '40HC-E',
    gp40HCF: '40HC-F',
    gp40RHE: '40RH-E',
    gp40RHF: '40RH-F',
    fullContainer: '重箱统计',
    emptyContainer: '空箱统计'
  },
  containerStatistics: {
    yardType: '类型',
    insideYardList: '在场',
    insidePortList: '在港',
    outsideYardList: '场外',
    onBoardList: '在船',
    summeryList: '汇总',
    vessel: '船名',
    voyage: '航次',
    pol: '起运港',
    pod: '目的港',
    yardCode: '设施编码',
    fullOrEmpty: '空/重',
    containerStatus: '箱况',
    portCode: '所在港口',
    quantity: '集装箱总数',
    fullOrEmptyList: ['空', '重'],
    containerStatusList: ['AV', 'DM'],
    insideYardDetail: '在场集装箱详情',
    insidePortDetail: '在港集装箱详情',
    outsideDetail: '场外集装箱详情',
    onBoardDetail: '在船集装箱详情',
    AVAmount: 'AV箱总数',
    DMAmount: 'DM箱总数',
    materialAmount: '物料箱总数',
    materialFlag: '是否物料箱'
  },
  dynamicDetail: {
    insideDetail: '在场集装箱详情',
    outsideDetail: '场外集装箱详情',
    logContainerSituationAV: 'AV',
    logContainerSituationDM: 'DM',
    soNoInvalidErr: '订舱号不存在！'
  },
  releaseList: {
    unReleased: '未放',
    releasedPartOf: '部分放',
    releasedWhole: '全放',
    overReleased: '超放',
    SOCNormal: '正常',
    SOCCancel: '已取消',
    SOCRejected: '已退关',
    releasedAndRequire: '已放箱量/需求量',
    requireContainerTypeQuantity: '需求箱型箱量',
    releasedContainerTypeQuantity: '已放箱型箱量',
    usedContainerTypeQuantity: '包船放箱量',
    opreationTime: '操作时间',
    releasedBtn: '放箱',
    sailSchedule: '船期',
    yardCode: '堆场代码',
    releaseOrder: '放箱顺序'
  },
  modifyRelease: {
    remark: '备注',
    saveRemarkSuccess: '备注保存成功',
    requirementContainerList: '计划用箱',
    releasedContainerList: '已放箱量',
    yardDataError: '查询堆场数据错误!',
    numberInputEmptyInvalid: '不能提交未填写数量的数据',
    saveReleasedDraftSuccess: '保存放箱草稿成功',
    editTips: '请先保存当前修改',
    releaseOrderInvalid: '请填写放箱顺序',
    dropWarnningTips: '此操作将不会保留当前修改, 是否继续?',
    thinkOver: '再想想'
  },
  releaseDetailPort: {
    planOnPort: '港口计划用箱',
    loadAmount: '加载存量',
    yard: '堆场',
    containerAmount: '存箱量',
    updateAmount: '更新存量',
    includeContainerTypeInvalid: '堆场包含箱型与需求箱型不符',
    duplicateYard: '不能重复添加堆场',
    containerAmountEmptyInvalid: '存箱量不能为空',
    releasedOrderEmptyInvalid: '放箱顺序号不能为空',
    batchDeletedSuccessTips: '批次已全部删除，即将回退到列表页',
    confirmAndBackBtn: '确认返回'
  },
  analyzelog: {
    reportSendType: '报文发送方',
    EDINingbo: '宁波EDI',
    EDIHamburg: '汉堡EDI',
    analysisTime: '解析时间',
    analysisData: '报文',
    resultDetail: '结果详情'
  },
  productionOrder: {
    statusList: ['草稿', '已确认', ' 已完成', '已更新', '已终止'],
    uploadTitle: '导入生产订单',
    orderNo: '订单号',
    client: '客户',
    memberName: '客户名',
    soNo: '订舱号',
    vesselName: '船名',
    voyageNo: '航次',
    pol: '起运港',
    polDevice: '提空设施',
    polDeviceCode: '提空设施编码',
    containerType: '设施对应箱型',
    releasedCount: '计划放箱数量',
    actualyCount: '已提数量',
    pod: '目的港',
    podDevice: '还空设施',
    billNo: '提单号',
    polOverdue: '起运港超期天数',
    podOverdue: '目的港超期天数',
    containerTypeAndAmount: '订舱箱型箱量统计',
    planContainer: '用箱统计',
    actualUsedList: '实际用箱信息',
    createOrder: '新建放单信息',
    viewOrder: '查看放单信息',
    editOrder: '编辑放单信息',
    orderNoInvalid: '请填写订单号',
    memberIdInvalid: '请选择客户',
    vesselNameInvalid: '请填写船名',
    voyageNoInvalid: '请填写航次',
    overdueDaysInvalid: '请填超期天数',
    saveAsDraft: '保存草稿',
    quantity: '数量',
    type: '类型',
    exhaustTime: '提空时间',
    weightBackTime: '还重时间',
    departureUseDays: '起运港用箱天数',
    pickUpWeightTime: '提重时间',
    emptyBackTime: '还空时间',
    destinationUseDays: '目的港用箱天数',
    departurePortInvalid: '请填写起运港',
    exhaustYardInvalid: '请填写提空设施',
    destinationPortInvalid: '请填写目的港',
    emptyBackYard: '请填写还空设施',
    importTemplet: '生产订单导入模板.xlsx',
    containerQuantityLimitedError: '实际用箱数量超过计划数量',
    soNoInvalid: '请输入订舱号',
    containerCountInvalidTitle: '放箱数量与订舱需求不符',
    warningAllcationText: '放箱数量为',
    warningRequiredText: '订舱需求为',
    warningIgnoreText: '如果忽略，提交成功后将无法直接修改，需要联系技术',
    ignoreBtn: '继续提交',
    resetBtn: '重新配置',
    portContainerListHeader: '计划放箱数量',
    workStatus: '作业状态',
    workStatusList: ['作业中', '退关中', '已退关', '已完成'],
    quitYard: '退关设施',
    quitTime: '退关时间',
    modifyReturnYard: '编辑退关交箱设施',
    yardName: '退关交箱设施',
    changedYardSuccess: '设施编码修改成功',
    quitYardInvalidError: '请填写设施名称',
    businessType: '业务类型',
    exportProductionListSuccess: '导出成功'
  },
  TransferOrderList: {
    allotNo: '调运编号',
    status: '状态',
    statusList: ['计划中', '已提交', '操作中', '调拨结束', '异常'],
    transferType: '调拨类型',
    transferMethod: '调拨方式',
    externalTrade: '外采',
    internalTrade: '自有船',
    createdTime: '创建时间',
    startTime: '调拨开始时间',
    doneTime: '完成时间',
    pol: '起运港',
    polDevice: '起运设施',
    pod: '目的港',
    podDevice: '目的设施',
    quantity: '计划箱型箱量',
    fulfillAllotContainerInfo: '完成箱型箱量',
    importTitle: '导入调拨单',
    importExcelFileName: '调拨单批量导入.xlsx',
    supplierName: '供应商',
    vesselName: '船名',
    voyageNo: '航次',
    transitClause: '运输条款',
    eta: 'ETA',
    etd: 'ETD',
    transferYard: '中转设施',
    importBlNo: '进口提单号',
    exportBtnTitle: '导出',
    exportTransferSuccess: '操作成功，请在任务列表中查看结果',
    deletedSuccess: '删除成功'
  },
  TransferOrderDetail: {
    viewOrder: '查看调拨单',
    editOrder: '编辑调拨单',
    createOrder: '新建调拨单',
    allotNo: '调运编号',
    transferType: '调拨类型',
    transferMethod: '调拨方式',
    transitClause: '运输条款',
    contractNo: '合同号',
    departurePort: '起运港',
    departureYard: '起运港设施',
    destinationPort: '目的港',
    destinationYard: '目的港设施',
    vesselName: '船名',
    voyageNo: '航次',
    eta: 'ETA',
    etd: 'ETD',
    transferYard: '中转设施',
    importBlNo: '进口提单号',
    estimateTime: '预计到达时间',
    startTime: '调拨开始时间',
    endTime: '调拨完成时间',
    yardCode: '堆场编码',
    yardName: '堆场名称',
    containerType: "箱型",
    yardQuantity: '在场数量',
    transferQuantity: '计划调拨数量',
    operation: '操作',
    containerNo: '箱号',
    byWay: '陆运',
    byBoat: '海运',
    liner: '沿海调运',
    expectTime: '预计到达时间',
    contractNoInvalid: '请输入合同号',
    transferTypeInvalid: '请选择调拨类型',
    transferMethodInvalid: '请选择调拨方式',
    transitClauseInvalid: '请选择运输条款',
    polInvalid: '请选择起运港',
    podInvalid: '请选择目的港',
    podYardInvalid: '请选择目的港设施',
    allotDateInvalid: '请选择调拨开始时间',
    expectDateInvalid: '请填写调拨完成时间',
    polContainerSectionTitle: '起运港集装箱信息',
    transferContainerInfo: '调拨集装箱信息',
    uploadTransferCointainer: '调拨集装箱导入',
    containerNoInvalid: '箱号错误',
    yardCodeInvalid: '堆场编码错误',
    containerTypeRepeatInvalid: '不能重复添加箱型',
    containerEmptyInvalid: '起运港集装箱信息不能为空',
    quantityEmptyInvalid: '请填写集装箱调拨数量',
    quantityMaxLimited: '计划调拨数量不能大于在场数量',
    transferBatchInfo: '集装箱批次信息',
    batchNo: '批次号',
    batchNoInvalid: '请填写批次号',
    containerTypeInvalid: '请填写箱型',
    planQuantity: '计划调拨数量',
    quantityInvalid: '请填写调拨数量',
    allotCentreQuantity: '调拨中数量',
    allotCompleteQuantity: '调拨完成数量',
    yardQuantityFullError: '超过该堆场的计划调拨数量',
    yardQuantityEmptyError: '该集装箱所在堆场没有计划调拨数量',
    referenceEmptyError: '起运港集装箱信息不能为空',
    importFileName: '调拨集装箱信息导入.xlsx',
    allIn: '总价',
    currency: '币种',
    supplierId: '供应商',
    supplierIdInvalid: '请选择供应商',
    leaveTime: '出场时间',
    completeTime: '完成时间',
    containerTypeError: '不正确的箱型',
    businessTypeInvalid: '请选择业务类型',
    businessOrderId: '请选择关联单号',
    saveAsDraft: '保存草稿',
    allotNoInvalid: '请填写调运编号',
    checkAllotNoInvalid: '调运编号已存在',
    departureYardInvalid: '请填写起运设施',
    vesselNameInvalid: '请填写船名',
    voyageNoInvalid: '请填写航次',
    etaInvalid: '请填写eta',
    etdInvalid: '请填写etd',
    transitClauseList: ['CY-CY', 'CY-FO', 'DOOR-DOOR'],
    quantityLimitInvalid: '修改数量不能小于原数量',
    allInInvalid: '请填写总价',
    currencyInvalid: '请选择货币类型'
  },
  reportList: {
    createDate: '生成时间',
    orderNo: '序号',
    weekDays: '星期',
    operation: '操作',
    downloadFileInvalid: '文件不存在',
    weekDaysList: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
    cloudFileName: '文件名'
  },
  agentImport: {
    importBtn: '批量补录'
  },
  containerFactory: {
    operationSuccess: '新建成功'
  }
}
