<template>
  <div>
    <!--行业术语检索-->
    <advance-select
      v-if="type == 'trade'"
      v-model="modelValue"
      :multiple="multiple"
      :method="termListApiFn"
      :fields="['chineseName','englishName']"
      :headers="['中文名', '英文名']"
      value-key="englishName"
      label-key="englishName"
      @selected="selectedFn"
      :disabled="disabled"
      :tips="tips"
      :defaultDisplay="defaultDisplay"
      ref="advanceSelectRef"
    >
    </advance-select>
    <advance-select
      v-if="type == 'airport'"
      v-model="modelValue"
      :multiple="multiple"
      :method="airportListApiFn"
      :headers="['英文名称', '三字码', '中文名称']"
      :fields="['englishName', 'code3', 'chineseName']"
      value-key="code3"
      label-key="code3"
      @selected="selectedFn"
      :disabled="disabled"
      :tips="tips"
      :defaultDisplay="defaultDisplay"
      ref="advanceSelectRef"
    >
    </advance-select>
    <advance-select
      v-if="type == 'seaport'"
      v-model="modelValue"
      :multiple="multiple"
      :newMultiple="newMultiple"
      :method="seaportListApiFn"
      :headers="['英文名称', '五字码', '中文名称']"
      :fields="['englishName', 'code5', 'chineseName']"
      :value-key="seaportKeyValue"
      :label-key="seaportKeyValue"
      @selected="selectedFn"
      :disabled="disabled"
      :tips="tips"
      :defaultDisplay="defaultDisplay"
      :disabledKey="disabledKey"
      :placeholder="placeholder"
      :size="size"
      :clearable="clearable"
      ref="advanceSelectRef"
    >
    </advance-select>
    <!-- 显示英文名的港口 -->
    <advance-select
      v-if="type == 'seaportEnName'"
      v-model="modelValue"
      :multiple="multiple"
      :newMultiple="newMultiple"
      :method="seaportListApiFn"
      :headers="['英文名称', '五字码', '中文名称']"
      :fields="['englishName', 'code5', 'chineseName']"
      value-key="code5"
      label-key="englishName"
      @selected="selectedFn"
      :disabled="disabled"
      :tips="tips"
      :defaultDisplay="defaultDisplay"
      :placeholder="placeholder"
      :size="size"
      ref="advanceSelectRef"
    >
    </advance-select>
    <advance-select
      v-if="type == 'addSeaport'"
      v-model="modelValue"
      :multiple="multiple"
      :method="seaportAddListApiFn"
      :headers="['英文名称', '五字码', '中文名称','是否已添加']"
      :fields="[]"
      value-key="id"
      label-key="code5"
      disabled-key="checked"
      @selected="selectedFn"
      :disabled="disabled"
      :tips="tips"
      :defaultDisplay="defaultDisplay"
      ref="advanceSelectRef"
    >
      <template slot="option" slot-scope="{row}">
        <div v-for="(field) in ['englishName', 'code5', 'chineseName','checked']" :key="field" class="item">
          <span v-if="field == 'checked'">
            <i class="iconfont icon-checkmark-outline" v-if="row[field]"></i>
          </span>
          <span v-else>{{row[field]}}</span>
        </div>
      </template>
    </advance-select>
    <advance-select
      v-if="type == 'customer'"
      v-model="modelValue"
      :multiple="multiple"
      :method="customerListApiFn"
      :fields="customerFields"
      :headers="customerHeaders"
      value-key="id"
      label-key="chineseName"
      @selected="selectedFn"
      :disabled="disabled"
      :defaultDisplay="defaultDisplay"
      :tips="tips"
      class="w-full"
      :size="size"
      :newMultiple="newMultiple"
      ref="advanceSelectRef"
    >
    </advance-select>
    <!-- 海特-订舱渠道客商下拉 -->
    <advance-select
      v-if="type == 'channelCustomer'"
      v-model="modelValue"
      :multiple="multiple"
      :method="channelCustomerListApiFn"
      :fields="['chineseName','directCustomerTypeName']"
      :headers="['中文名', '类型']"
      value-key="id"
      label-key="chineseName"
      @selected="selectedFn"
      :disabled="disabled"
      :defaultDisplay="defaultDisplay"
      :tips="tips"
      class="w-full"
      :size="size"
      ref="advanceSelectRef"
    >
    </advance-select>
    <!--供应商-->
    <advance-select
      v-if="type == 'supplier'"
      v-model="modelValue"
      :multiple="multiple"
      :method="supplierListApiFn"
      :fields="['chineseName','englishName']"
      :headers="['中文名', '英文名']"
      value-key="id"
      label-key="chineseName"
      @selected="selectedFn"
      :disabled="disabled"
      :defaultDisplay="defaultDisplay"
      :tips="tips"
      class="w-full"
      ref="advanceSelectRef"
    >
    </advance-select>
    <advance-select
      v-if="type == 'airLine'"
      v-model="modelValue"
      :multiple="multiple"
      :method="airLineListApiFn"
      :fields="['chineseName','englishName']"
      :headers="['中文名', '英文名']"
      value-key="id"
      label-key="chineseName"
      @selected="selectedFn"
      :disabled="disabled"
      :defaultDisplay="defaultDisplay"
      :tips="tips"
      ref="advanceSelectRef"
    >
    </advance-select>
    <advance-select
      v-if="type == 'shippingCompany'"
      v-model="modelValue"
      :multiple="multiple"
      :method="getShippingCompanyListApiFn"
      :fields="['chineseName','englishName']"
      :headers="['中文名', '英文名']"
      value-key="id"
      label-key="chineseName"
      @selected="selectedFn"
      :disabled="disabled"
      :defaultDisplay="defaultDisplay"
      :tips="tips"
      ref="advanceSelectRef"
    >
    </advance-select>
    <advance-select
      v-if="type == 'deliveryCompany'"
      v-model="modelValue"
      :multiple="multiple"
      :method="getExpressDeliveryListApiFn"
      :fields="['chineseName','englishName']"
      :headers="['中文名', '英文名']"
      value-key="id"
      label-key="chineseName"
      @selected="selectedFn"
      :disabled="disabled"
      :defaultDisplay="defaultDisplay"
      :tips="tips"
      ref="advanceSelectRef"
    >
    </advance-select>
    <advance-select
      v-if="type == 'member'"
      v-model="modelValue"
      :multiple="multiple"
      :method="memberListApiFn"
      :fields="['chineseName','englishName']"
      :headers="['中文名', '英文名']"
      value-key="id"
      label-key="chineseName"
      @selected="selectedFn"
      :disabled="disabled"
      :defaultDisplay="defaultDisplay"
      :tips="tips"
      ref="advanceSelectRef"
    >
    </advance-select>
    <advance-select
      v-if="type == 'settlementCompany'"
      v-model="modelValue"
      :multiple="multiple"
      :method="settlementCompanyApiFn"
      :fields="['settlementCompanyName','englishName']"
      :headers="['中文名', '英文名']"
      value-key="settlementCompanyId"
      label-key="settlementCompanyName"
      @selected="selectedFn"
      :disabled="disabled"
      :tips="tips"
      :defaultDisplay="defaultDisplay"
      ref="advanceSelectRef"
    >
    </advance-select>
    <advance-select
      v-if="type == 'businessFee'"
      v-model="modelValue"
      :multiple="multiple"
      :method="getFeeListApiFn"
      :value-key="valueKey || 'code'"
      label-key="chineseName"
      @selected="selectedFn"
      :disabled="disabled"
      :fields="['code','chineseName','englishName']"
      :headers="['代码', '中文名','英文名']"
      :defaultDisplay="defaultDisplay"
      ref="advanceSelectRef"
    >
    </advance-select>
    <advance-select
      v-if="type == 'fmsFee'"
      v-model="modelValue"
      :multiple="multiple"
      :method="getFmsFeeListApiFn"
      value-key="code"
      label-key="chineseName"
      @selected="selectedFn"
      :disabled="disabled"
      :fields="['code','chineseName']"
      :headers="['代码', '中文名']"
      :defaultDisplay="defaultDisplay"
      ref="advanceSelectRef"
    >
    </advance-select>
     <advance-select
      v-if="type == 'ovlProduct'"
      v-model="modelValue"
      :multiple="multiple"
      :method="getProductList"
      value-key="productCode"
      label-key="productName"
      @selected="selectedFn"
      :disabled="disabled"
      :fields="['productName','productCode']"
      :headers="[$t('logisticsProduct.logistics_product_name'),$t('logisticsProduct.logistics_product_code')]"
      class="w-full"
      :defaultDisplay="defaultDisplay"
      ref="advanceSelectRef"
    >
    </advance-select>
     <advance-select
      v-if="type == 'operation'"
      v-model="modelValue"
      :multiple="multiple"
      :method="getAllOperationApiFn"
      value-key="id"
      label-key="chineseName"
      @selected="selectedFn"
      :disabled="disabled"
      :fields="['chineseName']"
      :headers="[]"
      clearable
      :defaultDisplay="defaultDisplay"
      ref="advanceSelectRef"
      :tips='false'>
      </advance-select>
    <advance-select
      v-if="type == 'operationSpe'"
      v-model="modelValue"
      :multiple="multiple"
      :method="getAllOperationSpeApiFn"
      value-key="id"
      label-key="chineseName"
      @selected="selectedFn"
      :disabled="disabled"
      :fields="['chineseName']"
      :headers="[]"
      clearable
      :defaultDisplay="defaultDisplay"
      ref="advanceSelectRef"
      :tips='false'>
    </advance-select>
    <advance-select
      v-if="type == 'user'"
      v-model="modelValue"
      :multiple="multiple"
      :method="getUserDropDownListFun"
      value-key="id"
      label-key="chineseName"
      @selected="selectedFn"
      :disabled="disabled"
      :fields="['chineseName']"
      :headers="[]"
      clearable
      ref="advanceSelectRef"
      :tips='false'>
    </advance-select>
    <!-- 约价船名/航次 下拉 -->
    <advance-select
      v-if="type == 'vesselName'"
      v-model="modelValue"
      :multiple="multiple"
      :method="getVesselNameDropDownListFun"
      value-key="id"
      label-key="wholeName"
      @selected="selectedFn"
      :disabled="disabled"
      :defaultDisplay="defaultDisplay"
      :fields="['wholeName']"
      :headers="['船名/航次']"
      clearable
      ref="advanceSelectRef"
      :tips='false'>
    </advance-select>
    <!-- 订舱船名/航次 下拉 -->
    <advance-select
      v-if="type == 'sailing'"
      v-model="modelValue"
      :multiple="multiple"
      :method="getSailingDropDownListFun"
      value-key="id"
      label-key="wholeName"
      @selected="selectedFn"
      :disabled="disabled"
      :defaultDisplay="defaultDisplay"
      :fields="['wholeName']"
      :headers="['船名/航次']"
      clearable
      ref="advanceSelectRef"
      :tips='false'>
    </advance-select>
    <advance-select
      v-if="type == 'customerYHT'"
      v-model="modelValue"
      :multiple="multiple"
      :method="customerYHTListApiFn"
      :fields="['label']"
      :headers="['中文名']"
      value-key="value"
      label-key="label"
      @selected="selectedFn"
      :disabled="disabled"
      :defaultDisplay="defaultDisplay"
      :tips="tips"
      class="w-full"
      :size="size"
      ref="advanceSelectRef"
    >
    </advance-select>
    <!-- 根据港口查询船期 -->
    <advance-select
      v-if="type == 'sailingSchedule'"
      v-model="modelValue"
      :multiple="multiple"
      :method="getScheduleListFromPortFun"
      value-key="sailingId"
      label-key="vessel"
      @selected="selectedFn"
      :disabled="disabled"
      :defaultDisplay="defaultDisplay"
      :fields="['vessel', 'voyage']"
      :headers="['船名', '航次']"
      clearable
      :tips='false'
      ref="advanceSelectRef"
      :placeholder="placeholder">
    </advance-select>
    <!-- 目的港改单申请订舱号查询 -->
    <advance-select
      v-if="type == 'entityApply'"
      v-model.trim="modelValue"
      :multiple="multiple"
      :method="getCasJobBlListByBlNoApiFun"
      :fields="['blNo']"
      :headers="['订舱号']"
      value-key="id"
      label-key="blNo"
      @selected="selectedFn"
      :disabled="disabled"
      :defaultDisplay="defaultDisplay"
      :tips="tips"
      class="w-full"
      ref="advanceSelectRef"
      :size="size">
    </advance-select>
    <!-- FAK约号下拉 -->
    <!-- <advance-select
      v-if="type == 'fakNo'"
      v-model="modelValue"
      :multiple="multiple"
      :method="getContractNoDropdownApiFn"
      :headers="['约号']"
      :fields="['contractNo']"
      value-key="contractNo"
      label-key="contractNo"
      @selected="selectedFn"
      :disabled="disabled"
      :tips="tips"
      :defaultDisplay="defaultDisplay"
      :placeholder="placeholder"
      :size="size">
    </advance-select> -->
  </div>
</template>
<script>
import request from '@/utils/request'
import AdvanceSelect from "./AdvanceSelect"
import {
  seaportListApi,
  seaportAddListApi,
  airportListApi,
  getTerminologyListByTypeSearchApi,
  getMemberDropDownListApi,
  getCustomerDropDownListApi,
  getSupplierDropDownListApi,
  getSettlementCompanyListApi,
  getFeeListApi,
  getFmsFeeByKeyApi,
  airLineListApi,
  getAllOperationApi,
  getShippingCompanyListApi,
  getExpressDeliveryListApi,
  getAllOperationSpeApi, getYHTListApi
} from '@/api/common'
import { getProductListApi } from '@/api/OverseasWarehouse/peakSeasonSurcharge'
import { getUserDropDownList } from "@/api/commonComponent"
import {
  getVesselNameDropDownListApi,
  getSailingDropDownListApi,
  getChannelCustomerDropdownApi,
  getContractNoDropdownApi,
  getScheduleListFromPortApi
} from '@/api/contractPrice'
import { getYardListByRequestApi } from "@/api/schedule"
import { getCasJobBlListByBlNoApi } from "@/api/destinationMaterial"

export default {
	name: 'Port',
	components: { AdvanceSelect },
	props: {
		type: {
			type: String
		},
		value: {
			type: [String, Array]
		},
		// 远程请求
		method: { type: Function, default: null },
		// 不可编辑
		disabled: { type: Boolean, default: false },
		// 是否有清空按钮操作
		clearable: { type: Boolean, default: true },
		// 是否允许创建新条目进行选择
		allowCreate: { type: Boolean, default: false },
		// 是否多选
		multiple: { type: Boolean, default: false },
		placeholder: { type: String, default: '请输入关键词' },
    size: { type: String, default: 'mini' },
		// 远程搜索间隔时间
		wait: { type: Number, default: 200 },
		// 要取来显示到select框内的字段名
		valueKey: { type: String, default: '' },
		labelKey: { type: String, default: 'id' },
		// 固定的select的option
		fixedOptions: { type: Array, default: null },
		// 是否显示options 头部提示文字
		tips: { type: Boolean, default: true },
		// 是否显示options 头部提示文字
		defaultDisplay: { type: [String, Array], default: '' },
    disabledKey: { type: [String, Array], default: '' },
		// termCode
		termCode: { type: String, default: '' },
		seaportKeyValue: { type: String, default: 'code5' },
		requestParams: {
			type: Object,
			default: function () {
				return {}
			}
		},
    newMultiple: {type: Boolean, default: false},
    customerFields: {type: Array, default: () => ['chineseName', 'englishName']},
    customerHeaders: {type: Array, default: () => ['中文名', '英文名']}
	},
	data() {
		return {
			modelValue: null
		}
	},
	created() {
		this.modelValue = this.value
	},
	watch: {
		value(newValue) {
			this.modelValue = newValue
		},
		modelValue(newValue) {
			this.$emit('input', newValue)
		}
    // requestParams() {
		//   this.seaportListApiFn({keyword: ''})
    // }
	},
	methods: {
		selectedFn(item) {
			this.$emit('selected', item)
		},
		seaportAddListApiFn(params) {
			return seaportAddListApi(params)
		},
		seaportListApiFn(params) {
			const { keyword } = params
			const data = {
				keyword: keyword,
        ...this.requestParams
			}
			let fn = this.method || seaportListApi
			return fn(data)
		},
		airportListApiFn(params) {
			const { keyword } = params
			const data = {
				keyword: keyword
			}
			let fn = this.method || airportListApi
			return fn(data)
		},
		termListApiFn(params) {
			if (!this.termCode) {
				return
			}
			const { keyword } = params
			const data = {
				keyword: keyword,
				termCode: this.termCode
			}
			let fn = this.method || getTerminologyListByTypeSearchApi
			return fn(data)
		},
		customerListApiFn(params) {
			const { keyword } = params
			const data = {
				name: keyword,
				...this.requestParams
			}
			let fn = this.method || getCustomerDropDownListApi
			return fn(data)
		},
		customerYHTListApiFn(params) {
			const { keyword } = params
			const data = {
				name: keyword,
				...this.requestParams
			}
			let fn = this.method || getYHTListApi
			return fn(data)
		},
		channelCustomerListApiFn(params) {
			const { keyword } = params
			const data = {
				name: keyword,
				...this.requestParams
			}
			let fn = this.method || getChannelCustomerDropdownApi
			return fn(data)
		},
		supplierListApiFn(params) {
			const { keyword } = params
			const data = {
				name: keyword,
				...this.requestParams
			}
			let fn = this.method || getSupplierDropDownListApi
			return fn(data)
		},
		airLineListApiFn(params) {
			const { keyword } = params
			const data = {
				name: keyword
			}
			let fn = this.method || airLineListApi
			return fn(data)
		},
		memberListApiFn(params) {
			const { keyword } = params
			const data = {
				chineseName: keyword,
				auditingStatus: 2
			}
			let fn = this.method || getMemberDropDownListApi
			return fn(data)
		},
		settlementCompanyApiFn(params) {
			const { keyword } = params
			const data = {
				keyword: keyword,
				...this.requestParams
			}
			let fn = this.method || getSettlementCompanyListApi
			return fn(data)
		},
		getFeeListApiFn(params) {
			const { keyword } = params
			const data = {
				keyword: keyword,
				...this.requestParams
			}
			let fn = this.method || getFeeListApi
			return fn(data)
		},
		getFmsFeeListApiFn(params) {
			const { keyword } = params
			const data = {
				keyword: keyword
			}
			let fn = this.method || getFmsFeeByKeyApi
			return fn(data)
		},
		/**
		* 模糊查询列表
		*/
		getProductList({ keyword }) {
			return getProductListApi(keyword)
		},
		getAllOperationApiFn(params) {
			const { keyword } = params
			const data = {
				keyword: keyword
			}
			let fn = this.method || getAllOperationApi
			return fn(data)
		},
		// 海特
		getAllOperationSpeApiFn(params) {
			const { keyword } = params
			const data = {
				keyword: keyword
			}
			let fn = this.method || getAllOperationSpeApi
			return fn(data)
		},
		getUserDropDownListFun(params) {
			const { keyword } = params
			const data = {
				keyword: keyword
			}
			let fn = this.method || getUserDropDownList
			return fn(data)
		},
		getShippingCompanyListApiFn(params) {
			const { keyword } = params
			const data = {
				keyword: keyword
			}
			let fn = this.method || getShippingCompanyListApi
			return fn(data)
		},
		getExpressDeliveryListApiFn(params) {
			const { keyword } = params
			const data = {
				keyword: keyword
			}
			let fn = this.method || getExpressDeliveryListApi
			return fn(data)
		},
		/**
		 * 堆场
		 */
		getYardListApiFn(params) {
			const { keyword } = params
			const data = {
				keyword: keyword
			}
			let fn = this.method || getYardListByRequestApi
			return fn(data)
		},

		// async getContractNoDropdownApiFn(params) {
		//   const { keyword } = params
		//   const data = {
		//     contractNo: keyword
		//   }
		//   let fn = this.method || getContractNoDropdownApi
		//   return fn(data)
		// }
    async getVesselNameDropDownListFun(params) {
      const { keyword } = params
      const data = {
        vesselName: keyword,
        ...this.requestParams
      }
      let fn = this.method || getVesselNameDropDownListApi
      const res = await fn(data)
      let _data = null
      if (res.ok) {
        res.content.map(item => {
          item.wholeName = `${item.vesselName}/${item.voyageNo}/${item.routeCode}`
        })
        _data = res
      }
      return _data
    },
    async getSailingDropDownListFun(params) {
      const { keyword } = params
      const data = {
        keyword: keyword,
        pageSize: 100,
        ...this.requestParams
      }
      let fn = this.method || getSailingDropDownListApi
      const res = await fn(data)
      let _data = null
      if (res.ok) {
        res.content.map(item => {
          item.wholeName = `${item.vesselName}/${item.voyageNo}/${item.routeCode}`
        })
        _data = res
      }
      return _data
    },
    // 根据港口查询船期
    getScheduleListFromPortFun(params) {
      const { keyword } = params
      const data = {
        vesselName: keyword,
        ...this.requestParams
      }
      let fn = this.method || getScheduleListFromPortApi
      return fn(data)
    },
    getCasJobBlListByBlNoApiFun(params) {
      if (this.$isNotEmpty(params.keyword)) {
        const data = {
          'keyword': params.keyword
        }
        let fn = this.method || getCasJobBlListByBlNoApi
        return fn(data)
      } else {
        return new Promise((resolve, reject) => {
          resolve({
            content: []
          })
        })
      }
    }
    // async getContractNoDropdownApiFn(params) {
    //   const { keyword } = params
    //   const data = {
    //     contractNo: keyword
    //   }
    //   let fn = this.method || getContractNoDropdownApi
    //   return fn(data)
    // }
	}
}
</script>
<style scoped lang="scss">
.item {
	float: left;
	flex: 1;
	padding: 0 5px;
}
</style>
