export default {
  contractSpace: '总舱位数',
  actualLoadingSpace: '实装舱位',
  contractSpacePlaceholder: '请输入合约舱位数',
  blNo: '提单号',
  containerNo: '箱号',
  containerNumber: '集装箱号',
  sealNo: '封号',
  vesselName: '船名',
  voyageNo: '航次',
  polCode: '装货港',
  podCode: '卸货港',
  containerType: '箱型',
  ownerName: '箱属',
  typeAndQuantity: '箱型箱量',
  submitSuccess: '提交成功！',
  baseInfo: '基本信息',
  routeCode: '航线代码',
  etd: 'ETD',
  masterBlText: 'Master BL',
  houseBLText: 'House BL',
  paymentMethod: '付款方式',
  toPay: '到付',
  prepaid: '预付',
  containerNoPlaceholder: '请输入箱号',
  serialNo: '流水号',
  commandNo: '指令号',
  saveSuccess: '保存成功',
  memberName: '客户',
  memberPlaceholder: '客户名称/简称',
  hsCode: 'HS CODE',
  blStatus: '提单状态',
  declareStatus: '申报状态',
  waitForDeclare: '待申报',
  declareResult: '申报反馈',
  sampleError: '请选择正本/样单',
  blType: '提单类型',
  markDeclareStatus: '标记申报状态',
  recordMasterBl: '录入Master BL',
  grossWeight: '毛重',
  volume: '体积',
  printDataEmptyError: '请至少勾选一条记录进行打印',
  filterDataError: '请修改查询条件后，再次操作批量打印',
  booking: {
    sendBookingEmail: '发送订舱确认函',
    sendBookingInvalid: '所选订舱记录状态异常，无法批量发送订舱确认函',
    downloadBookingList: '批量下载订舱确认函',
    downloadBookingInvalid: '所选订舱记录状态异常，无法批量下载订舱确认函',
    downloadApiSuccess: '下载任务操作成功',
    addBooking: '新建订舱',
    cancelBooking: '取消订舱',
    advanceSearch: '高级搜索',
    simpleSearch: '简单搜索',
    loadingHard: '拼命加载中',
    soNoText: 'SO',
    allotYard: '分配提箱点',
    statisticInfo: '获取状态统计数据失败',
    cancelBookingConfirm: '确认要取消订舱吗？',
    allotInfoEmptyError: '请勾选单条订舱记录进行分配提箱点操作',
    noneAllotTips: '客户自有箱无需分配提箱点',
    nonePassedInfoTips: '请勾选单条[已通过]的订舱记录进行分配提箱点操作',
    cancelBookingEmptyError: '请勾选一条进行取消订舱的记录',
    selectInfoEmptyTips: '请选择记录后操作',
    sendSuccess: '发送成功',
    sendFailed: '发送失败',
    selectInfoErrorMessage: '所选订舱记录状态异常，无法批量标记',
    containerSizeTypeQty: '柜型柜量',
    vessel: '船名/航次',
    status: '状态'
  },
  suitCase: {
    soQuantity: 'SO箱量：',
    pickUpAddr: '提箱点',
    pickUpAddrEmptyInvalid: '请选择提箱点',
    remark: '备注',
    addPickup: '添加提箱点',
    removePickup: '删除提箱点',
    getPickupList: '获取提箱点列表',
    inputZeroError: '请输入大于0的数字',
    allotContainerAmoutnError: '提箱点分配的集装箱数量与客户申请的集装箱数量不符，请确认后再试。',
    sameContainerTypeError: '同一箱型下存在重复的提箱点，请检查',
    allotSuccess: '提箱点分配成功',
    allotFailed: '提箱点分配失败'
  },
  fleetList: {
    truckName: '车队名称',
    tagNo: '拖头车牌',
    bracketNo: '托架车牌',
    eirId: 'EIR-ID',
    driverName: '司机姓名',
    driverMobile: '司机手机',
    applicationTime: '申请时间',
    queryRange: '预约时间',
    cancelQuery: '取消预约',
    printBtn: '打印单据',
    trailersConfirmTips: '是否确认取消当前预约车队?',
    thinkOver: '再想想',
    cancelQuerySuccess: '取消预约成功',
    driverIdCardNo: '司机身份证号',
    pickUpAddress: '提箱点',
    returnAddress: '还箱点',
    selectedDataEmpty: '请先选择一条记录',
    cancelOrderFailed: '所选箱子预约状态已变更，请刷新后重试'
  },
  changeOrder: {
    soNoText: 'SO',
    changeContentType: '变更内容',
    sailingDuration: '船期',
    targetPort: '目的港',
    other: '其他',
    processingStatus: '处理状态',
    processing: '处理中',
    processed: '已处理',
    processedByName: '处理人',
    casOrder: '申请清单',
    processCasChange: '处理改单',
    processResult: '处理结果',
    resultEmptyInvalid: '请选择处理结果',
    processFinished: '处理完成',
    processRejected: '驳回申请',
    processRemark: '处理备注',
    remarkEmptyInvalid: '请输入处理备注',
    processBtn: '处理',
    changeRemark: '改单备注',
    guaranteeFileName: '改单保函',
    passed: '已通过',
    opreationByName: '操作人',
    processTime: '处理时间',
    changeDataEmptyError: '请勾选任意改单申请',
    processStatusError: '请选择处理中的状态进行操作',
    changedSuccess: '改单成功'
  },
  containerTransfer: {
    vesselPlaceholder: '请输入船名',
    voyagePlaceholder: '请输入航次',
    jobType: '作业类型',
    emptyPickup: '提空',
    fullPickup: '提重',
    emptyReturn: '还空',
    fullReturn: '还重',
    loadShip: '装船',
    removeShip: '卸船',
    jobPortCode: '作业港口',
    jobList: '集装箱作业清单',
    jobDuration: '作业时间',
    dataOriginName: '数据来源'
  },
  amsList: {
    blStatus: '申报状态',
    pushUnSubmit: '已提交未推送',
    createProcessing: '新增处理中',
    accessed: '已接收',
    onMatching: '匹配中',
    isMatched: '匹配成功',
    matchFailed: '匹配失败',
    deleteUnResult: '提交删除未回执',
    processingModify: '修改处理中',
    submitFailed: '提交失败',
    blMatch: '海单匹配（1Y）',
    isfMatch: 'ISF匹配（3Z）',
    hblOwn: '提单号归属',
    YHTText: '海特',
    ownerContainer: '自有',
    batchOrderError: '客户使用自有单号不支持批量出单，请选择单一条目出单',
    batchSelectedOrderError: '选项中客户使用自有单号不支持批量出单，请选择单一条目出单'
  },
  excelUpload: {
    clickToChooseFile: '点击选择导入文件',
    fileTypeError: '文件类型错误',
    fileLimited2M: '文件不能超过2M'
  },
  fleetStatus: {
    unInvited: '未作业',
    invited: '已邀请',
    ordered: '已预约',
    pickedUp: '已提空',
    returned: '已还重'
  },
  issuingMethod: '签发方式',
  shipCode: '船舶代码',
  confirmRelease: '确认放舱',
  packingCompleted: '装箱完成',
  goodsList: '货物清单'
}
