import Layout from "@/layout"

export const FmsReceiptClearRouterMap = {
  // 财务结算
  "fms:clear:manager": {
    component: Layout,
    alwaysShow: true,
    name: "Financial"
  },
  // 收款管理
  "fms:re:trans:list": {
    component: () => import(/* webpackChunkName: 'clear' */ '@/views/fms/receiptTransactions/transactions'),
    name: 'TransactionsReceiptList',
    children: ["fms:re:trans:detail"]
  },
  // 认领管理
  "fms:re:trans:claim:list": {
    component: () => import(/* webpackChunkName: 'clear' */ '@/views/fms/receiptTransactions/claim'),
    name: 'ClaimReceiptList',
    children: ["fms:re:trans:detail", 'fms:re:trans:claim:detail']
  },
  // 收款详情
  "fms:re:trans:detail": {
    hidden: true,
    path: 'receipt/transactions/detail',
    component: () => import(/* webpackChunkName: 'clear' */ '@/views/fms/receiptTransactions/detailTransactions'),
    name: 'TransactionsReceiptDetail',
    meta: { title: '收款详情' }
  },
  // 我的认领详情
  "fms:re:trans:claim:detail": {
    path: 'receipt/transactions/detail/claimer',
    component: () => import(/* webpackChunkName: 'clear' */ '@/views/fms/receiptTransactions/detailClaimer'),
    hidden: true,
    name: 'TransactionsReceiptDetailClaimer',
    meta: { title: '我的认领详情' }
  },
  // 核销管理
  "fms:re:clear:list": {
    path: 'receipt/clear/list',
    component: () => import(/* webpackChunkName: 'clear' */ '@/views/fms/receiptClearList/list'),
    name: 'ReceiptClearBusinessList',
    meta: { title: '核销管理' },
    children: ["fms:re:clear:detail", "fms:re:clear:offset:detail"]
  },
  // 核销管理
  "fms:re:clear:apply:list": {
    path: 'receipt/clear/apply/list',
    component: () => import(/* webpackChunkName: 'clear' */ '@/views/fms/receiptClearList/applyList'),
    name: 'ReceiptClearApplyOperatorList',
    meta: { title: '核销申请管理' },
    children: ["fms:re:clear:detail", "fms:re:clear:offset:detail"]
  },
  // 核销管理 详情
  "fms:re:clear:detail": {
    path: 'receipt/clear/detail',
    component: () => import(/* webpackChunkName: 'clear' */ '@/views/fms/receiptClearList/clearDetail'),
    hidden: true,
    name: 'ReceiptClearDetail',
    meta: { title: '核销详情' }
  },
  // 核销管理 对冲核销详情
  "fms:re:clear:offset:detail": {
    path: 'receipt/clear/offset/detail',
    component: () => import(/* webpackChunkName: 'clear' */ '@/views/fms/receiptClearList/offsetClearDetail'),
    hidden: true,
    name: 'ReceiptOffsetClearDetail',
    meta: { title: '对冲核销详情' }
  }
}
