export default {
  all: '全部',
  close: '关闭',
  upload: '上传',
  orderState: '订单状态',
  order: '订单',
  returnList: '返回列表',
  save: '保存',
  cancel: '取消',
  customerInformation: '客户信息',
  entrustmentInformation: '委托信息',
  requester: '委托单位',
  requesterHold: '请选择委托单位',
  salesperson: '销售人员',
  customerContact: '客户联系人',
  placeholder: '请选择',
  pleaseEnter: '请输入',
  customerLevel: '客户等级',
  settlementMethod: '结算方式',
  settlementUnit: '结算单位',
  email: '联系邮箱',
  phone: '联系电话',
  placeOfReceipt: '收货地',
  pol: '起运港',
  pol2: '装货港',
  polHold: '请输入起运港',
  polHold2: '请输入装货港',
  pod: '卸货港',
  pod2: '目的港',
  podHold: '请输入卸货港',
  shipBoss: '船司',
  shipBossHold: '请输入船司代码',
  valueAddedServices: '增值服务',
  placeOfDelivery: '交货地',
  shippingMethod: '运输方式',
  transshipmentInBatches: '分批/转船',
  acceptBatches: '接受分批',
  acceptTransshipment: '接受转船',
  sailingDate: '开航日期',
  SelectSailingDateHold: '请选择开航日期',
  shipName: '船名',
  shipNameHold: '请输入船名',
  voyage: '航次',
  cargoType: '货物类型',
  tradeTerms: '贸易条款',
  billType: '提单类型',
  payMethod: '付款方式',
  issuingMethod: '签发方式',
  marks: '货物描述',
  marksHold: '请输入货物描述',
  remarks: '订舱备注',
  orderRemarks: '订单备注',
  edit: '编辑',
  booking: '订舱',
  selectFileToImport: '选择文件导入',
  templateDownload: '模板下载',
  uploadEntrustment: '上传托书',
  shippingInformation: '船期信息',
  orderWebsite: '订舱网点',
  soNoSon: '分单号',
  polTransportTerm: '运输条款',
  shipperInformation: '货运方信息',
  contactHold: '请输入公司名称',
  street: '街道',
  streetHold: '请输入街道',
  city: '所在城市',
  pleaseEnterCity: '城市',
  countyAndDistrict: '县/区',
  provinceState: '省/州',
  countries: '国家',
  zipCode: '邮编',
  surname: '姓',
  name: '名',
  contactPhone: '联系电话',
  moreInfo: '更多信息',
  contacts: '联系人',
  cargoInformation: '货物信息',
  CAS: 'CAS号码',
  containerInformation: '集装箱信息',
  rateInformation: '费率信息',
  box: '箱型',
  quantity: '数量',
  weight: '毛重',
  isSoc: '客户自有箱',
  priceCardNumber: '约号/价卡号',
  remark: '备注',
  exitCustoms: '退关',
  basicInformation: '基础信息',
  SO: '订舱号',
  boxQuantity: '箱量',
  sumCount: '总箱量',
  unboxed: '待装箱量',
  number: '件数',
  sumQuantity: '总件数',
  sumGrossWeight: '总毛重',
  sumVolume: '总体积',
  packing: '装箱',
  packingInformation: '装箱信息',
  importPackingInformation: '导入装箱信息',
  notePacking: '注意：当前SO中仍有集装箱尚未完成装箱，如需并入其他提单，请在当前页面录入装箱信息，在其他订单的制单页面中添加当前SO进行补料。',
  containerNo: '箱号',
  sealNo: '封号',
  vgmWeight: 'VGM箱货重（KGS）',
  vgmMode: '称重方式',
  overall: '整体称重',
  cumulative: '累加计算',
  packageType: '包装单位',
  grossWeight: '毛重(KGS)',
  volume: '体积(CBM) ',
  volume2: '体积',
  toBeBooked: '待订舱',
  toBePrepared: '待制单',
  materialsToBeSupplemented: '待补料',
  toBeIssued: '待签发',
  issued: "已签发",
  cancelled: '已取消',
  orderNumber: '订单编号',
  remindWork: '作业提醒',
  carrierBlNo: '船司提单',
  memberBlNo: '客户提单',
  createByName: '创建人',
  createTime: '创建时间',
  updateByName: '最后更新人',
  updateTime: '最后更新时间',
  operating: '操作',
  pleaseCheckDataIssuance: '请勾选数据进行签发',
  determine: '确定',
  notToBeIssued: '请选择待签发的提单进行签发操作',
  toBeIssuedOne: '签发后订单业务信息将不可变更，是否继续？确认后标记签发',
  tips: '提示',
  toBeIssuedMore: '您选择了多条订单，签发后订单业务信息将不可变更，是否继续？确认后标记签发',
  view: '查看',
  bookingSpace: '订舱',
  copy: '复制',
  copyAll: '全量复制',
  documentPreparation: '制单',
  replenishment: '补料',
  issue: '签发',
  issuedSuccessfully: '签发成功',
  customerBill: '客户提单',
  bookingInformation: '订舱信息',
  maintainTime: '维护船期时间',
  uploadBookingReceipt: '上传订舱回执',
  freightForwarder: '货代',
  bookingBeCareful: '注意：请及时维护船期时间以便于提醒客户和运营人员进行订单跟踪。',
  boxTypeArr: '箱型/箱量/毛重',
  transfer: '中转',
  vesselVoyage: '船名/航次',
  secondVesselVoyage: '二程船名/航次',
  TransferVessel: '中转船名',
  TransferVoyage: '中转航次',
  transitPort: '中转港',
  selectDate: '选择日期时间',
  maintainTimeDialogCareful: '注意：请谨慎维护中转港和二程信息，此信息将影响到提单内容',
  submittedSuccessfully: '提交成功',
  fileTypeError: '文件格式错误！',
  fileCannotExceed: '文件不能超过',
  numberFilesCannotExceed: '文件数量不能超过',
  fileUploadedSuccessfully: '文件上传成功',
  uploadPackingList: '上传装箱单',
  uploadVGMData: '上传VGM资料',
  uploadWarehouseInformation: '上传预配仓单资料',
  uploadBillDocuments: '上传预配仓单资料',
  packingInformationNote: '注意：当前SO中仍有集装箱尚未完成装箱，如需并入其他提单，请在其他订单中添加当前SO进行补料。',
  true: '是',
  false: '否',
  parameterMissing: '参数缺失',
  cancelSuccess: '取消成功',
  delegateCancellation: '委托取消后无法恢复，是否继续？',
  bookingCancel: '取消后不可恢复，是否继续？',
  dataImport: '数据导入',
  pleaseSelectAFile: '请选择一个文件',
  mega: '美加线',
  ouDi: '欧地线',
  southeastAsia: '东南亚线',
  southAmericanLine: '南美线',
  africanLine: '非洲线',
  australiaSingaporeRailway: '澳新线',
  middleEastLine: '中东线',
  japanSouthKoreaLine: '日韩线',
  lafite: '拉非',
  asiaPacific: '亚太',
  sameBoxTypeIsSelected: '选择了相同的箱型，请编辑后再提交',
  doNotExceed30000: '预估单箱货重请勿超过30000KGS',
  containerWeight30000: '箱货重请勿超过30000KGS',
  newOrder: '新建订单',
  importVGMInformation: '导入VGM信息',
  cancelBooking: '取消订舱',
  BlType: '提单类型',
  alertOthers: '通知人',
  unmatched: '未对单',
  printSample: '打印样单',
  invitationSingle: '邀请对单',
  billNo: '提单号',
  pointBlEdit: '提示：1、MBL的客户提单号将在船司提单号确定后同步至客户提单；',
  pointBlEdit2: '2、HBL的客户提单号将在保存后自动生成。',
  billInformation: '提单信息',
  getPackingInformation: '获取装箱信息',
  packingInformationNote2: '注意：请在装箱页面补充箱货信息，当前页面将自动带入。',
  next: '下一步',
  prev: '上一步',
  showFee: '显示费用',
  billDetails: '提单明细',
  addBill: '新增提单',
  generateShippingBill: '生成船司提单',
  uploadBl: '上传提单',
  uploadBill: '上传提单文件',
  oneToOneStatus: '对单状态',
  replenishmentStatus: '补料状态',
  shipSBillNo: '关联船司提单号',
  customerBillNo: '关联客户提单号',
  pleaseSelectData: '请选择数据',
  orderStatusHasChanged: '订单状态已发生变更，请刷新后重试',
  addCustomerBill: '新增客户提单',
  addShipBossBill: '新增船司提单',
  batchExportCustomerBill: '批量导出客户提单',
  notYetSaved: '页面跳转后尚未保存的信息将丢失，如果信息已保存，请忽略此提示，是否继续？',
  returnBillDetails: '返回提单明细',
  successSaved: '保存成功',
  followingFields: '当前客户提单缺少以下字段，请在后期补足以便于正确生成船司提单：',
  missingBoxInformation: '当前客户提单缺少箱货信息，请在后期补足以便于正确生成船司提单',
  hasShippingBl: '当前提单已生成船司提单，请确认所有必填项不为空,并注意格式是否正确',
  changeShippingBl: '变更当前客户提单内容将影响船司提单，是否继续？',
  unpackedInformationChange: '变更内容不涉及箱货信息，船司提单将不做任何变更，是否继续？',
  thereIsAChangeInTheCargoInformation: '当前提单已生成船司提单，变更箱货信息将影响船司提单，是否继续？',
  noData: '暂无数据',
  unrelatedShippingCompanyBillOfLading: '请选择尚未关联任何船司提单的客户提单进行操作',
  missingCargoInformation: '所选提单中的箱货信息或必填项缺失，请确认后重试',
  pleaseAddTheContainerInformationAndTryAgain: '当前提单箱货信息缺失无法生成船司提单，请添加箱货信息后重试',
  typeTheSameCustomerBillOfLadingToOperate: '请勾选类型一致的客户提单进行操作',
  adjustCustomerBill: '调整客户提单',
  successfullyDeleted: '删除成功',
  generatedSuccessfully: '生成成功',
  canTBeRecoveredAfterDeletion: '删除后无法恢复，是否继续？',
  deleteCustomerShipBl: '将同时删除客户提单和船司提单，是否继续？',
  billOfLadingAssociatedShippingCompany: '当前提单已关联船司提单，删除后将修改船司提单内容，甚至直接删除船司提单，是否继续？',
  containerIsAssociatedWithBill: '当前集装箱已关联客户提单，保存将会变更客户提单数据，是否继续？',
  deletedContainersCannotBeRecovered: '删除集装箱将自动调整订舱信息中的箱量数据，被删除的集装箱将无法恢复，是否继续？',
  delete: '删除',
  linkedOut: '当前集装箱已关联客户提单，此操作将会变更提单内容，且退关后无法恢复，是否继续？',
  unconnectedExit: '集装箱退关后无法恢复，是否继续？',
  exitSuccessfully: '退关成功',
  checkOut: '退关确认',
  wholeTicketPart: '整票/部分',
  wholeTicket: '整票',
  part: '部分',
  container: '集装箱',
  exportPackingData: '导出装箱数据',
  unboxed2: '未装箱',
  pleaseSelectAContainer: '请选择集装箱',
  whetherToContinueExporting: '当前客户提单类型为MBL，是否继续导出海特提单？',
  confirmationOfBookingChannel: '订舱渠道确认',
  bookingChannel: '订舱渠道',
  other: '其他',
  bookingMethod: '订舱方式',
  automatic: '自动',
  artificial: '人工',
  prompt: '提示',
  confirmationOfBookingChannelTips1: '1、普货支持EB渠道线上自动订舱，冷冻品和危险品将暂时沿用线下订舱模式；',
  confirmationOfBookingChannelTips2: '2、SyncHub和其他渠道暂时将沿用线下订舱模式；',
  confirmationOfBookingChannelTips3: '3、不同渠道的操作模式不同，请谨慎选择。',
  userReferenceType: '用户参考类型',
  userReferenceNumber: '用户参考号码',
  otherInformation: '其他信息',
  confirmationLetterSentByEmail: '确认函发送邮箱',
  shipChooseType: '船期选择',
  shipScheduleInquiry: '船期查询',
  estimatedVoyage: '预计船名航次',
  departureTimeDeparturePort: '起运港最早离港时间',
  bookingAgentPort: '订舱代理',
  departureTime: '起运时间',
  direct: '直达',
  transship: '转船',
  routeCode: '航线代码',
  andBeyond: '及后',
  withinWeek: '周内',
  firstLane: '头程航线代码',
  secondLane: '二程航线代码',
  specifyTheBillNumber: '指定提单号',
  automaticallyGeneratedAfterSaving: '保存后自动生成',
  billProcessingInstructions: '提单处理说明',
  billDistribution: '提单分发',
  distributionOfOriginalBillOfLading: '正本提单分发',
  seaBillDistribution: 'Sea Waybill提单分发',
  bookingParty: '订舱方',
  contact: '发货人',
  freightForwarding: '货代',
  consignee: '收货人',
  notifyingParty: '通知方',
  secondNotifyingParty: '第二通知方',
  original: '正本',
  duplicate: '副本',
  shippingFee: '海运费',
  whereToPay: '付款地点',
  currency: '币种',
  payer: '付款方',
  requestItem: '请求项',
  requestItemRemarks: '请求项备注',
  orderProcessingRequest: '制单处理请求',
  showOnBL: '提单备注（显示在BL上）',
  doesNotShowOnBL: '其他处理提单的指令（不显示在BL上）',
  receiptCopyReceiptEmail: '回执副本接收邮箱',
  headWayshipName: '头程船名',
  firstVoyageShip: '头程船',
  firstVoyage: '头程航次',
  secondShipName: '二程船名',
  secondVoyage: '二程航次',
  sendBooking: '发送订舱',
  saveDraft: '保存草稿',
  thisBillOfLadingNumberAlreadyExists: '此提单号已存在，如需用同一SO号签发多票，请调整指定提单号',
  bookingProgress: '订舱处理中',
  submitBooking: '提交订舱',
  submitBookingTips: '将发送订舱申请，是否继续？',
  delBookingTips: '订舱信息删除后无法恢复，是否继续？',
  bookingHasBeenSubmitted: '订舱已提交，结果确认中，请刷新后重试',
  orderStatusChanged: '订单状态已变更，请在刷新后重试',
  packingInformationAMessage: "装箱信息至少保留一条信息",
  promptRequiredFields: '提示：必填项',
  promptRequiredFields2: '未处理，请确认后再试！',
  serialNumberQueryBooking: '通过流水号自动查询订舱结果',
  serialNumber: '流水号',
  bookingSerialNumber: '订舱流水号',
  serialNumberIsIncorrect: '订舱流水号格式不正确',
  characters8: '此项长度应为8个字符',
  sameConsigneeInformation: '同收货人信息',
  reasonForFailure: '失败原因',
  brazilianWoodPackagingTypes: '巴西木包装类型',
  sailTime: '开船时间',
  estimatedDeparture: '预计离港',
  transportPath: '运输路径',
  outboundLiner: '离港班轮',
  allSailingDates: '所有船期',
  shortestWay: '最短在途',
  estimatedCutOffTime: '截重时间(预估)',
  onTheWay: '在途',
  shipNameVoyageRoute: '船名/航次/航线--途径港口',
  expectedArrival: '预计到达',
  transferOnce: '中转一次',
  multipleTransfers: '中转多次',
  MON: '周一',
  TUE: '周二',
  WED: '周三',
  THUR: '周四',
  FRI: '周五',
  SAT: '周六',
  SUN: '周日',
  noShipDateInformationCanBeFound: '查询不到任何船期信息，请尝试',
  reQuery: '重新查询',
  or: '或',
  enterTheShipNameAndVoyageDirectly: '直接录入船名航次',
  day: '天',
  approachingEstimatedPortCutOffTime: '临近预估截港时间，请谨慎选择',
  weightCuttingCabinet: '截重柜',
  pleaseSelectAnyShippingDateFirst: '请先选择任意船期再提交订舱',
  feesReceivable: '应收费用',
  receivableExpenseConfirmationDocument: '应收费用确认单',
  feesPayable: '应付费用',
  payableExpenseConfirmation: '应付费用确认单',
  cost: '费用',
  electronicDocuments: '电子文件',
  preview: '预览',
  download: '下载',
  feeMigration: '费用迁移',
  sendToCustomer: '发送至客户',
  businessNo: '业务单号',
  collectionACSet: '收款账套',
  customerAddress: '客户地址',
  receivingMailbox: '接收邮箱',
  feeName: '费用名称',
  billingMethod: '计费方式',
  unitPrice: '单价',
  taxRate: '税率',
  taxAmount: '税额',
  amountExcludingTax: '不含税金额',
  expenseAmount: '费用金额',
  addExpenseConfirmation: '添加费用确认单',
  customerSettlementUnit: '客户结算单位',
  aRSettlementUnit: '应收结算单位',
  totalExpenses: '费用合计',
  mailNotes: '邮件备注',
  multipleMailboxPartitions: '多个邮箱地址之间请用；分隔',
  customerName: '客户名称',
  expenseConfirmation: '费用确认',
  applyForInvoicing: '申请开票',
  applyForWriteOff: '申请核销',
  confirmationNo: '确认单编号',
  confirmationState: '对账状态',
  sendStatus: '发送状态',
  invoicingApplicationStatus: '开票申请状态',
  invoiceState: '开票状态',
  containerDesc: '箱型箱量',
  currencyAmountJson: '费用总计',
  responsiblePersonName: '开单人员',
  drawer: '开单人',
  stringBusinessDate: '开单时间',
  toBeConfirmed: '待确认',
  selectExpensesToBeConfirmed: '请选择待确认或调整中的费用确认单',
  confirmed: '已确认',
  rejected: '被驳回',
  sendExpenseConfirmationToCustomer: '发送费用确认单至客户',
  useTheSameReceivingMailbox: '同一客户使用相同接收邮箱',
  useSameMailNotes: '同一客户使用相同邮件备注',
  details: '详情',
  transferInConfirmation: '转入确认单',
  pleaseSelectTransferInConfirmation: '请选择转入确认单',
  newExpenseConfirmation: '新建费用确认单',
  toChangeInto: '转入',
  pleaseCheckTheNeedTo: '请勾选需要从',
  transferInTheSelectedConfirmation: '中转出的费用项，转入所选择的确认单或新建确认单',
  allExpenseItemsWillBeMigratedTo: '当前全部费用项目将迁移到其他费用确认单，当前费用确认单将被删除，请谨慎操作！',
  editExpenseConfirmation: '费用确认单编辑',
  directEmail: '生成后直接发送到客户指定邮箱',
  emptySent: '已发送',
  notSent: '未发送',
  totalExpensesReceivable: '应收费用合计',
  checkAtLeastOnePieceOfData: '至少勾选一条数据',
  onlyOnePieceOfDataCanBeChecked: '只能勾选一条数据',
  sendConfirmationToCustomer: '发送确认单至客户',
  confirmationWillBeSentAsFollows: '将按照以下地址和备注信息发送费用确认单，是否继续？',
  sendOut: '发送',
  expenseEntity: '费用主体',
  entityNumber: '主体号码',
  expenseDeletionCannotBeRestored: '费用确认单删除后无法恢复，是否继续？',
  reconciliationStatusChanged: '对账状态已变更，如需调整请联系管理人员',
  relevantExpensesHaveBeenInvoiced: '相关费用已进入开票环节，无法调整',
  distributionIsAtLeastRequired: '提单分发至少一方必填',
  cannotBeChangedAfterConfirmationConfirmationSheetInformation: '确认后无法变更确认单信息，如需在开票前修改确认单信息，请联系管理人员，是否继续？',
  createdBy: '创建人',
  receivable: '应收',
  notMaintained: '未维护',
  maintained: '已维护',
  pyable: '应付',
  allRecorded: '全部入账',
  selectPosting: '选择入账',
  export: '导出',
  newBill: '新建账单',
  consolidatedStatement: '并入账单',
  entryMethod: '入账方式',
  expenseDetails: '费用明细',
  submit: '提交',
  masterOrderNumber: '主单号',
  billNumber: '账单编号',
  ownedSales: '所属销售',
  billStatus: '账单状态',
  pending: '待入账',
  hasBeenCredited: '已入账',
  Reconciled: '已对账',
  writeOffStatus: '核销状态',
  notWrittenOff: '未核销',
  writtenOff: '已核销',
  partialWriteOff: '部分核销',
  paymentACSet: '付款账套',
  supplierSettlementUnit: '供应商结算单位',
  supplierName: '供应商名称',
  confirmStatus: '确认状态',
  masterNo: '主单号',
  product: '所属产品',
  invoiceStateName: '收票状态',
  ticketsToBeReceived: '待收票',
  ticketsReceived: '已收票',
  partialTicketCollection: '部分收票',
  totalPayable: '应付合计',
  payableSettlementUnit: '应付结算单位',
  totalReceivable: '应收合计',
  searchConditionIsRequired: '检索条件：结算单位、结算方式 为必填项，请填写',
  billingCycle: '账单周期',
  expenseEntry: '费用入账',
  expenseList: '费用清单',
  expenseHasBeenAssociatedWithBill: '所选项对账状态不正确，请选择待入账的费用项操作',
  pleaseSelectTheSameCustomer: '所选费用的客户和结算单位不一致，请选择相同客户和相同结算单位的费用入账',
  theTicketSettlementFeeHasBeenConfirmed: '票结费用已经过确认，无需生成账单',
  theSearchConditionMustBeToBePosted: '检索条件必须为待入账',
  theFeeCannotBeModifiedAfterConfirmation: '确认后费用将无法修改，如需修改，请联系管理人员，是否继续？',
  inconsistentExpenseSettlementMethods: '所选费用的结算方式不一致，请选择结算方式一致的费用入账',
  totalExpensesPayable: '应付费用合计',
  singleBillGrossProfit: '单票毛利',
  fileName: '文件名',
  fileType: '文件类型',
  creationDate: '创建日期',
  fileSizeBbyte: '文件大小(byte)',
  fileFormat: '文件格式',
  expensesHaveBeenConfirmedAndCannotBeDeleted: '费用单已确认， 无法删除',
  changeOrderQuery: '改单查询',
  expenseDetailsFiltering: '费用明细过滤',
  changeOrderResultQuery: '改单结果查询',
  bill: '提单',
  changeOrderDialog1: '1、改单查询结果将通过邮件的方式通知到运营组；',
  changeOrderDialog2: '2、订舱信息发生变更时，除箱型箱量信息外，其他信息将自动更新系统数据，箱型箱量信息请按照邮件提示手动变更；',
  changeOrderDialog3: '3、提单信息发生变更时，将通过提单样单的方式反馈，请参考提单样单按需更新系统中的提单信息。',
  shippingCompany: '船公司',
  pleaseFillInAtLeastOneSearchCondition: '请至少填写一项检索条件',
  settlementUnitType: '结算单位类型',
  customer: '客户',
  destinationAgent: '目的港代理',
  destination: '目的地',
  exportOrderDetails: '订单业务明细',
  orderGrossProfitList: '订单毛利清单',
  adjustBoxTypeAndVolume: '调整箱型箱量',
  boxQuantityStatistics: '箱量统计',
  batchAdjustment: '批量调整',
  boxGenus: '箱属',
  beSpecified: '待指定',
  exit: '已退关',
  changeDocument: '变更单据',
  systemAutomaticDefault: '注意：录入费用项时计费方式、币种、数量、税率为系统自动填充的默认值，请仔细核对并按照实际情况调整！',
  successfullyReleasedTheCabinForQuery: '请勾选已成功放舱的订单进行查询',
  unconfirmed: '未确认',
  confirmationInProgress: '调整中',
  country: '国家',
  type: '类型',
  shippingAgency: '船代',
  yard: '堆场',
  shippingAgentFreightForwarder: '船代+货代',
  change: '变更',
  atMost5TextsCanBeAdded: '收发通 最多允许输入',
  atMost5TextsCanBeAdded2: '个字符，请调整行数或拼接到货物描述。',
  atMost5TextsCanBeAddedA: '最多允许输入',
  atMost5TextsCanBeAddedB: '个字符，请调整。',
  changeTheEntrustingUnit: '变更委托单位',
  payAttentionToCurrentCustomers: '注意：当前客户在  ',
  payAttentionToCurrentCustomers2: '上存在其他订舱信息，如需并单，请先进行并单操作，再进行费用录入！',
  adjustExpensesAsNeededCode1: '所关联订单中部分费用确认单已确认，无法将费用自动关联到当前并单的提单上，请联系结算测撤回确认单后手动调整关联费用。',
  adjustExpensesAsNeededCode2: '部分关联在SO上的费用已关联到当前提单下，请仔细核对当前订单中的应收应付费用',
  volumeChangeAfterBusinessOrderNoChange: '变更业务单号后箱量和箱型可能出现变化，请仔细确认费用是否需要调整！',
  pleaseSupplementTheExpenseInformation: '请补充费用项信息',
  overseasPosition: '海外仓头程',
  fbaHead: 'FBA头程',
  destinationServiceOnly: '仅目的港服务',
  lcl: '拼箱',
  customsClearance: '清关',
  trailer: '拖车',
  ticketSettlement: '票结',
  paymentInstructions: '付款指示',
  surchargeType: '附加费类型',
  paymentMethod: '付款方式',
  placeOfPayment: '付款地点',
  R24: 'R24 反恐申报信息',
  masterBill: '主提单',
  whetherToDeclareFreightForwarding: '是否申报货代提单',
  freightBillOfLading: '货代提单',
  addTo: '添加',
  nvoccFreightForwarderBillOfLadingNumber: 'NVOCC货代提单号码',
  scacCodeUS: 'SCAC 代码 (US)',
  scacCode: 'SCAC 代码',
  _8000Code: '8000 代码',
  _8000CodeCA: '8000 代码 (CA)',
  actualShipper: '实际发货人',
  actualConsignee: '实际收货人',
  actualFirstNotifyingParty: '实际第一通知方',
  actualSecondNotifyingParty: '实际第二通知方',
  pleaseEnterCompleteData: '请输入完整数据',
  rateReferenceNumber: '费率参考号码',
  countryOfOrigin: '原产地',
  secondNotifyingPartyInformation: '第二通知方信息',
  otherNotifyingPartyInformation: '其他通知方信息',
  shipNameVoyageAndCourse: '船名、航次以及航向',
  finalDestination: '最终目的地',
  marksNumbers: '唛头及号码',
  cargoDescriptionSummary: '货物描述摘要',
  numberOfPiecesAndPackagingUnit: '件数及包装单位',
  caseSealNo: '箱封号',
  rateType: '费率类型',
  showOnBillOfLading: '在提单上显示',
  displayForwarderInformationOnBillOfLading: '在提单上显示货代信息',
  indian24PermanentAccountNumber: 'Indian24-永久账号号码',
  importerExporterCode: '进出口商代码',
  messageID: '邮件ID',
  salesTaxIDNo: '销售税身份证号码',
  taxIDType: '税号类型',
  consigneeTaxId: '收货人税号',
  sendReplenishment: '发送补料',
  replenishmentCompleted: '补料完成',
  replenishmentAccountNo: '补料账号',
  uploadTheShippingCompanySBillOfLading: '上传船司提单',
  uploadFiles: '上传文件',
  pleaseCarefullySelectTheOperationAccount: '注意：请仔细选择操作账号，账号错误可能导致补料失败。',
  ifThereAreOtherCases: '如果有过属于其他情况，请说明',
  pleaseEnterETDTime: '请输入ETD时间',
  exportIsAllowedFor1MonthAtMost: '最多允许导出1个月的明细数据，请尝试分批导出',
  billOfLadingStatus: '提单状态',
  supplementing: '补料处理中',
  blDistributionError1: 'Sea Waybill提单总共最多只能提供3份',
  blDistributionError2: '正本提单最多只能提供3份',
  blDistributionError3: '提单副本总共最多只能提供9份',
  billOfLadingReplenishmentCompleted: '提单补料操作完成，请留意船司反馈井及时上传船司提单',
  expenseTemplate: '费用模板',
  saveAsCurrentCustomerExpenseTemplate: '保存为当前客户费用模板',
  saveExpenseItemUnitPrice: '保存费用项单价',
  templateName: '模板名称',
  specifyInvoiceType: '注意：默认所有发票类型为增值税普通发票，如客户需要开局其他类型的发票，请指定发票类型',
  duplicateTemplateName: '模板名称重复 ，是否覆盖原有模板？',
  pleaseSelectABusinessOrderNumberFirst: '请先选择业务单号',
  canadianCustomsDeclaration: '加拿大报关',
  nonShippingServiceFlag: '非船司服务',
  businessTag: '业务标签',
  saveCreate: '保存后开始创建船司提单',
  responsibleParty: '责任方',
  authorizer: '授权人',
  signatureAuthorized: '授权人签字',
  shipperToCarrier: '托运人应传送货柜秤重并由托运人正式授权专人签名给承运人',
  containsSpecialCharacters: '含有特殊字符，请重新编辑'
}
