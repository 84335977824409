export default {
	sailingInfo: '船期信息',
    routeLine: '航线',
    estimateDuration: '预计航行时间',
    dayText: '天',
    carryingDuration: '载重时间',
    estimateLeave: '预计离港',
    estimateArrive: '预计到达',
    polToPod: '装货港-卸货港',
    pol: '装货港',
    pod: '卸货港',
    vessel: '船名/航次',
    deliveryInfo: '货运信息',
    consigner: '发货人',
    street: '街道',
    streetPlaceholder: '请输入街道名称',
    city: '所在城市',
    cityPlaceholder: '请输入所在城市',
    areaText: '县/区',
    province: '省/州',
    country: '国家',
    postCode: '邮编',
    shipper: '联系人',
    lastName: '姓',
    firstName: '名',
    contactPhone: '联系电话',
    otherDeliveryInfo: '其他货运信息',
    consignee: '收货人',
    notifier: '通知人',
    goodsInfo: '货物信息',
    goodsType: '货物类型',
    goodsTypeEmptyInvalid: '请选择货物类型',
    pleaseUploadTheGoodsList: '请上传货物清单',
    goodsDesc: '货物描述',
    goodsDescPlaceholder: '请输入货物描述',
    goodsDescEmptyInvalid: '货物描述为必填项',
    englishInputOnlyInvalid: '仅支持英文和标点输入',
    casNo: 'CAS号码（化工品必填）',
    goodsList: '货物清单',
    containerInfo: '集装箱信息',
    containerSelectEmptyInvalid: '请选择箱型',
    grossWeight: '毛重（每箱）',
    socFlag: '货主自有',
    bookingRemark: '订舱备注',
    nonRequired: '（非必填）',
    remarkPlaceholder: '其他订舱备注信息，200字以内',
    deliveryMode: 'so出单方式',
    deliveryModeEmptyInvalid: '请选择so出单方式',
    multipleContainerOneTicket: '多柜一票',
    oneContainerOneTicket: '一柜一票',
    confirmEmail: '我希望发送订舱至',
    confirmEmailPlaceholder: "多个邮件地址请用分号';'隔开(最多允许255个字符长度)",
    socFlagAllot: '提还箱点分配',
    socFlagOneYard: '单一堆场提箱',
    socFlagMultipleYard: '跨堆场提箱',
    socFlagCustom: '自定义提箱',
    returnYard: '还箱点',
    polReturnYard: '起运港还箱点',
    yardName: '提箱点',
    releaseQuantity: '箱管放箱数',
    spaceQuantity: '已放舱',
    surplusQuantity: '剩余箱量',
    distributeFlag: '分配提箱点',
    distributeQuantity: '当前SO配箱数',
    containerNotReleaseTips: '审核通过后实际分配结果可能与页面展示不一致，请以实际分配结果为准',
    passAutoSendConfirmLetter: '确认放舱直接发送订舱确认函',
    rejectBtn: '驳回',
    verified: '审核通过',
    suitcaseNumCheckFail: '提箱数量校验失败',
    addressEmptyInvalid: '街道名称位必填项',
    inputNumberInterpunctionInvalid: '仅支持英文数字和标点输入',
    cityRequireInvalid: '所在城市为必填项',
    inputFormatInvalid: '请使用英文键盘输入字母(a-z, A-Z), 数字(0,9)和非字母数字字符 (! @ # s %)',
    inputZeroError: '请输入大于0的数字',
    containerQtyInvalid: '修改箱量时，如果需要增加箱量，可以在此模块操作，如果需要减少箱量，请在箱货信息列表中进行删除操作，请勿在此进行减少箱量操作。',
    limitOneToTowHundred: '请输入{minNum}-{maxNum}的正整数',
    inputQtyLessInvalid: '输入的数量不能小于已作业的箱子数量！',
    inputNumberWithSpace: '请输入数字(可以包含空格)',
    requireCompanyNameInvalid: '公司名称为必填项',
    companyLimit70: '公司名称最长允许70个字符',
    removeLessThanOne: '请至少保留一条集装箱信息',
    removeFailded: '删除失败',
    saveFirst: '有正在编辑的数据，请先进行保存！',
    requireContainerTypeInvalid: '【箱型】为必填项',
    inputNumberInvalid: '【毛重】仅支持数字和小数点输入',
    ownerSameTypeInvalid: '货主自有和箱型相同的数据已存在',
    containerTypeSaveFailed: '箱型数据保存失败',
    containerTypeInvalid: '集装箱信息校验未通过：',
    addressInfoInvalid: '地址无效',
    casNoInvalid: 'CAS号码不能输入中文',
    consigneeInvalid: '收货人校验失败',
    consignerInvalid: '发货人校验失败',
    notifierInvalid: '通知人校验失败',
    goodsInfoFormatInvalid: '【货物描述】为必填项且仅支持英文、数字和标点输入;',
    goodsInfoInvalid: '货物信息校验失败',
    containerInfoInvalid: '集装箱信息校验失败:有存在的未保存行数据！',
    requiredRejectReason: '驳回时驳回理由为必填项',
    rejectFailed: '驳回操作失败',
    submitSuccessAndSendEmail: '保存成功！',
    saveFailed: '保存操作失败',
    allotQuantityDifferent: '配箱数同客户订舱数不一致，请确认后再提交',
    chineseName: '中文品名',
    chineseNamePlaceholder: '请输入中文品名',
    fileUpload: '文件上传',
    fileType: '文件类型',
    selectFile: '选择文件',
    uploadComments: '上传备注',
    addFile: '添加文件',
    pleaseSelect: '请选择',
    operation: '操作',
    preview: '预览',
    download: '下载',
    see: '查看',
    clickUpload: '点击上传',
    polFreeDays: '起运港免箱期',
    podFreeDays: '目的港免箱期',
    boxSetting: '用箱设置',
    customerOrderNo: '客户订单编号'
}
