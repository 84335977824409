export default {
  soNo: '订舱号',
	soNoPlaceholder: '请输入订舱号',
  vesselName: '船名',
  vesselNamePlaceholder: '请输入船名',
  voyageNo: '航次',
  voyageNoPlaceholder: '请输入航次',
  polCode: '装货港',
  podCode: '卸货港',
  hblNos: "分提单号",
  subOrderFlag: "是否分单",
  transportTerm: "运输条款",
  memberName: "客户",
  containerSizeTypeQty: "箱型箱量",
  casManifestAdd: "舱单制作",
  export: "导出",
  yes: "是",
  no: "否",
  operating: "操作",
  view: "查看",
  cancel: "取消",
  determine: "确定",
  casManifestAddTips1: "1.输入需要制作预配舱单的单号，确认后系统会跳转至舱单制作界面，维护具体的舱单信息，完成预配舱单的制作！",
  casManifestAddTips2: "2.如输入的SO号，已进行过舱单制作时，则会跳转至该舱单的编辑界面！",
  casManifestBase: "基础信息",
  soNo2: "SO号",
  entityNo: "舱单号",
  vesselNameAndvoyageNo: "船名/航次",
  paymentMode: "付款方式",
  prepaid: "Prepaid",
  collect: "Collect",
  transceiverMessage: "收发通信息",
  shipper: "发货人（SHIPPER）",
  shipperCode: "发货人代码",
  shipperCountry: "发货人国家",
  shipperTel: "发货人电话",
  aeo: "AEO编号",
  shipperName: "发货人名称",
  shipperAddress: "发货人地址",
  tel: "电话",
  fax: "传真",
  consignee: "CONSIGNEE (收货人)",
  consigneeCode: "收货人代码",
  consigneeCountry: "收货人国家",
  consigneeTel: "收货人电话",
  consigneeName: "收货人名称",
  consigneeAddress: "收货人地址",
  notifier: "NOTIFY PARTY (通知人) ",
  notifierCode: "通货人代码",
  notifierCountry: "通货人国家",
  notifierTel: "通货人电话",
  notifierName: "通货人名称",
  notifierAddress: "通货人地址",
  nextStep: "下一步",
  lastStep: "上一步",
  detailedData: "舱单箱货明细",
  customsWithdrawalNo: "报关分提单号",
  addSplit: "添加分单",
  deleteSplit: "删除分单",
  copyBtn: "复制分单",
  getBoxNumber: "选择箱货信息",
  cargoEdit: "配件修改",
  delete: "移除箱子",
  addBox: "新增箱子",
  containerNo: "箱号",
  sealNo: "封号",
  containerType: "箱型",
  productQuantity: "件数",
  packageType: "包装类型",
  grossWeight: "毛重(KGS)",
  volume: "体积(CBM)",
  goodsName: "英文品名",
  mark: "唛头",
  hsCode: 'HS CODE',
  containerNoError: "箱号格式不正确",
  pleaseSelectARowFirst: "请先选择一条信息!",
  delSelectARowFirstWarming: "请至少保留一条信息!",
  vinStrsRequired: "车架号必填",
  vin: "车架号",
  cargoGrossWeight: "配件毛重",
  cargoVolume: "配件体积",
  cargoGoodsName: "配件品名",
  cargoMarks: "配件唛头",
  containerMessage: "装箱信息",
  containerDetail: "装箱明细",
  sumContainer: "总箱量",
  sumQuantity: "总件数",
  sumWeight: "总毛重",
  sumVolume: "总体积",
  brand: "品牌",
  model: "型号",
  newEnergyFlag: "新能源",
  volume2: "体积",
  weight: "重量",
  fittingsQuantity: "配件数量",
  fittingsPackageType: "配件包装单位",
  bracketType: "托架型号",
  bracketWeight: "托架重量",
  noQueryResult: "无查询结果",
  getBoxNumberTip: "注：已选用的车架，再未剔除前将无法在此查看选择到；",
  selectVinTip1: "本次查询车架数量",
  selectVinTip2: "查询结果数量",
  selectVinTip: "本次筛查选择",
  vinNumTip: "个车架",
  successSaved: "保存成功",
  exportTaskSuccess: "导出操作成功，请稍后去任务列表查看导出结果！",
  exportImport: "舱单模版导入",
  exportImportEdit: "舱单修改导入",
  uploadFile: "模版导入",
  caiCangTemplateExcel: "太仓口岸模版",
  caiCangExcel: "太仓口岸舱单导入模板.xlsx",
  operationMode: "操作模式",
  manualAdd: "手工新增",
  importAdd: "导入新增",
  backList: "返回列表",
  edit: "编辑",
  delSuccess: "删除成功",
  hblUpdRequestWarming: "所选数据缺少箱号或封号或箱型数据，请维护",
  hblUpdRequest2Warming: "所选数据缺少件数或包装类型或毛重或体积或品名数据，请维护",
  batchCargoWarning: "注：维护的毛重和体积确定后，将会根据箱子的件数自动计算箱子配件的毛重和体积；另外维护的品名将默认输入在各个箱子！",
  fittingsFlagDesc: "配件报关",
  requestMessage: "请完善必填信息！",
  boxEdit: "数据修改",
  fclFlag: "整箱/拼箱",
  fclFlagTrue: "整箱",
  fclFlagFlase: "拼箱",
  exportWarning: "导出请输入订舱号或报关分提单号或船名加航次数据",
  cntacExport: "太仓预配舱单导出",
  vgmExport: "VGM清单导出",
  manifestExport: "出口舱单导出",
  outExport: "出口集装箱清单导出",
  specialListExport: "SPECIAL LIST清单",
  ediExport: "EDI导出",
  hblCount: "报关单量",
  cntacShippingListExport: "太仓出运清单导出",
  sumTotal: "总报关票数",
  ticket: "票",
  totalCartons: "总箱数",
  vehicleInformationListExport: "单航次车辆信息明细",
  entityState: "舱单状态",
  all: "全部",
  statePrepare: "待制单",
  stateDocumented: "已制单",
  stateExport: "已导出",
  stateAbort: "中止",
  reconfiguration: "改配",
  manifestModification: "舱单改配",
  tip: "注：",
  manifestModificationTips1: "1.输入的SO号如未生成预配舱单时，系统将自动生成预配舱单；",
  manifestModificationTips2: "2.输入的SO号已生成预配舱单时，系统将所需改配的报关分提单号调整至该SO下，报关分提单号将不会做调整；",
  manifestModificationTips3: "3.所需要改配的报关分提单号，如有配件报关时，系统将会把对应的配件报关的单据调整至新的SO下；",
  manifestModificationTips4: "4.该类改配调整只针对汽车箱业务；"

}
