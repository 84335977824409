var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type == "trade"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.termListApiFn,
              fields: ["chineseName", "englishName"],
              headers: ["中文名", "英文名"],
              "value-key": "englishName",
              "label-key": "englishName",
              disabled: _vm.disabled,
              tips: _vm.tips,
              defaultDisplay: _vm.defaultDisplay,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "airport"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.airportListApiFn,
              headers: ["英文名称", "三字码", "中文名称"],
              fields: ["englishName", "code3", "chineseName"],
              "value-key": "code3",
              "label-key": "code3",
              disabled: _vm.disabled,
              tips: _vm.tips,
              defaultDisplay: _vm.defaultDisplay,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "seaport"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              newMultiple: _vm.newMultiple,
              method: _vm.seaportListApiFn,
              headers: ["英文名称", "五字码", "中文名称"],
              fields: ["englishName", "code5", "chineseName"],
              "value-key": _vm.seaportKeyValue,
              "label-key": _vm.seaportKeyValue,
              disabled: _vm.disabled,
              tips: _vm.tips,
              defaultDisplay: _vm.defaultDisplay,
              disabledKey: _vm.disabledKey,
              placeholder: _vm.placeholder,
              size: _vm.size,
              clearable: _vm.clearable,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "seaportEnName"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              newMultiple: _vm.newMultiple,
              method: _vm.seaportListApiFn,
              headers: ["英文名称", "五字码", "中文名称"],
              fields: ["englishName", "code5", "chineseName"],
              "value-key": "code5",
              "label-key": "englishName",
              disabled: _vm.disabled,
              tips: _vm.tips,
              defaultDisplay: _vm.defaultDisplay,
              placeholder: _vm.placeholder,
              size: _vm.size,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "addSeaport"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.seaportAddListApiFn,
              headers: ["英文名称", "五字码", "中文名称", "是否已添加"],
              fields: [],
              "value-key": "id",
              "label-key": "code5",
              "disabled-key": "checked",
              disabled: _vm.disabled,
              tips: _vm.tips,
              defaultDisplay: _vm.defaultDisplay,
            },
            on: { selected: _vm.selectedFn },
            scopedSlots: _vm._u(
              [
                {
                  key: "option",
                  fn: function (ref) {
                    var row = ref.row
                    return _vm._l(
                      ["englishName", "code5", "chineseName", "checked"],
                      function (field) {
                        return _c("div", { key: field, staticClass: "item" }, [
                          field == "checked"
                            ? _c("span", [
                                row[field]
                                  ? _c("i", {
                                      staticClass:
                                        "iconfont icon-checkmark-outline",
                                    })
                                  : _vm._e(),
                              ])
                            : _c("span", [_vm._v(_vm._s(row[field]))]),
                        ])
                      }
                    )
                  },
                },
              ],
              null,
              false,
              4239788399
            ),
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "customer"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            staticClass: "w-full",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.customerListApiFn,
              fields: _vm.customerFields,
              headers: _vm.customerHeaders,
              "value-key": "id",
              "label-key": "chineseName",
              disabled: _vm.disabled,
              defaultDisplay: _vm.defaultDisplay,
              tips: _vm.tips,
              size: _vm.size,
              newMultiple: _vm.newMultiple,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "channelCustomer"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            staticClass: "w-full",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.channelCustomerListApiFn,
              fields: ["chineseName", "directCustomerTypeName"],
              headers: ["中文名", "类型"],
              "value-key": "id",
              "label-key": "chineseName",
              disabled: _vm.disabled,
              defaultDisplay: _vm.defaultDisplay,
              tips: _vm.tips,
              size: _vm.size,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "supplier"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            staticClass: "w-full",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.supplierListApiFn,
              fields: ["chineseName", "englishName"],
              headers: ["中文名", "英文名"],
              "value-key": "id",
              "label-key": "chineseName",
              disabled: _vm.disabled,
              defaultDisplay: _vm.defaultDisplay,
              tips: _vm.tips,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "airLine"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.airLineListApiFn,
              fields: ["chineseName", "englishName"],
              headers: ["中文名", "英文名"],
              "value-key": "id",
              "label-key": "chineseName",
              disabled: _vm.disabled,
              defaultDisplay: _vm.defaultDisplay,
              tips: _vm.tips,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "shippingCompany"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.getShippingCompanyListApiFn,
              fields: ["chineseName", "englishName"],
              headers: ["中文名", "英文名"],
              "value-key": "id",
              "label-key": "chineseName",
              disabled: _vm.disabled,
              defaultDisplay: _vm.defaultDisplay,
              tips: _vm.tips,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "deliveryCompany"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.getExpressDeliveryListApiFn,
              fields: ["chineseName", "englishName"],
              headers: ["中文名", "英文名"],
              "value-key": "id",
              "label-key": "chineseName",
              disabled: _vm.disabled,
              defaultDisplay: _vm.defaultDisplay,
              tips: _vm.tips,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "member"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.memberListApiFn,
              fields: ["chineseName", "englishName"],
              headers: ["中文名", "英文名"],
              "value-key": "id",
              "label-key": "chineseName",
              disabled: _vm.disabled,
              defaultDisplay: _vm.defaultDisplay,
              tips: _vm.tips,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "settlementCompany"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.settlementCompanyApiFn,
              fields: ["settlementCompanyName", "englishName"],
              headers: ["中文名", "英文名"],
              "value-key": "settlementCompanyId",
              "label-key": "settlementCompanyName",
              disabled: _vm.disabled,
              tips: _vm.tips,
              defaultDisplay: _vm.defaultDisplay,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "businessFee"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.getFeeListApiFn,
              "value-key": _vm.valueKey || "code",
              "label-key": "chineseName",
              disabled: _vm.disabled,
              fields: ["code", "chineseName", "englishName"],
              headers: ["代码", "中文名", "英文名"],
              defaultDisplay: _vm.defaultDisplay,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "fmsFee"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.getFmsFeeListApiFn,
              "value-key": "code",
              "label-key": "chineseName",
              disabled: _vm.disabled,
              fields: ["code", "chineseName"],
              headers: ["代码", "中文名"],
              defaultDisplay: _vm.defaultDisplay,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "ovlProduct"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            staticClass: "w-full",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.getProductList,
              "value-key": "productCode",
              "label-key": "productName",
              disabled: _vm.disabled,
              fields: ["productName", "productCode"],
              headers: [
                _vm.$t("logisticsProduct.logistics_product_name"),
                _vm.$t("logisticsProduct.logistics_product_code"),
              ],
              defaultDisplay: _vm.defaultDisplay,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "operation"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.getAllOperationApiFn,
              "value-key": "id",
              "label-key": "chineseName",
              disabled: _vm.disabled,
              fields: ["chineseName"],
              headers: [],
              clearable: "",
              defaultDisplay: _vm.defaultDisplay,
              tips: false,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "operationSpe"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.getAllOperationSpeApiFn,
              "value-key": "id",
              "label-key": "chineseName",
              disabled: _vm.disabled,
              fields: ["chineseName"],
              headers: [],
              clearable: "",
              defaultDisplay: _vm.defaultDisplay,
              tips: false,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "user"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.getUserDropDownListFun,
              "value-key": "id",
              "label-key": "chineseName",
              disabled: _vm.disabled,
              fields: ["chineseName"],
              headers: [],
              clearable: "",
              tips: false,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "vesselName"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.getVesselNameDropDownListFun,
              "value-key": "id",
              "label-key": "wholeName",
              disabled: _vm.disabled,
              defaultDisplay: _vm.defaultDisplay,
              fields: ["wholeName"],
              headers: ["船名/航次"],
              clearable: "",
              tips: false,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "sailing"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.getSailingDropDownListFun,
              "value-key": "id",
              "label-key": "wholeName",
              disabled: _vm.disabled,
              defaultDisplay: _vm.defaultDisplay,
              fields: ["wholeName"],
              headers: ["船名/航次"],
              clearable: "",
              tips: false,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "customerYHT"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            staticClass: "w-full",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.customerYHTListApiFn,
              fields: ["label"],
              headers: ["中文名"],
              "value-key": "value",
              "label-key": "label",
              disabled: _vm.disabled,
              defaultDisplay: _vm.defaultDisplay,
              tips: _vm.tips,
              size: _vm.size,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "sailingSchedule"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.getScheduleListFromPortFun,
              "value-key": "sailingId",
              "label-key": "vessel",
              disabled: _vm.disabled,
              defaultDisplay: _vm.defaultDisplay,
              fields: ["vessel", "voyage"],
              headers: ["船名", "航次"],
              clearable: "",
              tips: false,
              placeholder: _vm.placeholder,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "entityApply"
        ? _c("advance-select", {
            ref: "advanceSelectRef",
            staticClass: "w-full",
            attrs: {
              multiple: _vm.multiple,
              method: _vm.getCasJobBlListByBlNoApiFun,
              fields: ["blNo"],
              headers: ["订舱号"],
              "value-key": "id",
              "label-key": "blNo",
              disabled: _vm.disabled,
              defaultDisplay: _vm.defaultDisplay,
              tips: _vm.tips,
              size: _vm.size,
            },
            on: { selected: _vm.selectedFn },
            model: {
              value: _vm.modelValue,
              callback: function ($$v) {
                _vm.modelValue = typeof $$v === "string" ? $$v.trim() : $$v
              },
              expression: "modelValue",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }