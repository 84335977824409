var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          staticClass: "w-full",
          attrs: {
            filterable: "",
            disabled: _vm.disabled,
            "filter-method": _vm.selectFilter,
          },
          on: { "visible-change": _vm.visibleChange },
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.filteredOptions, function (item, index) {
      return _c(
        "el-option",
        {
          key: item[_vm.valueKey],
          attrs: { label: item[_vm.nameKey], value: item[_vm.valueKey] },
        },
        [_vm._t("default", null, { option: item, index: index })],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }