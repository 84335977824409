// 约价
import request from '@/utils/request'

// 运输条款编辑提交
export function getPortByTransportTermApi(params) {
  return request({
    url: `/api/spm-service/spm/port/base/getPortByTransportTerm`,
    method: 'get',
    params
  })
}

// 运输条款新增
export function postTransportFeeApi(data) {
  return request({
    url: `/api/cpm-service/cpm/transport/fee`,
    method: 'POST',
    data
  })
}
// 运输条款编辑提交
export function putTransportFeeApi(data) {
  return request({
    url: `/api/cpm-service/cpm/transport/fee/${data.id}`,
    method: 'PUT',
    data
  })
}
// 运输条款编辑
export function postTransportFeeDetailApi(id) {
  return request({
    url: `/api/cpm-service/cpm/transport/fee/detail/${id}`,
    method: 'GET'
  })
}

// 运输条款查询
export function postTransportFeeListApi(params) {
  return request({
    url: `/api/cpm-service/cpm/transport/fee/list`,
    method: 'GET',
    params
  })
}
// 免用箱查询
export function postPortFeeListApi(params) {
  return request({
    url: `/api/cpm-service/cpm/port/free/setting/pages`,
    method: 'GET',
    params
  })
}
// new 免用箱查询
export function postPortContractFeeListApi(params) {
  return request({
    url: `/api/cpm-service/cpm/port/free/setting/contract/list`,
    method: 'GET',
    params
  })
}
// 長期约免用箱查询
export function postPortSettingContractFeeListApi(data) {
  return request({
    url: `/api/cpm-service/cpm/port/free/setting/contract/batch/list`,
    method: 'post',
    data
  })
}
// 根据船期查FAK免用箱
export function getChargePortFreeApi(sailingid) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/port/free/${sailingid}`,
    method: 'GET'
  })
}
// 1 港口LOCALCHARGE 费用创建
export function postPortChargeAddApi(data) {
  return request({
    url: `/api/cpm-service/cpm/port/charge`,
    method: 'POST',
    data
  })
}
// 港口LOCALCHARGE 费用调整
export function putPortChargeEditApi(data) {
  return request({
    url: `/api/cpm-service/cpm/port/charge/${data.id}`,
    method: 'PUT',
    data
  })
}
// 1 港口LOCALCHARGE 分页查询
export function postPortChargeListApi(params) {
  return request({
    url: `/api/cpm-service/cpm/port/charge/pages`,
    method: 'GET',
    params
  })
}
// 1 港口LOCALCHARGE 详情
export function postPortChargeDetailApi(id) {
  return request({
    url: `/api/cpm-service/cpm/port/charge/detail/${id}`,
    method: 'GET'
  })
}
// 1 港口LOCALCHARGE 删除
export function postPortChargeDelApi(id) {
  return request({
    url: `/api/cpm-service/cpm/port/charge/${id}`,
    method: 'DELETE'
  })
}
// 费用条款下拉选
export function getQueryTransportTermApi() {
  return request({
    url: `/api/cpm-service/cpm/transport/fee/queryTransportTerm`,
    method: 'GET'
  })
}

// 约价基础数据下拉
export function getEnumerateListApi(type) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/getCpmEnumType?type=${type}`,
    method: 'GET'
  })
}

// 约价管理列表
export function getContractPriceListApi(params) {
  return request({
    url: `/api/cpm-service/cpm/contract/pages`,
    method: 'GET',
    params
  })
}

// 约价新建 - FAK约号下拉
export function getContractNoDropdownApi(params) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/getFakContractNo`,
    method: 'GET',
    params
  })
}

// 船名下拉列表 - 带出航次
export function getVesselNameDropDownListApi(params) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/getValidSail`,
    method: 'GET',
    params
  })
}

// 订舱渠道管理 - 列表
export function getChannelListApi(params) {
  return request({
    url: `/api/ccm-service/ccm/channel/pages`,
    method: 'GET',
    params
  })
}

// 订舱渠道管理 - 详情
export function getChannelDetailApi(id) {
  return request({
    url: `/api/ccm-service/ccm/channel/detail/${id}`,
    method: 'GET'
  })
}

// 订舱渠道管理 - 提交新建
export function createChannelApi(data) {
  return request({
    url: `/api/ccm-service/ccm/channel`,
    method: 'POST',
    data
  })
}

// 订舱渠道管理 - 提交编辑
export function updateChannelApi(data) {
  return request({
    url: `/api/ccm-service/ccm/channel/${data.id}`,
    method: 'PUT',
    data
  })
}

// 订舱渠道管理 - 删除
export function deleteChannelApi(id) {
  return request({
    url: `/api/ccm-service/ccm/channel/${id}`,
    method: 'DELETE'
  })
}

// 获取未创建费用客户列表
export function getChannelCustomerDropdownApi(params) {
  return request({
    url: `/api/ccm-service/ccm/channel/customer/selections`,
    method: 'get',
    params
  })
}

// 查询船期免用箱
export function getSailPortByVesselApi(params) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/getSailPortByVessel`,
    method: 'GET',
    params
  })
}
// FAK页面，根据船期查询运输条款及港口
export function getQueryTransportTermBySailApi(params) {
  return request({
    url: `/api/cpm-service/cpm/transport/fee/queryTransportTermBySail`,
    method: 'GET',
    params
  })
}
// 查询基本类型枚举 1 约价类型
// 2 支付类型
// 3 附加费类型
// 4 货物类型
// 5 约的状态
export function getCpmEnumTypeApi(type) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/getCpmEnumType?type=${type}`,
    method: 'GET'
  })
}
// 1.1.7  获取客户渠道列表
export function getCustomerChannelApi(params) {
  return request({
    url: `/api/crm-service/omc/crm/customer/channel`,
    method: 'GET',
    params
  })
}
// 查询localcharge配置
export function getLocalChargeBySailApi(params) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/getLocalChargeBySail`,
    method: 'GET',
    params
  })
}
// 1.1-1 fak合约提交
export function postContracFaktCommitApi(data) {
  return request({
    url: `/api/cpm-service/cpm/contract/fak/commit`,
    method: 'POST',
    data
  })
}
// 1.1-2 fak合约创建草稿
export function postContracFaktDraftApi(data) {
  return request({
    url: `/api/cpm-service/cpm/contract/fak/draft`,
    method: 'POST',
    data
  })
}
// 根据约id查询详情信息
export function getContractChargeDetailsApi(id) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/${id}`,
    method: 'GET'
  })
}
// 根据fak约号查询基本信息
export function getFakContractByNoApi(contractNo) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/getFakContractByNo?contractNo=${contractNo}`,
    method: 'GET'
  })
}
// 1.1.7  获取客户列表
export function getCustomerSelectionsApi(params) {
  return request({
    url: `/api/crm-service/crm/customer/selections`,
    method: 'GET',
    params
  })
}
// 1.1-3 长期约价合约提交
export function getContractLongTermCommitApi(data) {
  return request({
    url: `/api/cpm-service/cpm/contract/longTerm/commit`,
    method: 'POST',
    data
  })
}
// 1.1-4 长期约价合约创建草稿
export function getContractLongTermDraftApi(data) {
  return request({
    url: `/api/cpm-service/cpm/contract/longTerm/draft`,
    method: 'POST',
    data
  })
}
// 1.1-5 非长期约价合约提交
export function getContractNonLongTermCommitApi(data) {
  return request({
    url: `/api/cpm-service/cpm/contract/nonLongTerm/commit`,
    method: 'POST',
    data
  })
}
// 1.1-6 非长期约价合约创建草稿
export function getContractNonLongTermDraftApi(data) {
  return request({
    url: `/api/cpm-service/cpm/contract/nonLongTerm/draft`,
    method: 'POST',
    data
  })
}

// 查询收发通
export function getContractScnApi(params) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/getContractScn`,
    method: 'GET',
    params
  })
}

// 1.1-7 根据约价id删除
export function delContractApi(id) {
  return request({
    url: `/api/cpm-service/cpm/contract/${id}`,
    method: 'DELETE'
  })
}
// 1.1-8 根据约价id作废
export function putContractApi(data) {
  return request({
    url: `/api/cpm-service/cpm/contract/cancel`,
    method: 'PUT',
    data
  })
}
// 1.1-9 约价合同调整
export function putContractAdjustApi(data) {
  return request({
    url: `/api/cpm-service/cpm/contract/adjust`,
    method: 'PUT',
    data
  })
}
// 查询操作日志
export function getContractLogApi(id) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/getContractLog/${id}`,
    method: 'GET'
  })
}

// 5.2提单收发通创建
export function getScnBlSaveApi(data) {
  return request({
    url: `/api/cpm-service/scn/agent/info/bl/save`,
    method: 'POST',
    data
  })
}

// 查看口岸提单设置
export function getPortBlConfigApi(params) {
  return request({
    url: `/api/spm-service/spm/port/bl/getByPort`,
    method: 'GET',
    params
  })
}

// 保存口岸设置
export function createPortBlConfigApi(data) {
  return request({
    url: `/api/spm-service/spm/port/bl`,
    method: 'POST',
    data
  })
}

// 编辑口岸设置
export function updatePortBlConfigApi(data) {
  return request({
    url: `/api/spm-service/spm/port/bl/${data.id}`,
    method: 'PUT',
    data
  })
}

// 通过港口查询船期
export function getScheduleListFromPortApi(params) {
  return request({
    url: `/api/cas-service/omc/cas/sailing/schedule/listByPod`,
    method: 'GET',
    params
  })
}

// 根据船期更新提单价格
export function updateChargeRecordScheduleApi(data) {
  return request({
    url: `/api/cas-service/omc/cas/createTaskUpdateCharge`,
    method: 'POST',
    data
  })
}
// 根据船期ID查询FAK海运费
export function getSailingFreeApi(sailingId) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/ocean/free/${sailingId}`,
    method: 'GET'
  })
}
// 查询约价海运费
export function postContractOceanFeeApi(data) {
  return request({
    url: `/api/cpm-service/cpm/contract/ocean/fee/list`,
    method: 'POST',
    data
  })
}
// 查询约价海运费
export function postContractBaseFeeApi(data) {
  return request({
    url: `/api/cpm-service/cpm/contract/base`,
    method: 'POST',
    data
  })
}
// 非长期约价查询免用箱信息
export function getContractSailingPortApi(params) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/sailing/port/free`,
    method: 'get',
    params
  })
}
// 2.5 获取约价合约信息（订舱/提单）
export function getContractBaseBlApi(contractNo) {
  return request({
    url: `/api/cpm-service/cpm/contract/base/${contractNo}`,
    method: 'get'
  })
}
// 根据约id查询详情信息
export function getContractApprovalDetailApi(id) {
  return request({
    url: `/api/cpf-service/flw/contract/manage/approval/detail/${id}`,
    method: 'get'
  })
}
// 1.4 审批转交
export function getContractManageTransferApi(data) {
  return request({
    url: `/api/cpf-service/flw/contract/manage/operate/transfer`,
    method: 'post',
    data
  })
}

// 校验是否审批最后一步
export function checkApprovalLastStepApi(data) {
  return request({
    url: `/api/cpf-service/flw/contract/manage/operate/approval/check`,
    method: 'post',
    data
  })
}

// 1.5 审批同意
export function getContractManageApprovalApi(data) {
  return request({
    url: `/api/cpf-service/flw/contract/manage/operate/approval`,
    method: 'post',
    data
  })
}
// 1.6 审批拒绝
export function getContractManageRejectApi(data) {
  return request({
    url: `/api/cpf-service/flw/contract/manage/operate/reject`,
    method: 'post',
    data
  })
}
// 1.7 获取操作日志(旧)
// export function getContractOperateLogApi(data) {
//   return request({
//     url: `/api/cpf-service/flw/contract/operate/log/${data.applyType}/${data.id}`,
//     method: 'get'
//   })
// }

// 1.7 获取审批记录(新)
export function getContractOperateLogApi(id) {
  return request({
    url: `/api/cpm-service/cpm/contract/flw/operate/log/${id}`,
    method: 'get'
  })
}

// 获取变更记录
export function getContractChangedLogApi(id) {
  return request({
    url: `/api/cpm-service/cpm/contract/operate/log/${id}`,
    method: 'get'
  })
}

// 变更记录跳转获取详情
export function getContractChangedDetailApi(id) {
  return request({
    url: `/api/cpm-service/cpm/contract/operate/log/history/detail/${id}`,
    method: 'get'
  })
}

// 是否存在同船期审核中的fak
export function getContractExistInApprovalApi(sailingId) {
  return request({
    url: `/api/cpm-service/cpm/contract/fak/existInApproval/${sailingId}`,
    method: 'get'
  })
}

// 根据船期查免用箱
export function getContractSailingPortFreeApi(params) {
  return request({
    url: `/api/cpm-service/cpm/contract/charge/sailing/base/port/free`,
    method: 'get',
    params
  })
}

// 订舱下的船期下拉
export function getSailingDropDownListApi(params) {
  return request({
    url: `/api/cas-service/cas/sailing/api/latestSailing`,
    method: 'get',
    params
  })
}
