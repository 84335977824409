export default {
  price_card_name: '价卡名称',
  please_enter_price_card_name: '请输入价卡名称',
  please_enter_cost_price_card_name: '请输入价卡名称',
  price_card_name_repeat: '价卡名称重复',
  country: '国家',
  warehouse_name: '仓库名称',
  please_select_warehouse_name: '请选择仓库名称',
  supplier: '供应商',
  supplier_desc: '名称/代码"',
  relative_cost_price_card_name: '关联成本价卡名称',
  price_card_type: '价卡类型',
  new_price_card_type: '新建价卡类型',
  please_select_price_card_type: '请选择价卡类型',
  status: '状态',
  please_select_status: '请选择状态',
  statusType: {
    'ALL': '全部'
  },
  effective_time: '有效时间',
  please_select_effective_time: '请选择有效时间',
  effective_start_time: '有效开始时间',
  effective_end_time: '有效结束时间',
  start_time: '开始时间',
  end_time: '结束时间',
  effective_time_tips: '大于成本价卡有效期范围，请注意！',
  YES: '是',
  NO: '否',
  to: '至',
  search: '搜索',
  reset: '重置',
  add: '新建',
  copy: '复制',
  copy_price_card: '其他价卡复制新建',
  view: '查看',
  edit: '编辑',
  detail: '详情',
  confirm: '确定',
  cancel: '取消',
  close: '关闭',
  operation: '操作',
  base_info: '基本信息',
  currency: '币种',
  type: '类型',
  cost: '成本',
  please_select_currency: '请选择币种',
  unit_category_type: '度量单位',
  please_select_unit_category_type: '请选择度量单位',
  save_draft: '保存草稿',
  save_draft_success: '保存草稿成功',
  submit: '提交',
  submit_success: '提交成功',
  save_success: '保存成功',
  un_save_info_mention: '尚未保存的信息将会丢失，确认关闭？',
  save_draft_info_mention: '将会保存为草稿，请前往列表查找该价卡，并点击“编辑”进行修改和提交等操作。',
  prompt: '提示',
  new_adjustment_coefficient: '新增时调整系数',
  index: '序号',
  charge_item_name: '费用名称',
  price: '价格',
  please_enter_price: '请输入价格',
  base_price: '成本价格',
  charge_mode_name: '计费方式',
  adjustment_coefficient: '调整系数',
  please_enter_adjustment_coefficient: '请输入调整系数',
  adjustment_price_before: '调整前价格',
  adjustment_price: '调整后价格',
  number_error: '请输入正确的数字',
  charge_condition: '收费条件',
  condition_and_mount: '条件与金额',
  condition_cannot_be_repeat: '条件值大小区间有重叠部分，请进行调整！',
  please_enter: '请输入',
  please_select: '请选择',
  next_step: '下一步',
  please_select_cost_price_card: '请选择成本价卡',
  cost_price_info_mention_one: '维护成本价卡，',
  cost_price_info_mention_two: '每一个仓库在同一个时间。',
  cost_price_info_mention_three: '只能存在一张成本价卡。',
  lower_price_info_mention_one: '最低卖价必须关联成本价卡。',
  lower_price_info_mention_two: '可以通过快速选择成本价卡，维护浮动系数，自动生成。',
  lower_price_info_mention_three: '也可以手动维护，但保存时不能低于成本价卡中对应费用的金额。',
  guide_price_info_mention_one: '指导价必须关联成本价卡。',
  guide_price_info_mention_two: '可以通过快速选择成本价卡，维护浮动系数，自动生成。',
  guide_price_info_mention_three: '也可以手动维护，但保存时不能低于成本价卡中对应费用的金额。',
  customer_price_info_mention_one: '客户价卡必须关联成本价卡。',
  customer_price_info_mention_two: '维护浮动系数，客户价卡将根据系统自动填充，然后进行手动调整。',
  customer_name: '客户名称',
  please_select_customer_name: '请选择客户名称',
  warehouse: '仓库',
  please_select_warehouse: '请选择仓库',
  new_customer_price_card: '新建客户价卡',
  back: '返回列表',
  exception_info: '类型异常，请刷新后重新获取！',
  exception_data: '数据异常，请刷新后重新获取！',
  price_cannot_be_null: '请先把价格填写完整，才能提交！！',
  be_cannot_be_null: '请先把范围填写完整，才能提交！！',
  adjustment_coefficient_cannot_be_null: '请先把价格填写完整，才能提交！！',
  please_select_price_card_one_data: '请先选择一行价卡数据！！',
  price_card_in: '价卡名称在(',
  price_card_already_exist: ')中已存在'
}
