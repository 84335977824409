export default {
  vesselName: '船名',
  voyageNo: '航次',
  containerType: '箱型',
  containerQuantity: '数量',
  ruleName: '规则名称',
  ruleType: '规则类型',
  bookingPlatform: '平台名称',
  endTime: '结束时间',
  polCode: '起运港',
  podCode: '目的港',
  containerRequireQuantity: '需要数量',
  etd: 'ETD',
  eta: 'ETA',
  unLimited: '无限制',
  lessThan: '小于',
  moreThan: '大于',
  spaceList: {
    soNo: '订单号',
    bookingNo: '订舱号',
    updateTime: '更新时间',
    allotTarget: '分配对象',
    allotStatus: '分配状态',
    alloted: '已分配',
    unalloted: '未分配',
    bookingStatus: '订单状态',
    allotBtn: '分配',
    cancelAllotBtn: '取消分配',
    allotSpace: '舱位分配',
    selectAllotTarget: '请选择将该舱位分配给',
    allotTargetEmptyInvalid: '请选择分配对象',
    bookingStatusName: '订单状态',
    allocatePersonnel: '分配人员'
  },
  lineCodeArray: {
    Africa: 'Africa / 非洲',
    LatinAmerica: 'Latin America / 拉美',
    Europe: 'Europe / 欧洲',
    Oceania: 'Oceania / 澳洲',
    Japan: 'Japan / 日本',
    NorthAmerica: 'North America / 北美',
    IntraAsia: 'Intra Asia / 亚洲'
  },
  spaceRuleList: {
    lineCode: '航线代码',
    creator: '创建用户',
    createTime: '创建时间',
    policyStatus: '状态',
    unStart: '未开始',
    processing: '进行中',
    finished: '已完成',
    stoped: '已结束',
    createRule: '新建规则',
    taskBroken: '任务已终止',
    stopBtn: '停止',
    stopSubtaskWarning: '是否停止该任务及其子任务?',
    deletedSuccess: '删除记录成功',
    deletedWarningTips: '该操作将永久删除记录，是否删除！',
    subtaskText: '子任务',
    successQuantity: '获取数量',
    priceRange: '单价区间',
    statusText: '状态',
    processCount: '执行次数',
    successCount: '成功次数',
    successRate: '成功率',
    subscribeSpace: '订阅舱位',
    includeInsurance: '是否保价',
    beginTime: '开始时间'
  },
  ruleSetter: {
    ruleInfo: '基本信息',
    spaceLineInfo: '航线及仓位信息',
    tacticsConfig: '策略配置',
    shipperInfo: '航运相关方',
    contactInfo: '订舱人信息',
    ruleNameEmptyInvalid: '规则名称不能为空',
    saveRuleSuccess: '规则保存成功',
    unSaveWarnningTips: '尚未保存，是否放弃本次操作?',
    thinkOver: '再想想'
  },
  baseInfoForm: {
    bookingPlatform: '适用平台',
    purchaseSpace: '抢舱',
    platformsEmptyInvalid: '请选择平台',
    limit8Input: '最长8位字符'
  },
  lineManagerForm: {
    purchaseType: '抢舱类别',
    normalLine: '普通航线',
    popularLine: '热门航线',
    insuranceService: '保价服务',
    priceRangeUSD: '单价区间USD',
    saveWarningTips: '当前操作将导致原有航线及舱位信息丢失！',
    confirmModify: '确认修改',
    ruleLimit: '最多只能新建5条子规则',
    polCodeEmptyInvalid: '请正确选择起运港',
    podCodeEmptyInvalid: '请正确选择目的港',
    containerTypeInvalid: '请正确选择箱型',
    amountEmptyInvalid: '请正填写数量',
    etdEmptyInvalid: 'ETD不能为空',
    subTaskWarnning: '此操作将永久子任务, 是否继续?',
    lineSpaceEmptyInvalid: '缺少航线舱位信息',
    saveLineInvalid: '请先保存航线舱位信息'
  },
  opreationForm: {
    startDateInvalid: '请选择开始日期',
    endDateInvalid: '请选择结束日期',
    purchaseTime: '抢舱时间点',
    startTimePointer: '请选择开始时间点',
    startTime: '开始时间',
    endTime: '结束时间',
    startDate: '开始日期',
    endDate: '结束日期',
    endTimePointer: '请选择结束时间点',
    sendMethods: '消息推送方式',
    sendTarget: '消息推送对象',
    sendTargetInvalid: '请选择消息推送对象',
    sendTrigger: '消息推送触发',
    ruleMatch: '规则匹配',
    ruleLose: '规则失效'
  },
  packageForm: {
    packageInfo: '货物信息',
    goodsName: '货物名称',
    packageAndLogo: '包装及标志',
    quantity: '件数',
    grossWeight: '重量（公斤）',
    volume: '体积（立方米）',
    goodsNameEmptyInvalid: '请输入货物名称',
    packageAndLogoEmptyInvalid: '请选择包装标志',
    quantityEmptyInvalid: '请输入货物数量',
    weightEmptyInvalid: '请输入货物重量',
    volumeEmptyInvalid: '请输入货物体积'
  },
  contactForm: {
    senderInfo: '发货人信息',
    companyName: '公司名称',
    contactAddress: '地址信息',
    contactMobile: '联系人电话',
    contactTelephone: '联系人固定电话号码',
    contactEmail: '联系人邮箱',
    companyEmptyInvalid: '公司名称不能为空',
    nameAndCompanyEmptyInvalid: '姓名/公司名称不能为空',
    addressEmptyInvalid: '详细地址不能为空'
  },
  shipperForm: {
    senderInfo: '发货人信息',
    shipperCompany: '公司名称',
    shipperArea: '国/省/区',
    shipperAddress: '详细地址',
    shipperAdditionalAddress: '地址补充信息',
    shipperMobile: '联系人电话',
    shipperEmail: '联系人邮箱',
    shipperCompanyEmptyInvalid: '公司名称不能为空',
    shipperAreaEmptyInvalid: '国家区域不能为空',
    shipperAddressEmptyInvalid: '详细地址不能为空',
    shipperAddressDetailEmptyInvalid: '补充信息不能为空',
    consigneeInfo: '收货人信息',
    consigneeCompany: '公司名称',
    consigneeArea: '国/省/区',
    consigneeAddress: '详细地址',
    consigneeAdditionalAddress: '地址补充信息',
    consigneeMobile: '联系人电话',
    consigneeEmail: '联系人邮箱',
    consigneeCompanyEmptyInvalid: '公司名称不能为空',
    consigneeAreaEmptyInvalid: '国家区域不能为空',
    consigneeAddressEmptyInvalid: '详细地址不能为空',
    consigneeAddressDetailEmptyInvalid: '补充信息不能为空',
    notifyInfo: '通知人信息',
    notifyCompany: '公司名称',
    notifyArea: '国/省/区',
    notifyAddress: '详细地址',
    notifyAdditionalAddress: '地址补充信息',
    notifyMobile: '联系人电话',
    notifyEmail: '联系人邮箱',
    notifyCompanyEmptyInvalid: '公司名称不能为空',
    notifyAreaEmptyInvalid: '国家区域不能为空',
    notifyAddressEmptyInvalid: '详细地址不能为空',
    notifyAddressDetailEmptyInvalid: '补充信息不能为空'
  },
  shippingSpace: {
    newClassContract: '新建舱位合约',
    approx: '约号',
    shippingDepartment: '船司',
    route: '航线',
    contractType: '合约类型',
    bookingChannel: '订舱渠道',
    termOfValidity: '有效期限',
    externalConfiguration: '外配',
    productPersonnel: '产品人员',
    inForce: '生效中',
    notEffective: '未生效',
    effectiveStatus: '生效状态',
    remarks: '备注',
    contractualSpace: '合约舱位（总量/周平均）',
    classStatistics: '舱位统计（已用/配舱）',
    lastWeek: '上周',
    thisWeek: '本周',
    nextWeek: '下周',
    creationTime: '创建时间',
    operation: '操作',
    invalid: '失效',
    takeEffect: '生效',
    newContract: '新增合约',
    contractualSpace2: '合约舱位',
    productOwner: '产品负责人',
    supplier: '供应商',
    average: '平均',
    endTimeCanOnlyBeSelectedInTheFuture: '结束时间只能选择将来时间',
    applicationNo: '申请编号',
    processingStatus: '处理状态',
    pending: '待处理',
    classApplicationInProgress: '舱位申请中',
    partiallyEquippedCabin: '部分配舱',
    equippedCabin: '已配舱',
    associationNo: '关联约号',
    sailingPeriod: '开航周期',
    applicationForNewClass: '新增舱位申请',
    applyForShippingSpace: '申请舱位',
    transportationTerms: '运输条款',
    designatedShippingCompany: '指定船司',
    specifyApproximateNumber: '指定约号',
    allocateSpace: '分配舱位',
    ordered: '已下单',
    applicationTime: '申请时间',
    pleaseSelectBoxType: '请选择箱型',
    applicant: '申请人',
    exportApplicationStatistics: '导出申请统计',
    distribution: '分配',
    operationLog: '操作日志',
    doNotSubmitTheSameBoxType: '请勿提交相同箱型'

  }
}
