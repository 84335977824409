import route from "./route";
import original from "./originTranslate";
import authorization from "./authorization";
import ETDInfoTable from "./ETDInfoTable";
// import BilListTable from './BilListTable'
import TipsMessage from "./TipsMessage";
import blInfo from "./blInfo";
import message from "./message.js";
import logisticsCompany from "./logisticsCompany";
import logisticsProduct from "./logisticsProduct";
import partition from "./partition";
import channel from "./channel";
import channelPriceCard from "./channelPriceCard";
import warehousePriceCard from "./warehousePriceCard";
import warehouse from "./warehouse";
import customerManage from "./customerManage";
import contract from "./contract";
import common from "./common";
import billAMS from "./billAMS";
import containerManager from "./containerManager";
import charteredBoat from "./charteredBoat";
import boatBlList from "./boatBlList";
import boatEtdList from "./boatEtdList";
import boatBookingDetail from "./boatBookingDetail";
import preManifest from "./preManifest";
import releaseBillList from "./releaseBillList";
import goodsManagement from "./goodsManagement";
import spaceRule from "./spaceRule";
import boatContainerList from "./boatContainerList";
import outInStorageManage from "./outInStorageManage";
import putInStorageManage from "./putInStorageManage";
import inventoryManager from "./inventoryManager";
import taskList from "./taskList";
import peakSeasonTimeManage from "./peakSeasonTimeManage";
// import billList from '@/lang/ZH-CN/billList'
import sea from "@/lang/ZH-CN/seaExportOrder";
import packageWarehouse from "./packageWarehouse";
import analyticStatistics from "./analyticStatistics"; // BI 船舶概览数据监控时间轴
import receiveFms from "./fmsReceive";
import fmsReceiveClear from './fmsReceiveClear'
import overseasManage from "./overseasManage";
import air from "./airExport";
import payableFms from "./fmsPayable";
import rateFms from "./fmsRate";
import feeItem from "./feeItem";
import planManager from "./planManager";
import member from "./member";
import fmsReceiveInvoice from "./fmsReceiveInvoice";
import InterfaceLog from "./InterfaceLog";
import role from './role';
import packageTransfer from "./packageTransfer";
import invoicingManage from './invoicingInfoManagement'
import chooseLine from "./chooseLine";
import owL from "./overviewLiner";
import containerQuit from "./containerQuit"
import containerFactory from './containerFactory'
import cleanFixed from './cleanFixed'
import manifest from './manifest'
import casDestination from './casDestination'
import finance from "./finance";


export default {
  ...original,
  route,
  common,
  authorization,
  // BilListTable,
  ETDInfoTable,
  TipsMessage,
  blInfo,
  ...message,
  logisticsCompany,
  logisticsProduct,
  partition,
  warehouse,
  channel,
  channelPriceCard,
  warehousePriceCard,
  billAMS,
  preManifest,
  goodsManagement,
  containerManager,
  charteredBoat,
  boatBlList,
  boatEtdList,
  boatBookingDetail,
  releaseBillList,
  spaceRule,
  boatContainerList,
  putInStorageManage,
  outInStorageManage,
  inventoryManager,
  taskList,
  peakSeasonTimeManage,
  ...message,
  // billList,
  // preManifest,
  sea,
  packageWarehouse,
  customerManage,
  contract,
  analyticStatistics,
  payableFms,
  receiveFms,
  fmsReceiveClear,
  rateFms,
  fmsReceiveInvoice,
  feeItem,
  overseasManage,
  air,
  planManager,
  InterfaceLog,
  member,
  role,
  packageTransfer,
  invoicingManage,
  chooseLine,
  owL,
  containerQuit,
  containerFactory,
  cleanFixed,
  manifest,
  casDestination,
  finance
};
