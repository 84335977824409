export default {
  office_name: '所属公司',
  year: '年份',
  add: '新建',
  rate_list: '汇率列表',
  currency_source: '源币种',
  currency_target: '目标币种',
  rate: '汇率',
  validity_date_begin: '生效时间',
  validity_date_end: '失效时间',
  operation: '操作',
  adjust: '调整',
  rate_operation: '汇率维护',
  auto_postpone: '自动延期',
  month: '月',
  month_sp: '指定月份',
  effective_period: '生效期间',
  cancel: '取消',
  confirm: '确定',
  save: '保存',
  un_publish: '待发布',
  is_delete_confirm: '是否确认删除？',
  delete_success: '删除成功',
  is_delete_publish: '是否确认发布？',
  publish_success: '发布成功',
  please_text_full_rate: '请先把汇率填写完整',
  adjust_success: '调整成功',
  edit_success: '编辑成功',
  add_success: '新增成功',
  need_one: '至少保留一条',
  currency_source_target_repeat: '源币种和目标币种不能相同重复的数据，请修改！'
}
