export default {
  soNo: '订舱号',
	soNoPlaceholder: '请输入订舱号',
  vesselName: '船名',
  vesselNamePlaceholder: '请输入船名',
  voyageNo: '航次',
  voyageNoPlaceholder: '请输入航次',
  polCode: '装货港',
  podCode: '卸货港',
  statusList: ['待提交', '待审核', '待上传', '待确认', '已审核', '驳回'],
  documentStatusList: ['待审核', '待上传', '待确认', '已审核', '驳回'],
  releaseType: "放单方式",
  oldReleaseType: "原放单方式",
  fclFlag: "整箱/拼箱",
  containerSizeTypeQty: "箱型箱量",
  applicationTime: '申请时间',
  applicant: '申请人',
  rejectReason: '驳回理由',
  reject: '驳回',
  audit: "审核人",
  auditTime: "审核时间",
  memberName: "客户",
  addApply: "新建改单申请",
  operating: "操作",
  backList: "返回列表",
  edit: "编辑",
  view: "查看",
  cancel: "取消",
  determine: "确定",
  billNo: '提单号',
  billNoPlaceholder: '请输入订舱号',
  orderType: "改单类型",
  applyTypeList: ["提单内容修改", "签发方式修改", "提单内容+签发方式修改"],
  blStatus: '提单状态',
  changeOrderUpload: "改单保函上传",
  changeExplain: "改单说明",
  portBillRecovery: "提单回收口岸",
  recycledCopies: "回收份数",
  billLadingRecall: "提单回收文件",
  import: "上传文件",
  addApplyTipOne: "1.请根据实际提单签发状态，进行维护【提单回收口岸】【回收份数】【回收资料上传】等信息；",
  addApplyTipTwo: "2.如提单实际已签发时，所有改单申请必须将已签发的【提单文件】回收作废，才能进行申请操作；",
  issueRemind: "签发提醒",
  materialGeneration: "资料生成",
  batchConfirmationPush: "批量确认推送",
  confirmationPush: "确认推送",
  restartPush: "重新推送",
  blNo: '提单号',
  containerNo: '箱号',
  containerNumber: '集装箱号',
  sealNo: '封号',
  containerType: '箱型',
  ownerName: '箱属',
  typeAndQuantity: '箱型箱量',
  submitSuccess: '提交成功！',
  baseInfo: '基本信息',
  routeCode: '航线代码',
  etd: 'ETD',
  masterBlText: 'Master BL',
  houseBLText: 'House BL',
  paymentMethod: '付款方式',
  toPay: '到付',
  prepaid: '预付',
  containerNoPlaceholder: '请输入箱号',
  serialNo: '流水号',
  commandNo: '指令号',
  saveSuccess: '保存成功',
  memberPlaceholder: '客户名称/简称',
  hsCode: 'HS CODE',
  declareStatus: '申报状态',
  waitForDeclare: '待申报',
  declareResult: '申报反馈',
  sampleError: '请选择正本/样单',
  blType: '提单类型',
  markDeclareStatus: '标记申报状态',
  recordMasterBl: '录入Master BL',
  grossWeight: '毛重',
  volume: '体积',
  fclFlagTrue: "整箱",
  fclFlagFlase: "拼箱",
  billDocument: "提单格式件",
  manifestDocument: "舱单格式件",
  cargoListDocument: "装船清单",
  sgiDocument: "SGI格式件",
  stowageDocument: "配载格式件",
  ensDocument: "ENS格式件",
  toBePrepared: '待制单',
  toWaitingPrepared: "待对单",
  waitConfirm: "待确认",
  waitIssue: "待签发",
  pushSerialNo: "推送流水号",
  pushStatus: "推送状态",
  blOriginal: "提单正本",
  billOfLading: "提单清单",
  amsBill: "AMS(提单信息)",
  amsContainer: "AMS(集装箱信息)",
  otherInformation: "其他资料",
  unloadShip: "卸船",
  do: "换DO",
  customsClearance: "清关",
  devanning: "拆箱",
  pickUp: "提货",
  returnContainer: "还柜",
  vesselAndvoyageRequestWarming: "必须筛选出同一船名、航次数据，才允许使用",
  vesselNameAndvoyageNo: "船名/航次",
  pushPort: "推送口岸",
  receiveEmails: "接收邮件",
  billInfo: "提单明细",
  receiveEmailsPlaceholder: "多个邮箱地址请用分号“;”隔开（最多允许255个字符长度）",
  successSaved: "保存成功",
  emailContent: "邮件正文",
  attachment: "附件上传",
  generate: "确认生成",
  batchConfirmationPushSuccess: "推送成功",
  pleaseSelectARowFirst: "请先选择一条信息!",
  fileStatusWarming: "存在非生成成功文件,请等待或处理失败文件",
  delSuccess: "删除成功",
  restartGenerate: "重新生成",
  uncommitted: "未提交",
  toPushed: "待推送",
  generation: "生成中",
  pushed: "已推送",
  amendmentOrder: "改单",
  pushDataRecord: "推送资料记录",
  accepted: "已接收",
  restartGenerateTips: "下方已生成的资料将会被删除后，重新按照勾选模版生成资料",
  dataRecordTips: "该目的港未设置对应资料文件格式，请将该港口所需的资料文件提供给系统管理员进行配置！",
  blCount: "提单数",
  orderModificationStatus: "改单状态",
  modificatInformation: "改单申请信息",
  nextStep: "下一步",
  amendmentOfOrder: "提单改单",
  billTipWarning: "如提单已实际签发，请维护对应的提单回收口岸",
  billModificatInformation: "提单更改信息",
  modifyContentName: "改单修改内容名称",
  prechangeInformation: "更改前信息",
  changedInformation: "更改后信息",
  orderAlterationRecord: "改单记录",
  orderModificationInformation: "改单信息",
  applyActionDesc: "动作",
  operateTime: "操作时间",
  operator: "操作人",
  applyTypeDesc: "改单类型",
  result: "处理结果",
  passAudit: "审核通过",
  podRecycledCopies: "目的港回收份数",
  podBillLadingRecall: "目的港提单回收文件",
  releaseTypeOpsName: ['正本', '电放', '目的港放单']

}
