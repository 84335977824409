import Layout from '@/layout'

const TrackingCenterRouteMap = {
  // 包船业务
  'trk': {
    component: Layout,
    path: '/TrackingCenter',
    alwaysShow: true,
    name: 'TrackingCenter',
    meta: {
      icon: 'icon-moshi'
    }
  },
  // 物流轨迹
  'stc:trk:index': {
    path: 'Index',
    component: () => import('@/views/cargo/v0/LogisticsTrack/index'),
    name: 'LogisticsTrack',
    meta: { title: '物流轨迹中心', noCache: true, affix: true },
    roles: [],
    children: ['cas:order:instruction:details', 'stc:trk:member', 'stc:trk:bl', 'stc:trk:container', 'stc:trk:vessel', 'stc:trk:contractPrice']
  },
  'stc:trk:member': {
    path: 'MemQry',
    component: () => import('@/views/cargo/v0/LogisticsTrack/customer'),
    name: 'LogisticsTrackCustomer',
    hidden: true,
    meta: { title: '客户订单查询' },
    roles: []
  },
  'stc:trk:bl': {
    path: 'BlQry',
    component: () => import('@/views/cargo/v0/LogisticsTrack/bill'),
    name: 'LogisticsTrackBill',
    hidden: true,
    meta: { title: '提单查询' },
    roles: []
  },
  'stc:trk:container': {
    path: 'CtnQry',
    component: () => import('@/views/cargo/v0/LogisticsTrack/container'),
    name: 'LogisticsTrackContainer',
    hidden: true,
    meta: { title: '集装箱查询' },
    roles: []
  },
  'stc:trk:vessel': {
    path: '/TrackingCenter/VesselQry',
    component: () => import('@/views/cargo/v0/LogisticsTrack/vessel'),
    name: 'LogisticsTrackVessel',
    hidden: true,
    meta: { title: '船期查询' },
    children: ['stc:trk:loadingDetails'],
    roles: []
  },
  'cas:order:instruction:details': {
    path: '/LogisticsTrack/NodeDetails',
    component: () => import('@/views/cargo/v0/LogisticsTrack/nodeDetails'),
    name: 'LogisticsTrackNodeDetails',
    hidden: true,
    meta: { title: '物流提单节点详情' },
    roles: []
  },
  'stc:trk:loadingDetails': {
    path: '/LogisticsTrack/LoadingDetails',
    component: () => import('@/views/cargo/v0/LogisticsTrack/loadingDetails'),
    name: 'LogisticsTrackLoadingDetails',
    hidden: true,
    meta: { title: '船舶配载详情' },
    roles: []
  },
  'stc:trk:contractPrice': {
    path: '/TrackingCenter/contractPrice',
    component: () => import('@/views/cargo/v0/LogisticsTrack/contractPrice'),
    name: 'LogisticsTrackContractPrice',
    hidden: true,
    meta: { title: '运价查询' },
    roles: []
  }
}

export default TrackingCenterRouteMap
