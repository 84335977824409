export default {
  dashboard: '首页',
  introduction: '简述',
  documentation: '文档',
  guide: '引导页',
  permission: '权限测试页',
  rolePermission: '角色权限',
  pagePermission: '页面权限',
  directivePermission: '指令权限',
  icons: '图标',
  components: '组件',
  componentIndex: '介绍',
  tinymce: '富文本编辑器',
  markdown: 'Markdown',
  jsonEditor: 'JSON编辑器',
  dndList: '列表拖拽',
  splitPane: 'Splitpane',
  avatarUpload: '头像上传',
  dropzone: 'Dropzone',
  sticky: 'Sticky',
  countTo: 'CountTo',
  componentMixin: '小组件',
  backToTop: '返回顶部',
  dragDialog: '拖拽 Dialog',
  dragSelect: '拖拽 Select',
  dragKanban: '可拖拽看板',
  charts: '图表',
  keyboardChart: '键盘图表',
  lineChart: '折线图',
  mixChart: '混合图表',
  example: '综合实例',
  nested: '路由嵌套',
  menu1: '菜单1',
  'menu1-1': '菜单1-1',
  'menu1-2': '菜单1-2',
  'menu1-2-1': '菜单1-2-1',
  'menu1-2-2': '菜单1-2-2',
  'menu1-3': '菜单1-3',
  menu2: '菜单2',
  Table: 'Table',
  dynamicTable: '动态Table',
  dragTable: '拖拽Table',
  inlineEditTable: 'Table内编辑',
  complexTable: '综合Table',
  tab: 'Tab',
  form: '表单',
  createArticle: '创建文章',
  editArticle: '编辑文章',
  articleList: '文章列表',
  errorPages: '错误页面',
  page401: '401',
  page404: '404',
  errorLog: '错误日志',
  excel: 'Excel',
  exportExcel: '导出 Excel',
  selectExcel: '导出 已选择项',
  mergeHeader: '导出 多级表头',
  uploadExcel: '上传 Excel',
  zip: 'Zip',
  pdf: 'PDF',
  exportZip: 'Export Zip',
  theme: '换肤',
  clipboardDemo: 'Clipboard',
  i18n: '国际化',
  externalLink: '外链',
  memberManagement: '会员管理',
  memberAuthorization: '会员列表',
  messageCenter: '站内消息',
  messageList: '全部消息',
  readList: '已读',
  unreadList: '未读',
  messageSetting: '消息设置',
  subscribeConfig: '订阅配置',
  receiverManage: '消息接收人管理',
  messageDetail: '消息详情',
  productEdit: '物流产品维护',
  channelPriceCardEdit: '渠道成本价卡维护',
  productCostPriceCardEdit: '渠道基础价卡维护',
  productCustomerPriceCardEdit: '渠道客户价卡维护',
  peakSeasonSurchargeEdit: '旺季时间管理维护',
  fuelRateEdit: '燃油费率管理维护',
  overseasWarehouse: '海外仓库管理',
  warehouseEdit: '仓库信息维护',
  warehousePriceCardEdit: '仓库价卡维护',
  warehousePriceCardCostEdit: '仓库价卡维护',
  warehousePriceCardDetail: '仓库价卡详情',
  warehouseSeasonEdit: '仓库旺季管理维护',
  customerManager: '客户管理',
  InterfaceLog: '接口日志',
  outboundDetail: '出库单详情',
  goodsDetial: '货品信息详情',
  warehouseBillDetail: '仓储计费单明细',
  storageReservationFormDetail: '入库预约单详情',
  storageIn: '入库单详情',
  linerOverviewList: '班轮总览列表'
}
