import Layout from "@/layout"

export const FmsRouterMap = {
  // 财务结算
  "fms:manage": {
    component: Layout,
    alwaysShow: true,
    name: "Financial"
  },
  // 充值记录
  "fms:recharge:record": {
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/member/recharge/rechargeRecord"),
    keepAlive: true,
    name: "RechargeRecord",
    children: ["fms:recharge:record:detail"]
  },
  // 充值记录详情
  "fms:recharge:record:detail": {
    path: "RechargeRecordDetail",
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/member/recharge/rechargeRecordDetail"),
    name: "RechargeRecordDetail",
    hidden: true,
    meta: {
      title: "充值记录详情",
      keepAlive: false,
      noCache: true,
      affix: false
    }
  },
  // 应收费用明细
  "fms:receive:charge:list": {
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/charge/receive"),
    name: "FmsReceiveCharge",
    children: ["fms:receive:apply:invoice", "fms:apply:clear:page", "fms:apply:clear:offset:page"]
  },
  // 应收费用确认单
  "fms:receive:confirmation:list": {
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/confirmation/receive"),
    name: "FmsReceiveConfirmation",
    children: ["fms:receive:confirmation:list:detail", "fms:receive:apply:invoice", "fms:apply:clear:page", "fms:apply:clear:offset:page"]
  },
  // 应收费用确认单详情
  "fms:receive:confirmation:list:detail": {
    path: "/fms/receive/confirmation/detail",
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/confirmation/receiveDetail"),
    name: "FmsReceiveConfirmationDetail",
    hidden: true,
    meta: { title: "应收费用确认单详情" }
  },
  // 应收账单管理
  "fms:receive:bill": {
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/bill/receive"),
    name: "FmsReceiveBill",
    children: ["fms:receive:bill:detail", "fms:receive:apply:invoice", "fms:apply:clear:page", "fms:apply:clear:offset:page"]
  },
  // 应收账单详情
  "fms:receive:bill:detail": {
    path: "/fms/receive/bill/detail",
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/bill/receiveDetail"),
    name: "FmsReceiveBillDetail",
    hidden: true,
    meta: { title: "应收账单详情" }
  },
  // 应付费用明细
  "fms:payable:charge:list": {
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/charge/payable"),
    name: "FmsPayableCharge"
  },
  // 应付费用确认单
  "fms:payable:confirmation:list": {
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/confirmation/payable"),
    name: "FmsPayableConfirmation",
    children: ["fms:payable:confirmation:list:detail"]
  },
  // 应付费用确认单详情
  "fms:payable:confirmation:list:detail": {
    path: "/fms/payable/confirmation/detail",
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/confirmation/payableDetail"),
    name: "FmsPayableConfirmationDetail",
    hidden: true,
    meta: { title: "应付费用确认单详情" }
  },
  // 应付账单管理
  "fms:payable:bill:list": {
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/bill/payable"),
    name: "FmsPayableBill",
    children: ["fms:payable:bill:list:detail"]
  },
  // 应付账单详情
  "fms:payable:bill:list:detail": {
    path: "/fms/payable/bill/detail",
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/bill/payableDetail"),
    name: "FmsPayableBillDetail",
    hidden: true,
    meta: { title: "应付账单详情" }
  },
  // 申请开票
  "fms:receive:apply:invoice": {
    path: "/fms/receive/apply/invoice",
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/applyForInvoice/index"),
    name: "FmsApplyForInvoice",
    hidden: true,
    meta: { title: "申请开票" }
  },
  // 税票申请管理
  "fms:receive:tax:apply:list": {
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/receiveInvoiceApply/tax"),
    name: "FmsTaxInvoiceListOperator",
    children: ['fms:receive:apply:invoice:detail', 'fms:receive:invoice:detail', "fms:apply:clear:page", "fms:apply:clear:offset:page"]
  },
  // 形票申请管理
  "fms:receive:commercial:apply:list": {
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/receiveInvoiceApply/commercial"),
    name: "FmsCommercialInvoiceListOperator",
    children: ['fms:receive:apply:invoice:detail', 'fms:receive:invoice:detail', "fms:apply:clear:page", "fms:apply:clear:offset:page"]
  },
  // 税票开票管理
  "fms:receive:tax:invoice:list": {
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/receiveInvoiceManage/tax"),
    name: "FmsTaxInvoiceListFinance",
    children: [
      'fms:receive:apply:invoice:detail',
      'fms:receive:invoice:detail',
      "fms:receive:apply:tax:invoice:make",
      "fms:receive:tax:invoice:reverse",
      "fms:receive:tax:invoice:update",
      "fms:apply:clear:page", "fms:apply:clear:offset:page"
    ]
  },
  // 形票开票管理
  "fms:receive:commercial:invoice:list": {
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/receiveInvoiceManage/commercial"),
    name: "FmsCommercialInvoiceListFinance",
    children: ['fms:receive:apply:invoice:detail', 'fms:receive:invoice:detail', "fms:apply:clear:page", "fms:apply:clear:offset:page"]
  },
  // 开票申请详情
  "fms:receive:apply:invoice:detail": {
    path: "/fms/receive/tax/invoice/apply/detail",
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/receiveInvoiceApply/detail"),
    hidden: true,
    name: "FmsTaxInvoiceApplyDetail",
    meta: { title: "开票申请详情" }
  },
  // 开票
  "fms:receive:apply:tax:invoice:make": {
    path: "/fms/receive/tax/invoice/make",
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/receiveInvoiceManage/makeInvoice"),
    name: "FmsTaxInvoiceMake",
    hidden: true,
    meta: { title: "开票" }
  },
  // 开具红冲
  "fms:receive:tax:invoice:reverse": {
    path: "/fms/receive/tax/invoice/reverse",
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/receiveInvoiceManage/reverseInvoice"),
    name: "FmsTaxInvoiceReverse",
    hidden: true,
    meta: { title: "开具红冲" }
  },
  // 编辑发票
  "fms:receive:tax:invoice:update": {
    path: "/fms/receive/tax/invoice/update",
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/receiveInvoiceManage/updateInvoiceObject"),
    hidden: true,
    name: "FmsTaxInvoiceUpdate",
    meta: { title: "编辑发票" }
  },
  // 发票详情
  "fms:receive:invoice:detail": {
    path: "/fms/receive/tax/invoice/detail",
    component: () => import(/* webpackChunkName: 'fms' */ "@/views/fms/receiveInvoiceManage/detail"),
    hidden: true,
    name: "FmsTaxInvoiceDetail",
    meta: { title: "发票详情" }
  },
  // 申请核销
  "fms:apply:clear:page": {
    path: 'receipt/clear/apply/clear',
    component: () => import(/* webpackChunkName: 'fms' */ '@/views/fms/receiptClear/applyClearPage'),
    hidden: true,
    name: 'FmsApplyClearPage',
    meta: { title: '申请核销' }
  },
  // 对冲核销
  "fms:apply:clear:offset:page": {
    path: 'receipt/clear/offset/clear',
    component: () => import(/* webpackChunkName: 'fms' */ '@/views/fms/receiptClear/offsetClearPage'),
    hidden: true,
    name: 'FmsOffsetClearPage',
    meta: { title: '对冲核销' }
  }
}
