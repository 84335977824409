export default {
  abnormalMainClass: "异常主类",
  interfaceAddressKeyword: "接口地址关键字",
  invokePacketKeyword: "调用报文关键字",
  responsePacketKeyword: "响应报文关键字",
  status: "状态",
  uniqueIdentifier: "唯一标识",
  abnormalSubClass: "异常子类",
  functionModule: "功能模块",
  caller: "调用方",
  byCaller: "被调用方",
  interfaceType: "接口类型",
  interfaceAddress: "接口地址",
  invokePacket: "调用报文",
  responsePacket: "响应报文",
  callTime: "调用时间",
  failureReason: "失败原因",
  detailTitle: '接口日志详情',
  all: '全部',
  expMainCategoryCode1: '数据异常',
  expMainCategoryCode2: '业务异常',
  expMainCategoryCode3: '网络异常',
  expMainCategoryCode4: '未维护异常',
};
