export default {
    'logistics_product_name': '物流产品名称',
    'please_enter_logistics_product_name': '请输入物流产品名称',
    'logistics_product_code': '物流产品代码',
    'please_enter_logistics_product_code': '请输入物流产品代码',
    'logistics_company': '物流公司',
    'please_select_logistics_company': '请选择物流公司',
    'logistics_company_desc': '名称/简称/代码',
    'logistics_company_abbreviation': '物流公司简称',
    'logistics_company_code': '物流公司代码',
    'country': '销售国家',
    'country_desc': '名称/代码',
    'transport_mode': '运输方式',
    'please_enter_transport_mode': '请选择运输方式',
    'search': '搜索',
    'reset': '重置',
    'add': '新建',
    'view': '查看',
    'confirm': '确定',
    'cancel': '取消',
    'operation': '操作',
    'timeliness': '时效',
    'please_enter_timeliness': '请输入时效',
    'day': '天',
    'sign': '签名',
    'please_select_sign': '请选择签名',
    'insurance': '保险/保价',
    'please_select_insurance': '请选择保险/保价',
    'lift_gate': 'LIFT GATE',
    'please_select_lift_gate': '请选择LIFT GATE',
    transportMode: {
        'all': '全部',
        'express_elivery': '快递',
        'truck': '卡车',
        'railway': '铁路',
        'trailer': '拖车'
    },
    'base_info': '基本信息',
    'product_type': '产品类别',
    'please_select_product_type': '请选择产品类别',
    'product_code': '产品代码',
    'please_select_product_code': '请输入产品代码',
    'product_name': '产品名称',
    'please_enter_product_name': '请输入产品名称',
    'product_country': '产品销售国家',
    'please_select_product_country': '请选择产品销售国家',
    'restrictive_condition': '限制条件',
    'please_select_restrictive_condition': '请选择限制分类',
    'please_select_restrictive_dto': '请选择限制字段',
    'please_select_unit': '请选择单位',
    'please_select_country': '请选择国家',
    'please_select_comparison_operators': '请选择比较符',
    'please_enter_number': '请输入数值',
    'add_restrictive_condition': '增加限制条件',
    'additional_service': '附加服务',
    'equal': '等于',
    'less_than': '小于',
    'greater_than': '大于',
    'equal_or_greater_than': '大于等于',
    'equal_or_less_than': '小于等于',
    'not_equal': '不等于',
    'weight_restrictions': '重量限制',
    'size_restrictions': '尺寸限制',
    'monetary_limitation': '金额限制',
    'logistics_product_detail': '物流产品详情',
    'edit_logistics_product': '物流产品编辑',
    'add_logistics_product': '物流产品新增',
    'peakSeasonTime': '旺季时间管理',
    'fuelRate': '燃油费率管理',
    partitionSet: '分区设置',
    remoteSettings: '偏远地区设置',
    haveRemoteAreaFlag: '是否有偏远地区',
    remoteAreaCode: '偏远地区代码',
    please_remoteAreaType: '请选择偏远地区类型',
    please_remoteAreaName: '请输入偏远地区代码',
    standard_product_rule: '标准品规则'


}
