export default {
	releaseNo: '申请编号',
    confirmTime: '确认时间',
    startDate: '开始日期',
    endDate: '结束日期',
    status: '申请状态',
    statusEmptyInvalid: '请选择申请状态',
    unConfirmed: '待确认',
    rejectedByBusiness: '商务已驳回',
    confirmedByBusiness: '商务已确认',
    rejectedByOpreator: '运营已驳回',
    releaseConfirmText: '放单确认',
    releaseDropText: '作废',
    memberName: '客戶名称',
    declareBlNo: '申请放单提单号',
    polCodeAndPodCode: '装卸货港',
    containerQuantity: '提单箱量',
    consignerInfo: '发货人信息',
    consigneeInfo: '收货人信息',
    notifierInfo: '通知人信息',
    totalAmount: '箱量总计',
    declareRemark: '申请备注',
    reject: '驳回',
    pass: '通过',
    paymentNotReceived: '款项未到账',
    reasonOfRejected: '驳回原因',
    reasonRejectPlaceholder: '请输入驳回原因',
    reasonEmptyInvalid: '请输入驳回原因',
    remarkVerify: '审核批注',
    remarkVerifyPlaceholder: '请输入审核批注',
    releaseFileView: '放单保函预览',
    paymentFileView: '付款凭证预览',
    nonFileUpload: '无任何凭证上传',
    unpayText: '款项未到账，暂不确认',
    lastWeek: '最近一周',
    lastMonth: '最近一个月',
    previousThreeMonth: '最近三个月',
    releaseFile: '放单保函',
    paymentFile: '水单',
    businessName: '商务确认人',
    businessTime: '确认时间',
    businessPassRemark: '商务审核批注',
    operateName: '运营确认人',
    operateTime: '确认时间',
    operatePassRemark: '运营审核批注',
    dropReason: '作废原因'
}