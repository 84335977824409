export default {
	shipowner: '船东',
    callSign: '呼号',
    SOCOnly: '仅SOC',
    packageMixin: '混装',
    planAmount: '计划用箱',
    planStepStart: '注意：请仔细确认用箱计划的调整数据，提交后箱管侧将按照此数据调整',
    useNormalRule: '使用海特规则',
    ifUseNormalRule: '请选择是否使用海特规则',
    endNo: '结束号码',
    endNoEmptyInvalid: '请输入结束号码',
    endNoFormatInvalid: '请输入字母开头四位数字结尾字段号码',
    stepEmptyInvalid: '请输入步长',
    stepFormatInvalid: '只能输入正整数',
    IMOEmptyInvalid: '请输入IMO',
    shippingDepartment: '船司',
    shippingDepartmentInvalid: '请选择船司',
    routeCodePlaceholder: '填写卸货港信息后自动获取',
    createPolCode: '增加装货港',
    returnPlace: '还箱点',
    returnPlaceEmptyInvalid: '请选择还箱点',
    CYOpen: 'CY OPEN',
    CYCut: 'CY CUT',
    SICut: 'SI CUT',
    VGMCut: 'VGM CUT',
    CUSCut: 'CUS CUT',
    bookingEndDate: '订舱结束日期',
    settlementCode: '结算代码',
    stepBy: '步长',
    startNo: '起始号码',
    startNoEmptyInvalid: '请输入起始号码',
    startNoFormatInvalid: '请输入字母开头四位数字结尾字段号码',
    bookingConfirmIllustration: '订舱确认函说明',
    IllustrationEmptyInvalid: '请输入说明信息',
    polCodePlan: '装货港用箱计划',
    podCodePlaceholder: '请选择卸货港',
    createInfo: '增加信息',
    addPod: '增加卸货港',
    transitPortOrNot: '是否中转港',
    countryOfDischargePort: '卸货港国家',
    transitPort: '中转港',
    agentInformation: '代理信息',
    chineseNameOfClient: '客户中文名',
    englishNameOfClient: '客户英文名',
    ATA: 'ATA',
    MBLPrefix: 'MBL前缀',
    upperCaseTips: '请输入大写字母',
    placeHolderPrefixMBL: '请输入MBL前缀',
    ruleForSONo: 'SO号段分配规则',
    createSONo: '增加号段',
    createSchedule: '新增船期',
    releaseConfiguration: '放箱配置',
    haiguanjiaConfig: '海管家船名配置',
    planConfiguration: '用箱计划调整',
    sysVesselName: '系统船名',
    haiguanjiaInvalid: '请输入海管家船名备案船名',
    noticeStart: '注意：请通过',
    haiguanjiaVesselList: '海管家船名清单',
    noticeAfter: '查阅当前系统船名是否存在；如果存在，请将 海管家船名 复制到上方输入框中。如果不存在，在联系海管家客服备案当前系统船名后，将客服反馈的海管家船名复制到上方输入框中。',
    withoutContainerConfig: '免柜设置',
    clientName: '客户名称',
    withoutContainerDays: '免柜天数',
    enterWithoutDays: '请输入免柜天数',
    adjustPlan: '调整计划',
    adjustPlanEmptyInvalid: '请选择调整计划',
    createText: '增加',
    reduceText: '减少',
    planAmountTotal: '总计划用箱',
    currentReleasedContainer: '目前已放箱',
    currentReleasedBerth: '目前已放舱',
    currentPickedContainer: '目前已提箱',
    yardName: '提箱点',
    releaseQuantity: '箱管放箱数',
    spaceQuantity: '已放舱数',
    pickedUpQuantity: '已提箱数',
    leftlessText: '计划减少/剩余未放舱',
    numberPlaceholder: '请输入数量',
    onShelvesBtn: '上架',
    offShelvesBtn: '下架',
    selectSailing: '船期选择',
    selectSchedule: '请选择船期',
    releasedTrigger: '放箱开关',
    releasedSetting: '放箱设置',
    releaseNotice: '放箱公告',
    closedNotice: '注意：关闭放箱（取消勾选）将导致该口岸或提箱点的集装箱无法进行预约作业，请谨慎操作！',
    triggerEmptyInvalid: '请选择放箱开关',
    releasedNoticeEmptyInvalid: '请输入放箱公告',
    releasedSettingEmptyInvalid: '请选择放箱设置',
    isTransform: '是否中转',
    createTime: '创建时间',
    sailingStatus: '状态',
    selectorEmptyInvalid: '请勾选任意一条，且每次仅可勾选一条进行配置',
    updateMessage: '更新成功',
    deleteWarnningText: '船期信息删除后无法恢复，是否继续？',
    submitWithoutContainerSucess: '免柜设置提交成功！',
    shortInChinese: '中文简称',
    shortInEnglish: '英文简称',
    unSaveWarnningTips: '尚未保存，是否放弃本次操作?',
    vesselNameEmptyInvalid: '请输入船名',
    voyageNoEmptyInvalid: '请输入船次',
    information: '信息',
    eta: 'ETA',
    enableSOQuantity: '可用SO数量',
    polCodeChange: '装货港变更',
    currentPolCode: '当前装货港',
    changeToOtherPort: '变更至港口',
    otherPortEmptyInvalid: '请选择其他装货港',
    otherPortPlaceholder: '请选择其他装货港',
    ETDNotice1: '1、请先在详情页中维护好需要改挂的港口和对应的提还箱点信息；',
    ETDNotice2: '2、原装货港的有效订舱数据将关联至新的装货港，已取消的订舱将不做变更;',
    ETDNotice3: '3、变更之后原装货港和提还箱点配置将失效，不可再通过原装货港订舱。',
    AWE: 'AWE',
    AAS1: 'AAS1',
    lineCodeErrorText: '请先维护航线信息',
    changePortSuccess: '变更成功！',
    ATDConfirmTips: 'ATD信息维护后将直接释放未使用的放箱数量，为确保箱管侧的数据正确，请在维护ATD信息前将未上船需退关的集装箱记录删除，是否继续保存？',
    duplicatePortErrorText: '港口数据重复',
    ownerNameMixinPlanEmptyInvalid: '箱属为混装时，请至少填写一项用箱计划',
    podCodeInfoError: '请维护卸货港信息',
    polCodeInfoError: '请维护装货港信息',
    soNoErrorText: '结束号码必须大于起始号码+步长',
    duplicateRuleError: '港口数据已使用海特规则，不能添加其他规则',
    numberOfAvailableSONumbersExceeds1000: '可用SO号数量超过1000，请分段维护！'
}
