import { Message } from 'element-ui';
let messageInstance = null;
const resetMessage = options => {
  if (messageInstance) {
    messageInstance.close();
  }
  options.showClose = true;
  messageInstance = Message(options);
};

['error', 'success', 'info', 'warning'].forEach(type => {
  resetMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      };
    }
    options.duration = 3000;
    options.type = type;
    options.showClose = true;
    return resetMessage(options);
  };
});
export default resetMessage
