var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.item.hidden
    ? _c(
        "div",
        { staticClass: "menu-wrapper" },
        [
          _vm.hasOneShowingChild(_vm.item.children, _vm.item) &&
          (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) &&
          !_vm.item.alwaysShow
            ? _c(
                "div",
                [
                  _vm.onlyOneChild.meta
                    ? _c(
                        "app-link",
                        {
                          attrs: { to: _vm.resolvePath(_vm.onlyOneChild.path) },
                        },
                        [
                          _c(
                            "el-menu-item",
                            {
                              class: {
                                "submenu-title-noDropdown": !_vm.isNest,
                              },
                              attrs: {
                                index: _vm.resolvePath(_vm.onlyOneChild.path),
                                pendingNums: _vm.pendingNums,
                              },
                            },
                            [
                              _c("span", { staticClass: "item-menu-title" }, [
                                _c("i", {
                                  class: [
                                    "iconfont",
                                    _vm.onlyOneChild.meta.icon ||
                                      _vm.item.meta.icon,
                                  ],
                                }),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.generateTitle(
                                        _vm.onlyOneChild.meta.title
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.onlyOneChild.name === "FlwContractList"
                                  ? _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.pendingNums > 0,
                                            expression: "pendingNums >0",
                                          },
                                        ],
                                        staticClass: "pending-nums",
                                      },
                                      [_vm._v(_vm._s(_vm.pendingNums))]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "el-submenu",
                {
                  ref: "subMenu",
                  attrs: {
                    index: _vm.resolvePath(_vm.item.path),
                    "popper-append-to-body": "",
                  },
                },
                [
                  _c("template", { slot: "title" }, [
                    _vm.item.meta
                      ? _c("span", { staticClass: "item-menu-title" }, [
                          _c("i", { class: ["iconfont", _vm.item.meta.icon] }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.generateTitle(_vm.item.meta.title))
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.item.name === "ContractPrice"
                            ? _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.pendingNums > 0,
                                      expression: "pendingNums >0",
                                    },
                                  ],
                                  staticClass: "pending-nums",
                                },
                                [_vm._v(_vm._s(_vm.pendingNums))]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.item.children, function (child, index) {
                    return _c("sidebar-item", {
                      key: index,
                      staticClass: "nest-menu",
                      attrs: {
                        "is-nest": true,
                        item: child,
                        "base-path": _vm.resolvePath(child.path),
                        pendingNums: _vm.pendingNums,
                      },
                    })
                  }),
                ],
                2
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }