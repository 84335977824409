<template>
	<el-select
		v-model="selectVal"
		filterable
		clearable
		remote
		reserve-keyword
		placeholder="请选择客户"
		:remote-method="remoteMethod"
		:loading="loading"
		size="mini"
		@change="change"
		v-bind="$attrs"
		v-on="$listeners"
	>
		<el-option
			v-for="(item, index) in customerList"
			:key="index"
			:label="item.abbreviation"
			:value="item.id"
		/>
	</el-select>
</template>

<script>
import { getCustomerSelectionsApi } from '@/api/authorization'
export default {
	name: 'MemberList',
	inheritAttrs: false,
	props: {
		value: String
	},
	data() {
		return {
			selectVal: this.value || null,
			loading: false,
			customerList: []
		}
	},
	created() {

	},
	watch: {
		value(val) {
			this.selectVal = val || []
		}
	},
	methods: {
		/**
* 远程获取会员列表
*/
		async remoteMethod(query) {
			query = query.trim()
			this.loading = true
			const _params = {
				pageSize: 100,
				pageNum: 1,
				range: ['b_customer'],
				keyword: query
			}
			const res = await getCustomerSelectionsApi(_params)
			if (res.ok) {
				this.customerList = res.content.list || []
				this.loading = false
			} else {
				this.$message({
					type: 'warning',
					message: this.$t('authorization.listEmpty')
				})
				this.loading = false
			}
		},
		change() {
			this.$emit('input', this.selectVal)
			let name = this.customerList.find(item => item.id === this.selectVal).abbreviation
			this.$emit('getValueName', name)
		}
	}
}
</script>

<style scoped lang="scss">
</style>
