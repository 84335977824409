var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lb-table" },
    [
      _c(
        "el-table",
        _vm._g(
          _vm._b(
            {
              ref: "elTable",
              attrs: {
                data: _vm.data,
                "span-method": this.merge ? this.mergeMethod : this.spanMethod,
              },
            },
            "el-table",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        _vm._l(_vm.column, function (item, index) {
          return _c(
            "lb-column",
            _vm._b(
              { key: index, attrs: { column: item } },
              "lb-column",
              _vm.$attrs,
              false
            )
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.pagination
        ? _c(
            "el-pagination",
            _vm._g(
              _vm._b(
                {
                  staticClass: "lb-table-pagination",
                  style: {
                    "margin-top": _vm.paginationTop,
                    "text-align": _vm.paginationAlign,
                  },
                  on: { "current-change": _vm.paginationCurrentChange },
                },
                "el-pagination",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }