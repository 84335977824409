var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "svg",
      {
        staticClass: "screenfull-svg",
        attrs: {
          t: "1508738709248",
          viewBox: "0 0 128 128",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "p-id": "2069",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          width: "128px",
          height: "128px",
        },
        on: { click: _vm.click },
      },
      [
        _c("g", { attrs: { id: "图层_2" } }, [
          _c("g", [
            _c("path", {
              attrs: {
                fill: "#409EFF",
                d: "M66.438,68.268c-2.658,0-4.531-1.833-4.531-4.269c0-2.437,2.129-4.268,4.531-4.268\n\t\t\tc2.656,0,4.528,1.831,4.528,4.268C70.968,66.435,68.839,68.268,66.438,68.268z",
              },
            }),
            _vm._v(" "),
            _c("path", {
              attrs: {
                fill: "#2F3133",
                d: "M47.604,73.827L9.261,112.171V84.032c0-2.501-2.044-4.546-4.544-4.546c-2.501,0-4.559,2.045-4.559,4.546\n\t\t\tv39.114c0,2.5,2.043,4.545,4.544,4.545h39.043c2.501,0,4.544-2.045,4.544-4.545s-2.043-4.545-4.544-4.545H15.692l38.343-38.345\n\t\t\tc1.786-1.771,1.786-4.657,0-6.415C52.249,72.068,49.376,72.068,47.604,73.827z M4.545,49.676c2.5,0,4.544-2.044,4.544-4.545\n\t\t\tV16.578c0-6.203,5.874-7.489,10.79-7.489h23.866c2.501,0,4.544-2.044,4.544-4.545S46.246,0,43.745,0H19.879\n\t\t\tC7.617,0,0,6.359,0,16.578v28.553C0,47.646,2.044,49.676,4.545,49.676z M123.133,79.486c-2.501,0-4.546,2.045-4.546,4.546v27.081\n\t\t\tc0,6.201-5.872,7.488-10.789,7.488H82.73c-2.5,0-4.545,2.045-4.545,4.545s2.045,4.545,4.545,4.545h25.054\n\t\t\tc12.261,0,19.878-6.359,19.878-16.578V84.032C127.678,81.502,125.634,79.486,123.133,79.486z",
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("g", { attrs: { id: "图层_3" } }, [
          _c("path", {
            attrs: {
              fill: "#409EFF",
              d: "M118.762,15.535v29.41c0,2.501,2.043,4.545,4.545,4.545c2.5,0,4.543-2.044,4.543-4.545V4.544\n\t\tc0-2.501-2.043-4.544-4.543-4.544H82.92c-2.502,0-4.545,2.043-4.545,4.544c0,2.5,2.043,4.545,4.545,4.545h29.41L74.002,47.432\n\t\tc-1.787,1.772-1.787,4.659,0,6.417c0.887,0.886,2.057,1.344,3.215,1.344s2.316-0.442,3.215-1.344\n\t\tC80.447,53.863,118.762,15.535,118.762,15.535z",
            },
          }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }