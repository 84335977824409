<template>
  <el-upload
    ref="uploadFile"
    action=""
    v-loading="loading"
    :drag="drag"
    :http-request="httpRequest"
    :before-upload="beforeUpload"
    :on-exceed="handleExceed"
    :on-remove="handleRemove"
    :on-preview="handlePreview"
    :on-progress="handleProgress"
    :before-remove="beforeRemove"
    :file-list="fileList"
    :accept="accept"
    :show-file-list="isShowFileList"
    :multiple="multiple"
    :limit="limit"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
    class="upload-container">
    <div v-if="!drag">
          <slot name="upload" v-if="$slots.upload"></slot>
      <el-button type="primary" size="mini" v-else> 上传文件 </el-button>
    </div>
    <div v-if="drag" class="preview-container">
      <template v-if="currentImage">
        <img :src="currentImage" class="preview-img">
      </template>
      <template v-else>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">{{$t('common.upload.uploadTitle')}}<em>{{$t('common.upload.uploadClickTitle')}}</em></div>
        <div class="el-upload__text">{{$t('common.upload.uploadSubTitle')}}</div>
      </template>
    </div>
    <div slot="tip">
      <slot name="uploadInnerTip"></slot>
    </div>
  </el-upload>
</template>

<script>
import { ossUpload } from '@/utils/ossUpload'

export default {
  inheritAttrs: false,
  name: 'UploadFile',
  data() {
    return {
      fileList: [],
      currentImage: '',
      loading: false,
      isShowFileList: false
    }
  },
  props: {
    drag: {
      type: Boolean,
      default: false
    },
    showFileList: {
      type: Boolean,
      default: true
    },
    value: {
      type: [Array, String],
      default: () => []
    },
    maxSize: {
      type: Number,
      default: 50
    },
    accept: {
      type: String,
      default: ''
    },
    // 导入之前的限制条件
    beforeUploadLimit: Function,
    uploadMethod: {
      type: Function
    },
    url: {
      type: String,
      default: 'api/mdm-service/web/upload/file'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 9999
    },
    disabled: {
      type: Boolean,
      default: false
    },
    webkitdirectory: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.isShowFileList = this.drag ? false : this.showFileList
  },
  mounted() {
    this.$nextTick(() => {
      if (this.webkitdirectory) {
        this.$refs.uploadFile.$children[0].$refs.input.webkitdirectory = true
      }
    })
  },
  watch: {
    value: {
      handler(val) {
        if (this.$isEmpty(val)) {
          this.fileList = []
          return
        }
        if (!Array.isArray(val)) {
          if (typeof val == 'object') {
            val = [val]
            val.forEach(v => {
              v.name = v.name || v.originFileName
            })
            this.fileList = val
          } else if (typeof val == 'string') {
            // 单个文件的上传 和 预览
            this.currentImage = val
          } else {
            console.log('参数错误')
          }
        } else {
          val.forEach(v => {
            v.name = v.name || v.originFileName
          })
          this.fileList = val
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {

	},
	methods: {
		httpRequest(data) {
			if (data.file.name && data.file.name.length > 150) {
				this.$message.error('文件名过长！')
				return
			}

			let callback = (res, data = null) => {
				// 当该组件被定义为单个图片上传预览时
				if (this.drag) {
					this.currentImage = res.url
				}

				// 当文件夹上传时
				if (this.webkitdirectory) {
					res.uid = data.file.uid
				}

				this.$emit('success', res)
				this.fileList.push(res)
				this.$emit('input', this.fileList)
				this.loading = false
			}
			this.loading = true
			// TODO
			// 上传接口需要FormData
			if (this.uploadMethod) {
				// 兼容ESIT提供的接口数据返回
				const formData = new FormData()
				formData.append('file', data.file)
				this.uploadMethod(formData).then(res => {
					if (res.ok) {
						callback(res.content)
					} else {
						// 上传失败 清除elment upload value
						this.fileList = []
						this.loading = false
						this.handleError(res)
						this.$emit('error')
					}
				}).catch(err => {
					this.$emit('error', err)
					this.loading = false
				})
			} else {
				// 兼容OSS
				ossUpload(data).then(res => {
					callback(res, data)
				}).catch(err => {
					this.$emit('error', err)
					this.loading = false
				})
			}
		},
		/**
	 * 上传文件错误
	 */
		handleError(err) {
			let msg = ''
			err.errors.forEach((v, index) => {
				msg += index > 0 ? '、' : ''
				msg += v.message
			})
			this.$message.error(msg)
		},

		/**
		 * 上传限制体条件
		 */
		async beforeUpload(file) {
			const isLimit = file.size / 1024 / 1024 < this.maxSize
			if (!isLimit) {
				this.$message.error(`文件超出最大限制${this.maxSize}M`)
			}
			let limit = true
			if (this.$isNotEmpty(this.beforeUploadLimit)) {
				limit = this.beforeUploadLimit(file)
			}
			let index = file.name.lastIndexOf(".")
			let suffix = file.name.substring(index + 1)
			if (this.accept && this.accept.indexOf(suffix) < 0) {
				this.$message.error('上传文件格式不支持')
				return Promise.reject()
			}
			return isLimit && limit ? Promise.resolve() : Promise.reject()
		},

		handleRemove(file, fileList) {
			this.fileList = fileList
			this.$emit('remove', file)
			this.$emit('input', fileList)
		},
    beforeRemove(file) {
      if (file.disabled) {
        this.$message.warning('无权删除！');
        return false
      }
    },
    handlePreview(file) {
      this.$emit('onPreview', file)
    },
    handleProgress() {
      this.$emit('onProgress')
    },
		/**
		 * 文件超出个数限制
		 */
		handleExceed() {
			this.$message.error('文件上传个数超出限制')
		}
	}
}
</script>
<style lang="scss" scoped>
.upload {
	/deep/ .el-upload {
		width: 100%;
	}
}
.preview-container {
	width: 100%;
	height: 100%;

	.preview-img {
		height: 100%;
	}
}
</style>
