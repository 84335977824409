var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-input", {
    attrs: {
      size: _vm.size,
      disabled: _vm.disabled,
      maxlength: _vm.maxLength,
      placeholder: "请输入",
    },
    on: { input: _vm.setInputVal },
    model: {
      value: _vm.inputVal,
      callback: function ($$v) {
        _vm.inputVal = $$v
      },
      expression: "inputVal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }