export default {
  orderNo: '订单号',
  batchNo: '批次号',
  inboundWarehouse: '入库仓库',
  blNo: '提单号',
  trackingNo: '运单号',
  number: '单号',
  oldNo: '旧单号',
  containerNo: '箱号',
  trackingNo2: '历史运单号',
  customerRefNo: '客户参考号',
  productCode: '渠道',
  status: '状态',
  transferStatus: '调拨状态',
  transferResult: '是否调拨',
  unRequisition: '未调拨',
  onRequisition: '调拨中',
  requisitionFinished: '调拨完成',
  revokeTransferred: '撤销调拨',
  revokeTip: '是否确认撤销调拨操作?',
  statusAll: '全部',
  statusDate: '状态时间',
  fastening: '是否扣件',
  locked: '是',
  unlock: '否',
  deliveryName: '渠道名称',
  deliveryCode: '渠道代码',
  orderedDate: '下单时间',
  checkinDate: '入库时间',
  checkoutDate: '出库时间',
  receivedTime: '揽收时间',
  declareTime: '预报时间',
  boxNo: '箱号',
  countryName: '国家名称',
  countryCode: '国家代码',
  statusList: ['待入库', '已称重', '已入库', '已装箱', '已出库', '已取消', '已退回', '已销毁', '已揽收'],
  boxStatusList: ['装箱中', '待出库', '出库成功'],
  importReport: '导入预报',
  importReportDialogTitle: '导入预报',
  importExcel: '导入Excel',
  clickHere: '支持扩展名: .xls,.xlsx 点击',
  downloadTemplate: '下载模板',
  submitUpload: '确认提交',
  duplicateUploadWarnning: '请勿重复上传数据',
  gotIt: '知道了',
  excelFileName: '导入预报模板.xlsx',
  viewDetailBtn: '详情',
  customerOrderWeight: '客户下单重量',
  customerDeclareWeight: '客户预报重',
  customerInboundWeight: '实际重量',
  customerOutWeight: '客户出库重量',
  parcelCoefficient: '计泡系数',
  length: '长',
  width: '宽',
  height: '高',
  volume: '体积',
  weight: '重量',
  grossHeavy: '毛重',
  inboundChargeWeight: '计费重',
  bubbleHeavy: '泡重',
  totalHeavy: '计重',
  totalCount: '汇总',
  shipperInfoTitle: '发货人信息',
  consigneeInfoTitle: '收货人信息',
  name: '姓名',
  telphone: '电话',
  zipCode: '邮编',
  company: '公司',
  address: '地址',
  minute: '详细地址',
  companyName: '公司名称',
  packageInfoTitle: '包裹信息',
  goodsCname: '中文名',
  goodsEname: '英文名',
  quantity: '产品数量',
  declareValue: '单件产品申报价值',
  hscode: '海关商品编码',
  skuCode: 'SKU',
  addressInfoEmpty: '地址信息不存在',
  goodsListEmpty: '商品列表不存在',
  goodsUrl: '产品销售链接',
  quickHandler: '启用快手',
  electronicWeigher: '启用电子秤',
  bulkyCargo: '泡货处理',
  singlePrint: '单票打印',
  packageForm: {
    bagNo: '袋号',
    orderNo: '单号',
    inboundWeight: '包裹重量(KG)',
    inboundLength: '包裹长(cm)',
    inboundWidth: '包裹宽(cm)',
    inboundHeight: '包裹高(cm)'
  },
  opreationBtn: '设置',
  checkInSuccessText: '入库成功',
  weightEmptyError: '重量不能为空',
  orderNoEmptyError: '单号不能为空',
  submitBtnError: '使用按钮提交',
  bulkyCargoError: '泡货体积不正确',
  systemError: '系统异常错误, 请联系管理员',
  createWeight: '下单重量',
  declareWeight: '预报重量',
  inboundWeight: '入库重量',
  weightVariation: '差异重量',
  chineseName: '客户名称',
  consigneeCountry: '国家',
  destinationCountry: '目的国',
  productName: '渠道',
  productCodeText: '渠道代码',
  scanTime: '扫描时间',
  scanQuantity: '已扫描件数',
  totalWeight: '已扫描重量',
  clearBtn: '清空',
  validateChannel: '验证渠道',
  validatePrefix: '验证单号前缀',
  validatePartner: '验证服务商',
  ifWithElectric: '是否带电',
  mixinElectric: '混装',
  withElectric: '带电',
  withoutElectric: '不带电',
  checkAreaText: '验证分拣分区',
  productMaxWeight: '上限重量',
  productMinWeight: '下限重量',
  boxType: '箱型',
  others: '其他',
  tare: '皮重',
  codeNo: '单号',
  checkinWeight: '收货重量',
  boxTypeList: ['645', '655', '755', '其他'],
  revokeOutboundBtn: '撤销装箱',
  printTag: '打印标签',
  boxNoEmptyError: '箱号不能为空',
  boxTypeEmptyError: '请选择一个箱型',
  boxVolumeError: '请正确填写体积',
  boxTareError: '请正确填写皮重',
  limitedWeightError: '总重量已超限',
  inboundFailed: '入库失败, 错误类型: ',
  invokeTip: '是否确认撤销入库操作?',
  cancelDrop: '再想想',
  boxSafekeeping: '当前箱号已封箱',
  targetCountry: '目的国',
  outboundTime: '出库完成时间',
  netWeight: '净重',
  ticketQuantity: '票数',
  boxDensity: '密度',
  boxCount: '已选箱数',
  batchOutBound: '一键出库',
  createBatch: '创建批次',
  copySelectedBtn: '一键复制',
  exportInfo: '导出',
  exportBtnList: '导出操作',
  exportOrderDetail: '导出入库明细',
  exportOrderSearch: '导出查询结果',
  printBoxTags: '打印箱唛',
  multipleSelectorEmpty: '请至少选择一条数据',
  batchOutboundConfirm: '出库操作不可逆，是否确认出库?',
  outBoundBtn: '确认出库',
  printBoxTagsError: '只能选择出库成功或待出库的箱子',
  batchOutboundSuccess: '出库成功',
  emptyUrl: '链接地址有错误',
  boxDetailEmpty: '未能获取详情数据',
  detailHeavy: '重量',
  selectionWarehouse: '仓库',
  warehouseShortName: '仓库简称',
  warehouseCode: '仓库代码',
  warehouseName: '仓库名称',
  warehouseContact: '联系人',
  contactMobile: '联系电话',
  warehouseAddress: '仓库地址',
  createWarehouseBtn: '新建',
  warehouseEnabled: '生效',
  warehouseDisabled: '失效',
  modifyWarehouse: '修改',
  enabledBtn: '启用',
  disabledBtn: '禁用',
  createWarehouseTitel: '新建仓库',
  modifyWarehouseTitle: '编辑仓库',
  codeEmptyError: '请填写仓库代码',
  nameEmptyError: '请填写仓库名称',
  shortNameEmptyError: '请填写仓库简称',
  warehouseModifySuccess: '修改仓库成功',
  warehouseCreateSuccess: '新建仓库成功',
  employeeAccount: '编码',
  employeeName: '人员姓名',
  employeeDepartment: '所属部门',
  employeeRemark: '备注',
  createEmployee: '新增人员',
  modifyEmployee: '修改人员',
  autoDiscrepancyWeight: '自动扣件差异重量(±g)',
  isDeclareWeight: '是否校验预报重量',
  isOrderWeight: '是否校验下单重量',
  validateDeclareWeight: '校验预报重量',
  validateOrderWeight: '校验下单重量',
  isValidateDiscrepancy: '是否差异校验',
  validateDiscrepancy: '差异校验',
  ignoreDeclare: '无预报允许入库',
  isIgnoreDeclare: '无预报允许入库',
  abbreviationEmptyError: '请选择客户名称',
  autoDiscrepancyWeightEmptyError: '请填写自动扣件差异重量',
  lockType: '暂扣类型',
  numberType: '单号类型',
  distrainDate: '扣件时间',
  lockedWarehouse: '扣件仓库',
  passPersonName: '放行人',
  passTime: '放行时间',
  returnPersonName: '退件人',
  returnTime: '退件时间',
  createLock: '新增',
  passBtn: '放行',
  returnBtn: '退回',
  dragBtn: '销毁',
  followBtn: '跟进',
  typeErrorWarning: '选择的数据不能进行放行操作',
  remarkIvalidWarning: '选择的数据中已有备注，不能跟进',
  remarkEmptyError: '请填写备注',
  operationSuccess: '操作完成',
  createDistrain: '新增扣件',
  requiredError: '该项目不能为空',
  followSuccess: '跟进成功',
  lockTypeDiffrentError: '只能对扣件类型相同的数据进行操作',
  operationError: '操作错误',
  checkonFailedText: '入库失败',
  checkoutFailedText: '出库失败',
  dropEditBtn: '撤销',
  currReportMode: '当前播报模式',
  setReportBtn: '设置播报模式',
  setReportTitle: '播报设置',
  confirmBtn: '完成',
  reportWeight: '报重量',
  reportCountry: '报国家',
  reportProduct: '报产品',
  reportArea: '报分区',
  uploadDuplicate: '不能重复上传',
  exportDetailSuccess: '导出请求已发送，请至任务列表中下载对应文件',
  recoveryBox: '解除封箱',
  recoverySuccess: '解除封箱成功',
  chaneelSearchLabel: '国家/渠道查询',
  scheduleNo: '问题编号',
  dutyName: '责任人',
  scheduleStatusList: ['待处理', '处理中', '已处理'],
  scheduleDesc: '问题描述',
  createTime: '创建时间',
  handlerTime: '处理时间',
  scheduleOrderNo: '单号',
  scheduleRemark: '备注',
  createSchedule: '新增待办',
  scheduleOrderNoInvalid: '单号不存在',
  scheduleDescEmptyError: '请填写问题描述',
  dutyNameEmptyError: '请选择责任人',
  createScheduleSuccess: '待办事项创建成功',
  extData: '分区',
  grid: '格口',
  batteryFlag: '是否带电',
  numberPre: '单号前缀',
  followTab: '跟进处理',
  processLog: '操作日志',
  finishedCommit: '完成并提交',
  submitSuccess: '操作保存成功',
  operatorName: '操作人',
  operatorTime: '操作时间',
  operatorRemark: '操作详情',
  uploadImg: '图片凭证',
  viewImg: '预览图片',
  demandLabel: '操作要求',
  markLabel: '外观标记',
  exactBoxNo: '多箱号精确查询',
  fuzzyBoxNo: '单箱号模糊查询',
  chennlDiffrentError: '渠道箱标签模板不一致',
  countryDiffrentError: '只能选择同一国家的箱子',
  specialLockTypeError: '入库成功出库失败',
  backType: '退回方式',
  logisticNo: '快递单号',
  providerName: '快递公司',
  customerSignature: '客户签名',
  entrustedCompany: '被委托公司',
  plateNo: '车牌号',
  backTypeEmptyErr: '请选择退回方式',
  logisticNoEmptyErr: '请填写快递单号',
  providerNameEmptyErr: '请选择快递公司',
  customerSignatureEmptyErr: '客户签名不能为空',
  entrustedCompanyEmptyErr: '被委托公司不能为空',
  plateNoEmptyErr: '车牌号不能为空',
  backTypeArr: ['快递退回', '司机退回', '客户自提', '客户委托第三方提货'],
  returnRemark: '退回备注',
  bagNo: '揽收袋号',
  bagNoEmptyError: '袋号不能为空',
  scanbox: '已扫描袋数',
  inboundPersonName: '入库人',
  boxPersonName: '装箱人',
  outboundPersonName: '出库人',
  importAgent: '导入外部揽收',
  agentExcelFileName: '外部揽收模板.xlsx',
  batchDeleteEmpty: '请选择要删除的记录',
  batchDeleteSuccess: '删除记录成功',
  boxSuffix: '箱号后缀',
  printTrackNo: '打印派送面单',
  boxSuffixEmpty: '请输入箱号后缀',
  largePackageNo: '大包号',
  mostWeightVariation: '差异重量(±g)',
  largePackageWeight: '大包重量',
  largePackageNoEmpty: '请填写大包号',
  mostWeightVariationEmpty: '请填写差异重量',
  largePackageWeightEmpty: '请填写大包重量',
  copySuccess: '复制信息成功',
  copyFailed: '复制信息失败',
  printDelay: '打印间隔',
  weightDiffFirst: '首次重量差异，请使用相同单号再称一次',
  filesLimited: '上传文件数量超限'
}
