import request from '@/utils/request'

export function getAuthListApi(params) {
  return request({
    url: '/api/member-service/mem/member/info/list',
    method: 'get',
    params
  })
}

export function getAuthDetailApi(params) {
  return request({
    url: '/api/member-service/mem/qualification/examine/detailForOperation?memberId=' + params,
    method: 'get'
  })
}

export function submitAuthApi(data) {
  return request({
    url: '/api/member-service/mem/qualification/examine/review',
    method: 'post',
    data
  })
}

/**
 * 查询全部的用户 不带子账户
 * @param {*} params
 * @returns
 */

export function getSelectionsApi(params) {
  return request({
    url: '/api/member-service/mem/member/info/selections',
    method: 'get',
    params
  })
}

export function getCustomerSelectionsApi(data) {
  return request({
    url: '/api/sys-service/pnr/partner/advanced',
    method: 'post',
    data
  })
}
