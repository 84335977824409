var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom-end",
            title: "",
            width: "300",
            trigger: "click",
          },
          on: { show: _vm.getTaskList },
        },
        [
          _c(
            "div",
            {
              staticClass: "notification-btn",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c(
                "div",
                { staticClass: "test", staticStyle: { position: "relative" } },
                [
                  _c(
                    "el-badge",
                    {
                      staticClass: "item",
                      attrs: { "is-dot": _vm.unreadNumber },
                    },
                    [
                      _c("i", {
                        staticClass: "iconfont icon-xiaoxi1 top-account",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("common.navbar.notification"),
                    name: "notification",
                  },
                },
                [
                  _c("div", { staticClass: "notification-container" }, [
                    _vm.unreadNumber
                      ? _c(
                          "div",
                          [
                            _vm._l(
                              _vm.notificationList,
                              function (item, index) {
                                return _c(
                                  "el-row",
                                  {
                                    key: index,
                                    staticClass: "notification-item",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickNotify(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("el-col", { attrs: { span: 3 } }, [
                                          _c("i", {
                                            staticClass:
                                              "iconfont icon-xiaoxi1 top-account",
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c("el-col", { attrs: { span: 21 } }, [
                                          _c(
                                            "div",
                                            { staticClass: "content" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "title" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.notifyTitle)
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "notification-time",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.messageTime)
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "see-more",
                                on: { click: _vm.seeMore },
                              },
                              [_vm._v(_vm._s(_vm.$t("common.navbar.viewMore")))]
                            ),
                          ],
                          2
                        )
                      : _c(
                          "div",
                          [
                            _c("el-empty", {
                              attrs: {
                                description: _vm.$t(
                                  "common.navbar.messageEmpty"
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    label: _vm.$t("common.navbar.taskList"),
                    name: "taskList",
                  },
                },
                [
                  _c("div", { staticClass: "notification-container" }, [
                    _vm.hasTask
                      ? _c(
                          "div",
                          [
                            _vm._l(_vm.taskList, function (item, index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "task-item" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "task-name" },
                                    [
                                      _c("i", {
                                        staticClass: "iconfont icon-piegraph",
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: item.taskName,
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "tooltip-div" },
                                            [_vm._v(_vm._s(item.taskName))]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "task-status" }, [
                                    _vm._v(_vm._s(item.taskStatusName)),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        "task-file",
                                        {
                                          "disabled-download":
                                            item.taskStatus !== 3,
                                        },
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickTask(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "iconfont icon-clouddownload",
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "see-more",
                                on: { click: _vm.viewMoreTask },
                              },
                              [_vm._v(_vm._s(_vm.$t("common.navbar.viewMore")))]
                            ),
                          ],
                          2
                        )
                      : _c(
                          "div",
                          [
                            _c("el-empty", {
                              attrs: {
                                description: _vm.$t("common.navbar.taskEmpty"),
                              },
                            }),
                          ],
                          1
                        ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }