export default {
  'logistics_company_abbreviation': '物流公司简称',
  'please_enter_logistics_company_abbreviation': '请输入物流公司简称',
  'logistics_company_code': '物流公司代码',
  'please_enter_logistics_company_code': '请输入物流公司代码',
  'country': '国家',
  'please_enter_country': '请选择国家',
  'cn_name': '中文名称',
  'please_enter_cn_name': '请输入中文名称',
  'en_name': '英文名称',
  'please_enter_en_name': '请输入英文名称',
  'transport_mode': '运输方式',
  'please_enter_transport_mode': '请选择运输方式',
  'search': '搜索',
  'reset': '重置',
  'add': '新建',
  'operation': '操作',
  'company_code': '公司代码',
  'please_enter_company_code': '请输入公司代码',
  'company_desc': '请输入公司代码（最多50位）',
  'company_abbreviation': '公司简称',
  'please_enter_company_abbreviation': '请输入简称',
  'confirm': '确定',
  'cancel': '取消',
  'view': '查看',
  'edit': '编辑',
  'add_logistics_company': '物流公司新增',
  'edit_logistics_company': '物流公司编辑',
  'logistics_company_detail': '物流公司详情',
  'just_add_transport_mode': '只允许增加运输方式',
  transportMode: {
    'all': '全部',
    'express_elivery': '快递',
    'truck': '卡车',
    'railway': '铁路',
    'trailer': '拖车'
  }
}
