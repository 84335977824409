export default {
  wholeCategories: "全部编码类别",
  systemCategory: "系统条码",
  SKUCategory: "电商SKU编码",
  sysCode: "系统编码",
  goodsInfo: "货品编码",
  warehouseId: "仓库",
  saleCountryCodes: "销售国家",
  groundingNo: "批次号",
  stockNameZh: "中文名",
  stockNameEn: "英文名",
  goodsTypeName: "货品类型",
  operatorDate: "操作时间",
  exportAll: "查询结果导出",
  orderTypeText: ["", "进库", "出库", "库存调整", "活动"],
  viewLog: "货品库存日志",
  libraryAgeLog: "库龄日志",
  viewBatchLog: "批次库存日志",
  viewAge: "批次库龄",
  skuOrCode: "系统条码/电商SKU编码",
  exportNow: "导出中",
  exportTips:
    "该过程会占用较长的时间，您可以关闭弹框，前往任务列表下载导出文件",
  batchStockAmount: "批次上架数量",
  stockAmount: "上架数量",
  batchCurrInventory: "批次实时库存",
  justInTimeInventory: "实时库存",
  stockAvailable: "可用库存",
  batchFreezeInventory: "批次冻结库存",
  frozenStock: "出库冻结库存",
  stockFrozen: "冻结库存",
  stockInventory: "在途库存",
  quantityOutbound: "总出库数",
  quantityGrounding: "总入库数",
  groundingAge: "批次库龄(天)",
  stockDate: "上架时间",
  stockNo: "入库单号",
  stockType: "库存类型",
  beforeOptQuantity: "操作前库存",
  afterOptQuantity: "操作后库存",
  associatedNo: "关联单号",
  changeTypeName: "单据类型",
  quantity: "操作数量",
  cnt: "数量",
  currency: "币种",
  billingDate: "计费日期",
  warehouseName: "仓库名称",
  billingUnit: "计费单位",
  estimatedAmount: "预估金额",
  enterAmount: "入账金额",
  containerNo: "集装箱号",
  length: "长（cm）",
  width: "宽（cm）",
  height: "高（cm）",
  libraryAge: "库龄",
  playJoe: "打托",
  quantityHold: "每托件数",
  chargeOfTora: "计费时剩余托数",
  customerNumber: "客户编号",
  status: "状态",
  adaptation: "续存",
  business_stop: "业务停止",
  emergency_stop: "紧急停止",
  customer_cName: "客户中文名称",
  customer_eName: "客户英文名称",
  customer_short_Name: "客户简答称",
  cubeQuantity: '总体积',
  price: '单价',
  stockUpdateTime: '出库/上架触发时间',
  priceCardName: '价卡名称',
  charge_calculate_state: '计费状态',
  failureReason: '失败原因',
  retryBill: '重试计费',
  recalculateSuccess: '计费成功',
  exportingDetails: '导出明细',
  exportBtn: '导出'
};
