var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isCollapse,
              expression: "!isCollapse",
            },
          ],
          staticClass: "logo-container",
        },
        [
          _c("img", { attrs: { src: require("@/assets/logo-yht.png") } }),
          _vm._v(" "),
          _c("p", [_vm._v("SPE LINER")]),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        {
          class: ["left-menu-scroll-wrap", { "opend-menu": !_vm.isCollapse }],
          attrs: { "wrap-class": "scrollbar-wrapper" },
        },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.$route.path,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": false,
                "unique-opened": true,
                mode: "vertical",
              },
            },
            _vm._l(_vm.getPermissionRoutes(), function (route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: {
                  item: route,
                  "base-path": route.path,
                  "pending-nums": _vm.pendingNums,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }