export default {
  placeholder: '请选择',
  placeholderInput: '请输入',
  entityNo: "提单号",
  memberName: "客户",
  vesselName: '船名',
  voyageNo: '航次',
  polCode: '装货港',
  podCode: '卸货港',
  soNo: "订舱号",
  entityState: "提单状态",
  chargeState: "计费状态",
  autocharge: "自动计费",
  oceanCharge: "海运费",
  polLocalChargeCost: "起运港LOCAL费用",
  podLocalChargeCost: "目的港LOCAL费用",
  polLocalCharge: "起运港LOCAL",
  podLocalCharge: "目的港LOCAL",
  commissionPayable: "应付佣金",
  vesselNameAndvoyageNo: "船名/航次",
  operating: "操作",
  view: "查看",
  memberPlaceholder: '客户名称/简称',
  sumTotal: "总计",
  ticket: "票",
  receivableOceanCharge: "应收海运费",
  receivablePolLocalCharge: "应收起运港Local费用",
  receivablePolLocalChargeT: "起运港LOCAL CHARGE",
  receivablePodLocalCharge: "应收目的港Local费用",
  receivablePodLocalChargeT: "目的港LOCAL CHARGE",
  save: '保存',
  cancel: '取消',
  determine: '确定',
  findRequestWarning: "请通过检索条件筛选船期后进行自动计费操作",
  autochargeSuccessful: "自动计费成功！",
  billInformation: "提单信息",
  requester: '委托单位',
  referenceSign: "涉及约号",
  blNo: "提单号",
  associatedBooking: "关联订舱",
  transportTerm: "运输条款",
  pol: "起运港/POL",
  pod: "卸货港/POD",
  containerSizeTypeQty: "箱型/箱量",
  costSummary: "费用总览",
  totalReceivable: "应收总计",
  totalPayable: "应付总计",
  commission: "佣金",
  billingOverview: "计费总览",
  returnList: "返回列表",
  receivableDetail: "应收明细",
  quantityWarming: "输入数量已超货物类型或箱属数量，请重新输入，最大值为：",
  success: "操作成功",
  delSuccess: "删除成功",
  orderType: "业务类型",
  billNo: "账单编号",
  billState: "对账状态",
  businessDate: "业务时间",
  portCode: "业务口岸",
  feeCode: "费用项",
  settlementCompany: "对账方",
  currency: "币种",
  amount: "单价",
  quantity: "数量",
  totalAmount: "金额",
  createByName: "创建人",
  createTime: "创建时间",
  autochargeMessage: "所选提单的对应费用已经计费，是否重新进行计费操作，重新计费将覆盖原有根据约价生成的费用明细，是否继续？",
  autochargeWarming: "重新计费将覆盖已生成的费用明细，是否继续？",
  addCharge: "添加费用"


}
