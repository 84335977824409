<template>
	<div style="display: none" />
</template>
<script>
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import { getToken } from '@/utils/auth'

export default {
	name: 'Notification',
	components: {},
	data() {
		return {
			reconnectNum: 3,
			stompClient: null
		}
	},
	watch: {
		'$store.state.user.token': function (newToken) {
			if (!newToken) {
				this.disconnect()
			}
		}
	},
	created() {
		this.connect()
	},
	methods: {
		clickRow(row) {
			// this.$router.push('/message/detail?id=' + row.id)
			this.$router.push({name: 'TaskList'})
		},
		connect() {
			if (getToken()) {
				const socket = new SockJS(`${process.env.VUE_APP_BASE_API}/api/comm-service/ws?Authorization=` + getToken(), null, { timeout: 60000, transports: 'websocket' })
				this.stompClient = Stomp.over(socket)
				this.stompClient.connect({}, this.onConnected, this.onError)
			}
		},
		onConnected() {
			// The connection has not been established yet
			// Put the send call within the async-body of the get-connection. So after the connection is made, call the send method.
			setTimeout(() => {
				this.stompClient.subscribe('/app/public', this.onMessageReceived)
				this.stompClient.subscribe('/user/message', this.onMessageReceived)
				this.stompClient.subscribe('/user/business', this.onMessageReceived)
			}, 600)
		},
		disconnect() {
			this.stompClient.disconnect(() => { })
		},
		onError() {
			// reconnect 3
			if (this.reconnectNum) {
				this.reconnectNum = this.reconnectNum - 1
				setTimeout(this.connect, 300000)
			}
		},

		onMessageReceived(payload) {
			const item = JSON.parse(payload.body)
			// 获取最新数目
			this.$store.dispatch('message/getUnreadNumber')
			this.$notify({
				title: item.title,
				message: item.content,
				position: 'bottom-right',
				duration: 5000,
				customClass: 'notification-send',
				iconClass: 'iconfont icon-xiaoxi1',
				onClick: () => {
					this.clickRow(item)
				}
			})
		}
	}
}
</script>
<style lang="scss">
.el-notification.notification-send {
	width: 275px;
}

.notification-send {
	.el-notification__icon.icon-xiaoxi1 {
		color: #5f7197;
		font-size: 24px;
	}
	.el-notification__title {
		font-size: 14px;
	}
	.el-notification__content {
		font-size: 12px;
		color: #333;

		p {
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 170px;
		}
	}
}
</style>
