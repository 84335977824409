export default {
  unlessOneSelected: '请至少选择一个！',
  forwarderBillError: '请输入12位以内的字符，可输入英文、数字',
  addressError: '请输入36-105以内的字符，可输入英文、数字、小写符号',
  companyNameError: '请输入35位以内的字符，可输入英文、数字、小写符号',
  forwarderBill: 'AMS信息录入',
  name: '名称',
  whetherCommission: '是否委托海特申报',
  whetherCommissionPlaceholder: '请选择',
  NVOCC: 'NVOCC 货代提单号码',
  SCACCode: 'SCAC代码',
  forwarderBillPlaceholder: '请输入货代提单号码',
  forwarderBillPlaceholderYHT: 'HBL号码由系统自动分配',
  SCACCodePlaceholder: '请输入SCAC代码',
  actualShipper: '实际发货人',
  actualConsignee: '实际收货人',
  actualFirstNotifyParty: '实际第一通知方',
  actualSecondInformant: '实际第二通知方',
  companyNamePlaceholder: '请输入实际发货人公司名',
  hsCodeEmptyInvalid: '请输入HS CODE',
  HSCodeless6: 'HS CODE必须不少于6位数',
  grossWeightEmptyInvalid: '请输入毛重',
  volumeEmptyInvalid: '请输入体积',
  mark: '唛头',
  markEmptyInvalid: '请输入唛头',
  remarkPlaceholder: '请输入唛头',
  goodsDesc: '货物描述',
  goodsDescPlaceholder: '货物描述',
  goodsDescEmptyInvalid: '请输入货物描述',
  containerInfo: '集装箱信息',
  createContainerInfo: '添加集装箱信息',
  createContainerNotice: '注意：如果不添加任何集装箱信息，则默认整个提单通过当前House BL申报AMS。如需分开申报，请仔细填写各House BL相关的集装箱信息。',
  removeContainer: '移除集装箱',
  totalQuantity: '件数总计：',
  sumGrossWeight: '毛重总计：',
  sumVolume: '体积总计：',
  addRow: '添加物料',
  pieceEmptyInvalid: '请输入件数',
  unit: '单位',
  unitEmptyInvalid: '请输入单位',
  bindContainerNo: '箱号添加',
  bindBtn: '添加',
  address: '地址',
  addressPlaceholder: '请输入详细地址',
  city: '城市',
  cityPlaceholder: '请输入城市名称',
  phone: '电话',
  countryPlaceholder: '国家/地区',
  areaCodePlaceholder: '区号',
  localPlaceholder: '本地',
  telex: '电传',
  telexPlaceholder: '请输入电传号码',
  zipCode: '邮政编码',
  zipCodePlaceholder: '请输入邮政编码',
  issueBlFlag: '是否签发货代提单',
  attributionBillNo: '提单号归属',
  YHT: '海特',
  own: '自有',
  sealNumber: '封号',
  portLoading: '装货港',
  unloadingPort: '卸货港',
  howToDeclare: '申报方式',
  declarationStatus: '申报状态',
  declarationFeedback: '申报反馈',
  declarer: '申报方式',
  determined: '待定',
  agentDeclaration: '代理申报',
  selfDeclared: '自行申报',
  toBeDeclared: '待申报',
  submissionFailed: '提交失败',
  addingProcessing: '新增处理中',
  modifying: '修改处理中',
  deletingInProgress: '删除处理中 ',
  rejected: '已驳回',
  received: '已接收',
  matching: '匹配中',
  matchSuccessfully: '匹配成功',
  submitted: '已提交',
  declared: '已申报',
  passed: '已通过',
  AMS: 'AMS申报',
  tips: '温馨提示：已审核通过的美线提单请尽快完成AMS申报，避免影响目的港清关！',
  billNumber: '提单号',
  approvalStatus: '审核状态',
  boxVolume: '箱型箱量',
  quantity: '件数',
  packageType: '单位',
  weight: '毛重',
  volume: '体积',
  blTypeName: '提单类型',
  paymentModeName: '付款方式',
  vessel: '船名',
  voyage: '航次',
  rejectReason: '驳回理由',
  submittedSuccessfully: '提交成功',
  validPhoneSeaHousekeeperError: '只包含数字,总长度不超过35个字符',
  pleaseEnterBillLadingNumber: '请输入货代提单号码',
  hblOwnPlaceholder: '请选择提单号归属',
  isEmptyDeclare: '空箱申报',
  boxQuantity: '箱型箱量',
  aBoxWeight: '单箱毛重',
  aBoxVolume: '单箱体积',
  pieceQutity: '件数'
}
