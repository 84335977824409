export default {
    base_info: '合同基本信息',
    essentialInformation: '基本信息',
    contract_type: '合同类型',
    contract_kind_name: '合同分类名称',
    contract_name: '合同名称',
    contract_status: '合同状态',
    business_type: '业务类型',
    contract_no: '合同编号',
    contract_no_cannot_be_repeat: '合同编号不能重复',
    customer_company_name: '客户签约主体',
    supplier_company_name: '供应商签约主体',
    company_name_sign: '签约主体',
    business_no: '统一社会信用代码',
    yht_name: '海特签约主体',
    customer_sign_name: '客户签约人',
    supplier_sign_name: '供应商签约人',
    sign_name: '签约人',
    yht_sign_name: '海特经办人',
    period: '有效时间',
    period_date: '有效期',
    contract_start_time: '合同开始时间',
    contract_end_time: '合同结束时间',
    sales_and_product: '所属销售/产品',
    sales: '所属销售',
    salesSupport: '销售支持',
    product: '所属产品',
    remarks: '备注',
    operation: '操作',
    view: '查看',
    edit: '编辑',
    edit_success: '编辑成功',
    add_success: '新增成功',
    cancel: "取消",
    delete: "删除",
    delete_success: '删除成功',
    delete_confirm: '确定要删除？',
    delete_attachment: "删除附件",
    confirm_delete_attachment: '确定要删除当前附件？',
    delete_settlement: "删除结算主体",
    confirm_delete_settlement: '确定要删除当前结算主体？',
    save_draft: "保存草稿",
    save_draf_success: '保存草稿成功',
    submit: '提交',
    submit_success: '提交成功',
    confirm: '确认',
    close: '关闭',

    please_select: '请选择',
    please_enter: '请输入',
    cannot_be_null: '不能为空',

    new_settlement: '新建结算抬头',
    edit_settlement: '编辑结算抬头',

    new_business: '新增业务',
    new_receipt_contract: '新建收款合同',
    new_pay_contract: '新增付款合同',
    new_trial_contract: '新增试单合同',
    new_piece_contract: '新增票结合同',

    receipt_contract: '收款合同',
    pay_contract: '付款合同',
    trial_contract: '试单合同',
    piece_contract: '票结合同',

    business_relative_info: '关联业务信息',
    account_period_info: '账期信息',
    deposit: '押金',
    recharge_currency: '结算币种',
    recharge_mode: '结算方式',
    bill_base: '出账日期',
    statement_base: '对账日期',
    invoice_base: '开票日期',
    receipt_base: '收款日期',
    payment_base: '付款日期',

    index: '序号',
    settlement_info: '结算抬头',
    settlement_company_name: '结算主体',
    copy_header: '复制抬头',
    historical_settlement_entity: '历史结算主体',
    currency: '币种',
    bank_name: '银行名称',
    bank_branch: '银行分行',
    bank_account: '银行账号',
    bank_address: '银行地址',
    bank_no: '银行行号',
    tax_num: '税号',
    address: '公司地址',
    tel: '公司电话',
    swift_code: 'SWIFE CODE',
    default_start_time: '生效时间',
    effective_time: '开始生效时间',
  header_type: '抬头类型',

    attachment_info: '上传附件',
    enclosure: '上传附件',
    attachment_info_mention: '(支持扩展名：.rar .zip .doc .docx .pdf)',
    attachment_info_name: '附件名称',
    uploader: '上传人',
    upload_time: '上传时间',
    ALL: "全部",
    please_add_title_data: '请添加一条结算抬头数据!',
    please_add_business_data: '请先添加一个关联业务!',
    period_date_error: '合同开始时间不能大于合同结束时间!',
    add_children: '添加子合同',
    add_business: '新增业务',
    contract_detail: '详情',

    history_contract: '历史合同',
    business_cooperation: '合作的业务',
    department_by: '所属部门',
    area_by: '所属区域',
    company_by: '所属公司',
    account_period_info_must_enter: '的账期信息请填写完整!',
    account_period_info_must_enter_all: '新增业务的账期信息请填写完整!',
    settlement_title_repeat: '不能重复添加结算抬头',
    create_by_name: '创建人',
    create_time: '创建时间',
    settlement_title_status_name: '结算抬头状态',
    audit_accept: '审核通过',
    credits: '合同授信额度',
    credits_can_not_repeat: '合同授信额度的币种不能重复',
    start_time: '开始时间',
    end_time: '结束时间',
    Enclosure: '附件',
    multipleSelectorEmpty: '请至少选择一条数据',
    palletInformation: '货盘信息',
    freightRate: '运价',
    settlementInstructions: '结算说明'

}
