import Layout from '@/layout'
export const OutInStorageManageRouterMap = {
  // 出库管理
  'ovl:outbound': {
    component: Layout,
    alwaysShow: true,
    name: 'OutInStorageManage'
  },
  // 出库管理-一件代发列表
  'ovl:outbound:drop:shipping': {
    component: () => import('@/views/cargo/v0/OverseasWarehouse/OutInStorageManage/outboundList'),
    children: ['ovl:outbound:detail'],
    name: 'OutboundList'
  },
  // 出库管理-转仓列表
  'ovl:outbound:transfer:list': {
    component: () => import('@/views/cargo/v0/OverseasWarehouse/OutInStorageManage/transferBoundList'),
    status: '20',
    children: ['ovl:outbound:detail'],
    name: 'TransferBoundList'
  },
  // 出库管理-转仓FBA列表
  'ovl:outbound:transfer:fba': {
    component: () => import('@/views/cargo/v0/OverseasWarehouse/OutInStorageManage/transferFBABoundList'),
    status: '22',
    children: ['ovl:outbound:detail'],
    name: 'TransferFBABoundList'
  },
  "ovl:outbound:detail": {
    path: 'detail',
    component: () => import('@/views/cargo/v0/OverseasWarehouse/OutInStorageManage/outboundDetail'),
    name: 'OutboundDetail',
    hidden: true,
    meta: { title: 'outboundDetail' }
  }
}
