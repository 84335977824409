export default {
	transferByPackage: '按包裹调拨',
	transferByBox: '按箱调拨',
	warehouseList: '目的仓库',
	boxNo: '箱号',
	tplParcelNo: '临时袋号',
	orderNo: '单号',
	warehouseEmptyError: '请选择目的仓库',
	orderNoEmptyError: '请填写单号',
	boxNoEmptyError: '请填写箱号',
	transferTime: '调拨时间',
	batchArrived: '批量到达',
	destinationWarehouse: '目的仓库',
	status: '状态',
	createBy: '创建人',
	updateBy: '更新人',
	noneSelectedRows: '请至少选择一条记录',
	shipmentWarehouse: '起运仓库',
	operatorSuccess: '操作成功',
	noIdError: '没有可打印箱唛的数据',
	transferList: {
		orderNo: '订单号',
		trackingNo: '运单号',
		inboundTime: '入库时间',
		tplParcelNo: '临时袋号',
		shipmentWarehouse: '发货仓库',
		destinationWarehouse: '目的仓库',
		channel: '渠道',
		requisitionNo: '箱号',
		requisitionTime: '调拨时间',
		loadingWarehouseName: '起运仓库',
		destinationWarehouseName: '目的仓库',
		operationBtn: '操作'
	},
	transferDetail: {
		boxNo: '箱号',
		transferredDetailEmpty: '未能获取详情数据',
		orderNo: '订单号',
		trackingNo: '运单号',
		inboundTime: '入库时间',
		loadingWarehouseName: '起运仓库',
		destinationWarehouseName: '目的仓库',
		productName: '渠道',
		inboundWeight: '订单实际重量'
	}
}
