import Layout from '@/layout'

const ContainerManagerRouterMap = {
  // 集装箱信息管理
  'ctn:container': {
    component: Layout,
    name: 'ContainerManager'
  },
  // 交易订单列表
  'ctn:order:list': {
    component: () => import('@/views/cargo/v0/ContainerManager/OrderList'),
    name: 'OrderList',
    children: ['ctn:order:list:create', 'ctn:order:list:detail']
  },
  // 生产订单列表
  'ctn:production:order': {
    component: () => import('@/views/cargo/v0/ContainerManager/ProductionOrderList'),
    name: 'ProductionOrderList',
    children: ['ctn:production:order:list:create', 'ctn:production:order:list:detail']
  },
  // 集装箱信息列表
  'ctn:info:list': {
    component: () => import('@/views/cargo/v0/ContainerManager/InfoList'),
    name: 'InfoList',
    children: ['ctn:info:list:create', 'ctn:info:list:detail']
  },
  // 租借信息列表
  // 'ctn:lease:list':{
  //   component: () => import('@/views/cargo/v0/ContainerManager/LeaseList'),
  //   name: 'LeaseList',
  //   children: ['ctn:lease:list:lease', 'ctn:lease:list:loan']
  // },
  // 箱动态列表
  'ctn:status:log:list': {
    component: () => import('@/views/cargo/v0/ContainerManager/DynamicList'),
    name: 'DynamicList',
    children: ['ctn:status:log:list:update', 'ctn:status:log:list:detail', 'ctn:status:log:list:list']
  },
  // // 在场列表
  // 'ctn:status:log:inyardList': {
  //   component: () => import('@/views/cargo/v0/ContainerManager/InsideYardList'),
  //   name: 'InsideYardList'
  // },
  // // 场外列表
  // 'ctn:status:log:outyardList': {
  //   component: () => import('@/views/cargo/v0/ContainerManager/OutsideYardList'),
  //   name: 'OutsideYardList'
  // },
  // // 在船列表
  // 'ctn:status:log:onboardList': {
  //   component: () => import('@/views/cargo/v0/ContainerManager/OnBoatList'),
  //   name: 'OnBoatList'
  // },
  // 调拨订单
  'ctn:allot:order': {
    component: () => import('@/views/cargo/v0/ContainerManager/TransferOrderList'),
    name: 'TransferOrderList',
    children: ['ctn:allot:order:create', 'ctn:allot:order:detail']
  },
  // 退出订单列表
  'ctn:quit:order': {
    component: () => import('@/views/cargo/v0/ContainerManager/ContainerQuitList'),
    name: 'ContainerOuitList',
    children: ['ctn:quit:order:create', 'ctn:quit:order:detail']
  },
  // 集装箱统计列表
  'ctn:statistics:list': {
    component: () => import('@/views/cargo/v0/ContainerManager/ContainerStatistics'),
    name: 'ContainerStatistics'
  },
  // 集装箱报表 - 租箱报表
  // 'ctn:report:list': {
  //   component: () => import('@/views/cargo/v0/ContainerManager/LeaseReportList'),
  //   name: 'leaseReportList'
  // },
  // 集装箱报表 - 盘存报表
  'ctn:report:list': {
    component: () => import('@/views/cargo/v0/ContainerManager/InventoryReportList'),
    name: 'InventoryReportList'
  },
  // 箱管下的任务列表
  'ctn:assignment:list': {
    component: () => import('@/views/cargo/v0/taskManager/taskList'),
    name: 'ContainerTaskList'
  },
  // 修洗箱
  'ctn:wash:order': {
    component: () => import('@/views/cargo/v0/ContainerManager/CleanFixedList'),
    name: 'CleanFixedList',
    children: ['ctn:wash:order:detail']
  },
  // 外代动态导入
  'ctn:dy:log:list': {
    component: () => import('@/views/cargo/v0/ContainerManager/AgentImport'),
    name: 'AgentImport'
  },
  // 放箱列表
  // 'ctn:release:list': {
  //   component: () => import('@/views/cargo/v0/ContainerManager/ReleasedList'),
  //   name: 'ReleasedList',
  //   children: ['ctn:release:list:views', 'ctn:release:list:edit']
  // },
  // // 报文解析日志
  // 'ctn:analyze:log:list': {
  //   component: () => import('@/views/cargo/v0/ContainerManager/AnalyzeLog'),
  //   name: 'AnalyzeLog',
  //   noCache: true
  // },
  // // 被拒动态
  // 'ctn:reject:log:list': {
  //   component: () => import('@/views/cargo/v0/ContainerManager/DynamicRejectList'),
  //   name: 'DynamicRejectList',
  //   noCache: true,
  //   children: ['ctn:reject:log:modify']
  // },
  // 新建页
  // 'ctn:order:list:create': {
  //   path: 'createOrder',
  //   component: () => import('@/views/cargo/v0/ContainerManager/OrderDetail'),
  //   name: 'OrderDetail',
  //   hidden: true,
  //   meta: { title: '交易订单详情', noCache: false }
  // },
  // 'ctn:production:order:list:create': {
  //   path: 'createProductionOrderDetail',
  //   component: () => import('@/views/cargo/v0/ContainerManager/ProductionOrderDetail'),
  //   name: 'ProductionOrderDetail',
  //   hidden: true,
  //   meta: { title: '生产订单详情', noCache: false }
  // },
  // 'ctn:info:list:create': {
  //   path: 'createInfoDetail',
  //   component: () => import('@/views/cargo/v0/ContainerManager/InfoDetail'),
  //   name: 'InfoDetail',
  //   hidden: true,
  //   meta: { title: '集装箱信息详情', noCache: false }
  // },
  // 'ctn:allot:order:create': {
  //   path: 'createTransferOrderDetail',
  //   component: () => import('@/views/cargo/v0/ContainerManager/TransferOrderDetail'),
  //   name: 'TransferOrderDetail',
  //   hidden: true,
  //   meta: { title: '调拨详情', noCache: false }
  // },
  // 'ctn:quit:order:create': {
  //   ath: 'createQuitDetail',
  //   component: () => import('@/views/cargo/v0/ContainerManager/ContainerQuitDetail'),
  //   name: 'ContainerQuitDetail',
  //   hidden: true,
  //   meta: { title: '集装箱退出详情', noCache: false }
  // },

  // 详情页
  'ctn:order:list:detail': {
    path: 'orderDetail',
    component: () => import('@/views/cargo/v0/ContainerManager/OrderDetail'),
    name: 'OrderDetail',
    hidden: true,
    meta: { title: 'ONHIRE订单详情', noCache: false }
  },
  'ctn:production:order:list:detail': {
    path: 'productionOrderDetail',
    component: () => import('@/views/cargo/v0/ContainerManager/ProductionOrderDetail'),
    name: 'ProductionOrderDetail',
    hidden: true,
    meta: { title: '放单详情', noCache: false }
  },
  'ctn:info:list:detail': {
    path: 'infoDetail',
    component: () => import('@/views/cargo/v0/ContainerManager/InfoDetail'),
    name: 'InfoDetail',
    hidden: true,
    meta: { title: '集装箱信息详情', noCache: false }
  },
  'ctn:status:log:list:update': {
    path: 'upgradeDynamic',
    component: () => import('@/views/cargo/v0/ContainerManager/DynamicUpgrade'),
    name: 'UpgradeDynamic',
    hidden: true,
    meta: { title: '更新箱动态', noCache: false}
  },
  'ctn:status:log:list:detail': {
    path: 'viewDynamicSubDetail',
    component: () => import('@/views/cargo/v0/ContainerManager/ViewDynamicSubDetail'),
    name: 'ViewDynamicSubDetail',
    hidden: true,
    meta: { title: '集装箱详情', noCache: false }
  },
  'ctn:status:log:list:list': {
    path: 'viewContainerList',
    component: () => import('@/views/cargo/v0/ContainerManager/ViewContainerList'),
    name: 'ViewContainerList',
    meta: { title: '查看集装箱列表', noCache: true },
    hidden: true
  },
  'ctn:allot:order:detail': {
    path: 'transferOrderDetail',
    component: () => import('@/views/cargo/v0/ContainerManager/TransferOrderDetail'),
    name: 'TransferOrderDetail',
    hidden: true,
    meta: { title: '调拨详情', noCache: false }
  },
  'ctn:quit:order:detail': {
    path: 'containerQuitDetail',
    component: () => import('@/views/cargo/v0/ContainerManager/ContainerQuitDetail'),
    name: 'ContainerQuitDetail',
    hidden: true,
    meta: { title: 'OFFHIRE订单详情', noCache: false }
  },
  'ctn:wash:order:detail': {
    path: 'cleanFixedDetail',
    component: () => import('@/views/cargo/v0/ContainerManager/CleanFixedDetail'),
    name: 'CleanFixedDetail',
    hidden: true,
    meta: { title: '修洗箱详情' }
  }
  // 'ctn:lease:list:lease': {
  //   path: 'leaseDetail/:editType?/:orderType?/:id?',
  //   component: () => import('@/views/cargo/v0/ContainerManager/LeaseDetail'),
  //   name: 'LeaseDetail',
  //   hidden: true,
  //   meta: { title: '租入信息详情', noCache: true }
  // },
  // 'ctn:lease:list:loan': {
  //   path: 'loanDetail/:editType?/:orderType?/:id?',
  //   component: () => import('@/views/cargo/v0/ContainerManager/LoanDetail'),
  //   name: 'LoanDetail',
  //   hidden: true,
  //   meta: { title: '借出信息详情', noCache: true }
  // },
  // 'ctn:release:list:views': {
  //   path: 'viewReleasedDetail/:sailScheduleId?',
  //   component: () => import('@/views/cargo/v0/ContainerManager/ViewReleasedDetail'),
  //   name: 'ViewReleasedDetail',
  //   hidden: true,
  //   meta: { title: '放箱详情查看', noCache: true }
  // },
  // 'ctn:release:list:edit': {
  //   path: 'modifyReleasedInfo/:sailScheduleId?',
  //   component: () => import('@/views/cargo/v0/ContainerManager/ModifyReleasedInfo'),
  //   name: 'ModifyReleasedInfo',
  //   hidden: true,
  //   meta: { title: '放箱编辑', noCache: true }
  // },
  // 'ctn:reject:log:modify': {
  //   path: 'daynamicRejectModify/:id?',
  //   component: () => import('@/views/cargo/v0/ContainerManager/DynamicRejectModify'),
  //   name: 'DynamicRejectModify',
  //   hidden: true,
  //   meta: { title: '拒绝放箱编辑', noCache: true }
  // },
}

export default ContainerManagerRouterMap
