export default {
  productCode: '产品代码',
  nameCode: '名称/代码',
  productName: '产品名称',
  companyId: '物流公司名称',
  nameCode1: '名称/简称/代码',
  status: '状态',
  all: '全部',
  active: '失效',
  inactive: '生效',
  detail: '查看',
  add: '新建',
  fuel: '燃油附加费率',
  fuelStartTime: '燃油附加费开始日期',
  fuelEndTime: '燃油附加费结束日期',
  operation: '操作',
  updateSuccess: '更新成功',
  saveSuccess: '保存成功',
  selectProduct: '请选择产品代码展示产品名称',
  selectProduct1: '请选择产品代码展示物流公司名称',
  cancel: '取消',
  save: '保存',
  chargeItemName: '费用名称',
  startTime: '旺季开始日期',
  endTime: '旺季结束日期',
  warehouse: '仓库名称',
  countryName: '国家',
  selectCountryName: '请选择产仓库后带出国家',
  selectSupplierName: '请选择产仓库后带出供应商',
  peakSeasonConfig: '旺季配置',
  peakSeasonTimeFlag: '是否有旺季',
  hasFuelRate: '是否有燃油',
  seasonTime: '旺季时间',
  endTimeFlag: '无结束日期',
  confirm: '状态变更会删除历史维护的数据，是否确定？',
  tips: '提示',
  confirmBtn: '确定',
  fuelRate: '燃油费率管理',
  chargingTime: '收取时间',
  errTips: '请维护数据完整再继续新增',
  seasonTimePrice: '附加费成本价',
  channelName: '渠道名称',
  channelCode: '渠道代码',
  channel: '渠道',
  costPrice: '附加费成本价',
  supplierName: '渠道供应商',
  beReleased: '待发布',
  release: '发布中',
  thePublished: '已发布',
  postFailure: '发布失败',
  basePrice: '成本价格',
  to: '至',
  publish: '发布',
  currency: '币种'
}
