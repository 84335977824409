/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

export const ContractPriceRouterMap = {

  // 港口免用箱-列
  'cpm:port:free:setting': {
    path: '/ContractPrice/PortFreeSetting',
    component: () => import('@/views/contractPrice/portFreeContainer'),
    name: 'PortFreeContainer',
    // hidden: true,
    meta: { title: '港口免用箱' },
    roles: []
  },
  // 运输条款及费用管理
  'cpm:transport:term:fee': {
    path: '/ContractPrice/TransportTermFee',
    component: () => import('@/views/contractPrice/transportationTerms'),
    name: 'TransportationTerms',
    // hidden: true,
    meta: { title: '运输条款管理' },
    roles: []
  },
  // 运输条款及费用管理
  'cpm:local:charge': {
    path: '/ContractPrice/LocalCharge',
    component: () => import('@/views/contractPrice/localCharge'),
    name: 'LocalCharge',
    // hidden: true,
    meta: { title: 'LOACL CHARGE管理' },
    roles: [],
    children: ['cpm:local:charge:details']
  },
  'cpm:local:charge:details': {
    path: '/ContractPrice/localChargeDetails',
    component: () => import('@/views/contractPrice/localChargeDetails'),
    name: 'LocalChargeDetails',
    hidden: true,
    meta: { title: 'LOACL CHARGE详情' },
    roles: [],
    children: ['cpm:local:charge:details']
  },
  // 口岸业务配置列表
  'spm:port:info:list': {
    path: '/ContractPrice/PortInfo',
    component: () => import('@/views/contractPrice/portConfigurationList'),
    name: 'PortConfigurationList',
    // hidden: true,
    meta: { title: '口岸业务配置' },
    roles: [],
    children: ['spm:port:info:details']
  },
  'spm:port:info:details': {
    path: '/Port/PortDetails',
    component: () => import('@/views/contractPrice/portConfigurationDetail'),
    name: 'PortConfigurationDetail',
    hidden: true,
    meta: { title: '口岸业务配置详情' },
    roles: [],
    children: []
  },
  // 约价管理主菜单
  'cpm:cpm': {
    component: Layout,
    path: '/ContractPrice',
    alwaysShow: true,
    name: 'ContractPrice',
    meta: {
      icon: 'icon-feiyongshenqing'
    }
  },
  // 约价管理列表
  'cpm:contract': {
    path: '/ContractPrice/ContractMgr',
    component: () => import('@/views/contractPrice/contractPriceList'),
    name: 'ContractMgr',
    // hidden: true,
    meta: { title: '约价管理' },
    roles: [],
    children: ['pnr:scn:details', 'cpm:contract:details:fak', 'cpm:contract::long', 'cpm:contract:details:long', 'cpm:contract:details:nolong', 'cpm:contract:port',
      'cpm:contract:view:fak', 'cpm:contract:view:long', 'cpm:contract:view:nolong', 'cpm:contract:history:view:long', 'cpm:contract:history:view:nolong', 'cpm:contract:history:view:fak'
      ]
  },
  // 约价审批列表
  'cpm:cpm:flw:contract': {
    component: () => import('@/views/contractPrice/flwContract/list'),
    name: 'FlwContractList',
    meta: {
      noCache: false
    },
    children: [
      'cpm:cpm:flw:contract:config',
      'cpm:contract:approvalDetails',
      'cpm:contract:approvalSoDetails',
      'cpm:contract:details:view',
      'cpm:contract:view:fak',
      'cpm:contract:view:long',
      'cpm:contract:view:nolong',
      'cpm:contract:dndDetail'
    ]
  },
  // 约价审批 - 审批记录
  'cpm:cpm:flw:record': {
    component: () => import('@/views/contractPrice/flwContract/record'),
    name: 'FlwContractRecord',
    meta: {
      noCache: false
    }
  },
  // 约价审批列表-审批流配置
  'cpm:cpm:flw:contract:config': {
    path: 'flwConfig',
    component: () => import('@/views/contractPrice/flwContract/flwConfig'),
    name: 'FlwConfig',
    meta: { title: '审批流配置' },
    hidden: true
  },
  // 约价审批详情
  'cpm:contract:approvalDetails': {
    path: '/ContractPrice/ApprovalDetails',
    component: () => import('@/views/contractPrice/flwContract/approvalDetails'),
    name: 'ApprovalDetails',
    hidden: true,
    meta: { title: '约价审批详情' },
    roles: [],
    children: []
  },
  // 约价审批详情 so
  'cpm:contract:approvalSoDetails': {
    path: '/ContractPrice/ApprovalSoDetails',
    component: () => import('@/views/contractPrice/flwContract/approvalSoDetails'),
    name: 'ApprovalSoDetails',
    hidden: true,
    meta: { title: '约价审批详情' },
    roles: [],
    children: []
  },
  'cpm:contract:details:view': {
    path: '/ContractPrice/ApprovalView',
    component: () => import('@/views/contractPrice/flwContract/approvalView'),
    name: 'ApprovalView',
    hidden: true,
    meta: { title: '查看约价审批' },
    roles: [],
    children: []
  },
  // 订舱渠道管理-列表
  'cpm:channel': {
    path: '/OrderChannelMgr',
    component: () => import('@/views/contractPrice/orderChannelList'),
    name: 'OrderChannelMgr',
    // hidden: true,
    meta: { title: '渠道管理' },
    roles: [],
    children: ['cpm:channel:details']
  },
  // 订舱渠道管理 - 详情
  'cpm:channel:details': {
    path: '/OrderChannelDetail/:operate/:id?',
    component: () => import('@/views/contractPrice/orderChannelDetail'),
    name: 'OrderChannelDetail',
    hidden: true,
    meta: { title: '编辑渠道管理' },
    roles: []
  },

  // 	收发通管理（订舱代理）
  'pnr:scn:agent': {
    path: '/scnManager/agentManager',
    component: () => import('@/views/spe/sendReceivingList'),
    name: 'SendReceivingList',
    // hidden: true,
    meta: { title: '收发通管理(订舱代理)' },
    roles: [],
    children: ['pnr:scn:details']
  },
  // 	收发通管理（航线运营）
  'pnr:scn:op': {
    path: '/scnManager/opManager',
    component: () => import('@/views/spe/sendReceivingRouteList'),
    name: 'SendReceivingRouteList',
    // hidden: true,
    meta: { title: '收发通管理(航线运营)' },
    roles: [],
    children: ['pnr:scn:details', 'pnr:scn:details2']
  },
  // 收发通详情
  'pnr:scn:details': {
    path: '/agentManager',
    component: () => import('@/views/spe/sendReceivingDetails'),
    name: 'SendReceivingDetails',
    hidden: true,
    meta: { title: '收发通信息编辑' },
    roles: []
  },
  // 约价详情 长期 'cpm:contract:details:long', 'cpm:contract:details:nolong',
  'cpm:contract:details:fak': {
    path: '/ContractPrice/FakDetails',
    component: () => import('@/views/contractPrice/fakContractDetails'),
    name: 'fakContractDetails',
    hidden: true,
    meta: { title: '约价FAK详情' },
    roles: []
  },
  'cpm:contract:view:fak': {
    path: '/ContractPrice/FakView',
    component: () => import('@/views/contractPrice/fakContractDetails/view'),
    name: 'fakContractView',
    hidden: true,
    meta: { title: '约价FAK详情' },
    roles: []
  },
  'cpm:contract:details:long': {
    path: '/ContractPrice/LongDetails',
    component: () => import('@/views/contractPrice/longContractDetails'),
    name: 'longContractDetails',
    hidden: true,
    meta: { title: '约价长期详情' },
    roles: []
  },
  'cpm:contract:view:long': {
    path: '/ContractPrice/LongView',
    component: () => import('@/views/contractPrice/longContractDetails/view'),
    name: 'longContractView',
    hidden: true,
    meta: { title: '约价长期详情' },
    roles: []
  },
  'cpm:contract:details:nolong': {
    path: '/ContractPrice/NoLongDetails',
    component: () => import('@/views/contractPrice/noLongContractDetails/indexNew'),
    name: 'noLongContractDetails',
    hidden: true,
    meta: { title: '约价非长期详情' },
    roles: []
  },
  'cpm:contract:view:nolong': {
    path: '/ContractPrice/NoLongView',
    component: () => import('@/views/contractPrice/noLongContractDetails/view'),
    name: 'noLongContractView',
    hidden: true,
    meta: { title: '约价非长期详情' },
    roles: []
  },
  // 履约统计列表
  'cpm:contract:statistics': {
    path: '/ContractPrice/CpmContractStatistics',
    component: () => import('@/views/contractPrice/statistics/list'),
    name: 'CpmStatisticsList',
    meta: {noCache: false},
    children: ['cpm:contract:statistics:approvalDetails']
  },
  // 履约统计详情
  'cpm:contract:statistics:approvalDetails': {
    path: '/ContractPrice/CpmContractStatisticsDetails',
    component: () => import('@/views/contractPrice/statistics/details'),
    name: 'CpmStatisticsDetails',
    hidden: true,
    meta: { title: '履约统计详情' },
    children: []
  },
  'cpm:contract:history:view:long': {
    path: '/ContractPrice/longHistoryView',
    component: () => import('@/views/contractPrice/longContractDetails/viewHistory'),
    name: 'LongViewHistory',
    hidden: true,
    meta: { title: '长期约价历史版本' },
    children: []
  },
  'cpm:contract:history:view:nolong': {
    path: '/ContractPrice/nolongHistoryView',
    component: () => import('@/views/contractPrice/noLongContractDetails/viewHistory'),
    name: 'NoLongViewHistory',
    hidden: true,
    meta: { title: '非长期约价历史版本' },
    children: []
  },
  'cpm:contract:history:view:fak': {
    path: '/ContractPrice/fakHistoryView',
    component: () => import('@/views/contractPrice/fakContractDetails/viewHistory'),
    name: 'FAKViewHistory',
    hidden: true,
    meta: { title: 'FAK历史版本' },
    children: []
  },
  // DND申请/审核/查看审核
  'cpm:contract:dndDetail': {
    path: '/ContractPrice/dndDetail',
    component: () => import('@/views/contractPrice/dndContract/dndDetail'),
    name: 'DNDDetail',
    hidden: true,
    meta: { title: 'DND详情' },
    children: []
  }
  // // DND审批
  // 'cpm:contract:verifyDND': {
  //   path: '/ContractPrice/verifyDND',
  //   component: () => import('@/views/contractPrice/dndContract/verifyDND'),
  //   name: 'VerifyDND',
  //   hidden: true,
  //   meta: { title: 'DND审批' },
  //   children: []
  // },
  // // DND查看审批
  // 'cpm:contract:viewDND': {
  //   path: '/ContractPrice/viewDND',
  //   component: () => import('@/views/contractPrice/dndContract/viewDND'),
  //   name: 'ViewDND',
  //   hidden: true,
  //   meta: { title: 'DND详情' },
  //   children: []
  // }
}
