var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          attrs: {
            filterable: "",
            clearable: "",
            remote: "",
            "reserve-keyword": "",
            placeholder: "请选择客户",
            "remote-method": _vm.remoteMethod,
            loading: _vm.loading,
            size: "mini",
          },
          on: { change: _vm.change },
          model: {
            value: _vm.selectVal,
            callback: function ($$v) {
              _vm.selectVal = $$v
            },
            expression: "selectVal",
          },
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.userList, function (item, index) {
      return _c("el-option", {
        key: index,
        attrs: { label: item.chineseName, value: item.id },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }