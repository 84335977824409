export default {
	createBtn: '新建',
	editRoleTitle: '新建/编辑角色',
	roleName: '角色名称',
	roleDesc: '角色描述',
	roleCode: '角色编码',
	copyRole: '复制角色',
	copiedRole: '被复制对象',
	copyTitle: '角色权限',
	assignUserTitle: '分配用户',
	confirmDeleteTip: '确认删除该角色?',
	saveSuccess: '保存成功',
	copySuccess: '复制成功',
	roleNameEmptyError: '请输入角色名称',
	roleDescEmptyError: '请输入角色描述',
	roleCodeEmptyError: '请输入角色编码',
	table: {
		id: 'id',
		name: '角色名称',
		createByName: '创建人',
		createTime: '创建日期',
		updateBy: '更新人员',
		updateTime: '更新日期',
		auth: '权限',
		description: '描述',
		checked: '可用',
		bAssigned: '分配',
		userName: '用户名',
		chineseName: '中文名',
		englishName: '英文名',
		officeShortName: '公司简称',
		departmentName: '部门',
		optBtn: '操作',
	}
}