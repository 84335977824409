export default {
  fee_code: '费用代码',
  fee_code_name: '费用代码/简称',
  fee_short_name: '费用简称',
  business_type: '业务类型',
  chinese_english_name: '中英文说明',
  chinese_name: '中文说明',
  english_name: '英文说明',
  remark: '备注',
  create_by: '创建人',
  create_time: '创建时间',
  operation: '操作',
  ALL: '全部',
  is_confirm_delete: '是否确认删除?',
  delete_success: '删除成功',
  add: '新建',
  edit: '编辑',
  delete: '删除',
  view: '查看',
  cancel: '取消',
  close: '关闭',
  use_business: '应用业务',
  save_draft: '保存草稿',
  save_draft_success: '保存草稿成功',
  submit: '提交',
  submit_success: '提交成功',
  cannot_be_null: '不能为空'
}
