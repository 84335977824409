var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table-column",
    _vm._g(
      _vm._b(
        {
          attrs: {
            prop: _vm.column.prop,
            label: _vm.column.label,
            type: _vm.column.type,
            index: _vm.column.index,
            "column-key": _vm.column.columnKey,
            width: _vm.column.width,
            "min-width": _vm.column.minWidth,
            fixed: _vm.column.fixed,
            "render-header": _vm.column.renderHeader,
            sortable: _vm.column.sortable || false,
            "sort-method": _vm.column.sortMethod,
            "sort-by": _vm.column.sortBy,
            "sort-orders": _vm.column.sortOrders,
            resizable: _vm.column.resizable || true,
            formatter: _vm.column.formatter,
            "show-overflow-tooltip": _vm.column.showOverflowTooltip || false,
            align: _vm.column.align || _vm.align || "left",
            "header-align":
              _vm.column.headerAlign ||
              _vm.headerAlign ||
              _vm.column.align ||
              _vm.align ||
              "left",
            "class-name": _vm.column.className,
            "label-class-name": _vm.column.labelClassName,
            selectable: _vm.column.selectable,
            "reserve-selection": _vm.column.reserveSelection || false,
            filters: _vm.column.filters,
            "filter-placement": _vm.column.filterPlacement,
            "filter-multiple": _vm.column.filterMultiple,
            "filter-method": _vm.column.filterMethod,
            "filtered-value": _vm.column.filteredValue,
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function (scope) {
                return [
                  _vm.column.renderHeader
                    ? _c("lb-render", {
                        attrs: {
                          scope: scope,
                          render: _vm.column.renderHeader,
                        },
                      })
                    : _c("span", [_vm._v(_vm._s(scope.column.label))]),
                ]
              },
            },
            {
              key: "default",
              fn: function (scope) {
                return [
                  _c("lb-render", {
                    attrs: { scope: scope, render: _vm.column.render },
                  }),
                ]
              },
            },
          ]),
        },
        "el-table-column",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm.column.children
        ? _vm._l(_vm.column.children, function (col, index) {
            return _c("lb-column", { key: index, attrs: { column: col } })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }