export default {
  warehouse_name: "仓库名称",
  please_enter_warehouse_name: "请输入仓库名称",
  country: "国家",
  country_desc: "名称/代码",
  supplier: "仓库供应商",
  please_select_supplier: "请选择供应商",
  supplier_desc: "名称/代码",
  status: "状态",
  statusType: {
    ALL: "全部",
    EFFECTIVE: "生效",
    LOSE_EFFICACY: "失效"
  },
  search: "搜索",
  reset: "重置",
  add: "新建",
  view: "查看",
  edit: "编辑",
  save: "保存",
  confirm: "确定",
  cancel: "取消",
  operation: "操作",
  lose_efficacy_info: "失效后的仓库将无法下单，您还要继续吗？",
  status_update_success: "状态更新成功",
  base_info: "基本信息",
  product_category: "产品类别",
  please_select_product_category: "请选择产品类别",
  areas: "所在地区",
  please_select_areas: "请选择所在地区",
  city: "城市",
  please_enter_city: "请输入城市",
  detail_address: "详细地址",
  please_enter_detail_address: "请输入详细地址",
  zip_code: "邮编",
  please_enter_zip_code: "请输入邮编",
  in_warehouse_contact: "入库联系人",
  please_enter_in_warehouse_contact: "请输入入库联系人",
  in_warehouse_email: "入库人邮箱",
  please_enter_in_warehouse_email: "请输入入库联系人邮箱",
  out_warehouse_contact: "出库联系人",
  please_enter_out_warehouse_contact: "请输入出库联系人",
  out_warehouse_email: "出库人邮箱",
  please_enter_out_warehouse_email: "请输入出库联系人邮箱",
  please_enter_warehouse_phone: "请输入联系电话",
  warehouse_phone_area_no: "电话",
  please_enter_warehouse_phone_area_no: "请输入联系电话区号",
  zone: "所在时区",
  please_select_zone: "请选择所在时区",
  work_time: "作业时间",
  start_work_time: "开始时间",
  please_select_start_work_time: "请选择开始时间",
  end_work_time: "结束时间",
  please_select_end_work_time: "请选择结束时间",
  cut_time: "截单时间",
  please_select_cut_time: "请选择截单时间",
  docking_ways: "对接方式",
  please_select_docking_ways: "请选择对接方式",
  allow_snap_shoot: "是否支持快照",
  please_select_allow_snap_shoot: "请选择是否支持快照",
  YES: "是",
  NO: "否",
  snap_shoot_time: "快照时点",
  please_select_snap_shoot_time: "请选择快照时点",
  in_warehouse_config: "入库配置",
  in_warehouse_type: "入库类型",
  please_select_in_warehouse_type: "请选择入库类型",
  container_type: "可接受箱型",
  please_select_container_type: "请选择可接受箱型",
  receive_goods_type: "货物类型",
  please_select_receive_goods_type: "请选择货物类型",
  support_self_send: "支持自送仓",
  please_select_support_self_send: "请选择支持自送仓",
  allow_get_entry_order: "入库单是否可取消",
  please_select_allow_get_entry_order: "请选择入库单是否可取消",
  warehouse_inner_config: "库内配置",
  support_batch: "是否支持批次",
  please_select_support_batch: "请选择是否支持批次",
  warehouse_inner_service: "库内服务",
  please_select_warehouse_inner_service: "请选择库内服务",
  out_warehouse_config: "出库配置",
  out_warehouse_type: "出库类型",
  please_select_out_warehouse_type: "请选择出库类型",
  allow_out_entry_order: "出库单是否可取消",
  please_select_allow_out_entry_order: "请选择出库单是否可取消",
  logistics_product: "物流产品",
  logistics_product_desc: "名称/代码",
  please_select_logistics_product: "请选择物流产品",
  logistics_product_code: "物流产品代码",
  logistics_product_name: "物流产品名称",
  logistics_company_name: "物流公司名称",
  delete: "删除",
  increase: "添加",
  tail_send_channel: "尾程派送渠道",
  delivery_channel: "物流渠道",
  channel_code: "渠道代码",
  channel_desc: "名称/代码",
  please_select_channel: "请选择渠道代码",
  channel_name: "渠道名称",
  product_name: "物流产品名称",
  product_name_desc: "请选择存在物流产品的渠道",
  supplier_name: "供应商",
  supplier_name_desc: "请选择存在供应商的渠道",
  save_success: "保存成功",
  delete_info: "是否确认要删除该条数据?",
  prompt: "提示",
  add_channel: "添加渠道",
  add_logistics_product: "添加物流产品",
  upload_batch: "批量导入",
  warehouse_info_upload: "仓库信息上传",
  click_upload: "点击上传",
  upload_info: "支持扩展名：.xlxs，点击",
  download_template: "下载模板",
  upload_info_prompt: "请勿重复上传数据",
  know: "知道了",
  ccOperatorFlag: "是否抄送海外仓运营邮箱",
  contactsType: "联系人类型",
  please_select_contactsType: "请选择联系人类型",
  inStorageContact: "入库联系人",
  outStorageContact: "出库联系人",
  contactName: "联系人名称",
  please_contact_name: "请输入联系人名称",
  contactEmail: "联系人邮箱",
  please_contact_email: "请输入联系人邮箱",
  Autonomous_surface_single_logistics_company: "自供面单物流公司",
  please_logistics_company: "请选择物流公司代码",
  peakSeasonTimeWarehouse: '旺季配置',
  baseConfig: '基本配置',
  contactInfo: '联系人信息',
  warehouse_code: '仓库代码',
  please_input_warehouse_code: '请输入仓库代码',
  saveItemNow: '请先保存正在编辑的模块'
};
