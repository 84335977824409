export default {
  warehouse: '入库仓库',
  orderState: '状态',
  inWarehouseType: '入库类型',
  orderInboundNo: '入库单号',
  newInbound: '新建入库单',
  newStorageReservation: '创建入库预约单',
  expectArriveWarehouseTime: '预计到仓时间',
  storageReservationNumber: '入库预约单号',
  createTime: '创建日期',
  forecastSKUTypes: '预报SKU种类',
  forecastSKUTotal: '预报SKU总数',
  remark: '备注',
  refuseReason: '拒绝原因',
  input_refuseReason: '请输入拒绝原因',
  warehouseInsufficient: '仓库库容不足',
  other: '其他',
  reason: '原因',
  handleResult: '处理结果',
  inventoryQuantity: '预计入库数量',
  planWarehousQuantity: '计划入库数',
  uploadErr: '货品导入解析失败，点击可下载解析结果文件。',
  trayNo: '托盘号',
  cartonNo: '箱号',
  containerType: '箱型',
  vessel: '船名',
  voyage: '航次',
  selectETA: '请选择ETA日期',
  ETA: 'ETA',
  skuSpecies: '计划入库SKU种类',
  skuNum: 'sku数量',
  length: '长（CM）',
  width: '宽（CM）',
  height: '高（CM）  ',
  volume: '体积（CBM）',
  weight: '重量（KG）',
  expectTrayCount: '预报托盘数',
  expectBoxCount: '预报箱数',
  deliverType: '交货类型',
  storageReservationDetail: '入库预约单详情',
  skuInfo: 'SKU信息',
  skuType: 'SKU种类',
  skuTotal: '计划入库SKU总数',
  shelvesType: '实际上架SKU种类',
  shelvesTotal: '实际上架SKU总数',
  totalVolume: '计划入库SKU总体积',
  shelvesVolumeTotal: '实际上架SKU总体积',
  gooodsErr: '请维护SKU信息',
  failureReason: '失败原因',
  rejectReason: '拒绝原因',
  deliverCargoBeginTime: '收货开始时间',
  deliverCargoCompleteTime: '收货完成时间',
  firstPutawayTime: '首次上架时间',
  putawayComplete: '全部上架时间',
  pushTime: '推送时间',
  putawayCompleteTime: '上架完成时间',
  groupingSkuKindQuantity: '已上架种类',
  groupingSkuQuantity: '已上架数量',
  appointInTips: '入库单必须和已确认的入库预约单相关联',
  memberNo: '客户系统入库单号',
  systemCode: '系统条码',
  firstLeg: '头程信息',
  goodsAndBox: '货品及装箱信息',
  orderInboundDetail: '入库单详情',
  baseInfo: "基本信息",
  downloadTemplate: '下载模板',
  storageReservationNumberClick: '入库预约单号（点击预约单号展示入库预约单信息）',
  maintenanceMode: '是否一箱一件',
  trayCount: '托盘数',
  boxCount: '箱数',
  tips: '支持扩展名：.xlsx',
  pageMaintenanceMode: '页面维护模式',
  mattersNeedingAttention: '注意事项：',
  usingTheInformation: '装箱信息',
  goodsInformation: '货品信息',
  add: '添加',
  theTmportMode: '导入模式',
  clearAllData: '清除所有数据',
  "Note: If you cut back to import mode, the data maintained in this mode will be completely removed remove": "注意：如果切回导入模式，此模式维护的数据 将被全部清除",
  TheSerialNumber: '序号',
  addSKU: '添加SKU',
  "Please select the SKU code of e-commerce": "请选择电商SKU编码",
  "Data with duplicate pallet number or container number will be merged automatically": "托盘号或者箱号重复的数据会自动合并",
  singleBoxNumber: '单箱数量',
  all: "全部",
  draft: '草稿',
  skuCode: '电商SKU编码',
  waitingList: '待接单',
  hasRefusedTo: '已拒绝',
  haveOrder: '已接单',
  warehouseReceiving: '仓库接收',
  deliveredWarehouse: '已下发仓库',
  warehouseToConfirm: '仓库接单',
  warehouseRefusedTo: '仓库拒绝',
  TheEntryListHasBeenGenerated: '入库单已生成',
  warehouseReceivingOrder: '仓库接单',
  firstLegInTransit: '头程在途',
  startReceiving: '开始收货',
  goodsFinish: "收货完成",
  startOn: '开始上架',
  partOfTheShelf: '部分上架',
  allTheShelves: '全部上架',
  cancel: '取消',
  FCLPallet: '整柜（托盘）',
  FCLBulk: "整柜（散货）",
  pallet: '托盘',
  courier: '快递',
  returnOfTreasury: '退货入库',
  sinceTheSend: '自送',
  lanShou: "揽收",
  'Error message': '错误提示',
  'Form the first': '、表格第',
  'line': '行',
  warehouseId: '仓库',
  'plannedQuantity': '请维护计划入库数量',
  'estimatedQuantity': '请维护预计入库数量',
  'RepeatAddition': '重复添加货品',
  chineseName: '货品中文名',
  'Planned warehousing quantity': '计划入库数量',
  'Only positive integers can be entered': '只能输入正整数',
  'The change of warehouse country will empty the SKU data previously maintained,Are you sure': '仓库国家变更会清空此前维护的SKU数据，是否确定？',
  'Please save the CURRENT box SKU data first': '请先保存当前箱子SKU数据',
  'Please select the warehouse under the country': '请选择国家下面的仓库',
  "Imports overwrite manually maintained data": '导入会覆盖手动维护的数据',
  "Continue?": '是否继续？',
  'First, form': '、表格第',
  'You can choose to save the draft and come back later to edit the entry booking that needs to be maintained': '您可以选择保存草稿，晚些时候再来继续编辑需要维护的入库预约单。',
  'Are you sure you want to cancel?': '您确定取消吗？',
  'Continue editing': '继续编辑',
  'Please select warehouse': '请选择仓库',
  followUp: '跟进',
  followUpSuccess: '跟进成功',
  expectedNumberShelves: '预计上架数量',
  actualNumberShelves: '实际上架数量',
  operationTime: '实际上架时间',
  please_select_operationTime: '请选择实际上架时间',
  please_input_actualNumberShelves: '请输入实际上架数量',
  containerNo: '集装箱号',
  confirmFollowUp: '仓库已确认待跟进',
  refuseFollowUp: '仓库已拒绝待跟进',
  lookDetail: '查看批次库存',
  addInfo: '补录信息',
  realTrayCount: '实际托盘数',
  realBoxQuantity: '实际箱数',
  costInfo: '费用信息',
  push_status: '推送状态',
  exporField: '下游仓库',
  please_select_expor_field: '请选择下游仓库',
  goodsCode: '货品编码',
  please_select_goods_code: '请选择货品编码',
  handlerTime: '可能需要一定的处理时间',
  handlerRes: '完成后会有系统通知。请前往任务列表查看结果',
  handlerTips: '导出操作的耗时与数量的大小成正比',
  customOrderNo: '客户订单号',
  isCurrentZone: '时间以当前时区展示',
}
