<template>
  <div v-if="!item.hidden" class="menu-wrapper">
    <div v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
          <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{'submenu-title-noDropdown':!isNest}" :pendingNums="pendingNums">
          <span class="item-menu-title">
            <i :class="['iconfont', (onlyOneChild.meta.icon||item.meta.icon)]" />
            <span>{{ generateTitle(onlyOneChild.meta.title) }}</span>
            <span v-if="onlyOneChild.name === 'FlwContractList'" v-show="pendingNums >0" class="pending-nums">{{pendingNums}}</span>
          </span>
          </el-menu-item>
      </app-link>
    </div>
    <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title">
        <span class="item-menu-title" v-if='item.meta'>
          <i :class="['iconfont', item.meta.icon]" />
          <span>{{ generateTitle(item.meta.title) }}</span>
          <span v-if="item.name === 'ContractPrice'" v-show="pendingNums >0" class="pending-nums">{{pendingNums}}</span>
        </span>
      </template>
      <sidebar-item
        v-for="(child,index) in item.children"
        :key="index"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        :pendingNums="pendingNums"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import path from 'path'
import { generateTitle } from '@/utils/i18n'
import { isExternal } from '@/utils/validate'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'

export default {
  name: 'SidebarItem',
  components: { AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    },
    pendingNums: {
      type: Number,
      default: 0
    }
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null
    return {}
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }

      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    },

    generateTitle
  }
}
</script>
<style lang="scss" scoped>
.item-menu-title {
  span,i {
    display: inline-block;
    vertical-align: middle;
  }
  .iconfont {
    margin-right: 5px;
    font-size: 14px;
    color: rgb(191, 203, 217);
  }
  .pending-nums{color: #fff;
    display: inline-block;
    background: red;
    height: 18px;
    line-height: 18px;
    width: 18px;
    border-radius: 100%;
    text-align: center;
    font-size: 10px;
    overflow: hidden}
}
</style>
