export default {
  topSearchPlaceholder: '搜索会员名/手机/邮箱',
  topSearchLabel: '会员名称',
  topSearchBtnTxt: '查询',
  listEmpty: '暂无结果',
  table: {
    nameLabel: '会员名称',
    memberTypeLabel: '会员类型',
    unauthorized: '未认证',
    personalTyep: '个人认证',
    enterpriseTyep: '企业认证',
    contactMobileLabel: '手机号',
    contactEmailLabel: '邮箱',
    statusLabel: '状态',
    registeredDateLabel: '注册日期',
    optionLabel: '操作',
    check: '查看详情',
    revoke: '撤销'
  },
  status: {
    unSubmit: '未提交',
    processing: '待审核',
    success: '已通过',
    rejected: '已拒绝',
    rejectedText: '审核拒绝',
    successText: '审核成功'

  },
  detail: {
    baseInfoLabel: '基本信息',
    authInfoLabel: '认证信息',
    legalLabel: '法人信息',
    contactLabel: '联系人',
    idLabel: '会员号',
    typeLabel: '会员类型',
    mobileLabel: '手机号',
    emailLabel: '邮箱:',
    registerDateLabel: '注册日期',
    authTypeLabel: '认证状态',
    nameLabel: '姓名',
    croporateNameLabel: '企业名称',
    corporateTelephoneLabel: '公司电话',
    identityNumLabel: '身份证号',
    licenceNoLabel: '统一社会信用代码',
    capitalLabel: '注册资金',
    capitalUnit: '万人民币',
    bussinessDateLabel: '营业期限',
    bussinessScopeLabel: '经营范围',
    bussinessLicenseLabel: '营业执照',
    identityFaceLabel: '身份证正面',
    identityBackLabel: '身份证反面',
    addressLabel: '公司地址',
    abbreviation: '用户简称',
    abbreviationDesc: '请输入一个该用户的简称',
    rejectReason: '拒绝理由',
    reject: '拒绝认证',
    resolve: '审批通过',
    rejectTitle: '请填写本次被拒绝的原因',
    reasonEmpty: '拒绝理由不能为空',
    rejectSuccess: '本次审批已拒绝',
    resolveSuccess: '审批成功',
    confirmBtn: '确认提交',
    addAccount: '开通账户',
    addAccountTitle: '请选择币种账户',
    addAccountBtn: '确认开通',
    addAccountSuccess: '开通成功'
  },
  member_id: '会员ID',
  member_abbreviation: '会员简称',
  please_enter_member_abbreviation: '请输入会员简称',
  member_abbreviation_cannot_be_null: '会员简称不能为空',
  admin_name: '管理员名称',
  please_enter_admin_name: '请输入管理员名称',
  admin_name_cannot_be_null: '管理员名称不能为空',
  refresh_list: '刷新列表',
  new_member: '新增会员',
  edit_member: '编辑会员',
  member_type: '会员类型',
  member_type_cannot_be_null: '会员类型不能为空',
  please_select: '请选择',
  memberType: {
    company: '企业认证',
    personal: '个人认证'
  },
  company_name_cannot_be_null: '企业名称不能为空',
  company_name_cannot_be_repeat: '该企业名称已被注册，不允许重复注册',
  business_licence_no_cannot_be_null: '社会统一信用代码不能为空',
  business_licence_no_cannot_be_repeat: '该统一社会信用代码重复',
  register_country: '注册国家',
  register_country_cannot_be_null: '国家不能为空',
  if_member_admin_account: '是否配置会员管理员账号',
  yes: '是',
  no: '否',
  member_id_is: '会员ID：',
  msg: '消息',
  cancel: '取消',
  confirm: '确定',
  mobile_un_auth_cannot_receive_message: '手机号未验证，不能接收消息，',
  email_un_auth_cannot_receive_message: '邮箱未验证，不能接收消息，',
  afresh_send: '重新发送',
  send_success: '发送成功',
  auth_message_null: '暂无认证信息',
  mobile_des: '请维护手机或邮箱，二选一必填',
  email_des: '请维护手机或邮箱，二选一必填',
  company_auth_info: '企业认证信息',
  get_company_info: '获取企业信息',
  registered_address: '所属地区',
  company_address: '公司地址',
  company_english_name: '英文名称',
  capital: '注册资金',
  capital_unit: '万元',
  operation_period: '营业期限',
  registered_date: '注册日期',
  corporate_telephone: '公司电话',
  business_scope: '经营范围',
  business_license: '营业执照照片',
  company_corporate_info: '企业法人信息',
  corporate_name: '法人姓名',
  corporate_mobile: '法人手机号',
  corporate_identity_card_number: '法人身份证号',
  identity_card_face: '身份证正面照片',
  identity_card_back: '身份证背面照片',
  personal_corporate_info: '个人认证信息',
  personal_name: '姓名',
  personal_name_cannot_be_null: '姓名不能为空',
  identity_card_number: '身份证号',
  identity_card_number_error: '身份证格式不正确',
  identity_card_number_cannot_be_repeat: '该身份证号已被注册，不允许重复注册',
  identity_card_number_cannot_be_null: '身份证号不能为空',
  mobile_or_email_cannot_be_null: '手机号和邮箱不能同时为空！',
  edit_success: '编辑成功！',
  email_cannot_be_repeat: '该邮箱已被注册，不允许重复注册',
  email_error: '邮箱格式不正确',
  mobile_cannot_be_repeat: '该手机号已被注册，不允许重复注册',
  mobile_error: '手机号格式不正确',
  company_phone_error: '公司电话格式错误',
  view: '查看',
  edit: '编辑',
  inception_business: '开通钱包账户',
  auth_login: '授权登录'
}
