import request from '@/utils/request'

// 1.1 约价审批列表
export function getFlwContractListApi(params) {
  return request({
    url: `/api/cpf-service/flw/contract/pages`,
    method: 'get',
    params
  })
}

// 待审批条目
export function getFlwContractReminderNumApi() {
  return request({
    url: `/api/cpf-service/flw/contract/reminderNum`,
    method: 'get'

  })
}

// 1.1 约价审批列表统计
export function getFlwContractCountApi(params) {
  return request({
    url: `/api/cpf-service/flw/contract/listStatistics`,
    method: 'get',
    params
  })
}

// 1.5 获取操作日志
export function getFlwContractOperateLogApi(id) {
  return request({
    url: `/api/cpf-service/flw/contract/operate/log/${id}`,
    method: 'get'
  })
}

// 1.4 获取枚举
export function getFlwContractEnumTypeApi(params) {
  return request({
    url: `/api/cpf-service/flw/contract/getEnumType`,
    method: 'get',
    params
  })
}

// 1.2 约价审批列表-审批清单导出
export function flwContractExportApi(params) {
  return request({
    url: `/api/cpf-service/flw/contract/export`,
    method: 'get',
    params
  })
}

// 1.1流程配置保存
export function flwFlowSaveApi(data) {
  return request({
    url: `/api/mdm-service/flw/flow/save`,
    method: 'post',
    data
  })
}

// 1.2 获取有效审批流配置
export function queryByFlowTypeApi(params) {
  return request({
    url: `/api/mdm-service/flw/flow/queryByFlowType`,
    method: 'get',
    params
  })
}

// 1.3 根据业务类型和工作流类型查询规则条件下拉
export function queryFlowRulesByFlowTypeApi(params) {
  return request({
    url: `/api/mdm-service/flw/flow/queryFlowRulesByFlowType`,
    method: 'get',
    params
  })
}

// 1.3 审批页面详情接口
export function getContractApprovalDetailApi(id) {
  return request({
    url: `/api/cpf-service/flw/contract/approval/detail/${id}`,
    method: 'get'
  })
}

// 审批撤回
export function revokeContractApi(data) {
  return request({
    url: `/api/cpf-service/flw/contract/operate/revoke`,
    method: 'post',
    data
  })
}

// 重新申请时验证是否重复申请
export function checkRestartContractApi(id) {
  return request({
    url: `/api/cpf-service/flw/contract/operate/restartCreate/check/${id}`,
    method: 'get'
  })
}

// 约价审批记录列表
export function getFlwContractRecordApi(params) {
  return request({
    url: `/api/cpf-service/flw/contract/record/pages`,
    method: 'get',
    params
  })
}

// 约价审批记录统计
export function getFlwContractRecordCountApi(params) {
  return request({
    url: `/api/cpf-service/flw/contract/record/listStatistics`,
    method: 'get',
    params
  })
}
