<template>
  <div class="set-search-date">
     <el-date-picker
      size="mini"
      v-model="startValue"
      type="date"
      placeholder="开始日期"
      @change="startChange"
      :picker-options="startPickerOptions"
      :value-format="valueFormat"
      :disabled="disabledStart"
      :clearable="clearable"
      @clear="$emit('clear')">
    </el-date-picker>
    <span>-</span>
    <el-date-picker
      size="mini"
      v-model="endValue"
      :picker-options="endPickerOptions"
      :value-format="valueFormat"
      type="date"
      :disabled="disabledEnd"
      placeholder="结束日期"
      @change="endChange"
      :clearable="clearable">
    </el-date-picker>
  </div>
</template>
<script>
import { parse } from 'bowser'

export default {
	name: "SearchDatePicker",
	props: {
		value: {
			type: Array,
			default: function () {
				return []
			}
		},
		valueFormat: {
			type: String,
			default: 'timestamp'
		},
		disabledStart: {
			type: Boolean,
			default: false
		},
		disabledEnd: {
			type: Boolean,
			default: false
		},
		clearable: {
			type: Boolean,
			default: true
		},
		// 限定只能取2个默认值中间的值
		// 且固定某个值不能更改
		defaltLimit: {
			type: Array,
			default: function () {
				return []
			}
		},
		maxEndTime: {
			type: Number,
			default: null
		}
	},
	watch: {
		value: {
			handler(val) {
				this.startValue = val[0] ? parseInt(val[0], 10) : ''
				this.endValue = val[1] ? parseInt(val[1], 10) : ''
				if (this.defaltLimit) {
					let startValue = this.defaltLimit[0] ? parseInt(this.defaltLimit[0], 10) : ''
					let endValue = this.defaltLimit[1] ? parseInt(this.defaltLimit[1], 10) : ''
					this.startPickerOptions = {
						disabledDate(time) {
							if (startValue) {
								return time.getTime() < new Date(startValue).getTime() || time.getTime() > new Date(endValue).getTime()
							} else {
								return false
							}
						}
					}
				} else {
					// 根据值去设定选择范围
					this.setStartPickerOptions()
					this.setEndPickerOptions()
				}
			},
			deep: true,
			immediate: true
		}
	},
	data() {
		return {
			startValue: '',
			endValue: '',
			endPickerOptions: {},
			startPickerOptions: {}
		}
	},
	methods: {
		setEndPickerOptions() {
			let startValue = this.startValue
			let maxEndTime = this.maxEndTime
			this.endPickerOptions = {
				disabledDate(time) {
					if (startValue) {
						if (maxEndTime) {
							let endTime = startValue ? new Date(startValue).valueOf() + maxEndTime * 24 * 60 * 60 * 1000 : Date.now();
							let startTime = startValue ? new Date(startValue).valueOf() : Date.now();
							return time && time.valueOf() < startTime || time.valueOf() > endTime
						} else {
							return time.getTime() < new Date(startValue).getTime();
						}
					} else {
						return false
					}
				}
			}
		},
		startChange() {
			// 如果么有设置 限定某个固定值的范围 则可以根据选的动态来设定
			if (!this.limitMiddle) {
				this.setEndPickerOptions()
			}
			// 开始时间默认为00:00:00 结束时间拼接23:59:59
			let endValue = this.endValue
			let startValue = this.startValue
			if (this.startValue) {
				startValue = this.$moment(this.startValue).startOf('day').valueOf()
			}
			if (this.endValue) {
				endValue = this.$moment(this.endValue).endOf('day').valueOf()
			}
			this.$emit('input', [startValue, endValue])
			this.$emit('change')
		},
		setStartPickerOptions() {
			let endValue = this.endValue
			this.startPickerOptions = {
				disabledDate(time) {
					if (endValue) {
						return time.getTime() > new Date(endValue).getTime();
					} else {
						return false
					}
				}
			}
		},
		endChange() {
			// 如果么有设置 限定某个固定值的范围 则可以根据选的动态来设定
			if (!this.limitMiddle) {
				this.setStartPickerOptions()
			}
			// 开始时间默认为00:00:00 结束时间拼接23:59:59
			let endValue = this.endValue
			let startValue = this.startValue
			if (this.startValue) {
				startValue = this.$moment(this.startValue).startOf('day').valueOf()
			}
			if (this.endValue) {
				endValue = this.$moment(this.endValue).endOf('day').valueOf()
			}
			this.$emit('input', [startValue, endValue])
			this.$emit('change')
		}
	}
}
</script>

<style lang="scss" scoped>
.set-search-date {
	/deep/ .el-date-editor {
		width: 123px !important;
		.el-input__inner {
			padding-right: 20px;
		}
	}
}
</style>
