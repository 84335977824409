export default {
  code: '编号',
  cnName: '中文名称',
  enName: '英文名称',
  addShipper: '新建货主',
  editShipper: '编辑货主',
  orderNo: '出库单号',
  timeoutTips: '超48小时未出库',
  orderType: '出库单类型',
  aUndertakesTo: '一件代发',
  warehouse: '出库仓库',
  trackNo: '物流单号',
  baseInfo: '基础信息',
  recipientInfo: '收件人信息',
  recipient: '收件人',
  tailLiftTrack: '尾程轨迹',
  operationLog: '操作日志',
  userOutBoundNo: '客户系统出库单号',
  status: '状态',
  all: '全部',
  sendWarehouse: '发货仓库',
  transportMode: '派送方式',
  isPickup: '是否自提',
  pickupType: '是否自提/自供面单',
  skuSpecies: 'sku种类',
  isShipmentFiles: '是否自供面单',
  tradePlatform: '电商平台',
  warehouseInfo: '仓库信息',
  tradeNo: '平台订单号',
  shipmentNo: '快递单号',
  emptyData: '暂无轨迹',
  shipmentLabelFiles: '快递面单',
  carrier: '物流公司',
  pickupInfo: '提货信息',
  carrierName: '第三方物流公司',
  shipmentNumber: '物流单号',
  please_input_shipmentNumber: '请输入物流单号',
  carrierContact: '联系人',
  carrierMobile: '联系电话',
  pleaseEnterSendWarehouse: '请输入发货仓库',
  pleaseEnterTransportMode: '请输入派送方式',
  pleaseEnterTradePlatform: '请输入电商平台',
  pleaseEnterTradeNo: '请输入亚马逊订单号',
  pleaseEnterAmazonOrderNo: '请输入平台订单号',
  pleaseEnterReferenceNo: '请输入亚马逊参考号',
  pleaseEnterShipmentNo: '请输入快递单号',
  pleaseUploadShipmentLabelFiles: '请上传快递面单',
  pleaseEnterArrivalContact: '请输入收货人',
  pleaseEnterArrivalContactTel: '请输入联系电话',
  pleaseEnterArrivalContactEmail: '请输入收件人邮箱',
  pleaseEnterArrivalCountry: '请输入国家',
  pleaseEnterArrivalProvince: '请维护州、省、郡',
  pleaseEnterArrivalCity: '请输入城市',
  pleaseEnterArrivalZipcode: '请输入邮编',
  pleaseEnterArrivalAddress: '请输入详细地址',
  outStorageGoods: '出库商品',
  isTrue: '是',
  isFalse: '否',
  abnormalMessage: '异常原因',
  isAll: '全部',
  number: '序号',
  skuNumber: '电商SKU编码',
  systemBarCode: '商品条码',
  stockAvailable: '可用数量',
  quantity: '计划出库数量',
  cnt: '数量',
  length: '长（CM)',
  width: '宽（CM)',
  height: '高（CM)',
  weight: '重量（KG)',
  outTime: '出库时间',
  please_select_outTime: '请选择出库时间',
  createTime: '创建时间',
  submitTime: '提交时间',
  outDetail: '出库单详情',
  outBoundProduct: '尾程服务',
  logicProductName: '物流产品',
  childProduct: '子产品',
  prescription: '时效',
  weekdays: '工作日',
  arrivalInfo: '收货人信息',
  arrivalContact: '收货人',
  fba: 'FBA仓',
  arrivalNormalized: '收货地址',
  arrivalContactTel: '联系电话',
  arrivalContactEmail: '收件人邮箱',
  email: '邮箱',
  skuName: '货品中文名',
  skuEnglishName: '货品英文名',
  abnormalFlag: "是否异常",
  operation: '操作',
  rejectMessage: '拒绝原因',
  arrivalCountry: '国家',
  arrivalProvince: '州、省、郡',
  arrivalCity: '城市',
  arrivalZipcode: '邮编',
  arrivalAddress1: '详细地址1',
  arrivalAddress: '详细地址',
  arrivalAddress2: '详细地址2',
  houseNumber: '门牌号',
  addedServices: '增值服务',
  goodsInfo: '货品及装箱信息',
  pleaseUploadFile: '请上传文件',
  uploadFile: '上传文件',
  pleaseEnterNumber: '请输入数量',
  cancelFn: '取消',
  draftFn: '保存草稿',
  draftSuccess: '保存草稿成功！',
  submitFn: '提交',
  submitSuccess: '提交成功！',
  cancelInfo: '您可以选择保存草稿，晚些时候再来继续编辑需要维护的出库单数据。',
  confirmCancel: '您确定取消吗？',
  continueEdit: '继续编辑',
  emailError: '邮箱格式不正确',
  mobileError: '联系电话格式不正确',
  zipCodeError: '邮编格式不正确',
  theDelivery: '出库数量',
  serviceName: '服务项',
  lookFile: '查看文件',
  operationOf: '操作人',
  logOriginName: '角色',
  time: '时间',
  logContent: '操作内容',
  emptyImg: '当前没有可预览的图片',
  amazonOrderNo: '亚马逊订单号',
  amazonReferenceNumber: '亚马逊参考号',
  outSkuTips: '请先添加出库商品',
  pleaseAddSku: '请先添加一条出库商品',
  pleaseFBA: '请选择FBA仓',
  uploadBOL: '上传BOL',
  addInfo: '补录信息',
  actualPalletQuantity: '托盘数',
  boxQuantity: '箱数',
  inputActualPalletQuantity: '请输托盘数量',
  pInt: '只能输入正整数',
  addFile: '补录文件',
  addFileSuccess: '补录成功',
  upload: '请上传',
  file: '文件',
  orderClass: '订单类型',
  updateStatus: '状态更新',
  batchUpdate: '批量更新',
  handlerTime: '可能需要一定的处理时间',
  handlerRes: '完成后会有系统通知。请前往任务列表查看结果',
  handlerTips: '导出操作的耗时与数量的大小成正比',
  handlerTips1: '导入操作的耗时与数量的大小成正比',
  transshipment: '转运',
  transshipmentFBA: '转运FBA',
  actualOutQuantity: '实际出库数量',
  inBoundNo: '入库订单号',
  outBoundNo: '出库订单号',
  please_input_outQuantity: '请输入出库数量',
  costDetail: '费用明细',
  costName: '费用名称',
  billingTerms: '计费条件',
  currency: '币种',
  basePrice: '单价',
  estimatedAmount: '预估金额',
  estimatedTime: '预估时间',
  detail: '详情',
  fileCnt: '文件数量',
  combined: '合计',
  reject: '审核拒绝',
  aduit: '审核通过',
  messageTips: '请补录以下数据',
  inputRejectMessage: '请输入拒绝原因',
  warehouseZone: '仓库所在时区',
  beijingTime: '北京时间',
  batchImportShipmentNo: '导入快递单号',
  batchImport: "批量导入",
  batchExportShipment: '面单导出',
  orderOfName: '订单号命名',
  logisticsOfName: '物流单号命名',
  logisticsSingleNumberEnter: '物流单号录入',
  importLogisticsSingleNumber: '导入物流单号',
  isCurrentZone: '时间以当前时区展示',
}
