import Layout from '@/layout'

const CharteredBoatRouteMap = {
  // 包船业务
  'cas:order': {
    component: Layout,
    alwaysShow: true,
    name: 'CharteredBoat',
    meta: {
      icon: 'icon-TERMINAL_RELEASE'
    }
  },
  // 船期信息列表
  'cas:etd:list': {
    component: () => import('@/views/cargo/v0/CharteredBoat/ETDList'),
    name: 'ETDList',
    meta: {
      noCache: false
    },
    children: ['cas:etd:list:info']
  },
  'omc:cas:manager': {
    component: Layout,
    alwaysShow: true,
    name: 'BookingManage',
    meta: {
      icon: 'icon-a-0_dingcangguanli-haiyun'
    }
  },
  // 订舱信息列表
  'cas:booking:list': {
    component: () => import('@/views/cargo/v0/CharteredBoat/booking'),
    name: 'Booking',
    children: []
  },
  // 订舱信息列表--销售
  'cas:order:seller:list': {
    path: '/CharteredBoat/Booking/sales',
    component: () => import('@/views/cargo/v0/CharteredBoat/bookingSales'),
    name: 'BookingSale',
    hidden: false,
    meta: { title: '订舱管理（销售）' },
    roles: [],
    children: ['cas:order:seller:audit']
  },
  // 订舱信息列表--销售支持
  'cas:order:seller_support:list': {
    path: '/CharteredBoat/Booking/salesSupport',
    component: () => import('@/views/cargo/v0/CharteredBoat/bookingSalesSupport'),
    name: 'BookingSaleSupport',
    hidden: false,
    meta: { title: '订舱管理（销售支持）' },
    roles: []
  },
  // 订舱信息列表--订舱代理
  'cas:order:booking_agent:list': {
    path: '/CharteredBoat/Booking/bookingAgent',
    component: () => import('@/views/cargo/v0/CharteredBoat/bookingAgent'),
    name: 'BookingAgent',
    hidden: false,
    meta: { title: '订舱管理（订舱代理）' },
    roles: []
  }, // 订舱信息列表--航线运营列表
  'cas:order:route_op:list': {
    path: '/CharteredBoat/Booking/routeOp',
    component: () => import('@/views/cargo/v0/CharteredBoat/bookingRouteOp'),
    name: 'BookingRouteOp',
    hidden: false,
    roles: []
  },
  'cas:order:operate_record:list': {
    path: '/CharteredBoat/Booking/bookingOperateRecord',
    component: () => import('@/views/cargo/v0/CharteredBoat/bookingOperateRecord'),
    name: 'bookingOperateRecord',
    hidden: false,
    roles: []
  },
  // 班轮总览
  'cas:sailing:dashboard:list': {
    path: '/CharteredBoat/sailingScheduleDashboard',
    component: () => import('@/views/cargo/v0/CharteredBoat/dashboardList'),
    name: 'DashboardList',
    hidden: false,
    meta: { title: 'linerOverviewList', icon: 'icon-colorlens', noCache: true },
    roles: [],
    children: ['cas:sailing:dashboard:details']
  },
  // 'sailing:dashboard': {
  //   path: 'CharteredBoat/sailingScheduleDashboard',
  //   component: () => import('@/views/cargo/v0/CharteredBoat/dashboardList'),
  //   name: 'DashboardList',
  //   meta: { title: 'linerOverviewList', icon: 'icon-colorlens', noCache: true, affix: true }
  // },
  // 班轮总览详情
  'cas:sailing:dashboard:details': {
    path: '/CharteredBoat/dashboard/details',
    component: () => import('@/views/cargo/v0/CharteredBoat/dashboardDetails'),
    name: 'DashboardDetails',
    hidden: true,
    meta: { title: '班轮总览详情' },
    roles: [],
    children: []
  },
  // 集港指令
  'cas:order:instruction:list': {
    path: '/CharteredBoat/packingInstructions',
    component: () => import('@/views/cargo/v0/CharteredBoat/packingInstructions'),
    name: 'PackingInstructions',
    hidden: false,
    meta: { title: '集港指令' },
    roles: [],
    children: ['cas:order:instruction:info']
  },
  'omc:cas:fee': {
    component: Layout,
    path: '/Gathering',
    alwaysShow: true,
    name: 'PackingManage',
    meta: {
      icon: 'icon-wuliuguanli'
    }
  },
  // 集港指令详情
  'cas:order:instruction:info': {
    path: '/CharteredBoat/packingInstructionsInfo',
    component: () => import('@/views/cargo/v0/CharteredBoat/packingInstructionsInfo'),
    name: 'PackingInstructionsDetails',
    hidden: true,
    meta: { title: '集港指令详情' },
    roles: []
  },
  // 装箱指令
  'cas:vehicle:dynamic': {
    path: '/CharteredBoat/vehicleInventory',
    component: () => import('@/views/cargo/v0/CharteredBoat/vehicleInventory'),
    name: 'VehicleInventory',
    hidden: false,
    meta: { title: '车辆动态' },
    roles: []
  },
  'omc:cas:material': {
    component: Layout,
    path: '/Document',
    alwaysShow: true,
    name: 'DocumentManage',
    meta: {
      icon: 'icon-zhu-fen-dan'
    }
  },
  // 提单信息列表
  'cas:bl:list': {
    component: () => import('@/views/cargo/v0/CharteredBoat/BillList'),
    name: 'BLList',
    meta: {
      noCache: false
    },
    children: ['cas:bl:list:info']
  },
  // 箱货信息列表
  'cas:container:list': {
    component: () => import('@/views/cargo/v0/CharteredBoat/ContainerList'),
    name: 'ContainerList'
  },
  // 车队预约记录
  'cas:fleet:list': {
    component: () => import('@/views/cargo/v0/CharteredBoat/FleetList'),
    name: 'FleetList'
  },
  // 改单申请列表
  'cas:change:order:list': {
    component: () => import('@/views/cargo/v0/CharteredBoat/ChangeOrderList'),
    name: 'ChangeOrderList'
  },
  // 集装箱作业记录
  'cas:container:transfer': {
    component: () => import('@/views/cargo/v0/CharteredBoat/containerTransfer'),
    name: 'containerTransfer'
  },
  // AMS申报信息列表
  'cas:ams:list': {
    component: () => import('@/views/cargo/v0/CharteredBoat/AMSList'),
    name: 'AMSList',
    children: ['cas:ams:list:bill']
  },
  'cas:release:business:list': {
    path: '/CharteredBoat/ReleaseBillListBusiness',
    component: () => import('@/views/cargo/v0/CharteredBoat/ReleaseBillListBusiness'),
    name: 'ReleaseBillListBusiness'
    // meta: { title: '放单管理列表(商务)', }
  },
  'cas:release:operate:list': {
    path: 'ReleaseBillListOperate',
    component: () => import('@/views/cargo/v0/CharteredBoat/ReleaseBillListOperate'),
    name: 'ReleaseBillListOperate'
    // meta: { title: '放单管理列表(运营)', noCache: true }
  },
  'cas:pre:manifest:list': {
    path: 'PreManifestList',
    component: () => import('@/views/cargo/v0/CharteredBoat/preManifestList'),
    name: 'PreManifestList',
    children: ['cas:pre:manifest:list:info']
  },

  // 详情
  'cas:etd:list:info': {
    path: 'ETDInfo',
    component: () => import('@/views/cargo/v0/CharteredBoat/ETDInfo'),
    name: 'ETDInfo',
    hidden: true,
    meta: { title: '船期信息列表维护', keepAlive: true }
  },
  'cas:member:space:control:list': {
    path: 'memberSpaceControlList',
    component: () => import('@/views/cargo/v0/CharteredBoat/customerCabinManagement'),
    name: 'CustomerCabinManagement',
    hidden: false,
    meta: { title: '客户舱位管理', keepAlive: true }
  },
  'cas:bl:list:info': {
    path: 'BlInfo',
    component: () => import('@/views/cargo/v0/CharteredBoat/orderView'),
    name: 'BLInfo',
    hidden: true,
    meta: { title: '提单信息详情' }
  },
  'cas:ams:list:bill': {
    path: 'BillAMS',
    component: () => import('@/views/cargo/v0/CharteredBoat/BillAMS'),
    name: 'BillAMS',
    hidden: true,
    meta: { title: 'AMS申报' }
  },
  'cas:pre:manifest:list:info': {
    path: 'PreManifestInfo',
    component: () => import('@/views/cargo/v0/CharteredBoat/preManifestInfo'),
    name: 'PreManifestInfo',
    hidden: true,
    meta: { title: '舱单申报' }
  },
  'cro:order:list': {
    component: () => import('@/views/cargo/v0/CharteredBoat/stowManage/stowList.vue'),
    name: 'StowList',
    children: ['cro:order:detail', 'cro:order:instruction:batch:add']
  },
  'cro:order:instructionList': {
    component: () => import('@/views/cargo/v0/CharteredBoat/stowManage/stowInstruction.vue'),
    name: 'StowInstruction',
    children: ['cro:order:instruction:detail']
  },
  'cro:order:detail': {
    path: 'order/detail',
    component: () => import('@/views/cargo/v0/CharteredBoat/stowManage/stowDetail'),
    name: 'StowDetail',
    hidden: true,
    meta: { title: '放箱详情' }
  },
  'cro:order:instruction:detail': {
    path: 'order/instruction/detail',
    component: () => import('@/views/cargo/v0/CharteredBoat/stowManage/stowInstructionDetail'),
    name: 'StowInstructionDetail',
    hidden: true,
    meta: { title: '放箱指令详情' }
  },
  'cro:order:instruction:batch:add': {
    path: 'order/instruction/batch/add',
    component: () => import('@/views/cargo/v0/CharteredBoat/stowManage/stowBatchAdd'),
    name: 'StowBatchAdd',
    hidden: true,
    meta: { title: '批量放箱' }
  }
}

export default CharteredBoatRouteMap
