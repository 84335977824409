export default {
  rowIndex: '序号',
  factoryName: '箱厂名称',
  factoryCode: '箱厂代码',
  remark: '备注',
  createBtn: '新建',
  createFormTitle: '新建箱厂',
  factoryNameInvalid: '请输入箱厂名称',
  factoryCodeInvalid: '请输入箱厂编码'
}
