export default {
  sysCode: '系统编码',
  addItem: '新建货品',
  editItem: '编辑货品',
  detailItem: '查看货品',
  baseInfo: '基本信息',
  goodsCnName: '中文名称',
  goodsEnName: '英文名称',
  goodsSize: '货品尺寸',
  status: '状态',
  draft: '草稿',
  toAudit: '待审核',
  refused: '已拒绝',
  approved: '已审核',
  aduit: '审核',
  refuse: '拒绝',
  createTime: '创建时间',
  available: '可用',
  length: '长',
  width: '宽',
  height: '高',
  weight: '货品重量',
  weight2: '净重',
  sizePhotos: '尺寸照片',
  weightPhotos: '重量照片',
  uploadSizePhotos: '请上传尺寸照片',
  uploadWeightPhotos: '请上传重量照片',
  goodsType: '货品类型',
  memberId: '所属客户',
  addGoods: '新增货品',
  editGoods: '编辑货品',
  uploadWeightTip: '一张电子秤上的重量照片，每张照片文件小于5MB',
  uploadPhotoTip: '需要提供长宽高共三张图片，每张照片文件小于5MB',
  uploadWeightErr: '请上传一张重量照片',
  uploadPhotoErr: '请上传三张尺寸照片',
  repeatCodeData: '相同电商平台自定义编码不能重复',
  generalCargo: '普货',
  electricProducts: '带电产品',
  dangerousGoods: '危险品',
  FDAProduct: 'FDA产品',
  shelfLifeProduct: '保质期产品',
  goodsSaleCountryList: '销售国家',
  declaredValue: '申报价值',
  currency: '币种',
  nameCode: '名称/代码',
  addGoodsSaleCountry: '新增销售国家',
  shipper: '货主',
  goodsDetail: '货品详情',
  encodingInfo: '编码信息',
  elecSkuCode: '电商SKU编码',
  sysBarCode: '系统条码',
  customCode: '自定义编码',
  placeholderCode: '请输入编号',
  addCustomCode: '增加自定义编码',
  electricBusinessPlatform: '电商平台',
  warehouse: '仓库',
  sinceTheSite: '自建站',
  other: '其他',
  warinTips: '该货品提交了新的“销售国家”或“自定义编码”，目前还在审核中。审核通过之前只能在原有的销售国家下单或者使用原有的自定义编码。',
  errTips: '该货品新增的“销售国家”或“自定义编码”，审核未通过。',
  successTips: '该货品新增的“销售国家”或“自定义编码”，已通过审核。',
  upload: '导入照片',
  uploadBatch: '批量导入照片',
  uploadErr: '不符合规则的数据上传失败！',
  photoTips: '',
  excelTips: '',
  uploadSuccess: '货品导入成功',
  awaitAduit: '新增货品提交成功，请等待审核！',
  awaitAduitTip: '待审核的货品可以创建入库预约单，但无法转换成正式入库单。正式入库单还需等待货品审核通过。',
  uploadSuccessTips: '货品信已经导入成功！请前往列表查看！',
  parsing: '正在解析',
  theKnow: '关闭窗口后台处理',
  determine: '确定',
  parsingTips: '正在解析，该过程根据货品的数量会占用较长的时间，您可以关闭弹框，前往任务列表查看解析进度',
  refuseOptName: '不通过原因',
  draftBackTips: '您可以选择保存草稿，晚些时候再来继续编辑需要新增的货品。',
  tips: '您确定取消吗？',
  backTips: '取消后，您只能通过再次编辑维护新的信息，本次维护的信息不会保存。',
  continueEdit: '继续编辑',
  cancel: '取消',
  batchUpload: '批量导入',
  batchUploadPhoto: '图片导入',
  goodsInfoUpload: '货品信息上传',
  excelTitleTip: '支持扩展名：.xlxs，点击',
  downloadTel: '下载模板',
  goodsPhotoUpload: '货品照片上传',
  photoUploadRule: '“照片导入”的文件规则：',
  uploadParsing: '上传解析',
  attention: '注意事项:',
  excelTip1: '1.上传解析成功的货品为“草稿”状态；',
  excelTip2: '2.再次导入系统中存在的SKU号的货品信息，且该货品为“草稿”状态时，解析成功后将覆盖原有的信息；',
  excelTip3: '3.照片可以后续上传，或者通过页面的“编辑”、“照片导入”功能补齐；',
  excelTip4: 'i.对应货品的操作栏点击“导入照片”导入该货品的照片；',
  excelTip5: 'ii.对应货品的操作栏点击“编辑”进入编辑页面，可以上传图片；',
  excelTip6: 'iii.在顶部使用“照片导入”功能导入照片；',
  photpTip1: '1.以SKU号为文件夹名；',
  photpTip2: '2.文件夹下最多可有4张图片，且货品重量的照片文件名必须为“weight”；',
  photpTip3: '3.上传照片时，若文件夹名对应的SKU并不存在于系统中，那么系统将新增该SKU号的货品且状态为“草稿”；',
  photpTip4: '1.导入的文件为三层结构，第一层文件夹名称任意名称，该文件夹的第二层文件夹名为“SKU号”，第三层为图片文件；',
  photpTip5: '2.第二层文件夹最多可有4张图片，且货品重量的照片文件名必须为“weight”；',
  delConfirmContent: '删除后，您只能通过再次编辑维护新的信息，原有的信息将无法回恢复。',
  delConfirmTitle: '您确定要删除么？',
  commitApproval: '提交审批',
  operation: '操作',
  reason: '原因',
  addRefusedReason: '请填写审核拒绝的原因',
  refusedReason: '不通过原因',
  remark: '备注',
  auditReason1: '尺寸和照片不符',
  auditReason2: '重量和照片不符',
  auditReason3: '其他',
  categoryName: '品类名称',
  levelName: '品类级别',
  parentName: '上级品类',
  addCategory: '添加品类',
  editCategory: '编辑品类',
  batch_audit: '批量审核',
  batch_audit_success: '批量审核成功',
  batch_audit_more_than_200: '查询超过200条数据，请调整查询条件后再提交。',
  mention: '提示',
  please_select: '请选择',
  please_enter: '请输入',
  audit_result: '审核结果',
  deleteTips1: '删除一级品类将会删除关联的二级品类，确认删除？',
  first_category: '一级品类',
  second_category: '二级品类',
  printBarcode: '打印条码',
  codeType: '编码类型',
  printCnt: '打印数量',
  labelSize: '标签尺寸',
  originCountry: '原产地',
  add: '添加',
  please_select_codeType: '请选择编码类型',
  please_select_customCode: '请选择自定义编码',
  please_select_size: '请选择标签尺寸',
  please_select_originCountry: '请选择原产地',
  labelSize1: '100*60毫米',
  labelSize2: '70*50毫米',
  labelSize3: '60*40毫米',
  CNY: '人民币'
}
