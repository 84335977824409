export default {
  orderNo: '订单号',
  containerNo: '集装箱号',
  state: '状态',
  yardCode: '设施编码',
  waitForFixed: '已确认待修理',
  completed: '修洗完成',
  batchComplete: '批量完成',
  importInfo: '导入',
  importFileName: '集装箱修洗订单导入模板.xlsx',
  batchFileName: '集装箱修洗完成导入模板.xlsx',
  completeFormTitle: '修理完成',
  submitCleanCompleted: '操作成功',
  completeTimeInvalid: '清选择完成时间',
  completeTime: '修理完成时间',
  remark: '备注',
  list: {
    orderNo: '订单号',
    containerNo: '集装箱号',
    containerType: '箱型',
    state: '状态',
    yardCode: '设施编码',
    estimateMoney: '预估总价',
    affirmMoney: '确认总价',
    evaluateTime: '估价时间',
    enterTime: '进场时间',
    completeTime: '完成时间',
    createTime: '创建时间'
  },
  detail: {
    title: '修洗箱订单详情',
    orderNo: '订单号',
    containerNo: '集装箱号',
    containerType: '箱型',
    state: '状态',
    yardCode: '设施编码',
    evaluateTime: '估价时间',
    enterTime: '进场时间',
    completeTime: '完成时间',
    waitForFixed: '已确认待修理',
    completed: '修洗完成',
    price: '报价',
    No: '序号',
    componentsCode: '部件代码',
    positionCode: '位置代码',
    damageCode: '损坏代码',
    repairCode: '修理代码',
    componentsLong: '长',
    componentsWide: '宽',
    componentsNumber: '件数',
    party: 'Party',
    manHour: '工时',
    cost: '工时费',
    materialsCost: '材料费',
    subtotal: '小计',
    totalText: '合计'
  }
}
