export default {
  BLInfo: '提单信息',
  bookingNumber: '提单号',
  otherBL: '以下信息参考其他提单',
  BLType: '提单类型',
  requiredBLType: '请选择提单类型',
  original: '正本',
  telex: '电放',
  paymentMethod: '付款方式',
  requiredPaymentMode: '请选择付款方式',
  shipper: '发货人',
  placeholderShipper: '请输入发货人信息',
  requiredShipperAddress: '发货人为必填项',
  consignee: '收货人',
  placeholderConsignee: '请输入收货人信息',
  alertOthers: '通知人',
  placeholderAlertOthers: '请输入通知人信息',
  copyConsignee: '同收货人信息',
  notifierSEmail: '收货人或通知人邮箱地址（不显示在提单中）',
  placeholderAlertEmail: '请输入收货人或通知人邮箱地址',
  emailError: '邮箱格式错误',
  BLLading: '提单唛头',
  placeholderLading: '请输入需要展示在提单上的唛头信息',
  requiredBLLading: '提单唛头为必填项',
  BLDescribe: '提单货描',
  placeholderDescribe: '请输入需要展示在提单上的货物描述信息',
  requiredDescribe: '提单货描为必填项',
  reason: '驳回理由',
  cancel: '取消',
  nextStep: '下一步',
  submit: '提交',
  pageFocus: '页面聚焦',
  increaseContainer: '增加集装箱',
  add: '增加',
  containerVolume: '箱量',
  totalNumber: '总件数',
  grossWeight: '总毛重',
  totalCapacity: '总体积',
  boxType: '箱型',
  boxNumber: '箱号',
  sealNumber: '封号',
  removeCabinet: '移除此柜',
  addCargoDetails: '添加货物明细',
  number: '件数',
  unit: '单位',
  oneWeight: '毛重',
  volume: '体积',
  cargoDescription: '货物描述',
  operating: '操作',
  placeholderSO: '请输入SO或箱号',
  submitSuc: '提交成功',
  submitBlMergeSuc: '拆合成功',
  submitResult: '您的补料信息已提交成功，请耐心等待平台审核结果。',
  createBL: '创建提单',
  platformAudit: '平台审核',
  examinationPassed: '审核通过',
  issueBL: '发放提单',
  userName: '用户名',
  backToList: '返回列表',
  viewBL: '查看提单',
  pleaseAll: '请填写所有信息',
  pleaseAllHSCODE: 'HS CODE最少输入6位数字',
  placeholdContainerNo: '请输入箱号',
  placeholdSealNo: '请输入封号',
  income: '收',
  issue: '发',
  through: '通',
  reject: '驳回',
  pleaseEnterRejection: '驳回时驳回理由为必填项',
  placeholder: '请输入',
  successRejected: '驳回成功',
  reasonOfRejected: '驳回原因',
  examine: '审核',
  examineInfoError: '审核信息驳回',
  successExamine: '审核通过',
  noBox: '集装箱不存在或船名/航次/装货港/卸货港与其他集装箱不一致，请确认后再试',
  addRepeatedly: '集装箱已存在，请勿重复添加',
  incorrectStatus: '集装箱的状态不正确，请添加待补料的集装箱',
  incorrectStatusAudited: '集装箱的状态不正确，请添加已审核的集装箱',
  containerNoError: '箱号格式不正确',
  unPaymentYet: '加入的集装箱尚未确认付款，当前提单需提示客户重新提交放单申请，是否继续？',
  changeMBL: '变更MBL',
  currentMBL: '当前MBL',
  correlationBL: '已关联提单号',
  changeToMBL: '变更为MBL',
  MBLEmptyInvalid: '请输入MBL',
  MBLPlaceholder: '请输入MBL',
  leftOneData: '请至少保留一条信息',
  adjustmentFreeDays: '免柜天数调整',
  pleaseSearchSameBoat: '请先检索同一水船的数据进行下载',
  pleaseModifyTheQueryConditions: '请修改查询条件后，再次操作',
  determine: '确定',
  daysOverdue: '逾期天数',
  customerDimension: '客户维度',
  billOfLadingDimension: '提单维度',
  containerDimension: '集装箱维度',
  modifyeDataSubmit: '请修改数据后再提交',
  continueFreeDays: '您将编辑的数据存在多种免柜天数，修改后将该数据下所有箱子的免柜天数修改为一致，请确认是否还将继续操作！',
  cutoverFreeDays: '您将进行维度切换，切换后将导致你已调整的数据失效，请确认是否继续切换！',
  containerOverdueList: '集装箱逾期清单',
  positiveIntegerGreaterThan0: '逾期天数请填写大于0的正整数',
  QINGDAO: '青岛',
  GUANGZHOU: '广州',
  SHANGHAI: '上海',
  NINGBO: '宁波',
  XIAMEN: '厦门',
  SHENZHEN: '深圳',
  TAICANG: '太仓',
  referenceBillOfLading: '参考提单'
}
