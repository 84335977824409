export default {
  createOrder: '新增集装箱退出',
  modifyOrder: '修改集装箱退出',
  updateOrder: '补录集装箱退出',
  viewOrder: '查看集装箱退出',
  createText: '新建',
  orderNo: '订单号',
  rent: '退租',
  contractNo: '合同号',
  orderType: '订单类型',
  orderStatus: '状态',
  orderTypeList: ['退租', '报废', '报失'],
  orderStatusList: ['草稿', '已提交', '操作中', '已完成'],
  batchFinished: '批量完成',
  quantityAndAmount: '集装箱统计',
  deliveredContainerQuantity: '已完成集装箱统计',
  createdTime: '创建时间',
  quitStartTime: '退出开始时间',
  quitEndTime: '退出完成时间',
  finishedBtn: '完成',
  authNo: '授权号',
  batchNo: '批次号',
  saveAsDraft: '保存草稿',
  deliveryPlace: '交箱点信息',
  yardCode: '设施编码',
  containerType: '箱型',
  quitMax: '退出数量上限',
  quitPlan: '计划退出数量',
  operatingQuantity: '操作中数量',
  finishedQuantity: '已完成数量',
  createDeliveryBtn: '添加交箱点',
  quitContainerInfo: '退出集装箱信息',
  transferNo: '调拨单号',
  polYardCode: '起运设施编码',
  podYardCode: '目的设施编码',
  containerNo: '箱号',
  outYardTime: '出场时间',
  quitFinishedTime: '完成时间',
  dropWarnningTips: '是否放弃当前修改',
  orderNoInvalid: '请选择订单类型',
  contractNoInvalid: '请填写合同号',
  startDateInvalid: '请填写退出开始时间',
  endDateInvalid: '请填写退出完成时间',
  infoEmptyInvalid: '请填写交箱点信息',
  submitQuitSuccess: '退出订单保存成功',
  insideYardList: '在场',
  inPort: '在港',
  outYard: '场外',
  onBoard: '在船',
  deleteListDataTips: '是否确认删除当前数据?',
  deletedSuccess: '删除成功',
  containerTypeRepeatInvalid: '不能重复添加箱型'
}
