export default {
  charge: '费用',
  confirmation: '费用确认单',
  bill: '账单',
  invoice: '发票',

  apply_invoice: '申请开票',
  can_not_be_null: '不能为空',
  step_1: '步骤一',
  step_2: '步骤二',
  step_3: '步骤三',
  confirm_invoice_charge: '确认开票费用',
  text_invoice_config: '填写开票配置',
  submit_success: '提交成功',
  submit_success_info: '恭喜您，电子形式发票已开具成功，发票号',
  invoice_source: '开票来源',
  charge_detail: '费用明细',
  has_select: '已选择',
  items: '项',
  applying_amount: '未申请开票金额',
  invoice_apply_amount: '申请开票金额',
  invoice_apply_amount_total: '申请开票合计',
  invoice_apply_no: '开票申请编号',
  please_select_invoice_apply_charge: '请勾选要申请开票的费用',

  invoice_config: '开票配置',

  default_name: '默认表头',
  apply_no: '申请编号',
  business_type_name: '业务类型',
  settlement_company_name: '结算单位',
  settlement_office_name: '结算分公司',
  invoice_currency_amount_str: '金额币种',
  invoice_type_name: '发票类型',
  invoice_medium_name: '发票材质',
  invoice_item_mode_name: '开票名称',
  invoice_group_type_name: '开票方式',
  invoice_state_name_invoice: '开票状态',
  apply_state_name: '申请状态',
  apply_by_name: '申请人',
  apply_time: '申请时间',

  apply_total: '申请合计',
  total: '合计',


  operation: '操作',
  view: '查看',
  to_invoice: '开票',
  recall: '撤回',

  preview: '预览',
  ALL: '全部',
  please_enter: '请输入',

  download_invoice_file: '下载发票',
  cancel_invoice_file: '作废发票',
  apply_clear: '申请核销',
  offset_clear: '对冲核销',

  invoice_seq_no: '发票流水号',
  invoice_no: '发票号',
  invoice_code: '发票编码',
  invoice_by_name: '开票人',
  invoice_date: '开票时间',
  nullify_date: '作废时间',
  tax_system_state_name: '税控状态',
  invoice_state_name: '发票状态',
  notify_state_name: '推送状态',
  express_state_name: '寄送状态',
  instruction_state_name: '申请核销状态',
  clear_state_name: '核销状态',
  print_state_name: '打印状态',
  express_no: '快递单号',
  amount: '金额',

  except_message: '异常原因',
  except_state_name: '异常状态',

  upload_invoice_file_cell: '扫描件发票',
  upload_charge_file_cell: '扫描件账单',
  upload_other_file_cell: '其他扫描件',


  un_invoice_apply: '待开票申请',
  recall_apply: '已撤回申请',
  my_apply: '我的申请',
  my_commercial_invoice: '我的形票',
  all_apply: '全部申请',
  all_commercial_invoice: '全部形票',

  my_tax_invoice: '我的税票',
  all_tax_invoice: '全部税票'
}
