import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken, setToken, getUserInfo } from '@/utils/auth' // get token from cookie
import lodash from 'lodash'
import path from 'path'
// import webTracker from 'web-tracker'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/agentLogin', '/auth-redirect', '/notify/identification/email', 'EmailIdentification', '/CharteredBoat/ReleaseBillListBusiness', '/CharteredBoat/ReleaseBillListOperate', '/monitoryTimeline',
'/CharteredBoat/sailingScheduleDashboard', '/TrackingCenter/share', '/ContractPrice/ApprovalDetails', '/ContractPrice/ApprovalSoDetails', '/ContractPrice/LongView', '/ContractPrice/FakView', '/ContractPrice/NoLongView'] // no redirect whitelist
const loginFreeWhiteList = ['/CharteredBoat/ReleaseBillListBusiness', '/CharteredBoat/ReleaseBillListOperate', '/CharteredBoat/sailingScheduleDashboard',
  '/TrackingCenter/share', '/ContractPrice/ApprovalDetails', '/ContractPrice/ApprovalSoDetails', '/ContractPrice/LongView', '/ContractPrice/FakView', '/ContractPrice/NoLongView'] // 免登录白名单

function checkIfToRouterNameExists(to, layer) {
  let res = 0
  layer.forEach(l => {
    res += l.children && l.children.length > 0 ? checkIfToRouterNameExists(to, l.children) : l.name === to.name ? 1 : 0
    // res += l.name === to.name ? 1 : l.children && l.children.length > 0 ? checkIfToRouterNameExists(to, l.children) : 0
  })
  return res
}

router.beforeEach(async (to, from, next) => {
  // 终止所有请求
  if (store.getters.cancelList.length > 0) {
    store.getters.cancelList.forEach(cancel => {
      cancel('cancelRequest')
    })

    // 清空cancel 列表
    store.dispatch('axiosCancel/clearList')
  }

  // start progress bar
  NProgress.start()

  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken && !to.query.token) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0
      if (hasRoles) {
        let checked = checkIfToRouterNameExists(to, store.getters.permission_routes)
        if (checked) {
          // let userInfo = getUserInfo()
          // webTracker.send({
          //   logType: 'business',
          //   logCode: 'menu',
          //   logName: '菜单',
          //   elementType: 'button',
          //   event: 'click',
          //   userId: userInfo.id,
          //   menuName: to.meta.title
          // })
          return next()
        } else {
          // 没有首页权限的账户，使用路由的第一个菜单作为默认跳转
          const _routeObj = store.getters.permission_routes[0]
          const _path = _routeObj
            ? _routeObj.children.length > 0
            ? path.resolve(_routeObj.path, _routeObj.children[0].path)
            : _routeObj.path
           : '/401'
          return next(_path)
        }
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          const { roles, menu } = await store.dispatch('user/getInfo')

          // generate accessible routes map based on roles
          const menuList = await store.dispatch('permission/setTopMenu', menu)
          // dynamically add accessible routes
          router.addRoutes(menuList.realPathRoutes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          next({ ...to, replace: true })
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken')
          Message.error(error || 'Has Error')
          next(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1 || whiteList.indexOf(to.name) !== -1) {
      // in the free login whitelist, go directly
      if (loginFreeWhiteList.indexOf(to.path) !== -1) {
        if (!!to.query && !!to.query.token) {
          setToken(to.query.token)
          store.dispatch('user/setTemplateToken', to.query.token)
          const getUserBaseInfo = await store.dispatch('user/getUserBaseInfo')
          let query = lodash.cloneDeep(to.query)
          delete query.token
          next({
            path: to.path,
            query: {
              ...query
              // releaseNo: to.query.releaseNo
            }
          })
        } else {
          next(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
          NProgress.done()
        }
      } else {
        next()
      }
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${encodeURIComponent(to.fullPath)}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
