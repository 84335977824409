export default {
  price_card_name: '价卡名称',
  price_card_code: '价卡编码',
  please_enter_price_card_name: '请输入价卡名称',
  please_enter_price_card_code: '请输入价卡编码',
  please_enter_price_card_name_desc: '请输入成本价卡名称',
  country: '国家',
  channel: '渠道名称',
  please_select_channel: '请选择渠道',
  channel_desc: '名称/代码',
  product_code: '物流产品',
  product_code_desc: '名称/代码',
  please_select_product_code: '请选择物流产品',
  please_select_product_code_desc: '请选择渠道带出物流产品',
  relative_cost_price_card_name: '关联成本价卡名称',
  price_card_type: '价卡类型',
  new_price_card_type: '新建价卡类型',
  please_select_price_card_type: '请选择价卡类型',
  priceCardType: {
    'ALL': '全部',
    'GUIDE': '指导价',
    'LOWER': '最低卖价'
  },
  please_newAdjustmentCoefficient: '请输入调整系数',
  status: '状态',
  please_select_status: '请选择状态',
  statusType: {
    'ALL': '全部',
    'DRAFT': '草稿',
    'SUBMIT': '已提交',
    'PUBLISH': '发布中',
    'PUBLISHED_SUCCESSFUL': '发布成功',
    'PUBLISHED_FAILED': '发布失败'
  },
  effective_time: '有效时间',
  please_select_effective_time: '请选择有效时间',
  effective_start_time: '有效开始时间',
  effective_end_time: '有效结束时间',
  start_time: '开始时间',
  end_time: '结束时间',
  effective_time_tips: '大于成本价卡有效期范围，请注意！',
  YES: '是',
  NO: '否',
  to: '至',
  search: '搜索',
  reset: '重置',
  add: '新建',
  view: '查看',
  edit: '编辑',
  confirm: '确定',
  cancel: '取消',
  operation: '操作',
  base_info: '基本信息',
  currency: '币种',
  please_select_currency: '请选择币种',
  unit_category_type: '度量单位',
  please_select_unit_category_type: '请选择度量单位',
  unitCategoryType: {
    METRIC: '公制 （米、千克）',
    IMPERIAL: '英制（英尺、磅）'
  },
  unitCategory: {
    KG: '千克',
    LB: '磅'
  },
  destWarehouseType: {
    FBA: 'FBA仓库'
  },
  dest_warehouse_type: '目的地类型',
  please_select_dest_warehouse_type: '请选择目的地类型',
  meter_bubble_coefficient: '计泡系数',
  please_enter_meter_bubble_coefficient: '请输入计泡系数',
  delivery_charge: '派送费',
  charge: '费用',
  save_draft: '保存草稿',
  submit: '提交',
  save_success: '保存成功',
  un_save_info_mention: '尚未保存的信息将会丢失，确认关闭？',
  save_draft_info_mention: '将会保存为草稿，请前往列表查找该价卡，并点击“编辑”进行修改和提交等操作。',
  prompt: '提示',
  compute_mode: '单价/总价',
  please_enter_compute_mode: '请选择单价/总价',
  computeMode: {
    UNIT: '单价',
    ALL: '总价'
  },
  delivery_charge_upload_file: '派送费导入文件',
  delivery_charge_template: '派送费模板',
  upload_file: '上传文件',
  download_template: '模板下载',
  product_type_un_support: '运输方式不支持',
  parse_success: '解析成功',
  rate: '费率',
  fuel_rate: '燃油费率',
  rate_time_range: '费率时间范围',
  no_data: '暂无数据',
  new_adjustment_coefficient: '新增时调整系数',
  index: '序号',
  charge_item_name: '费用名称',
  area: '分区',
  please_select_area: '请选择分区',
  is_area: '是否分区',
  price: '价格',
  please_enter_price: '请输入价格',
  base_price: '成本价格',
  adjustment_coefficient: '调整系数',
  please_enter_adjustment_coefficient: '请输入调整系数',
  adjustment_price: '调整后价格',
  number_error: '请输入正确的数字',
  charge_condition: '收费条件',
  charge_condition_cannot_be_null: '收费条件值不能为空',
  charge_condition_end_value_is_greater_than_start_value: '收费条件结束值不能大于起始值',
  please_enter: '请输入',
  please_select: '请选择',
  price_cannot_be_null: '的价格不能为空',
  next_step: '下一步',
  please_select_cost_price_card: '请选择成本价卡',
  lower_price_info_mention_one: '最低卖价必须关联成本价卡。',
  lower_price_info_mention_two: '可以通过快速选择成本价卡，维护浮动系数，自动生成。',
  lower_price_info_mention_three: '生成之后可以手动调整。',
  lower_price_info_mention_four: '每一个物流产品在相同时间只能有一张生效的最低卖价价卡。',
  lower_price_info_mention_five: '请先物流产品，然后选择成本价卡（仅一个），然后根据展示的成本价卡，预加载数据后进行价卡维护。',
  guide_price_info_mention_one: '指导价必须关联成本价卡。',
  guide_price_info_mention_two: '可以通过快速选择成本价卡，维护浮动系数，自动生成。',
  guide_price_info_mention_three: '也可以手动维护，但保存时不能低于成本价卡中对应费用的金额。',
  guide_price_info_mention_four: '每一个物流产品在相同时间只能有一张生效的指导价价卡。',
  guide_price_info_mention_five: '请先选择物流产品，然后根据展示的成本价卡，预加载数据后进行价卡维护。',
  customer_price_info_mention_one: '客户价卡必须关联成本价卡。',
  customer_price_info_mention_two: '维护浮动系数，客户价卡将根据系统自动填充，然后进行手动调整。',
  customer_name: '客户名称',
  new_customer_name: '新建价卡所属客户',
  please_select_customer_name: '请选择客户名称',
  delivery_charge_coefficient: '派送费系数',
  surcharge_coefficient: '附加费系数',
  pss_coefficient: '旺季附加费系数',
  carriage_coefficient: '运费系数',
  weight_start_value: '对应起始重量段',
  please_enter_weight_start_value: '请输入起始重量段',
  weight_end_value: '对应结束重量段',
  please_enter_weight_end_value: '请输入结束重量段',
  warehouse: '仓库',
  please_select_warehouse: '请选择仓库',
  destination: '目的地',
  please_select_destination: '请选择目的地',
  start_tray: '起始托盘数',
  please_enter_start_tray: '请输入起始托盘数',
  end_tray: '结束托盘数',
  please_enter_end_tray: '请输入结束托盘数',
  carriage_min_amount: '派送费最小金额',
  please_enter_carriage_min_amount: '请输入派送费最小金额',
  please_enter_oversizeSurchargeMinChargeWeight: '请输入快递超大尺寸附加费最低计费重',
  oversizeSurchargeMinChargeWeight: '快递超大尺寸附加费最低计费重',
  weight_start_value_less_than_end_value: '起始重量不能大于结束重量',
  weight_end_value_greater_than_start_value: '结束重量不能小于起始重量',
  tray_start_value_less_than_end_value: '起始托盘数不能大于托盘数',
  tray_end_value_greater_than_start_value: '结束托盘数不能小于起始托盘数',
  weightInfo: '重量/',
  weight_unit: '重量单位',
  weightType: {
    LB: 'LB',
    KG: '千克'
  },
  tray: '托盘',
  refresh_price_card: '刷新价卡',
  price_card_show_mention: '价格展示为“调整前价格/调整后价格“',
  new_customer_price_card: '新建客户价卡',
  by_cost_price_card: '通过成本价卡',
  pre_load: '预加载',
  show_cost_price_card_effective_time: '查看成本有效期',
  upToNow: '至今',
  peak_season_surcharge: '旺季附加费',
  delTips1: '此操作将永久删除此成本价卡及所有关联的状态符合删除条件的价卡，是否继续？',
  delTips2: '此操作将永久删除此价卡，是否继续？',
  copyBtn: '其他价卡复制新建',
  please_select_copy_priceCard: '请选择要复制的价卡',
  new_price_card_name: '新建价卡名称',
  price_card_repeat: '价卡名称重复',
  please_new_price_card_name: '请输入新建价卡名称'
}
