export default {
    channel_code: '渠道代码',
    please_enter_channel_code: '请输入渠道代码',
    channel_name: '渠道名称',
    please_enter_channel_name: '请输入渠道名称',
    product_code: '物流产品',
    please_select_product_code: '请输入物流产品',
    product_name: '物流产品名称',
    product_code_des: '名称/代码',
    supplier: '供应商',
    please_select_supplier: '请选择供应商',
    supplier_desc: '名称/代码',
    search: '搜索',
    reset: '重置',
    add: '新建',
    view: '查看',
    edit: '编辑',
    confirm: '确定',
    cancel: '取消',
    operation: '操作',
    channel_detail: '渠道详情',
    edit_channel: '渠道编辑',
    add_channel: '渠道新增',
    supplierChannelCode: '供应商渠道代码',
    please_enter_supplier_channel_code: '请输入供应商渠道代码',
    base_info: '基本信息'
}
