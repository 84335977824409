export default {
  loadingPort: '装货港',
  downPort: '卸货港',
  fiveCode: '五字码',
  bookingEndDate: '订舱结束日期',
  operation: '操作',
  pickPoint: '提箱点',
  returnPoint: '还箱点',
  supportBox: '支持箱型',
  transitPortOrNot: '是否中转港',
  countryOfDischargePort: '卸货港国家',
  transitPort: '中转港',
  agentInformation: '代理信息',
  placeholder: '请选择',
  placeholderInput: '请输入',
  vesselName: '船名',
  voyageNo: '航次',
  shipowner: '船东',
  carrierName: '是否中转',
  callSign: '呼号',
  backList: '返回列表',
  addSchedule: '新增船期',
  baseInfo: '基本信息',
  shippingDepartment: '船司',
  routeCode: '航线代码',
  information: '信息',
  add: '增加',
  pickUpOrReturn: '提还箱点',
  boxConfiguration: '放箱配置',
  chineseName: '中文名称',
  englishName: '英文名称'
}
