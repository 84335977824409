import Layout from '@/layout'

export const CasDestinationManagerRouterMap = {
  // 目的港
  'cas:destination:material': {
    component: Layout,
    alwaysShow: true,
    name: 'CasDestinationMaterialManager'
  },
  // 目的港管理(单证)
  'cas:destination:materialList': {
    component: () => import('@/views/cargo/v0/destination/destinationMaterialList'),
    name: 'DestinationMaterialList',
    children: ['cas:destination:material:generate', 'cas:destination:material:generate:view']
  },
  // 目的港管理 - 资料生成
  'cas:destination:material:generate': {
    path: 'Destination/MaterialGenerate',
    component: () => import('@/views/cargo/v0/destination/materialGenerate'),
    name: 'MaterialGenerate',
    meta: { title: '资料生成' },
    hidden: true
  },
  // 目的港管理 - 详情
  'cas:destination:material:generate:view': {
    path: 'Destination/MaterialGenerateView',
    component: () => import('@/views/cargo/v0/destination/materialGenerateView'),
    name: 'MaterialGenerateView',
    meta: { title: '详情' },
    hidden: true
  },
  // 目的港管理(代理)
  'cas:destination:material:agencyList': {
    component: () => import('@/views/cargo/v0/destination/destinationMaterialAgencyList'),
    name: 'DestinationMaterialAgencyList',
    children: []
  },
  // 改单管理（口岸代理）
  'cas:entityApply:portAgencyList': {
    component: () => import('@/views/cargo/v0/destination/entityApplyPortAgencyList'),
    name: 'CasPodList',
    meta: {
      noCache: false
    },
    children: ['cas:entityApply:portAgencyList:detail', 'cas:entityApply:portAgencyList:create', 'cas:entityApply:portAgencyList:edit']
  },
  // 改单管理（单证）
  'cas:entityApply:documentList': {
    component: () => import('@/views/cargo/v0/destination/entityApplyDocumentList'),
    name: 'CasDocumentList',
    meta: {
      noCache: false
    },
    children: ['cas:entityApply:documentList:audit', 'cas:entityApply:documentList:view', 'cas:entityApply:documentList:confirm']
  },
  // 改单管理（目的港代理）
  'cas:entityApply:destinationAgentList': {
    component: () => import('@/views/cargo/v0/destination/destinationAgentList'),
    name: 'DestinationAgentList',
    children: []
  },
  // 新建改单申请
  'cas:entityApply:portAgencyList:create': {
    path: 'Destination/AddEntityApply/:operation/:id?',
    component: () => import('@/views/cargo/v0/destination/AddEntityApplyCreate'),
    name: 'AddEntityApplyCreate',
    children: [],
    meta: { title: '新建改单申请' },
    hidden: true
  },
  // 新建改单申请
  'cas:entityApply:portAgencyList:edit': {
    path: 'Destination/AddEntityApply/:operation/:id?',
    component: () => import('@/views/cargo/v0/destination/AddEntityApplyEdit'),
    name: 'AddEntityApplyEdit',
    children: [],
    meta: { title: '改单申请编辑' },
    hidden: true
  },
  // 改单详情
  'cas:entityApply:portAgencyList:detail': {
    path: 'Destination/AddEntityApply/:operation/:id?',
    component: () => import('@/views/cargo/v0/destination/components/addEntityApply'),
    name: 'AddEntityApply',
    children: [],
    meta: { title: '改单申请详情' },
    hidden: true
  },
  // 改单申请审核
  'cas:entityApply:documentList:audit': {
    path: 'Destination/entityApplyDocumentAudit/:operation/:id?',
    component: () => import('@/views/cargo/v0/destination/entityApplyDocumentAudit'),
    name: 'EntityApplyDocumentAudit',
    children: [],
    meta: { title: '改单申请审核' },
    hidden: true
  },
  // 申请详情
  'cas:entityApply:documentList:view': {
    path: 'Destination/entityApplyDocumentAudit/:operation/:id?',
    component: () => import('@/views/cargo/v0/destination/entityApplyDocumentView'),
    name: 'EntityApplyDocumentView',
    children: [],
    meta: { title: '申请详情' },
    hidden: true
  },
  // 废单回执确认
  'cas:entityApply:documentList:confirm': {
    path: 'Destination/entityApplyDocumentAudit/:operation/:id?',
    component: () => import('@/views/cargo/v0/destination/entityApplyDocumentConfirm'),
    name: 'EntityApplyDocumentConfirm',
    children: [],
    meta: { title: '废单回执确认' },
    hidden: true
  }
  // 改单管理（目的港代码）
  // 'cas:entityApply:destinationAgentList': {
  //   component: () => import('@/views/cargo/v0/destination/entityApplyDocumentList'),
  //   name: 'CasDocumentList',
  //   children: []
  // }
}
