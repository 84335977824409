export default {
	declareMethod: '申报方式',
    declareByAgent: '代理申报',
    declareBySelf: '自行申报',
    declareFailed: '提交失败',
    onCreating: '新增处理中',
    onModifying: '修改处理中',
    onDeleteing: '删除处理中',
    ENSTypeOne: 'ENS(海特)',
    ENSTypeTwo: 'ENS(XPF)',
    downType: '提单清单',
    middleEast: '舱单报文（中东）',
    typeCollection: '样单合集',
    deleteBl: '删除提单',
    signBl: '签发提单',
    declareStatusEmptyError: '请选择申报状态',
    masterBlNo: 'Master BL No',
    masterBlNoEmptyError: '请输入Master BL No',
    blPrint: '提单打印',
    blFormat: '提单格式',
    blFormatEmptyError: '请选择提单格式',
    nameOfHaite: '海特',
    nameOfYihaitong: '海特',
    MBLText: 'MBL',
    HBLText: 'HBL',
    blPrefix: '单号前缀',
    blPrefixEmptyError: '请选择单号前缀',
    COSLText: 'COSL',
    YHTText: 'YHT',
    sailingStart: '开船日期',
    sailingStartEmptyError: '请选择开船日期',
    ATDText: 'ATD',
    blTypeEmptyError: '请选择提单类型',
    blTypeCopy: 'COPY',
    blTypeOrignal: 'ORIGINAL',
    blTypeSeaWayBill: 'SEA WAYBILL',
    blPrintType: '正本/样单',
    blPrintTypeEmptyError: '请选择正本/样单',
    blSample: '样单',
    blOriginal: '正本',
    chooseFormat: '格式选择',
    formatEmptyError: '请选择导出格式',
    excelFile: 'Excel',
    pdfFile: 'PDF',
    downloadTemplete: '下载模板',
    exportTemplate: '样单导出',
    infoFrom: '信息来源',
    typeAndAmount: '箱型箱量',
    quantityText: '件数',
    payPlatform: '付款渠道',
    deletedDataEmptyError: '请勾选一条进行删除记录',
    blAlreadyDeletedError: '提单已签发不能删除，请重新选择再试',
    exportSuccessTips: '导出成功，请到任务列表页面查看导出进度！',
    signedDataEmptyError: '请勾选数据进行签发',
    blStatusError: '提单状态为待签发方可标记签发',
    blStatusErrorConfirm: '提单状态为待确认方可确认签发',
    blSignedSuccess: '签发提单成功',
    markDataEmptyErrro: '请勾选数据进行标记',
    markDataVerifiedError: '请勾选审核通过的美线且申报方式不是待定的数据进行标记',
    sailingDifferentError: '请搜索同一船期的数据进行导出',
    askRelease: '申请放单',
    releaseOrder: '确认放单',
    unfill: '待补料',
    recordMBL: '录入MBL'
}
