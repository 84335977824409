export default {
  messageCenter: {
    allRead: '标记为已读',
    allMessage: '全部类型消息',
    sysMessage: '系统公告',
    businessMessage: '业务消息',
    messageTitle: '消息标题',
    messageType: '消息类型',
    messageTime: '消息时间',
    showUnread: '仅显示未读消息',
    operating: '操作',
    updateSuccess: '批量更新成功',
    goBackList: '返回列表',
    prev: '上一条',
    next: '下一条',
    needOneLine: '请先勾选一行'
  },
  receiverManage: {
    add: '新增接收人',
    receiver: '接收人',
    name: '姓名',
    mobile: '手机号',
    email: '邮件',
    operating: '操作',
    edit: '编辑',
    delete: '删除',
    addSuccess: '新增成功',
    editSuccess: '编辑成功',
    deleteSuccess: '删除成功',
    isNeedName: '姓名不能为空',
    mobileRegFalse: '手机号格式不正确',
    emailRegFalse: '邮箱格式不正确',
    isNeedEamil: '邮箱不能为空',
    resend: '重新发送',
    resendSuccess: '重新发送成功',
    mobileUnIdentification: '手机号未验证，不能接收消息，',
    emailUnIdentification: '邮箱未验证，不能接收消息，',
    mobileOrEmailNeedOne: '手机号和邮箱不能同时为空！',
    emailIdentification: '您已成功激活邮箱，该邮箱可用于接收来自海运智运的消息通知。'
  },
  subscribe: {
    batchSetReceiver: '批量设置接收人',
    selectReceiverInfo: '请选择要接收消息联系人',
    addReceiver: '新增接收人',
    setReceiver: '设置接收人',
    messageType: '消息类型',
    sys: '站内信',
    sms: '短信',
    email: '邮件',
    edit: '修改',
    receivers: '接收人',
    setSuccess: '设置接收人成功',
    setEnableSuccess: '设置成功',
    needOneLine: '请先勾选一行'
  }
}
