export default {
    entity_no: '订单号',
    bill_no: '账单号',
    bill_status: '账单状态',
    bill_name: '账单名称',
    bill_charge_detail: '账单明细',
    customer_name: '客户名称',
    settlement_office_name: '结算分公司',
    settlement_company_name: '结算单位',
    warehouse_name: '仓库名称',
    sales_names: '所属销售',
    product_names: '所属销售',
    currency: '币种',
    bill_amount: '账单金额',
    amount: '金额',
    bill_date: '账单日期',
    settlement_mode: '结算方式',
    period: '账期',
    status: '状态',
    invoice_status: '开票状态',
    apply_clear_status: '申请核销状态',
    clear_status: '核销状态',
    verification_status: '核销状态',
    confirmation_state: '费用确认单状态',
    invoice_apply_state_name: '申请开票状态',
    operation: '操作',
    remove: '移除',
    bank_account: '银行账户',
    detail: '详情',
    search: '查询',
    reset: '重置',
    delete: '删除',
    cancel: '取消',
    confirm: '确认',
    mention: '提示',
    view: '查看',
    please_enter: '请输入',
    please_select: '请选择',
    ALL: '全部',
    serial_no: '流水号',
    order_no: '订单号',
    business_no: '业务编号',
    MBL: '主单号',
    HBL: '分单号',
    SO: 'SO',
    business_type: '业务类型',
    charge_name: '费用名称',
    unit_name: '计费单位',
    curreny: '币种',
    price: '单价',
    number: '数量',
    calc_amount: '预估金额',
    entry_amount: '入账金额',
    old_entry_amount: '原入账金额',
    after_change_entry_amount: '调整后入账金额',
    UN_APPLY_INVOICE: '未申请开票',
    UN_VERIFY: '待对账',
    next: '下一步',
    add: '添加',
    add_charge: '添加费用',
    add_charge_success: '添加费用成功',
    change_charge: '调整费用',
    change_charge_success: '调整费用成功',
    tax_rate: '税率',
    tax_amount: '税额',
    exclude_tax_amount: '不含税金额',
    business_date: '业务时间',
    entry_date: '入账时间',
    entry_date_estimate: '预计入账时间',
    bill_date_estimate: '预计出账日期',
    bill_date_actual: '实际出账日期',
    confirm_date_estimate: '预计对账日期',
    confirm_date_actual: '实际对账日期',
    add_charge_item: '添加费用项',
    sum: '合计',
    receive_sum: '应收合计',
    join_bill: '加入账单',
    join_bill_success: '加入账单成功',
    download_bill: '下载账单',
    ovl_bill_confirm: '对账确认',
    ovl_bill_confirm_des: '对账确认之后，如需撤回账单，需要前往结算模块进行操作。',
    ovl_bill_confirm_success: '对账确认成功',
    fms_bill_confirm: '确认对账',
    fms_bill_confirm_des: '确认对账后将无法修改账单，是否确认？',
    fms_bill_confirm_success: '确认对账成功',
    data_error_no_entity: '数据错误，没有对应的实体订单信息！',
    bill_unallow: '不再出账',
    bill_unallow_des: '此操作后该费用明细将不再出账，是否继续?',
    bill_unallow_success: '不再出账成功',
    bill_allow: '恢复出账',
    bill_allow_des: '此操作后将该费用明细恢复到可出账状态，是否继续?',
    bill_allow_success: '恢复出账成功',
    create_bill: '生成账单',
    already_create_bill: '账单已生成',
    click_bill_no: '点击账单编号',
    view_detail: '查看详情',
    bill_recall: '撤回',
    bill_recall_des: '账单撤回后，费用退回原始状态，无法完成对账，是否确认撤回？',
    bill_recall_success: '撤回成功',
    export_bill: '导出账单',
    please_select_one_then_bill: '请先选中一行，再进行生成账单！',
    this_confirm_un_confirm: '存在费用确认单还未确认，不能生成账单！',
    this_confirm_has_entry: '存在已经入账的费用，不能生成账单！',
    need_same_settlement_company: '同一结算主体的才能进行生成账单！',
    need_same_business_type: '同一业务类型的才能进行生成账单！',
    need_same_settlement_mode: '同一结算方式的才能进行生成账单！',
    charge_confirm: '确认费用',
    charge_confirm_des: '是否确认费用？',
    recall_charge_confirm: '撤回确认',
    recall_charge_confirm_des: '是否撤回确认费用？',
    recall_charge_confirm_success: '撤回确认成功！',
    charge_confirm_success: '费用确认单确认成功',
    download_file_wait: '账单文件正在生成中，请5分钟后再尝试',
    mbl_no: 'MAWB NO',
    can_not_be_null: '不能为空',
    charge_amount: '费用金额',
    invoiced_no: '发票号',
    invoiced_date: '开票时间',
    invoiced_by: '开票人',
    receive_invoice: '开票',
    receive_cleared: '核销',
    receive_cancel_cleared: '撤回核销',
    cancel_cleared_success: '撤回核销成功',
    cannot_be_null: '不能为空',
    please_select_one_data: '请先选择一行数据',
    cleard_date: '核销时间',
    receive_cleard: '核销',
    receive_invoiced: '开票',
    profit_and_loss_audit: '盈亏审核',
    export_file: '导入文件',
    accounting_period: '所属账期',
    profit_state: '盈亏状态',
    profit_date: '盈亏审核时间',
    invoice_date: '开票时间',
    clear_by: '核销人',
    clear_date: '核销时间',
    ticket_status: '收票状态',
    part_clear: '部分核销',
    receipt_pay_date: '实际到账日期',
    cleard_record: '核销记录',
    cleard_detail: '核销详情',
    please_text: '请填写',
    part_cleared_success: '部分核销成功！',
    cleared_amount_text_error: '本次核销金额输入格式不对',
    cleared_amount_un_match_un_cleard: '本次核销金额正负与待核销金额的不匹配',
    cleared_amount_can_not_than_un_cleard: '本次核销金额不能大于待核销金额',
    cleared_by: '核销人',
    cleared_date: '核销时间',
    cleared_amount: '核销金额',
    un_cleared_amount: '待核销金额',
    has_cleared_amount: '已核销金额',
    can_cleared_amount: '本次核销金额',
    charge_amount_curreny: '费用金额币种',
    HAWB: 'HAWB NO',
    confirmation_no: '流水号',
    confirm_by_name: '对账人',
    charge_no: '费用ID',
    submit_name: '提交人',
    submit_time: '提交时间',
    standard_amount: '费用本位币金额（含税）',
    un_clear_standard_amount: '剩余未销金额(本位币)',
    please_select_business_type_then_export: '请先选择业务类型!',
    please_select_same_business_type_then_export: '请勾选相同业务类型的数据',
    no_template_can_not_export: '当前业务类型未配置模板，暂时无法进行导出Excel',
    export_operate_success: '导出操作成功，请稍后去任务列表查看导出结果！',
    export_excel: '导出Excel',
    all_in: '全部加入',
    select_in: '选择加入',
    charge_detail: '费用明细',
    index_no: '序号',
    can_not_operation_in_adjust: '账单状态为调整中，不能进行该操作!',
    create_by_name: '创建人',
    file_status: '文件状态',
    piece_need_confirm: '票结费用需要费用确认单已确认才能进行申请开票操作!',
    need_un_invoice_and_bill_check: '账单状态已对账、开票状态未开票或部分开票才能进行申请开票操作!',
    need_un_invoice_and_bill_check_sea: '票结、开票申请状态未申请或部分申请、开票状态未开票或部分开票才能进行申请开票操作!',
    invoicing_confirmed: '确认单状态为已确认才能进行申请开票操作!',
    un_clear_amount_total: '未销原币合计',
    can_not_operation_is_cleared: '核销状态为已核销，不能进行核销操作!'
}
