<template>
  <el-select
    v-model="modelValue"
    class="cargo-advance-select"
    :style="{position:'relative'}"
    :remote-method="remoteMethod"
    :loading="loading"
    :placeholder="placeholder"
    :clearable="clearable"
    :multiple="multiple"
    :allow-create="allowCreate"
    filterable
    remote
    reserve-keyword
    :size="size"
    :disabled="disabled"
    @change="change"
    value-key="id"
  >
    <div v-if="tips && options.length">
      <div class="tip-count-options w-full view-content">下表仅显示前100条满足检索条件的记录</div>
      <el-option key="header" :disabled="true" value="header" class="w-full view-content">
        <slot>
          <div v-for="(field) in headers" :key="field" class="item">{{ field }}</div>
        </slot>
      </el-option>
    </div>
    <el-option
      v-for="item in options"
      :key="multiple?item[valueKey]:item[valueKey]"
      :disabled="multiple ? disabledKey.indexOf(item[valueKey])>-1:disabledKey ? item[disabledKey] : false"
      :label="item[labelKey]"
      :value="item[valueKey]"
      :class="tips?'w-full view-content':''"
    >
      <slot name="option" :row="item" />
      <slot>
        <div v-for="(field) in fields" :key="field" class="item">{{ item[field] }}</div>
      </slot>
    </el-option>
  </el-select>

</template>
<script>
export default {
  name: 'AdvanceSelect',
  props: {
    value: {
      type: [String, Array]
    },
    // 远程请求
    method: { type: Function, default: null },
    // 不可编辑
    disabled: { type: Boolean, default: false },
    // 是否有清空按钮操作
    clearable: { type: Boolean, default: true },
    // 是否允许创建新条目进行选择
    allowCreate: { type: Boolean, default: false },
    // 是否多选
    multiple: { type: Boolean, default: false },
    placeholder: { type: String, default: '请输入关键词' },
    // 远程搜索间隔时间
    wait: { type: Number, default: 200 },
    // 要取来显示到select框内的字段名
    valueKey: { type: String, default: 'code5' },
    labelKey: { type: String, default: 'id' },
    disabledKey: { type: [String, Array], default: () => [] },
    // 固定的select的option
    fixedOptions: { type: Array, default: null },
    // 构造option的头部
    headers: { type: Array, default: () => [] },
    fields: { type: Array, default: () => [] },
    // 是否显示options 头部提示文字
    tips: { type: Boolean, default: true },
    // 是否显示options 头部提示文字
    defaultDisplay: { type: [String, Array], default: '' },
    size: { type: String, default: 'mini' },
    newMultiple: { type: Boolean, default: false }
  },
  data() {
    return {
      modelValue: '',
      loading: false,
      options: [],
      map: {},
      optionsMap: {}
    }
  },
  watch: {
    value: {
      handler(newV, oldV) {
        this.modelValue = newV
        this.initOptions()
      },
      immediate: true
      // handler(newV, oldV) {
      //  this.modelValue = newV
      //  // 构造初始化的options
      //  if (this.options.length === 0) {
      //    if (this.multiple) {
      //      // 多选的 数组传入
   //          if (this.defaultDisplay.length > 0 && newV.length > 0) {
      //        let arr = []
   //            if (this.newMultiple) {
   //              // this.options = this.defaultDisplay || []
   //              let arr = [...this.defaultDisplay]
   //              let optionsMap = {}
   //              arr.forEach(ex => {
   //                this.options[this.valueKey] = ex
   //                optionsMap[ex[this.valueKey]] = ex
   //              })
   //              this.optionsMap = optionsMap
   //            } else {
   //              newV.forEach((v, index) => {
   //                arr.push({
   //                  [this.valueKey]: v,
   //                  [this.labelKey]: this.defaultDisplay[index]
   //                })
   //              })
   //              this.options = arr
   //            }
      //      }
      //    } else {
      //      // 单选的构造
      //      if (this.defaultDisplay && newV) {
      //        this.options = [{
      //          [this.valueKey]: this.value,
      //          [this.labelKey]: this.defaultDisplay
      //        }]
      //      }
      //    }
      //  }
      // },
      // immediate: true
    }
    // defaultDisplay: {
    //  handler(newV, oldV) {
    //    if (this.value && newV && this.options.length == 0) {
    //      this.options = [{
    //        [this.valueKey]: this.value,
    //        [this.labelKey]: newV
    //      }]
    //    }
    //  },
    //  immediate: true
    // }
  },
  created() {
    this.modelValue = this.value
  },
  methods: {
    initOptions() {
      // 构造初始化的options 基本限定编辑回显的时候 只会回显一次
      if (this.options.length === 0 && this.value) {
         // 多选的 数组传入
        if (Array.isArray(this.defaultDisplay) && this.defaultDisplay.length > 0) {
          this.options = this.defaultDisplay
          this.defaultDisplay.map(item => {
            this.optionsMap[item[this.valueKey]] = item
          })
        } else if (this.value && typeof this.defaultDisplay === 'string') { // 单选
          this.options = [{
            [this.valueKey]: this.value,
            [this.labelKey]: this.defaultDisplay
          }]
          this.optionsMap[this.value] = {
            [this.valueKey]: this.value,
            [this.labelKey]: this.defaultDisplay
          }
        }
      }
    },
    remoteMethod(keyword) {
      this.fetchList(keyword)
    },
    fetchList(keyword) {
      keyword = keyword.trim()
      const fn = this.method
      if (!fn) {
        console.error('请先设置请求数据的接口')
        return
      }
      this.loading = true
      fn({ keyword: keyword }).then(res => {
        let arr = []
        if (res.content.list === undefined) {
          arr = res.content || []
        } else {
          arr = res.content.list || []
        }
        arr.forEach(ex => {
          this.optionsMap[ex[this.valueKey]] = ex
        })
        if (this.multiple) {
          this.modelValue.map(id => {
            let isSome = arr.some(ex => {
              if (ex[this.valueKey] === id) {
                return true
              }
            })
            if (!isSome && this.optionsMap[id]) {
              arr.push(this.optionsMap[id])
            }
          })
        } else {
          const optionsMap = {}
          arr.forEach(ex => {
            optionsMap[ex[this.valueKey]] = ex
          })
          this.optionsMap = optionsMap
        }
        this.options = arr
        this.loading = false
      })
    },
    change(val) {
      if (!this.multiple) {
        if (val === 'ALL') {
          this.$emit('input', 'ALL')
        } else {
          const item = this.optionsMap && this.optionsMap[this.modelValue]
          this.$emit('input', this.modelValue)
          // 在查询时候使用selected去触发list，
          // v-model的value还未更新到表单数据上
          // 需等待next tick
          this.$nextTick(() => {
            this.$emit('selected', item || {})
          })
        }
      } else {
        const items = []
        this.modelValue.forEach(ex => {
          items.push(this.optionsMap[ex])
        })
        this.$emit('input', this.modelValue)
        this.$nextTick(() => {
          this.$emit('selected', items)
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.cargo-advance-select {
  width: 100%;
}

.view-content {
  display: flex;
  justify-content: space-between;
  min-width: 400px !important;
  max-width: 900px !important;
}

.tip-count-options {
  padding-top: 10px;
  padding-left: 18px;
  font-size: 14px;
  color: rgb(192, 196, 204);
  height: 36px;
  top: 0px;
  background: rgb(252, 252, 252);
  z-index: 1000;
}

.item {
  float: left;
  flex: 1;
  padding: 0 5px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
