import { asyncRoutes, constantRoutes, asyncRoutesMap } from '@/router'
import {
  saveFavoriteApi,
  removeFavoriteApi,
  getFavoriteListApi,
  getLastestVisitedListApi,
  getUserMenuApi
} from '@/api/user'
import Layout from '@/layout'

// 记录全部子路由 - 去重使用
let genalChildRoutes = []

// 两个总路由变量
let realPathRoutes = []

/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}

function setMenuConstract(menuList) {
  realPathRoutes = []
  menuList.map(mainMenu => {
    const routerObj = {
      name: '',
      path: '',
      component: null,
      alwaysShow: false,
      meta: {},
      children: []
    }
    if (asyncRoutesMap[mainMenu.code]) {
      const _asyncRoute = asyncRoutesMap[mainMenu.code]
      routerObj.component = _asyncRoute.component
      routerObj.name = checkExsit(_asyncRoute.name) || mainMenu.name
      routerObj.path = mainMenu.path
      routerObj.alwaysShow = checkExsit(_asyncRoute.alwaysShow) || false
      routerObj.meta = {
        icon: _asyncRoute.meta.icon || mainMenu.icon,
        title: mainMenu.title,
        status: null,
        noCache: null
      }
    } else {
      routerObj.component = Layout
      routerObj.name = `commonLayout_${mainMenu.id}`
      routerObj.path = checkExsit(mainMenu.path) || '/'
      routerObj.alwaysShow = true
      routerObj.meta = {
        icon: mainMenu.icon,
        title: mainMenu.title,
        status: null,
        noCache: null
      }
    }
    if (mainMenu.children && mainMenu.children.length > 0) { // 设置二级菜单
      setSubMenu(mainMenu.children, routerObj.children)
    }
    realPathRoutes.push(routerObj)
  })
}

function setSubMenu(menuList, containerArr) {
  menuList.map(subMenu => {
    if (asyncRoutesMap[subMenu.code]) {
      const _asyncRouter = asyncRoutesMap[subMenu.code]
      const routerObj = {
        name: _asyncRouter.name,
        path: subMenu.path,
        component: _asyncRouter.component,
        hidden: checkExsit(_asyncRouter.hidden) || false,
        children: [],
        meta: {
          title: subMenu.title,
          icon: '',
          noCache: _asyncRouter.meta ? checkExsit(_asyncRouter.meta.noCache) : true,
          affix: _asyncRouter.meta ? checkExsit(_asyncRouter.meta.affix) : false
        }
      }

      containerArr.push(routerObj)

      if (_asyncRouter.children && _asyncRouter.children.length > 0) {
        // setDetailMenu(_asyncRouter.children, routerObj.children)
        setDetailMenu(_asyncRouter.children, containerArr)
      }
    }
  })
}

function setDetailMenu(menuList, containerArr) {
  menuList.map(subMenu => {
    if (asyncRoutesMap[subMenu]) {
      const _asyncRouter = asyncRoutesMap[subMenu]
      const routerObj = {
        name: _asyncRouter.name,
        path: _asyncRouter.path,
        component: _asyncRouter.component,
        hidden: checkExsit(_asyncRouter.hidden) || false,
        meta: _asyncRouter.meta,
        children: []
      }

      containerArr.push(routerObj)
    }
  })
}

function checkExsit(prop) {
  if (prop !== null && typeof prop !== 'undefined') {
    return prop
  }
  return false
}

const state = {
  routes: [],
  addRoutes: [],
  favourRoutes: [],
  lastestRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = routes.concat(constantRoutes)
  },
  SET_FAVOURROUTES: (state, newFavorite) => { // 获取已收藏的菜单且倒序
    state.favourRoutes = newFavorite
  },
  SET_LASTESTROUTES: (state, lastVisited) => { // 最近使用按时间倒序再取前5
    state.lastestRoutes = lastVisited
  }
}

const actions = {
  setTopMenu({ dispatch, commit, state }, menus) {
    const accessRoutes = []
    return new Promise(resolve => {
      setMenuConstract(menus)
      // console.log('realPathRoutes', JSON.stringify(realPathRoutes))
      commit('SET_ROUTES', realPathRoutes)
      dispatch('user/refreshUserMenu', menus, { root: true })
      resolve({ accessRoutes, realPathRoutes })
    })
  },
  // 点击收藏某个菜单
  favourMenu({ dispatch, commit }, menuObj) {
    return new Promise(async (resolve, reject) => {
      let res = null
      if (menuObj.favorite) {
        res = await removeFavoriteApi(menuObj.id)
        if (res && res.ok) {
          dispatch('refreshMenu')
          dispatch('getFavoriteRoutes')
          resolve('ok')
        } else {
          reject('err')
        }
      } else {
        res = await saveFavoriteApi(menuObj.id)
        if (res && res.ok) {
          dispatch('refreshMenu')
          dispatch('getFavoriteRoutes')
          resolve('ok')
        } else {
          reject('err')
        }
      }
    })
  },
  async refreshMenu({ dispatch }) {
    const res = await getUserMenuApi()
    if (res && res.ok) {
      dispatch('setTopMenu', res.content)
    }
  },
  async getFavoriteRoutes({ commit }) {
    const res = await getFavoriteListApi()
    if (res && res.ok) {
      // const favoriteList = generateRoutes(res.content)
      commit('SET_FAVOURROUTES', [])
    }
  },
  async getLatestRoutes({ commit }) {
    const res = await getLastestVisitedListApi()
    if (res && res.ok) {
      // const lastestList = generateRoutes(res.content)
      commit('SET_LASTESTROUTES', [])
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
