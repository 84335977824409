export default {
  createTagTitle: '新增订舱申请',
  editTagTitle: '订舱单详情',
  step: '步骤',
  desc1: '选择航线船期',
  desc2: '提供订舱明细',
  polCity: '出发城市',
  polPlaceholder: '请选择起运地',
  protocol: '运输条款',
  podCity: '目的城市',
  podPlaceholder: '请选择目的地',
  vesselAndVoyage: '船名/航次/预计离港',
  vesselPlaceholder: '请选择船期',
  containerType: '货物类型',
  nextStep: '下一步',
  vesselValidate: '请输入船期',
  polValidate: '请选择起运港',
  podValidate: '请选择目的港',
  memberIdValidate: '请选择委托客户',
  infoError: '获取详情信息失败了',
  vesselError: '船期信息有误，请重新选择'
}
