export default {
	soNo: '订舱号',
	soNoPlaceholder: '请输入订舱号',
	blNoPlaceholder: '请输入提单号',
	vesselNamePlaceholder: '请输入船名',
	voyageNoPlaceholder: '请输入航次',
	serialNoPlaceholder: '请输入流水号',
	containerStatus: '集装箱状态',
  businessStatus: '业务状态',
  cartonCondition: '装箱状态',
	normal: '正常',
	clearance: '已退关',
	removed: '已删除',
	sealNoPlaceholder: '请输入流水号',
	trailerStatus: '拖车状态',
	VGMStatus: 'VGM状态',
	unDeclare: '未申报',
	declared: '已申报',
	containerPool: '箱号池',
	containerVerifiedError: '部分柜子的提单补料尚未通过审核，请确认后重试',
	containerStatusError: '部分柜子为申请放单状态，请确认后重试',
	containerRemovedError: '部分柜子已删除，请确认后重试',
	containerClearanceError: '部分柜子已退关，请确认后重试',
	memberDifferentError: '部分柜子属于不同客户、船期或卸货港，请确认后重试',
	statusDifferentError: '请选择相同状态的提单',
	selectedNo: '已选箱号',
	mergeToBl: '并入提单',
	createBl: '创建新提单',
	unexsitBLNo: '提单号不存在',
	sameOriginError: '所选箱子的客户、船期或目的港与此提单不符',
	checkStatusError: '部分柜子的提单补料尚未通过审核或状态为申请放单，请确认后重试',
	mergeNotAllow: '不允许并入申请放单的提单',
	splitNotAllow: '只能选择待制单、或待对单的提单进行拆合',
	blNoRequired: '提单号为必填项',
	blNoExsit: '提单号已存在',
	payToUnpay: '所选提单已确认付款，并入未确认的集装箱需提示客户重新提交放单申请，是否继续？',
	amountChanged: '原提单箱量发生变更，需重新签发提单，是否继续？',
	newAmountChanged: '当前提单箱量变更，需重新签发提单，是否继续？',
	expiredOperation: '您长时间没有操作，刷新后重新操作',
	overTime: '超时',
	soList: '订舱清单',
	VGMList: 'VGM清单',
	checkBlList: '提单补料',
  preparationBl: '提单制作',
	splitOrMergeBL: '提单拆合',
	removeOrClearance: '删除/退关',
	changeOrder: '改单',
	changeOwner: '变更箱属',
	changeContainerNo: '变更箱号',
	changeSailing: '变更船期',
	changePol: '变更目的港',
	emptyData: '无数据',
	importContent: '导入内容',
	pickUpData: '提箱数据',
	returnData: '还箱数据',
	downloadTemplate: '模板下载',
	originalVessel: '原船名',
	originalVoyage: '原航次',
	sailingLabel: '船名/航次/预计离港',
	changeOwnerNotice1: '注意：1、箱属变更将自动修改集装箱的提箱点信息，请谨慎操作；',
	changeOwnerNotice2: '2、箱属变更将变更整个SO下集装箱的箱属，不支持单一集装箱箱属变更。',
	operationTactics: '配置策略',
	socFlagOneYard: '单一堆场提箱',
	socFlagMultipleYard: '跨堆场提箱',
	socFlagCustom: '自定义提箱',
	pickUpAddress: '提箱点',
	numberFromContainerManager: '箱管放箱数',
	released: '已放舱',
	leftContainer: '剩余箱量',
	allotPickupAddress: '分配提箱点',
	currentSOAllotNumber: '当前SO配箱数',
	containerNoConflict: '录入的箱号同下列箱号冲突，请同步修改',
	targetCity: '目的城市',
	containerTrail: '集装箱轨迹',
	startPort: '起运港',
	transformPort: '中转港',
	remark: '备注',
	trailerStatusName: '拖车作业状态',
	returnAddress: '还箱点',
	truckName: '预约车队',
	eirId: 'EIR-ID',
	pickUpTime: '提吉时间',
	fullInTime: '还重时间',
	unloadingTimeFormat: '卸船时间',
	liftingTimeFormat: '提重时间',
	returnTime: '还吉时间',
	freeDays: '免柜天数',
	overdueDays: '逾期天数',
	containerNoError: '箱号格式不正确',
	containerInfoEmptyError: '请至少勾选一条集装箱信息',
	differentNumberFromOwner: '配箱数同客户订舱数不一致，请确认后再提交',
	updateSelectEmpty: '请勾选单条集装箱信息进行变更',
	existSelectedError: '请选择已存在箱号的条目进行箱号变更',
	sameSailingCheckedError: '请勾选同一船期下的集装箱信息进行变更',
  samePolCheckedError: '请选择同一装货港的集装箱进行提箱点变更',
  sameExitheckedError: '所选集装箱已作业或已退关，无法变更',
  theSelectedContainerCannotBeASOCContainer: '所选集装箱不能是SOC箱',
  nonSailingUsedError: '后续无可用船期，或后续船期不包含所选集装箱的装卸货港',
	changeSailingSuccess: '变更船期成功',
	containerExisted: '当前已存在',
	differentSailingError: '请先检索同一水船的数据进行下载！',
	printDataEmptyError: '请修改查询条件后，再次操作',
	uploadFileEmptyError: '请选择一个文件',
	uploadFileSuccess: '文件上传成功',
	apiError: '接口错误',
	submitDataEmptyError: '请至少选择一条记录',
	blStatusError: '请选择待补料的箱子进行操作',
	blOnDeletedError: '已删除的箱子不能进行提单补料',
	blOnClearanceError: '已退关的箱子不能进行提单补料',
	checkSameOriginError: '所选条目的客户、船名航次、装货港、卸货港、运输条款不一致，请确认后再操作',
	checkCOCNoError: '所选部分箱子箱号未知，无法进行补料',
	checkDelStatusError: '所选集装箱已作业，不可删除，请在后期按照退关处理',
	checkBLStatusDeletedSuccess: '柜子的订舱信息有所变更，删除后请留意重新发送订舱确认函',
	checkBlStatusDeleteConfirm: '部分柜子的已开始补料操作，删除柜子将变更现有的提单信息，确认删除？',
	confirmDelete: '确定删除',
	containerNoEmptyError: '当前集装箱无箱号，无法标记退关',
	blStatusReleased: '所选集装箱已申请放单/确认放单/签发，无法标记退关',
	clearanceSuccess: '退关成功',
	declaredAMSError: '集装箱已申报AMS，如果标记退关，将自动变更或直接删除AMS申报信息，是否继续？',
	clearanceWarning: '退关将导致所关联的提单箱量和件毛体总数变更，是否继续？',
	changePickUpPoint: '变更提箱点',
	shipName: '船名',
	voyage: '航次',
	affectedContainerVolume: '影响箱量',
	changeSucceeded: '变更成功',
	synchronousChangeOfSoShippingDateInformation: '同步变更SO船期信息',
  synchronousChangeOfSoDestinationInformation: '同步变更SO目的港信息',
  packingInfoImport: '装箱信息导入',
  unboxed: '未装箱',
  boxed: '已装箱'

}
