export default {
  un_clear: '待核销',
  un_offset_clear: '待对冲核销',
  all_clear: '全部核销',
  all_offset_clear: '全部对冲核销',
  apply_clear_no: '申请核销编号',
  clear_no: '核销编号',
  apply_clear_total_amount: '申请核销合计',
  invoice_no: '发票号',
  business_type_name: '业务类型',
  settlement_company_name: '结算单位',
  currency_value: '币种金额',
  current_apply_currency_value: '本次申请币种金额',
  bill_no: '账单号'
}
