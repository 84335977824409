var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "right-menu" },
    [
      _vm.device !== "mobile"
        ? [
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: _vm.$t("common.navbar.messageCenter"),
                  placement: "bottom",
                },
              },
              [_c("message", { staticClass: "screenfull right-menu-item" })],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: _vm.$t("common.navbar.accountInfo"),
                  placement: "bottom",
                },
              },
              [
                _c("user-center", {
                  staticClass: "screenfull right-menu-item",
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tooltip",
              {
                attrs: {
                  content: _vm.$t("common.navbar.screenfull"),
                  placement: "bottom",
                },
              },
              [_c("screenfull", { staticClass: "screenfull right-menu-item" })],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }