export default {

  orderNumber: '订单编号',
  pol: '起运港',
  pol2: '装货港',
  requiredPol: '请选择起运港',
  pod: '卸货港',
  requiredPod: '请选择目的港',
  pod2: '目的港',
  requester: '委托单位',
  requesterHold: '请选择委托单位',
  settlementUnit: '结算单位',
  airline: '航空公司',
  airline2: '航司',
  requiredAirline: "请选择航空公司",
  orderState: '订单状态',
  all: '全部',
  toBeQuoted: '待报价',
  toBeConfirmed: "待确认",
  refusalOfBooking: "拒绝订舱",
  forCargo: "待配舱",
  cargoPlan: "未配舱",
  toBeBooked: '待外配',
  toBePrepared: '待入仓',
  toTakeOff: '待起飞',
  inTransit: "运输中",
  toArrivalAtPort: "已到港",
  toFinished: '已完结',
  finished: '完结',
  finishedBeCareful: '提交后主票和小票的业务信息将锁定不允许修改，是否还进行提交操作？',
  toCanceled: '已取消',
  flightNo: '航班号',
  requiredFlightNo: "请输入航班号",
  createByName: '创建人',
  feesReceivable: '应收费用',
  toMaintained: '待维护',
  toBeenMaintained: '已维护',
  toConfirmed: '已确认',
  toAdjusted: '调整中',
  feesPayable: '应付费用',
  newOrders: '新增订单',
  toMatch: '改配',
  takeOff: '起飞',
  arrivalAtPort: '到港',
  ata: "实际到港时间",
  ataSuccessfulEnd: "到港成功！",
  requestAta: "请选择实际到港时间",
  returnList: '返回列表',
  save: '保存',
  cancel: '取消',
  determine: '确定',
  remarks: '订舱备注',
  edit: '编辑',
  booking: '订舱',
  bookingConfirmation: "订舱确认",
  bookingConfirmationSuccessfulEnd: "订舱确认成功！",
  bookingTip: '订舱提示',
  bookingTip1: '该订单“',
  bookingTip2: "”已通过审核，是否进行订舱并输出订舱文件？",
  view: '查看',
  orderNo: '订单号',
  productLine: '产品航线',
  productName: "产品名称",
  blModeDesc: '操作模式',
  requiredBlMode: "请选择操作模式",
  straightSingle: '直单',
  noStraightSingle: '非直单',
  mblNo: 'MAWB NO',
  requiredMblNo: "请输入MAWB NO",
  mblNoName: '主单号',
  hblNo: 'HAWB NO',
  hblNoName: '分单号',
  orderStateDesc: '订单状态',
  customerName: '委托单位',
  customerName2: '客户名称',
  airlineName: '航空公司',
  actualQuantity: '件数(预计/实际)',
  actualGrossWeight: '毛重(预计/实际)',
  actualVolume: '体积(预计/实际)',
  etd: '航班日期',
  requiredEtd: "请选择航班日期",
  etd2: '预计航班时间',
  requiredEtd2: "请输入预计航班日期",
  atd: '实际航班日期',
  atd2: '实际开航时间',
  requiredAtd: "请选择实际开航时间",
  atdSuccessfulEnd: "起飞成功！",
  createTime: '创建时间',
  updateByName: '更新人',
  updateTime: '更新时间',
  operating: '操作',
  PleaseSelectARowFirst: '请先选择一行！',
  successfulEnd: '完结成功！',
  bookingSuccessfulEnd: '订舱成功！',
  matchBatchErrorTipByStatus: '已取消或者已完结状态，不能进行批量改配！',
  matchBatchErrorTipByPol2AndPodAndFlightNo: '起运港、目的港、航班号，不一致或者ETD已锁定，不能进行批量改配！',
  matchBatchErrorTipByPol2AndPod: '起运港、目的港不一致，不能进行批量操作！',
  cancellationOfOrderEnd: "取消订单成功",
  cancellationOfOrderTip: "取消后不可恢复，是否继续?",
  copy: '复制',
  basicInformation: '基础信息',
  orderModify: '订单调整',
  customerLevel: '客户等级',
  settlementMethod: '结算方式',
  customerContact: '客户联系人',
  phone: '联系电话',
  pleaseEnterArrivalContactTel: "请输入联系电话",
  email: '联系邮箱',
  contactEmailPlaceholder: '请输入联系邮箱',
  joeBookPrices: '托书价格',
  cargoInformation: '货物信息',
  cargoType: '货物类型',
  generalCargo: "普货",
  dangerousGoods: "危险品",
  refrigeratedGoods: "冷藏品",
  overweightCargo: "超标货",
  hangGarment: "挂衣",
  requiredCargoType: "请选择货物类型",
  orderQuantity: "委托件数",
  requiredOrderQuantity: "请输入委托件数",
  orderGrossWeight: "委托毛重",
  requiredOrderGrossWeight: "请输入委托毛重",
  orderVolume: "委托体积",
  requiredOrderVolume: "请输入委托体积",
  orderAndActualQuantity: '件数(委/实)',
  packType: '包装',
  packType2: "包装类型",
  orderAndActualVolume: "毛重(委/实)/KGS",
  orderAndActualVolume2: '体积(委/实)/CBM',
  orderAndActualChargeableWeight: "计费重量(委/实)/M/T",
  cargoEnName: "货物英文品名",
  cargoCnName: "货物中文品名",
  palletSizes: "托盘尺寸",
  shippingSpecialist: "舱位信息",
  shippingSpaceTypeDesc: "舱位类型",
  requiredShippingSpaceType: "请选择舱位类型",
  ownShippingSpace: "自有舱位",
  agentShippingSpace: "代理舱位",
  productAirlineName: "产品航线",
  requiredProductAirLine: "请选择产品航线",
  delivery: '收货地',
  erd: "货好日期",
  erd2: "货好时间",
  bookingAgentPort: '订舱代理',
  requiredBookingAgentPort: "请选择订舱代理",
  loadingAgentName: '进仓代理',
  eta: '到港日期',
  eta2: "预计到港时间",
  requiredEta: "请选择预计到港时间",
  destinationAgentName: '目的港代理',
  receipt: '交货地',
  requiredReceiptCode: "请选择交货地",
  inbound: "入仓",
  inboundSuccessfulEnd: "入仓成功！",
  inboundNo: '入仓编号',
  inboundDeadline: '截止入仓时间',
  sendingAndReceivingMessages: '收发通信息',
  orderShipperAddress: '委托托运人',
  orderConsigneeAddress: '委托收货人',
  orderNotifyAddress: '委托通知人',
  order: '订单',
  cost: '费用',
  receivableExpenseConfirmationDocument: '应收费用确认单',
  editReceivableExpenseConfirmationDocument: "编辑应收费用确认单",
  addReceivableExpenseConfirmationDocument: "新增应收费用确认单",
  payableExpenseConfirmation: '应付费用确认单',
  editPayableExpenseConfirmation: '编辑应付费用确认单',
  addPayableExpenseConfirmation: '新增应付费用确认单',
  electronicDocuments: '电子文件',
  submit: '提交',
  cancellationOfOrder: '取消订单',
  checkTicket: '查看主票',
  export: '导出Excel',
  entityNo: '业务编号',
  billNumber: '账单编号',
  feeName: '费用名称',
  billingMethod: '计费方式',
  currency: '币种',
  unitPrice: '单价',
  taxRate: '税率',
  quantity: '数量',
  taxAmount: '税额',
  amountExcludingTax: '不含税金额',
  expenseAmount: '费用金额',
  supplierName: '供应商名称',
  clearingHouse: '结算公司',
  ownedSales: '所属销售',
  salesperson: '销售人员',
  actualGrossWeight2: '实际毛重',
  actualVolume2: '实际体积',
  actualNumber: "实际件数",
  actualChargeableWeight: '实际计费重',
  actualChargeableWeight2: '计费重',
  billStatus: '账单状态',
  invoiceStateName: '开票状态',
  writeOffStatus: '核销状态',
  allRecorded: '全部入账',
  selectPosting: '选择入账',
  totalPayable: '应付合计',
  pleaseFillInAtLeastOneSearchCondition: '请至少填写一项检索条件',
  theSearchConditionMustBeToBePosted: '检索条件必须为待入账',
  totalReceivable: '应收合计',
  expenseDetailsFiltering: '费用明细过滤',
  placeholder: '请选择',
  next: '下一步',
  prev: '上一步',
  submittedSuccessfully: '提交成功',
  expenseList: '费用清单',
  expenseHasBeenAssociatedWithBill: '部分费用已关联账单，请选择未入账的费用入账',
  pleaseSelectTheSameCustomer: '所选费用的客户和结算单位不一致，请选择相同客户和相同结算单位的费用入账',
  pleaseSelectTheSameCustomerPay: '所选费用结算单位不一致，请选择相同结算单位的费用入账',
  inconsistentExpenseSettlementMethods: '所选费用的结算方式不一致，请选择结算方式一致的费用入账',
  theTicketSettlementFeeHasBeenConfirmed: '票结费用已经过确认，无需生成账单',
  settlementUnitType: '结算单位类型',
  customer: '客户',
  destinationAgent: '目的港代理',
  destination: '目的地',
  soakRate: '分泡比例',
  serialNumber: '流水号',
  chargeNo: '费用ID',
  placeholderTip: '快捷检索: 主单号',
  seachMore: '更多条件',
  chargeAdjustDes: '是否确认调整？',
  chargeAdjustSuccess: '费用确认单调整成功',
  successSaved: '保存成功',
  updateTime2: "修改时间",
  updateByName2: "修改人",
  clear: "清除",
  print: "打印",
  totalAmount: "金额合计：",
  combinedProfits: "利润合计：",
  customerInformation: '客户信息',
  stowageInformation: "运输信息",
  makingCargoPlan: "配舱",
  makingCargoPlanSuccess: "配舱成功",
  newSuccess: "新增成功！",
  cancelTip: "尚未保存的信息将会丢失，确认关闭？",
  tips: '提示',
  customerType: "客户类型",
  cancellationOfOrderWarm: "该订单存在费用项，不允许进行取消操作",
  mergeWarm: "请先选择两行以上进行合并！",
  mergeWarm2: "请选择操作模式为“非直单”业务进行合并生成主单操作！",
  mergeWarm3: "请选择相同“信息”的订单进行合并生成主单操作！",
  mergeSuccess: "合并成功！",
  peer: "同行",
  directCustomer: "直客",
  serviceInformation: "业务信息",
  actual: "实际",
  predict: "预计",
  updateSuccessfully: "更新成功！",
  leastOneLineToExport: "请至少选择一行进行导出",
  addExpenseConfirmation: '添加费用确认单',
  adjustSuccess: '调整成功',
  delete: '删除',
  adjustConfirmation: '调整确认',
  adjust: "调整",
  paymentACSet: '付款账套',
  moveConfirmationWarm: '请选择以下费用确认单号，该费用将迁移至该费用确认单，或选择【新增费用确认单】，该费用迁移至新增的费用确认单！',
  move: "迁移",
  confirmationIdTo: "费用确认单号",
  newAddConfirm: "新增费用确认单",
  isDeleteFn: "是否确认删除？",
  successfullyDeleted: '删除成功',
  moveSuccess: '迁移成功',
  requireReceiveEmails: "接收邮箱必填！",
  nationalEncouragementscholarship: "国内空运代理",
  customsBroker: "报关行",
  warehouseDistributionServiceProvider: "仓配服务商",
  truckCompany: "卡车公司",
  contacts: '联系人',
  contactPhone: '联系电话',
  existMblNo: "主单号重复",
  existMblNo2Error: "主单号后半段必须填写8位",
  length: "长(CM)",
  width: "宽(CM)",
  height: "高(CM)",
  requiredWeightVolumeNumber: "请先填写件数、毛重、体积！",
  weight: '毛重',
  weightKGS: '毛重(KGS)',
  batchMatchSuccessfully: "批量改配成功！",
  matchSuccessfully: "改配成功！",
  shippingSpaceToMatch: "舱位改配",
  batchShippingSpaceToMatch: "批量改配",
  new: '新建',
  productModel: "产品模式",
  requiredProductModel: "请选择产品模式",
  productAgreement: "产品协议",
  requiredProductAgreement: "请选择产品协议",
  productLine2: "产品线路",
  cardTransferService: "卡转服务",
  valueAddedServices: "增值服务",
  productIsValid: "产品有效期",
  requiredProductIsValid: "请选择产品有效期",
  status: "状态",
  publish: "发布",
  publishTip: "该信息确认无误?",
  publishSuccessfully: "发布成功",
  cancellation: "作废",
  cancellationTip: "作废后不可恢复，是否继续?",
  cancellationfully: "作废成功",
  outsideCode: "外采代码",
  requiredOutsideCode: "请输入外采代码",
  productRequirement: "产品要求",
  airlineAssessmentCycle: "航司考核周期",
  requiredAirlineAssessmentCycle: "请选择航司考核周期",
  singleAssessmentGoods: "单班考核货量",
  theoreticalVolume: "理论装载体积",
  pleaseEnter: '请输入',
  estimatedNumber: "预计件数",
  estimatedGrossWeight: "预计毛重",
  estimatedVolume: "预计体积",
  entrustedChargingWeight: "委托计费重",
  putTank: "放舱",
  putTankSuccess: "放舱成功",
  MON: '周一',
  TUE: '周二',
  WED: '周三',
  THUR: '周四',
  FRI: '周五',
  SAT: '周六',
  SUN: '周日',
  next7Days: "未来7天",
  thisWeek: "本周",
  firstHalfOfAMonth: "上半月",
  secondHalfOfAMonth: "下半月",
  currentMonth: "本月",
  assessmentBasisWeight: "考核基重",
  surplusAssessWeight: "剩余装载基重",
  loadableVolume: "可装载体积",
  estimatedResidualBaseWeight: "预估剩余基重",
  estimatedResidualVolume: "预估剩余体积",
  actualResidualBaseWeight: "实装剩余基重",
  actualResidualVolume: "实装剩余体积",
  actualBaseWeightRate: "实装基重率",
  actualVolumeUtilization: "实装体积利用率",
  exportTheLoadingPlan: "导出装板计划",
  theoryOfVolume: "理论体积",
  baseWeightUtilization: "基重利用率",
  volumeUtilizationRate: "体积利用率",
  routeInformation: "航线信息",
  courseDetails: "航线明细",
  packingPlateRemark: "装板备注",
  cargoSize: "货物尺寸",
  operationalStaff: "运营人员",
  routePlan: "航班计划",
  requiredRoutePlan: "请选择航班计划",
  productNote: "产品备注",
  staging: "暂存",
  week: "星期",
  statePlan: "计划状态",
  flightScheduling: "航班计划",
  flightStatus: "航班状态",
  appointedDay: "指定日期",
  adjustPlan: "调整计划",
  cancelPlan: "取消计划",
  newFlightSchedule: "新增航班计划",
  cancelPlanWarm: "取消后该航班计划无法再给业务引用，确认是否还继续取消！",
  cancelPlanBusinessWarm: "该航班计划已有业务引用，请将该计划的业务移除后再进行取消！",
  planIn: "计划月份",
  date: "日期",
  plateCut: "板型",
  remark: '备注',
  planCreator: "计划创建人",
  assessmentOfASingleClass: "单班考核",
  assessmentOfWeeks: "周考核",
  halfTheInspection: "半月考核",
  onTheInspection: "月考核",
  quarterlyReview: "季度考核",
  allFlights: "全年航班",
  quarterOfFlight: "季度航班",
  aSingleFlight: "单个航班",
  fixedPrice: "固定价格",
  floatingPrices: "浮动价格",
  backToBackProtocol: "背靠背协议",
  charteredAirplane: "包机",
  wrapperSheet: "包板",
  packageQuantity: "包量",
  foreignPurchasing: "外采",
  yes: "是",
  no: "否",
  toBeReleased: "待发布",
  havePublished: "已发布",
  haveBeenCancelled: "已作废",
  inTheWorks: "计划中",
  inTheFreight: "揽货中",
  flightCancellation: "航班取消",
  hasBeenTakeOff: "已起飞",
  noNewContent: "无新增内容，无需保存",
  cancelSuccess: '取消成功',
  etdBusinessLockState: "存在ETD已锁定数据，不能进行批量改配！",
  BSAShippingSpaceStatus: "BSA舱位情况：",
  conditionOfStowageRearCabin: "配载后舱位情况",
  exportSuccess: "导出成功",
  exportTaskSuccess: "导出操作成功，请稍后去任务列表查看导出结果！",
  confirmTime: "提交时间",
  confirmByInfo: "提交人",
  orderInformation: "订单信息",
  orderType: "订单类型",
  smallBusiness: "小包业务",
  matchsOutside: "外配",
  exportWarmETD: "导出功能必须添加【ETD】范围检索",
  charge: "费用清单",
  business: "业务清单",
  productPersonnel: "产品人员",
  requiredProductPersonnel: "请选择产品人员",
  settlementCompanyContractLifeWarn: "请联系销售人员补录该客户对应合同信息后，再进行费用创建",
  airFreight: "空运费",
  documentCharge: "文件费",
  CustomsTransmissionServiceCharge: "海关数据传输服务费",
  requesterModify: "委托调整",
  handlingCode: "操作代码",
  palletSide: "板内板外",
  inThePlate: "板内",
  offBoard: "板外",
  planAirlineWarm: "选择的航班日期与客户委托不一致，请确认",
  airLineWarm: "选择的产品航线与客户委托不一致，请确认",
  invoicingApplicationStatus: "开票申请状态",
  drawer: "开单人",
  drawerTime: "开单时间",
  exitCustoms: '退关',
  exitCustoms2: '已退关',
  haveInHand: '进行中',
  completed: '已完成',
  exitCustomsTip: "退关后不可恢复，是否继续?",
  exitCustomsEnd: "退关订单成功",
  transitPort: '中转港',
  pleaseSelectData: '请选择数据',
  applyForInvoice: '申请开票',
  productCosts: '产品成本',
  orderConfirmation: "订单确认",
  associatedCostPrice: "关联成本价",
  informationIsNotCompletePleaseImprove: "订单信息不全，请完善",
  addWQVWarming: "出运计划的件毛体，小于等于所有订单汇总的件毛体时，才能新增操作",
  saveWQVWarming: "出运计划的件毛体，大于所有订单汇总的件毛体时，无法保存",
  planNo: "计划单号",
  timeliness: "服务时效",
  addOrder: "增加订单",
  packageDeliveryPlan: "小包出运计划",
  ShippingState: "出运状态",
  portionCargoPlan: "部分配舱",
  planAdjustment: "计划调整",
  accomplishCargoPlan: "已配舱",
  delBagPlanWarming: "只允许删除订单状态为【待配舱】的订单业务",
  attachmentInformation: "附件信息",
  fileTypeError: '文件格式错误！',
  fileCannotExceed: '文件不能超过',
  numberFilesCannotExceed: '文件数量不能超过',
  fileUploadedSuccessfully: '文件上传成功',
  uploadEntrustment: '上传托书',
  uploadEntrustmentWarning: "请上传托书",
  headPartOfTheBubble: "头程分泡",
  headPartOfTheBubble2: "头程分泡(产品)",
  tailSeparationBubble: "尾程分泡",
  tailSeparationBubble2: "尾程分泡(产品)",
  orderOperationLog: "订单操作日志",
  action: "动作",
  operationTime: "操作时间",
  operator: "操作人",
  modifyContent: "修改内容",
  batchQuotation: "批量报价",
  customerMandateInformation: "客户委托信息",
  typeRatio: "货型比例",
  clientOrder: "客户托书",
  quotationInformation: "报价信息",
  customerSplitBubbleHead: "客户分泡(头程)",
  customerSplitBubbleTrail: "客户分泡(尾程)",
  servicesAvailable: "服务项",
  isSpecialPrice: "是否特价",
  quotation: "报价",
  restartQuotation: "重新报价",
  quotationAdjust: "报价调整",
  selfReportAndSelfSubmit: "自报自交",
  trailersService: "拖车服务",
  customsDeclarationService: "报关服务",
  applicationForSpecialPrice: "特价申请书",
  uploadApplicationForSpecialPriceWarning: "请上传特价申请书",
  refusingBookSpace: "拒绝订舱理由",
  salesQuotation: "销售报价 ",
  kg: "千克",
  ticket: "票",
  refuse: "拒绝",
  refusalReasons: "拒绝理由",
  refusalReasonsWarning: "请输入拒绝理由",
  quotationWaitConfirmedWarning: "勾选业务包含了已报价订单，批量报价将会删除原有报价信息，按照新的报价进行提交，请确认是否继续！",
  quotationRefusalBookingWarning: "勾选业务包含被拒绝订舱业务，批量报价时将不会展示原有报价信息，请确认是否继续！",
  quotationRefusalAndConfirmedWarning: "勾选业务包含待确认和拒绝订舱的业务，批量报价会将原有报价信息删除同时不会展示原有报价信息，请确认是否继续！",
  greaterThan: "高",
  equalTo: "等于",
  lessThan: "小于",
  verify: "校验",
  productQuotation: "产品报价",
  productSeparationBubbleFirst: "产品分泡(头程)",
  productSeparationBubbleTail: "产品分泡(尾程)",
  verifyFlase: "校验不通过，是否还允许订单继续流转配舱。",
  voidContractWarning: "该客户下单的业务日期内未有合同无法进行下单操作，请联系销售人员补充合同。",
  voidContractWarning2: "该客户当前日期未有有效合同,请注意",
  applicationWriteOff: "申请核销",
  costEstimate: "费用预估",
  estimatedProfitOnSales: "预估销售利润",
  productQuotationEdit: "产品报价编辑",
  applyClearStatus: "申请核销状态",
  nothing: "无",
  cancellationReason: "取消原因",
  priceProblem: "价格问题",
  deliveryTimeProblem: "货好时间问题",
  shippingSpaceProblem: "舱位问题",
  otherProblem: "其它",
  // ________
  close: '关闭',
  upload: '上传',

  entrustmentInformation: '委托信息',

  polHold: '请输入起运港',
  polHold2: '请输入装货港',
  podHold: '请输入卸货港',
  shipBoss: '船司',
  shipBossHold: '请输入船司代码',
  shippingMethod: '运输方式',
  transshipmentInBatches: '分批/转船',
  acceptBatches: '接受分批',
  acceptTransshipment: '接受转船',
  sailingDate: '开航日期',
  SelectSailingDateHold: '请选择开航日期',
  shipName: '船名',
  shipNameHold: '请输入船名',
  voyage: '航次',

  tradeTerms: '贸易条款',
  billType: '提单类型',
  payMethod: '付款方式',
  issuingMethod: '签发方式',
  marks: '货物描述',
  marksHold: '请输入货物描述',

  selectFileToImport: '选择文件导入',
  templateDownload: '模板下载',

  shippingInformation: '船期信息',
  orderWebsite: '订舱网点',
  soNoSon: '分单号',
  polTransportTerm: '运输条款',
  shipperInformation: '货运方信息',
  contactHold: '请输入公司名称',
  street: '街道',
  streetHold: '请输入街道',
  city: '所在城市',
  pleaseEnterCity: '城市',
  countyAndDistrict: '县/区',
  provinceState: '省/州',
  countries: '国家',
  zipCode: '邮编',
  surname: '姓',
  name: '名',
  moreInfo: '更多信息',


  CAS: 'CAS号码',
  containerInformation: '集装箱信息',
  rateInformation: '费率信息',
  box: '箱型',


  isSoc: '客户自有箱',
  priceCardNumber: '约号/价卡号',


  // basicInformation: '基础信息',
  SO: '订舱号',
  boxQuantity: '箱量',
  sumCount: '总箱量',
  unboxed: '待装箱量',
  number: '件数',
  sumQuantity: '总件数',
  sumGrossWeight: '总毛重',
  sumVolume: '总体积',
  packing: '装箱',
  packingInformation: '装箱信息',
  importPackingInformation: '导入装箱信息',
  notePacking: '注意：当前SO中仍有集装箱尚未完成装箱，如需并入其他提单，请在当前页面录入装箱信息，在其他订单的制单页面中添加当前SO进行补料。',
  containerNo: '箱号',
  sealNo: '封号',
  vgmWeight: 'VGM箱货重（KGS）',
  vgmMode: '称重方式',
  overall: '整体称重',
  cumulative: '累加计算',
  packageType: '包装单位',
  grossWeight: '毛重(KGS)',
  volume: '体积(CBM) ',
  volume2: '体积',

  materialsToBeSupplemented: '待补料',
  toBeIssued: '待签发',
  issued: "已签发",
  cancelled: '已取消',

  remindWork: '作业提醒',
  carrierBlNo: '船司提单',
  memberBlNo: '客户提单',

  pleaseCheckDataIssuance: '请勾选数据进行签发',
  notToBeIssued: '请选择待签发的提单进行签发操作',
  toBeIssuedOne: '签发后订单业务信息将不可变更，是否继续？确认后标记签发',

  toBeIssuedMore: '您选择了多条订单，签发后订单业务信息将不可变更，是否继续？确认后标记签发',
  bookingSpace: '订舱',
  documentPreparation: '制单',
  replenishment: '补料',
  issue: '签发',
  issuedSuccessfully: '签发成功',
  customerBill: '客户提单',
  bookingInformation: '订舱信息',
  maintainTime: '维护船期时间',
  uploadBookingReceipt: '上传订舱回执',
  freightForwarder: '货代',
  bookingBeCareful: '注意：请及时维护船期时间以便于提醒客户和运营人员进行订单跟踪。',
  boxTypeArr: '箱型/箱量/毛重',
  transfer: '中转',
  vesselVoyage: '船名/航次',
  secondVesselVoyage: '二程船名/航次',
  TransferVessel: '中转船名',
  TransferVoyage: '中转航次',

  selectDate: '选择日期时间',
  maintainTimeDialogCareful: '注意：请谨慎维护中转港和二程信息，此信息将影响到提单内容',


  uploadPackingList: '上传装箱单',
  uploadVGMData: '上传VGM资料',
  uploadWarehouseInformation: '上传预配仓单资料',
  uploadBillDocuments: '上传预配仓单资料',
  packingInformationNote: '注意：当前SO中仍有集装箱尚未完成装箱，如需并入其他提单，请在其他订单中添加当前SO进行补料。',
  true: '是',
  false: '否',
  parameterMissing: '参数缺失',

  delegateCancellation: '委托取消后无法恢复，是否继续？',
  bookingCancel: '取消后不可恢复，是否继续？',
  dataImport: '数据导入',
  pleaseSelectAFile: '请选择一个文件',
  mega: '美加线',
  ouDi: '欧地线',
  southeastAsia: '东南亚线',
  southAmericanLine: '南美线',
  africanLine: '非洲线',
  australiaSingaporeRailway: '澳新线',
  middleEastLine: '中东线',
  lafite: '拉非',
  asiaPacific: '亚太',
  sameBoxTypeIsSelected: '选择了相同的箱型，请编辑后再提交',
  doNotExceed30000: '预估单箱货重请勿超过30000KGS',
  newOrder: '新建订单',
  importVGMInformation: '导入VGM信息',
  cancelBooking: '取消订舱',
  BlType: '提单类型',
  alertOthers: '通知人',
  unmatched: '未对单',
  printSample: '打印样单',
  invitationSingle: '邀请对单',
  billNo: '提单号',
  pointBlEdit: '提示：1、MBL的客户提单号将在船司提单号确定后同步至客户提单；',
  pointBlEdit2: '2、HBL的客户提单号将在保存后自动生成。',
  billInformation: '提单信息',
  getPackingInformation: '获取装箱信息',
  packingInformationNote2: '注意：请在装箱页面补充箱货信息，当前页面将自动带入。',

  showFee: '显示费用',
  billDetails: '提单明细',
  addBill: '新增提单',
  generateShippingBill: '生成船司提单',
  uploadBl: '上传提单',
  uploadBill: '上传提单文件',
  oneToOneStatus: '对单状态',
  replenishmentStatus: '补料状态',
  shipSBillNo: '关联船司提单号',
  customerBillNo: '关联客户提单号',

  orderStatusHasChanged: '订单状态已发生变更，请刷新后重试',
  addCustomerBill: '新增客户提单',
  addShipBossBill: '新增船司提单',
  batchExportCustomerBill: '批量导出客户提单',
  notYetSaved: '页面跳转后尚未保存的信息将丢失，如果信息已保存，请忽略此提示，是否继续？',
  returnBillDetails: '返回提单明细',

  followingFields: '当前客户提单缺少以下字段，请在后期补足以便于正确生成船司提单：',
  missingBoxInformation: '当前客户提单缺少箱货信息，请在后期补足以便于正确生成船司提单',
  hasShippingBl: '当前提单已生成船司提单，请确认所有必填项不为空',
  changeShippingBl: '变更当前客户提单内容将影响船司提单，是否继续？',
  unpackedInformationChange: '变更内容不涉及箱货信息，船司提单将不做任何变更，是否继续？',
  thereIsAChangeInTheCargoInformation: '当前提单已生成船司提单，变更箱货信息将影响船司提单，是否继续？',
  noData: '暂无数据',
  unrelatedShippingCompanyBillOfLading: '请选择尚未关联任何船司提单的客户提单进行操作',
  missingCargoInformation: '所选提单中的箱货信息或必填项缺失，请确认后重试',
  pleaseAddTheContainerInformationAndTryAgain: '当前提单箱货信息缺失无法生成船司提单，请添加箱货信息后重试',
  typeTheSameCustomerBillOfLadingToOperate: '请勾选类型一致的客户提单进行操作',
  adjustCustomerBill: '调整客户提单',
  generatedSuccessfully: '生成成功',
  canTBeRecoveredAfterDeletion: '删除后无法恢复，是否继续？',
  deleteCustomerShipBl: '将同时删除客户提单和船司提单，是否继续？',
  billOfLadingAssociatedShippingCompany: '当前提单已关联船司提单，删除后将修改船司提单内容，甚至直接删除船司提单，是否继续？',
  containerIsAssociatedWithBill: '当前集装箱已关联客户提单，保存将会变更客户提单数据，是否继续？',
  deletedContainersCannotBeRecovered: '删除集装箱将自动调整订舱信息中的箱量数据，被删除的集装箱将无法恢复，是否继续？',

  linkedOut: '当前集装箱已关联客户提单，此操作将会变更提单内容，且退关后无法恢复，是否继续？',
  unconnectedExit: '集装箱退关后无法恢复，是否继续？',
  exitSuccessfully: '退关成功',
  checkOut: '退关确认',
  wholeTicketPart: '整票/部分',
  wholeTicket: '整票',
  part: '部分',
  container: '集装箱',
  exportPackingData: '导出装箱数据',
  unboxed2: '未装箱',
  pleaseSelectAContainer: '请选择集装箱',
  whetherToContinueExporting: '当前客户提单类型为MBL，是否继续导出海特提单？',
  confirmationOfBookingChannel: '订舱渠道确认',
  bookingChannel: '订舱渠道',
  other: '其他',
  bookingMethod: '订舱方式',
  automatic: '自动',
  artificial: '人工',
  prompt: '提示',
  confirmationOfBookingChannelTips1: '1、普货支持EB渠道线上自动订舱，冷冻品和危险品将暂时沿用线下订舱模式；',
  confirmationOfBookingChannelTips2: '2、SyncHub和其他渠道暂时将沿用线下订舱模式；',
  confirmationOfBookingChannelTips3: '3、不同渠道的操作模式不同，请谨慎选择。',
  userReferenceType: '用户参考类型',
  userReferenceNumber: '用户参考号码',
  otherInformation: '其他信息',
  confirmationLetterSentByEmail: '确认函发送邮箱',
  shipChooseType: '船期选择',
  shipScheduleInquiry: '船期查询',
  estimatedVoyage: '预计船名航次',
  departureTimeDeparturePort: '起运港最早离港时间',
  departureTime: '起运时间',
  direct: '直达',
  transship: '转船',
  routeCode: '航线代码',
  andBeyond: '及后',
  withinWeek: '周内',
  firstLane: '头程航线代码',
  secondLane: '二程航线代码',
  specifyTheBillNumber: '指定提单号',
  automaticallyGeneratedAfterSaving: '保存后自动生成',
  billProcessingInstructions: '提单处理说明',
  billDistribution: '提单分发',
  bookingParty: '订舱方',
  contact: '发货人',
  freightForwarding: '货代',
  consignee: '收货人',
  notifyingParty: '通知方',
  secondNotifyingParty: '第二通知方',
  original: '正本',
  duplicate: '副本',
  shippingFee: '海运费',
  whereToPay: '付款地点',

  payer: '付款方',
  requestItem: '请求项',
  requestItemRemarks: '请求项备注',
  orderProcessingRequest: '制单处理请求',
  showOnBL: '提单备注（显示在BL上）',
  doesNotShowOnBL: '其他处理提单的指令（不显示在BL上）',
  receiptCopyReceiptEmail: '回执副本接收邮箱',
  headWayshipName: '头程船名',
  firstVoyage: '头程航次',
  secondShipName: '二程船名',
  secondVoyage: '二程航次',
  sendBooking: '发送订舱',
  saveDraft: '保存草稿',
  thisBillOfLadingNumberAlreadyExists: '此提单号已存在，如需用同一SO号签发多票，请调整指定提单号',
  bookingProgress: '订舱处理中',
  submitBooking: '提交订舱',
  submitBookingTips: '将发送订舱申请，是否继续？',
  delBookingTips: '订舱信息删除后无法恢复，是否继续？',
  bookingHasBeenSubmitted: '订舱已提交，结果确认中，请刷新后重试',
  orderStatusChanged: '订单状态已变更，请在刷新后重试',
  packingInformationAMessage: "装箱信息至少保留一条信息",
  promptRequiredFields: '提示：必填项',
  promptRequiredFields2: '未处理，请确认后再试！',
  serialNumberQueryBooking: '通过流水号自动查询订舱结果',
  serialNumberIsIncorrect: '订舱流水号格式不正确',
  characters8: '此项长度应为8个字符',
  sameConsigneeInformation: '同收货人信息',
  reasonForFailure: '失败原因',
  brazilianWoodPackagingTypes: '巴西木包装类型',
  sailTime: '开船时间',
  estimatedDeparture: '预计离港',
  transportPath: '运输路径',
  outboundLiner: '离港班轮',
  allSailingDates: '所有船期',
  shortestWay: '最短在途',
  estimatedCutOffTime: '截重时间(预估)',
  onTheWay: '在途',
  shipNameVoyageRoute: '船名/航次/航线--途径港口',
  expectedArrival: '预计到达',
  transferOnce: '中转一次',
  multipleTransfers: '中转多次',

  noShipDateInformationCanBeFound: '查询不到任何船期信息，请尝试',
  reQuery: '重新查询',
  or: '或',
  enterTheShipNameAndVoyageDirectly: '直接录入船名航次',
  day: '天',
  approachingEstimatedPortCutOffTime: '临近预估截港时间，请谨慎选择',
  weightCuttingCabinet: '截重柜',
  pleaseSelectAnyShippingDateFirst: '请先选择任意船期再提交订舱',

  preview: '预览',
  download: '下载',
  feeMigration: '费用迁移',
  sendToCustomer: '发送至客户',
  businessNo: '业务单号',
  collectionACSet: '收款账套',
  customerAddress: '客户地址',
  receivingMailbox: '接收邮箱',

  customerSettlementUnit: '客户结算单位',
  totalExpenses: '费用合计',
  mailNotes: '邮件备注',
  multipleMailboxPartitions: '多个邮箱地址之间请用；分隔',

  expenseConfirmation: '费用确认',
  applyForInvoicing: '申请开票状态',
  confirmationNo: '确认单编号',
  confirmationState: '对账状态',
  sendStatus: '发送状态',
  invoiceState: '开票状态',
  containerDesc: '箱型箱量',
  currencyAmountJson: '费用总计',
  responsiblePersonName: '开单人员',
  stringBusinessDate: '开单时间',

  confirmed: '已确认',
  rejected: '被驳回',
  sendExpenseConfirmationToCustomer: '发送费用确认单至客户',
  useTheSameReceivingMailbox: '同一客户使用相同接收邮箱',
  useSameMailNotes: '同一客户使用相同邮件备注',
  details: '详情',
  transferInConfirmation: '转入确认单',
  pleaseSelectTransferInConfirmation: '请选择转入确认单',
  newExpenseConfirmation: '新建费用确认单',
  toChangeInto: '转入',
  pleaseCheckTheNeedTo: '请勾选需要从',
  transferInTheSelectedConfirmation: '中转出的费用项，转入所选择的确认单或新建确认单',
  allExpenseItemsWillBeMigratedTo: '当前全部费用项目将迁移到其他费用确认单，当前费用确认单将被删除，请谨慎操作！',
  editExpenseConfirmation: '费用确认单编辑',
  directEmail: '生成后直接发送到客户指定邮箱',
  emptySent: '已发送',
  notSent: '未发送',
  totalExpensesReceivable: '应收费用合计',
  checkAtLeastOnePieceOfData: '至少勾选一条数据',
  onlyOnePieceOfDataCanBeChecked: '只能勾选一条数据',
  sendConfirmationToCustomer: '发送确认单至客户',
  confirmationWillBeSentAsFollows: '将按照以下地址和备注信息发送费用确认单，是否继续？',
  sendOut: '发送',
  expenseEntity: '费用主体',
  entityNumber: '主体号码',
  expenseDeletionCannotBeRestored: '费用确认单删除后无法恢复，是否继续？',
  reconciliationStatusChanged: '对账状态已变更，如需调整请联系管理人员',
  relevantExpensesHaveBeenInvoiced: '相关费用已进入开票环节，无法调整',
  distributionIsAtLeastRequired: '提单分发至少一方必填',
  cannotBeChangedAfterConfirmationConfirmationSheetInformation: '确认后无法变更确认单信息，如需在开票前修改确认单信息，请联系管理人员，是否继续？',
  createdBy: '创建人',
  receivable: '应收',
  notMaintained: '未维护',
  maintained: '已维护',
  pyable: '应付',


  newBill: '新建账单',
  consolidatedStatement: '并入账单',
  entryMethod: '入账方式',
  expenseDetails: '费用明细',
  masterOrderNumber: '主单号',


  pending: '待入账',
  hasBeenCredited: '已入账',
  Reconciled: '已对账',

  notWrittenOff: '未核销',
  writtenOff: '已核销',
  partialWriteOff: '部分核销',

  supplierSettlementUnit: '供应商结算单位',

  confirmStatus: '确认状态',
  masterNo: '主单号',
  product: '所属产品',

  ticketsToBeReceived: '待收票',
  ticketsReceived: '已收票',
  partialTicketCollection: '部分收票',

  searchConditionIsRequired: '检索条件：客户名称、结算单位、结算方式 为必填项，请填写',
  billingCycle: '账单周期',
  expenseEntry: '费用 入账',

  theFeeCannotBeModifiedAfterConfirmation: '确认后费用将无法修改，如需修改，请联系管理人员，是否继续？',
  totalExpensesPayable: '应付费用合计',
  singleBillGrossProfit: '单票毛利',
  fileName: '文件名',
  fileType: '文件类型',
  creationDate: '创建日期',
  fileSizeBbyte: '文件大小(byte)',
  fileFormat: '文件格式'


}
