var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    _vm._g(
      _vm._b(
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "uploadFile",
          staticClass: "upload-container",
          attrs: {
            action: "",
            drag: _vm.drag,
            "http-request": _vm.httpRequest,
            "before-upload": _vm.beforeUpload,
            "on-exceed": _vm.handleExceed,
            "on-remove": _vm.handleRemove,
            "on-preview": _vm.handlePreview,
            "on-progress": _vm.handleProgress,
            "before-remove": _vm.beforeRemove,
            "file-list": _vm.fileList,
            accept: _vm.accept,
            "show-file-list": _vm.isShowFileList,
            multiple: _vm.multiple,
            limit: _vm.limit,
            disabled: _vm.disabled,
          },
        },
        "el-upload",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      !_vm.drag
        ? _c(
            "div",
            [
              _vm.$slots.upload
                ? _vm._t("upload")
                : _c(
                    "el-button",
                    { attrs: { type: "primary", size: "mini" } },
                    [_vm._v(" 上传文件 ")]
                  ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.drag
        ? _c(
            "div",
            { staticClass: "preview-container" },
            [
              _vm.currentImage
                ? [
                    _c("img", {
                      staticClass: "preview-img",
                      attrs: { src: _vm.currentImage },
                    }),
                  ]
                : [
                    _c("i", { staticClass: "el-icon-upload" }),
                    _vm._v(" "),
                    _c("div", { staticClass: "el-upload__text" }, [
                      _vm._v(_vm._s(_vm.$t("common.upload.uploadTitle"))),
                      _c("em", [
                        _vm._v(
                          _vm._s(_vm.$t("common.upload.uploadClickTitle"))
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "el-upload__text" }, [
                      _vm._v(_vm._s(_vm.$t("common.upload.uploadSubTitle"))),
                    ]),
                  ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "tip" }, slot: "tip" },
        [_vm._t("uploadInnerTip")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }