import Layout from '@/layout'

export const CasManifestExporttRouterMap = {
  // 预配舱单
  'cas:manifest': {
    component: Layout,
    alwaysShow: true,
    name: 'CasManifestExport'
  },
  'cas:manifest:list': {
    component: () => import('@/views/cargo/v0/manifest/manifestList'),
    name: 'ManifestList',
    meta: {
      noCache: false
    },
    children: ['cas:manifest:list:create', 'cas:manifest:list:createCfs']
  },
  'cas:manifest:list:create': {
    path: 'Manifest/create',
    component: () => import('@/views/cargo/v0/manifest/orderDetail'),
    name: 'ManifestListOrderDetail',
    meta: { title: '舱单详情'},
    hidden: true
  },

  'cas:manifest:list:createCfs': {
    path: 'Manifest/cfs',
    component: () => import('@/views/cargo/v0/manifest/orderDetailCFS'),
    name: 'ManifestListOrderDetailCFS',
    meta: { title: '舱单详情'},
    hidden: true
  }

}
