export default {
  createByName: '任务发起人',
  taskStatus: '状态',
  statusList: ['全部', '未开始', '进行中', '已完成', '失败'],
  originFileName: '上传文件名',
  taskStatusName: '任务状态',
  handlerRes: '处理结果',
  originCloudFileName: '源文件',
  parseCloudFileName: '结果文件',
  parsingFailure: '文件解析失败',
  executeBegin: '开始时间',
  executeEnd: '结束时间',
  taskInstance: '任务名',
  source_file: '源文件',
  refresh: '刷新'
}
