/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

export const FinanceRouterMap = {
  // 账务管理
  'fin:manage': {
    component: Layout,
    alwaysShow: true,
    name: 'FinanceManager',
    meta: {
      icon: 'icon-shuzihuobi'
    }
  },
  // 提单计费
  'fin:manage:billBilling:list': {
    component: () => import('@/views/finance/billBilling/list'),
    name: 'FinanceBillBillingList',
    children: ['fin:manage:billBilling:orderDetail', 'cpm:contract:view:fak', 'cpm:contract:view:long', 'cpm:contract:view:nolong']
  },

  'fin:manage:billBilling:orderDetail': {
    path: 'financeBillBilling/orderDetail',
    component: () => import('@/views/finance/billBilling/orderDetail'),
    name: 'FinanceBillBillingOrderDetail',
    hidden: true,
    meta: { title: '订单详情' }
  },

  'cpm:contract:view:fak': {
    path: '/ContractPrice/FakView',
    component: () => import('@/views/contractPrice/fakContractDetails/view'),
    name: 'fakContractView',
    hidden: true,
    meta: { title: '约价FAK详情' },
    roles: []
  },
  'cpm:contract:view:long': {
    path: '/ContractPrice/LongView',
    component: () => import('@/views/contractPrice/longContractDetails/view'),
    name: 'longContractView',
    hidden: true,
    meta: { title: '约价长期详情' },
    roles: []
  },
  'cpm:contract:view:nolong': {
    path: '/ContractPrice/NoLongView',
    component: () => import('@/views/contractPrice/noLongContractDetails/view'),
    name: 'noLongContractView',
    hidden: true,
    meta: { title: '约价非长期详情' },
    roles: []
  },
  // 应收明细列表
  'fin:manage:receiveConfirmation:list': {
    component: () => import('@/views/finance/receiveConfirmation/list'),
    name: 'FinanceReceiveConfirmationList'
  }
}
