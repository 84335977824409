var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom-end",
            title: "",
            width: "200",
            trigger: "click",
          },
        },
        [
          _c("div", { staticClass: "notification-container" }, [
            _c("div", { staticClass: "avator-wrap" }, [
              _c("img", {
                attrs: { src: require("@/assets/userAvator2.png"), alt: "" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "name-show" }, [
                _vm._v(_vm._s(this.userInfo.chineseName)),
              ]),
            ]),
            _vm._v(" "),
            _c("ul", [
              _c(
                "li",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "iconfont icon-security" },
                      on: { click: _vm.redirectChangePassword },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.navbar.changePassword")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "iconfont icon-paperclip" },
                      on: { click: _vm.redirectSubscribeList },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.navbar.subscribeConfig")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "iconfont icon-paperplane" },
                      on: { click: _vm.redirectReceiver },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.navbar.messageSender")))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "iconfont icon-power" },
                      on: { click: _vm.logout },
                    },
                    [_vm._v(_vm._s(_vm.$t("common.navbar.logOut")))]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "notification-btn",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c(
                "div",
                { staticClass: "test", staticStyle: { position: "relative" } },
                [_c("i", { staticClass: "iconfont icon-quanxian top-account" })]
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }