var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "inputNumber",
      staticClass: "xtt-input-number",
      class: { "xtt-input-number--group": _vm.$slots.append },
    },
    [
      _c(
        "div",
        {
          class: [
            "el-input-number",
            _vm.inputNumberSize
              ? "el-input-number--" + _vm.inputNumberSize
              : "",
            { "is-disabled": _vm.inputNumberDisabled },
            { "is-without-controls": !_vm.controls },
            { "is-controls-right": _vm.controlsAtRight },
          ],
          on: {
            dragstart: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _vm.controls
            ? _c(
                "span",
                {
                  staticClass: "el-input-number__decrease",
                  class: { "is-disabled": _vm.minDisabled },
                  attrs: { role: "button" },
                  on: {
                    click: _vm.decrease,
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.decrease($event)
                    },
                  },
                },
                [
                  _c("i", {
                    class:
                      "el-icon-" +
                      (_vm.controlsAtRight ? "arrow-down" : "minus"),
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.controls
            ? _c(
                "span",
                {
                  staticClass: "el-input-number__increase",
                  class: { "is-disabled": _vm.maxDisabled },
                  attrs: { role: "button" },
                  on: {
                    click: _vm.increase,
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.increase($event)
                    },
                  },
                },
                [
                  _c("i", {
                    class:
                      "el-icon-" + (_vm.controlsAtRight ? "arrow-up" : "plus"),
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("el-input", {
            ref: "input",
            class: [_vm.textRight ? "text-right" : ""],
            attrs: {
              placeholder: _vm.placeholder,
              disabled: _vm.inputNumberDisabled,
              size: _vm.inputNumberSize,
              max: _vm.max,
              min: _vm.min,
              name: _vm.name,
              label: _vm.label,
              readonly: _vm.readonly,
              type: "number",
              title: "",
            },
            on: {
              blur: _vm.handleBlur,
              focus: _vm.handleFocus,
              change: _vm.handleChange,
              input: function ($event) {
                return _vm.handleInput(_vm.userInput)
              },
            },
            nativeOn: {
              keydown: [
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.increase($event)
                },
                function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.decrease($event)
                },
              ],
            },
            model: {
              value: _vm.userInput,
              callback: function ($$v) {
                _vm.userInput = $$v
              },
              expression: "userInput",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$slots.append
        ? _c(
            "div",
            { staticClass: "input-number__append" },
            [_vm._t("append")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }